import Button from "components/ui/Button/Button";
import Modal from "components/ui/Modal/Modal";
import { useTourContext } from "components/ui/Tour/Context";
import EmailHeader from "components/ui/scenarios/Email/Header";
import { GET_STARTED_EMAIL_ID, GET_STARTED_VIDEO_URLS, MOTHERLOAD_CHEAT } from "constants/scenario";
import { getEmail } from "helpers/getters";
import useBundle from "hooks/useBundle";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { FC, RefObject, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { selectHasStartedTest, startTest } from "redux/modules/scenario";
import { GAEventName, fireGAEvent } from "utils/gtag";
export interface IProps {
  scrollBoxRef: RefObject<HTMLDivElement>;
}

const GetStartedEmailDetail: FC<IProps> = props => {
  const { data } = useBundle();
  const { scrollBoxRef } = props;
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const hasStartedTest = useAppSelector(selectHasStartedTest);
  const [isEndTourModalOpen, setIsEndTourModalOpen] = useState(false);
  const [isTourPromptModalOpen, setIsTourPromptModalOpen] = useState(true);

  const hasCompletedWalkthrough = sessionStorage.getItem("hasCompletedWalkthrough") === "true";
  const hasCompletedTestBefore = MOTHERLOAD_CHEAT ? true : !data?.firstTime;

  const { setIsOpen: setIsTourOpen } = useTourContext({
    onTourFinish: () => setIsEndTourModalOpen(true)
  });

  const handleStartTest = () => {
    fireGAEvent({ name: GAEventName.TestStart });

    dispatch(startTest());
  };

  const handleStartGuide = () => {
    scrollBoxRef.current?.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });

    setIsTourOpen(true);
  };

  const handleCloseWalkthroughPrompt = () => {
    sessionStorage.setItem("hasCompletedWalkthrough", "true");
    setIsTourPromptModalOpen(false);
  };

  const handleStartWalkthrough = () => {
    handleCloseWalkthroughPrompt();
    handleStartGuide();
  };

  const videoSrc = useMemo(() => {
    if (!data?.scenario) return GET_STARTED_VIDEO_URLS.IT_MANAGER;
    switch (data?.scenario) {
      case "IT_MANAGER":
        return GET_STARTED_VIDEO_URLS.IT_MANAGER;
      case "HR_ASSISTANT":
        return GET_STARTED_VIDEO_URLS.HR_ASSISANT;
      case "PROCUREMENT":
        return GET_STARTED_VIDEO_URLS.PROCUREMENT;
    }
  }, [data]);

  return (
    <>
      <EmailHeader
        className="mb-14"
        emailId={GET_STARTED_EMAIL_ID}
        from={{ name: intl.formatMessage({ id: "scenarios.administrator" }) }}
        date={new Date()}
      />

      <h1 className="header-1000 mb-10">
        <FormattedMessage id="scenarios.inbox.get.started.email.subject" />
      </h1>

      <h2 className="header-600 mb-9">
        <FormattedMessage id="scenarios.inbox.get.started.email.subject.2" />
      </h2>

      <div className="mb-9">
        <video controls>
          <source src={videoSrc} type="video/mp4" />
        </video>
      </div>

      <div className="mb-9 flex flex-col gap-y-5">
        <FormattedMessage
          id={`scenarios.inbox.get.started.email.body.${data?.scenario}`}
          values={{
            p: chunks => <p className="body-800">{chunks}</p>,
            b: chunks => <b>{chunks}</b>,
            li: chunks => <li>{chunks}</li>,
            email: getEmail(data?.scenario)
          }}
        />
      </div>

      <div className="flex justify-between">
        <Button
          variant="secondary"
          onClick={() => {
            fireGAEvent({ name: GAEventName.ViewToolTip });

            handleStartGuide();
          }}
          disabled={hasStartedTest}
        >
          <FormattedMessage id="scenarios.inbox.get.started.email.view.guide" />
        </Button>

        <Button
          onClick={() => {
            fireGAEvent({ name: GAEventName.StartTestEmail });

            handleStartTest();
          }}
          disabled={hasStartedTest}
        >
          <FormattedMessage id="scenarios.inbox.get.started.email.view.start" />
        </Button>
      </div>

      {/* Will open when the user has finished the "Tour" */}
      <Modal
        className="max-w-[805px] !p-15"
        title={intl.formatMessage({ id: "scenarios.tour.end.modal.title" })}
        open={isEndTourModalOpen}
        onClose={() => setIsEndTourModalOpen(false)}
      >
        <div className="mt-9">
          <div className="mb-15">
            <FormattedMessage
              id="scenarios.tour.end.modal.desc"
              values={{
                p: chunks => <p className="body-900 mb-9">{chunks}</p>,
                b: chunks => <b>{chunks}</b>
              }}
            />
          </div>

          <div className="flex justify-between">
            <Button
              variant="secondary"
              onClick={() => {
                fireGAEvent({ name: GAEventName.RestartTooltip });
                setIsEndTourModalOpen(false);
              }}
            >
              <FormattedMessage id="scenarios.inbox.get.started.email.close.guide" />
            </Button>
            <Button
              onClick={() => {
                fireGAEvent({ name: GAEventName.StartTestTooltip });
                setIsEndTourModalOpen(false);
                handleStartTest();
              }}
            >
              <FormattedMessage id="scenarios.inbox.get.started.email.view.start" />
            </Button>
          </div>
        </div>
      </Modal>

      {/* To open on page load based on whether tour was completed */}
      {!hasCompletedWalkthrough && (
        <Modal
          className="max-w-[872px] !p-[2.25rem]"
          title={
            hasCompletedTestBefore
              ? intl.formatMessage({ id: "scenarios.tour.prompt.modal.return.title" })
              : intl.formatMessage({ id: "scenarios.tour.prompt.modal.title" })
          }
          open={isTourPromptModalOpen}
          onClose={() => {
            setIsEndTourModalOpen(false);
          }}
        >
          <div className="mt-9">
            <div className="mb-15 whitespace-pre-line">
              <FormattedMessage
                id={
                  hasCompletedTestBefore
                    ? "scenarios.tour.prompt.modal.return.desc"
                    : "scenarios.tour.prompt.modal.desc"
                }
              />
            </div>

            {hasCompletedTestBefore ? (
              <div className="flex justify-center gap-9">
                <Button className="px-9" variant="secondary" onClick={() => handleStartWalkthrough()}>
                  <FormattedMessage id="scenarios.tour.prompt.CTA.start" />
                </Button>
                <Button className="px-9" onClick={() => handleCloseWalkthroughPrompt()}>
                  <FormattedMessage id="scenarios.tour.prompt.CTA.skip" />
                </Button>
              </div>
            ) : (
              <Button className="mx-auto" variant="primary" onClick={() => handleStartWalkthrough()}>
                <FormattedMessage id="scenarios.tour.prompt.CTA.begin" />
              </Button>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default GetStartedEmailDetail;

import Button from "components/ui/Button/Button";
import Input from "components/ui/Form/Input/Input";
import { TResetPasswordInputs } from "pages/admin/auth/reset-password-flow/ImplementerResetPassword";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import useFireGAEventOnMount from "hooks/useFireGAEventOnMount";
import { GAEventName } from "utils/gtag";

export interface IProps {}

const EmailForm: FC<IProps> = props => {
  const methods = useFormContext<TResetPasswordInputs>();

  // i18n
  const intl = useIntl();

  useFireGAEventOnMount({ name: GAEventName.ScreenView, param: "Implementer - Forgot Password" });

  return (
    <>
      <h1 className="header-900 mb-9">
        <FormattedMessage id="admin.forgotten.password.page.title" />
      </h1>

      <p className="body-900 mb-8">
        <FormattedMessage id="admin.forgotten.password.page.desc" />
      </p>

      <p className="body-900 mb-9">
        <FormattedMessage id="admin.forgotten.password.page.org.code" />
      </p>

      <Input
        wrapperClassName="mb-9"
        className="mx-auto max-w-[280px]"
        errorClassName="text-center"
        label={intl.formatMessage({ id: "general.email" })}
        hideLabel
        placeholder={intl.formatMessage({ id: "general.enter.email" })}
        required
        {...methods.register("email")}
      />

      <Button type="submit" className="mx-auto">
        <FormattedMessage id="general.continue" />
      </Button>
    </>
  );
};

export default EmailForm;

import { Dialog } from "@headlessui/react";
import { TooltipItem } from "chart.js";
import classNames from "classnames";
import LoadingWrapper from "components/ui/Loading/LoadingWrapper";
import { ModalWrapper as Modal } from "components/ui/Modal/Modal";
import Percentage from "components/ui/Percentage/Percentage";
import StackedBarChart from "components/ui/admin/Charts/StackedBarChart";
import colours from "components/ui/admin/Charts/constants/colours";
import { barStyle } from "components/ui/admin/Charts/constants/style";
import AttendanceScores from "components/ui/admin/Dashboard/AttendanceScores";
import EmptyResult from "components/ui/admin/Dashboard/EmptyResult";
import ActionBar from "components/ui/admin/Elements/ActionBar";
import Card from "components/ui/admin/Elements/Card";
import StatCard from "components/ui/admin/Elements/StatCard";
import ResultsEmailPreview from "components/ui/admin/PhishingEmail/EmailPreview";
import { useTestControllerImplementerTest, useTestControllerImplementerViewTest } from "generated/api/apiComponents";
import { EmailScoresDto } from "generated/api/apiSchemas";
import useFireGAEventOnMount from "hooks/useFireGAEventOnMount";
import WorkstyleExplainerModal from "pages/admin/dashboard/components/WorkstyleExplainerModal";
import { FC, useMemo, useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { GAEventName } from "utils/gtag";
import ParticipantTestTable from "../components/ParticipantTestTable";

export interface IProps {}

const getSessionEmailResults = (intl: IntlShape, emailScores?: EmailScoresDto[]) => {
  if (!emailScores) return;

  if (emailScores.every(email => !email.amber && !email.green && !email.red)) return;

  const labels = emailScores.map((item, index) =>
    intl.formatMessage({ id: "admin.sessions.details.email" }, { number: index + 1 })
  );

  const green = emailScores.map(item => item.green);
  const red = emailScores.map(item => item.red);
  const amber = emailScores.map(item => item.amber);

  return {
    labels,
    datasets: [
      {
        label: intl.formatMessage({ id: "admin.dashboard.doughnut.notIdentified" }),
        data: red,
        backgroundColor: colours.red,
        ...barStyle
      },
      {
        label: intl.formatMessage({ id: "admin.dashboard.doughnut.identifiedIgnored" }),
        data: amber,
        backgroundColor: colours.amber,
        ...barStyle
      },
      {
        label: intl.formatMessage({ id: "admin.dashboard.doughnut.identified" }),
        data: green,
        backgroundColor: colours.green,
        ...barStyle
      }
    ]
  };
};

export const PopoverContent = ({
  dataset,
  raw
}: {
  dataset: TooltipItem<"bar">["dataset"] | TooltipItem<"doughnut">["dataset"];
  raw: string;
}) => {
  // Get type based on colour.
  let type: string = "";

  switch (dataset.backgroundColor) {
    case colours.red:
      type = "notIdentified";
      break;
    case colours.green:
      type = "identified";
      break;

    case colours.amber:
      type = "identifiedIgnored";
      break;
  }

  return (
    <>
      <p className="header-800 text-center text-primary-700">
        <FormattedMessage id={`admin.dashboard.chart.${type}`} values={{ count: raw }} />
      </p>
      <div className="mt-6 bg-primary-300 p-4 text-center">
        <p className="header-400">
          <FormattedMessage id={`admin.dashboard.chart.${type}.help`} values={{ count: raw }} />
        </p>
      </div>
    </>
  );
};

const TestDetailsPage: FC<IProps> = props => {
  const [showModal, setShowModal] = useState(false);
  const [selectedEmailId, setSelectedEmailId] = useState<string>();

  // i18n
  const intl = useIntl();

  // Router
  const { sessionId } = useParams<{ sessionId: string }>();

  useFireGAEventOnMount({ name: GAEventName.ScreenView, param: "Implementer - Tests (Individual View)" });

  // Query
  const { data, isLoading } = useTestControllerImplementerViewTest(
    {
      pathParams: {
        // INFO: Casted as string as on enabled if not null
        id: sessionId as string
      }
    },
    {
      enabled: !!sessionId
    }
  );

  const { data: testData } = useTestControllerImplementerTest(
    {
      pathParams: {
        id: sessionId as string
      }
    },
    {
      enabled: !!sessionId
    }
  );

  const selectedEmail = useMemo(() => {
    return testData?.emails.find(email => email.id === selectedEmailId);
  }, [selectedEmailId, testData?.emails]);

  const chartData = useMemo(() => getSessionEmailResults(intl, data?.emailScores), [data?.emailScores, intl]);
  return (
    <>
      <LoadingWrapper isLoading={isLoading && !data}>
        <ActionBar titleProps={{ id: "admin.sessions.details.title", values: { name: data?.name } }} />

        <div className="mb-3 flex w-full flex-col gap-3 pt-9 md:flex-row">
          <Card
            className="flex-1"
            titleProps={{ id: "admin.sessions.details.responses", values: { name: data?.name } }}
          >
            <div className="flex flex-col gap-9 md:flex-row">
              <StatCard
                backgroundImage="total-sessions-sent"
                titleProps={{ id: "admin.sessions.details.invited", values: { limit: data?.maxTrainees } }}
                value={data?.maxTrainees}
                className="min-w-[100px] flex-1 md:min-w-[180px]"
              />
              <StatCard
                backgroundImage="total-responses"
                titleProps={{
                  id: "admin.sessions.details.sessionResponses",
                  values: { limit: data?.completedTrainees }
                }}
                value={data?.completedTrainees}
                className="min-w-[100px] flex-1 md:min-w-[180px]"
              />
            </div>
          </Card>
          <Card
            className="flex-1"
            titleProps={{ id: "admin.dashboard.scores" }}
            cta={{ textProps: { id: "general.whatDoesThisMean" }, onClick: () => setShowModal(true) }}
          >
            <div className="flex flex-col gap-9 md:flex-row">
              <StatCard
                backgroundImage="workstyle-score"
                titleProps={{ id: "admin.dashboard.workstyleScore" }}
                value={<Percentage value={data?.workAccuracy ?? 0} />}
                className="min-w-[100px] flex-1 md:min-w-[180px]"
              />
              <StatCard
                backgroundImage="phishing-score"
                titleProps={{ id: "admin.dashboard.phishingScore" }}
                value={<Percentage value={data?.phishingRate ?? 0} />}
                className="min-w-[100px] flex-1 md:min-w-[180px]"
              />
            </div>
          </Card>
        </div>
        <Card className="mb-3 w-full" titleProps={{ id: "admin.dashboard.completionRate" }}>
          <div className="bg-primary-300 p-8">
            <AttendanceScores
              totalCompleted={data?.completedTrainees}
              totalInvited={data?.maxTrainees}
              totalCompletedLabel={intl.formatMessage({ id: "admin.dashboard.attendance.scores.total.completed" })}
              totalInvitedLabel={intl.formatMessage({ id: "admin.dashboard.attendance.scores.total.invited" })}
            />
          </div>
        </Card>
        <Card
          className={classNames("mb-3 flex w-full flex-col", chartData ? "min-h-[554px]" : "h-[260px]")}
          titleProps={{ id: "admin.dashboard.overallPhishingResults" }}
        >
          <div className="grow">
            {chartData ? (
              <StackedBarChart
                chartProps={{ data: chartData }}
                getTooltipContent={args => <PopoverContent {...args} />}
                onYAxisClick={i => {
                  setSelectedEmailId(data?.emailScores?.[i]?.id);
                }}
              />
            ) : (
              <EmptyResult />
            )}
          </div>
          <Modal
            open={!!selectedEmail}
            onClose={() => {
              setSelectedEmailId(undefined);
            }}
          >
            <div className="fixed inset-0 flex items-center justify-center p-4">
              <Dialog.Panel>
                <ResultsEmailPreview
                  scenario={data?.scenario}
                  className="max-w-[742px] bg-white"
                  subject={selectedEmail?.subject}
                  body={selectedEmail?.body}
                  sender={selectedEmail?.sender}
                  cc={selectedEmail?.cc}
                />
              </Dialog.Panel>
            </div>
          </Modal>
          {chartData && (
            <p className="body-600 mt-auto">
              <FormattedMessage id="admin.sessions.details.chart.help" />
            </p>
          )}
        </Card>
        <Card className="mb-3 w-full">
          {sessionId && <ParticipantTestTable testId={sessionId} testName={data?.name} />}
        </Card>
      </LoadingWrapper>
      <WorkstyleExplainerModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default TestDetailsPage;

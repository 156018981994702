import classNames from "classnames";
import { ComponentProps, FC } from "react";
import { FormattedMessage } from "react-intl";

type TProps = ComponentProps<typeof FormattedMessage>;

export interface ITermsSection {
  title?: TProps;
  text?: TProps;
  level?: 1 | 2;
  sections?: ITermsSection[];
  table?: {
    headers: {
      id: string;
    }[];
    rows: {
      ids: string[];
    }[];
  };
}

interface IProps extends ITermsSection {
  className?: string;
}

const TermsSection: FC<IProps> = ({ title, text, table, level = 1, sections, className }) => {
  const HeaderElement: keyof JSX.IntrinsicElements = `h${level}`;

  return (
    <section className={className}>
      {title && (
        <HeaderElement className={classNames(level === 1 ? "header-900" : "header-800", "mb-7")}>
          <FormattedMessage {...title} />
        </HeaderElement>
      )}
      {text && (
        <p className="body-900 whitespace-pre-line">
          <FormattedMessage {...text} />
        </p>
      )}
      {table && (
        <table className="mb-7 w-full">
          <thead className="sr-only">
            <tr>
              {table.headers.map(header => (
                <th key={header.id}>
                  <FormattedMessage id={header.id} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {table.rows.map((row, index) => (
              <tr key={index}>
                {row.ids.map((id, cellIndex) => (
                  <td key={id} className={classNames(cellIndex === 0 && "!font-bold", "body-900")}>
                    <FormattedMessage id={id} />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {sections?.map((term, index) => {
        return <TermsSection key={index} {...term} className={classNames(index < sections.length - 1 && "mb-15")} />;
      })}
    </section>
  );
};

export default TermsSection;

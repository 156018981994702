import { FC, PropsWithChildren } from "react";
import { RadioGroup } from "@headlessui/react";
import { useFormContext, useController } from "react-hook-form";

interface IProps {
  label: string;
  options: { value: string | boolean; label: string }[];
  name: string;
  className?: string;
}

/** A heaadless-ui radio input */
const RadioGr: FC<PropsWithChildren<IProps>> = ({ label, options, name, className }) => {
  const { control } = useFormContext();
  const { field } = useController({ name: name, control: control });

  return (
    <RadioGroup value={field.value} onChange={field.onChange} className="flex flex-col gap-y-5">
      <RadioGroup.Label className="">{label}</RadioGroup.Label>
      {options?.map((opt, i) => {
        return (
          <RadioGroup.Option
            key={`${i}-${opt.label}`}
            value={opt.value}
            className={({ checked }) =>
              `${checked ? "border-primary-500" : ""}
                    border-xl relative flex cursor-pointer rounded-xl border border-black px-5 py-4 focus:outline-none`
            }
          >
            {({ checked }) => (
              <div className="flex w-full items-center justify-between">
                <div className="flex items-center">
                  <div className="text-sm">
                    <RadioGroup.Label as="p" className="font-medium">
                      {opt.label}
                    </RadioGroup.Label>
                  </div>
                </div>

                <div
                  className={`${
                    checked ? "border-primary-500" : ""
                  } relative flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border border-black focus:outline-none`}
                >
                  <span className={`${checked ? "bg-primary-500" : "bg-white"} relative flex h-5 w-5 rounded-full`} />
                </div>
              </div>
            )}
          </RadioGroup.Option>
        );
      })}
    </RadioGroup>
  );
};

export default RadioGr;

import useBundle from "hooks/useBundle";
import { FC, PropsWithChildren, useMemo } from "react";
import HallMark from "./Hallmark";

interface IProps {
  hallmarkId: string;
  onClick?: (hallmarkId: string) => void;
}

const TraineeHallMark: FC<PropsWithChildren<IProps>> = ({ children, hallmarkId, onClick }) => {
  const { data } = useBundle();

  const hallmark = useMemo(
    () => data?.hallmarks.find(({ id }: { id: string }) => id === hallmarkId),
    [data?.hallmarks, hallmarkId]
  );

  return (
    <HallMark hallmark={hallmark} onClick={onClick}>
      {children}
    </HallMark>
  );
};

export default TraineeHallMark;

import { FC, PropsWithChildren, useMemo } from "react";
import { FormattedNumber } from "react-intl";

interface IProps {
  /**
   * Percentage value (as a decimal)
   */
  value: number;
  className?: string;
}

const Percentage: FC<PropsWithChildren<IProps>> = ({ value, className }) => {
  const percent = useMemo(() => value * 100, [value]);

  return (
    <span className={className}>
      <FormattedNumber value={percent} minimumFractionDigits={0} maximumFractionDigits={2} />%
    </span>
  );
};

export default Percentage;

import { useState } from "react";
import { FormattedMessage } from "react-intl";
import Button from "../../Button/Button";
import LinkBtn from "components/ui/LinkBtn/LinkBtn";

export default function Scribble() {
  const [buttonClicked, setButtonClicked] = useState(false);
  const [currentLoad, setCurrentLoad] = useState(0);

  if (buttonClicked) {
    setTimeout(() => {
      if (currentLoad < 348) {
        setCurrentLoad(currentLoad + 1);
      }
    }, 10);
  }

  return (
    <div>
      {buttonClicked ? (
        <div className="flex flex-col space-y-4 pt-16 text-center">
          <h1 className="pb-4 text-4xl">
            <FormattedMessage id="scenarios.web.scribble.heading" />
          </h1>
          {currentLoad < 348 ? (
            <div className="relative mx-auto w-[348px] pb-4">
              <div id="progress-bar" className="absolute h-4 w-[348px] rounded-md bg-slate-600" />
              <div
                id="progress"
                className="absolute h-4 rounded-md bg-pink-500"
                style={{ width: `${currentLoad}px` }}
              />
            </div>
          ) : (
            <>
              <h1 className="text-2xl text-white">
                <FormattedMessage id="scenarios.web.download.complete" />
              </h1>
              <p>
                <FormattedMessage
                  id="scenarios.web.complete.message"
                  values={{ type: "Downloading", name: "Scribble" }}
                />
              </p>
              <LinkBtn to="../" className="mx-auto rounded-xl border-0 bg-[#4A5567] text-white">
                <FormattedMessage id="general.close" />
              </LinkBtn>
            </>
          )}
        </div>
      ) : (
        <div className="flex items-start justify-center pt-16">
          <img className="w-[300px]" src="/web-items/scribble.png" alt="A Scribble" />
          <div className="ml-4 flex flex-col items-start space-y-4">
            <h1 className="pb-4 text-2xl">
              <FormattedMessage id="scenarios.web.scribble.title" />
            </h1>
            <ul className="list-disc pl-4 uppercase">
              <li>
                <FormattedMessage id="scenarios.web.scribble.list.1" />
              </li>
              <li>
                <FormattedMessage id="scenarios.web.scribble.list.2" />
              </li>
              <li>
                <FormattedMessage id="scenarios.web.scribble.list.3" />
              </li>
              <li>
                <FormattedMessage id="scenarios.web.scribble.list.4" />
              </li>
              <li>
                <FormattedMessage id="scenarios.web.scribble.list.5" />
              </li>
            </ul>
            <Button
              onClick={() => setButtonClicked(true)}
              className="rounded-md border-0 bg-[#4A5567] px-8 py-2 text-white"
            >
              <FormattedMessage id="scenarios.web.download" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

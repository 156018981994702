import * as yup from "yup";

yup.setLocale({
  mixed: {
    default: values => ({ id: "errors.mixed.default", values }),
    notType: values => ({ id: "errors.mixed.notType", values }),
    required: () => ({ id: "errors.mixed.required" })
  },
  number: {
    min: ({ min }) => ({ id: "errors.number.min", values: { min } }),
    max: ({ max }) => ({ id: "errors.number.max", values: { max } })
  },
  string: {
    min: ({ min }) => ({ id: "errors.string.min", values: { min } }),
    max: ({ max }) => ({ id: "errors.string.max", values: { max } }),
    email: () => ({ id: "errors.string.email" })
  }
});

export default yup;

import { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

interface IProps {
  children: ReactNode;
  detail: "from" | "to" | "subject" | "date";
}

const EmailPopoverDetail = ({ detail, children }: IProps) => {
  return (
    <p className="flex">
      <span className="body-1000 mr-1.5">
        <FormattedMessage id={`scenarios.inbox.popover.${detail}`} />
      </span>
      <span className="body-500 mr-1">{children}</span>
    </p>
  );
};

export default EmailPopoverDetail;

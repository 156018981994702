import Modal from "components/ui/scenarios/Files/Modal";
import useBundle from "hooks/useBundle";
import { FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

export interface IProps {}

const FileDetails: FC<IProps> = () => {
  const { data } = useBundle();
  const { fileId } = useParams<{ fileId: string }>();
  const navigate = useNavigate();

  const file = useMemo(() => data?.files.find(i => i.id === fileId), [data, fileId]);

  return (
    <Modal
      file={file?.url ?? ""}
      visible={true}
      setVisible={visible => {
        if (!visible) navigate("../");
      }}
    />
  );
};

export default FileDetails;

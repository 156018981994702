import LinkBtn from "components/ui/LinkBtn/LinkBtn";
import useGetSessionResults from "hooks/useGetSessionResults";
import WorkStyles from "pages/scenario/results/work-style-feedback/components/WorkStyles";
import { FC, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useEffectOnce } from "react-use";
import FinalEmailContext from "state-management/contexts/finalEmailContext";
import { GAEventName, fireGAEvent } from "utils/gtag";

export interface IProps {}

const WorkStyleFeedbackResults: FC<IProps> = props => {
  const { data } = useGetSessionResults();
  const { finalEmail } = useContext(FinalEmailContext);

  useEffectOnce(() => {
    fireGAEvent({ name: GAEventName.ScreenView, param: "Testee - Work Style Feeback" });
  });

  let areAllScoreAbove75 = true;
  // Assume true, if any are found to be lower than 75% we assumed wrong!
  for (const score of Object.values(data?.scores ?? {})) {
    if (parseFloat(score) < 0.75) {
      areAllScoreAbove75 = false;
      break;
    }
  }

  return (
    <div>
      <h1 className="header-900 mb-9">
        <FormattedMessage id="scenarios.results.work.style.feedback.title" />
      </h1>

      <p className="body-900 mb-9">
        <FormattedMessage id="scenarios.results.work.style.feedback.introduction" />
      </p>

      <p className="body-900 mb-9">
        <FormattedMessage id="scenarios.results.work.style.feedback.disclaimer" />
      </p>

      {areAllScoreAbove75 && (
        <p className="body-900 mb-9">
          <FormattedMessage id="scenarios.results.work.style.feedback.desc.high" />
        </p>
      )}

      <WorkStyles scores={data?.scores} />

      <FormattedMessage
        id="scenarios.results.work.style.feedback.desc.bottom"
        values={{
          p: chunks => <p className="body-900 mb-9">{chunks}</p>,
          br: () => <br />
        }}
      />

      <div className=" flex justify-between">
        <LinkBtn to={`../phishing-emails/${finalEmail.finalEmailId}`}>
          <FormattedMessage id="scenarios.results.work.style.feedback.back" />
        </LinkBtn>
        <LinkBtn to="../share-info">
          <FormattedMessage id="scenarios.results.work.style.feedback.finish" />
        </LinkBtn>
      </div>
    </div>
  );
};

export default WorkStyleFeedbackResults;

import classNames from "classnames";
import Avatar from "components/ui/scenarios/Email/Avatar";
import DOMPurify from "dompurify";
import { PhishingEmail, Scenario } from "generated/api/apiSchemas";
import parse, { DOMNode, Element, HTMLReactParserOptions, domToReact } from "html-react-parser";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import ImplementerHallmark from "./ImplementerHallmark";
import TraineeHallMark from "./TraineeHallmark";

export interface IProps {
  className?: string;
  phishingRate?: "emails-bad" | "emails-okay" | "emails-good";
  scenario?: Scenario;
  subject?: PhishingEmail["subject"];
  sender?: PhishingEmail["sender"];
  body?: PhishingEmail["body"];
  cc?: PhishingEmail["cc"];
  onHallmarkClick?: (hallmarkId: string) => void;
}

const ResultsEmailPreview: FC<IProps> = props => {
  const { className, subject, sender, body, phishingRate = "emails-bad", onHallmarkClick, scenario, cc } = props;

  const sanitizeAndParseInput = (input: string) => {
    const sanitizedString: string = DOMPurify.sanitize(input, { ADD_TAGS: ["hallmark"] });

    const options: HTMLReactParserOptions = {
      replace(domNode) {
        if (domNode instanceof Element && domNode.tagName === "hallmark") {
          const children = domNode.children as DOMNode[];
          if (scenario) {
            return (
              <ImplementerHallmark scenario={scenario} hallmarkId={domNode.attribs.id} onClick={onHallmarkClick}>
                {domToReact(children, options)}
              </ImplementerHallmark>
            );
          } else {
            return (
              <TraineeHallMark hallmarkId={domNode.attribs.id} onClick={onHallmarkClick}>
                {domToReact(children, options)}
              </TraineeHallMark>
            );
          }
        }
      }
    };

    return parse(sanitizedString, options);
  };

  return (
    <div
      className={classNames(
        className,
        "rounded-xl border-t-[11px] px-8 pb-8 pt-5 shadow-light-shadow",
        phishingRate === "emails-good" && "border-[#1E7200]",
        phishingRate === "emails-okay" && "border-[#FFAA05]",
        phishingRate === "emails-bad" && "border-error-500"
      )}
    >
      <h3 className="header-700 mb-5">{subject && sanitizeAndParseInput(subject)}</h3>

      <div className="mb-6 flex">
        <Avatar size="sm" name={DOMPurify.sanitize(sender?.name ?? "")} />

        <div className="ml-3">
          <div className="leading-none">
            <span className="body-700 mr-1.5">{sender?.name && sanitizeAndParseInput(sender.name)}</span>
            <span className="body-600">
              {"<"}
              {sender?.email && sanitizeAndParseInput(sender.email)}
              {">"}
            </span>
          </div>
          {cc && <p className="body-600">CC: {sanitizeAndParseInput(cc.email)}</p>}
          <p className="body-600">
            <FormattedMessage id="scenarios.results.phishing.emails.to.you" />
          </p>
        </div>
      </div>

      <div className="body-600 flex flex-col gap-y-5 rounded-lg bg-primary-300 px-4 py-3">
        {body && sanitizeAndParseInput(body)}
      </div>
    </div>
  );
};

export default ResultsEmailPreview;

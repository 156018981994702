import { ErrorMessage } from "@hookform/error-message";
import classNames from "classnames";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";

interface IProps {
  name: string;
  className?: string;
}

/** General styled error message for inputs. */
const InputError: FC<IProps> = ({ name, className }) => {
  // form
  const {
    formState: { errors }
  } = useFormContext();

  return (
    <ErrorMessage
      name={name}
      errors={errors}
      render={({ message }: any) => (
        <p className={classNames(className, "body-700 mt-4 text-left text-error-500")}>
          {typeof message === "string" ? message : <FormattedMessage {...message} />}
        </p>
      )}
    />
  );
};

export default InputError;

import { useQueryClient } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import classNames from "classnames";
import Button from "components/ui/Button/Button";
import LinkBtn from "components/ui/LinkBtn/LinkBtn";
import LoadingWrapper from "components/ui/Loading/LoadingWrapper";
import Modal, { ModalButtonContainer } from "components/ui/Modal/Modal";
import Table, { TABLE_ACTION_CELL_SIZE, TableEmpty } from "components/ui/Table/Table";
import ActionBar from "components/ui/admin/Elements/ActionBar";
import Card from "components/ui/admin/Elements/Card";
import BinFillIcon from "components/ui/icons/BinFill";
import EyeFillIcon from "components/ui/icons/EyeFill";
import { ToastType, useToastContext } from "context/toast.provider";
import {
  useTestControllerImplementerDeleteTest,
  useTestControllerImplementerGetAllTests
} from "generated/api/apiComponents";
import { useApiContext } from "generated/api/apiContext";
import { DetailedTestResponseDto, Scenario } from "generated/api/apiSchemas";
import { getScenarioBackgroundColour, getScenarioDifficulty, getScenarioName } from "helpers/getters";
import useFireGAEventOnMount from "hooks/useFireGAEventOnMount";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useMatch, useNavigate, useParams } from "react-router-dom";
import { GAEventName, fireGAEvent } from "utils/gtag";
import InformationIcon from "../../../assets/icons/information.svg";
import ScenarioDifficultyModal from "./components/DifficultyModal";

export interface IProps {}

const TestsPage: FC<IProps> = () => {
  const [hasBeenClicked, setHasBeenClicked] = useState<string | null>(null);
  const { openToast } = useToastContext();
  const [showDifficultyModal, setShowDifficultyModal] = useState(false);
  const [showScenarioModal, setShowScenarioModal] = useState(false);

  const queryClient = useQueryClient();
  const { queryKeyFn } = useApiContext();

  // Router
  const navigate = useNavigate();
  const { sessionId } = useParams<{ sessionId: string }>();
  const isDeleteOpen = useMatch("admin/sessions/delete/:sessionId");

  // i18n
  const intl = useIntl();

  useFireGAEventOnMount({ name: GAEventName.ScreenView, param: "Implementer - Tests (Index View)" });

  useEffect(() => {
    if (!isDeleteOpen) return;

    fireGAEvent({ name: GAEventName.ScreenView, param: "Implementer - Delete Test" });
  }, [isDeleteOpen]);

  // Query
  const { data, isLoading: getLoading } = useTestControllerImplementerGetAllTests({});

  // Mutation
  const { mutate: deleteTest, isLoading: deleteLoading } = useTestControllerImplementerDeleteTest({
    onSuccess: () => {
      handleCloseDelete();
      queryClient.invalidateQueries({
        queryKey: queryKeyFn({
          path: "/implementer/tests",
          operationId: "testControllerImplementerGetAllTests",
          variables: {}
        })
      });
    }
  });

  /** Copies a link with the origin and the test ID. */
  const handleCopyLink = useCallback(
    (id: string) => {
      navigator.clipboard.writeText(`${origin}/scenario/${id}`);
      openToast(intl.formatMessage({ id: "admin.sessions.copiedUrl" }), ToastType.SUCCESS, true);
      setHasBeenClicked(id);
      setTimeout(() => {
        setHasBeenClicked(null);
      }, 5000);
    },
    [intl, openToast]
  );

  // Table
  const tableHeaders = useMemo<ColumnDef<DetailedTestResponseDto>[]>(
    () => [
      {
        accessorKey: "name",
        header: intl.formatMessage({ id: "admin.sessions.table.name" }),
        enableSorting: false
      },
      {
        accessorKey: "scenario",
        header: () => {
          return (
            <div className="flex w-fit gap-3">
              <span>{intl.formatMessage({ id: "admin.sessions.table.difficulty" })}</span>
              <button onClick={() => setShowDifficultyModal(true)}>
                <img style={{ minWidth: "24px" }} width={"24px"} src={InformationIcon} alt="more information" />
              </button>
            </div>
          );
        },
        cell: cell => {
          return (
            <p
              className="flex w-[140px] items-center justify-center py-1"
              style={{
                background: getScenarioBackgroundColour(cell?.getValue() as Scenario | undefined),
                color: cell?.getValue() !== "PROCUREMENT" ? "white" : "black"
              }}
            >
              {intl.formatMessage({ id: getScenarioDifficulty(cell.getValue() as Scenario | undefined) })}
            </p>
          );
        },
        enableSorting: false
      },
      {
        accessorKey: "scenario",
        header: () => {
          return (
            <div className="flex  w-fit gap-3">
              <span>{intl.formatMessage({ id: "admin.sessions.table.scenario" })}</span>
              <button onClick={() => setShowScenarioModal(true)}>
                <img style={{ minWidth: "24px" }} src={InformationIcon} alt="more information" />
              </button>
            </div>
          );
        },
        cell: cell => {
          return getScenarioName(cell.getValue() as Scenario | undefined);
        },
        enableSorting: false
      },
      {
        id: "reset",
        header: intl.formatMessage({ id: "admin.sessions.table.sessionURL" }),
        cell: ({ row }) => (
          <button
            className={classNames("underline", hasBeenClicked === row.original.id && "text-primary-500")}
            onClick={() => handleCopyLink(row.original.id)}
          >
            <FormattedMessage id="admin.sessions.copyUrl" />
          </button>
        )
      },
      {
        id: "actions",
        header: "",
        cell: ({ row }) => (
          <div className="flex items-center gap-2.5">
            {/* Delete */}
            <Link
              to={`/admin/sessions/delete/${row.original.id}`}
              aria-label={intl.formatMessage({ id: "general.delete" })}
            >
              <BinFillIcon className="text-error-500" />
            </Link>

            {/* View */}
            <Link
              to={`/admin/sessions/${row.original.id}`}
              aria-label={intl.formatMessage({ id: "admin.sessions.table.viewTest" })}
            >
              <EyeFillIcon className="text-primary-500" />
            </Link>
          </div>
        ),
        size: TABLE_ACTION_CELL_SIZE
      }
    ],
    [handleCopyLink, hasBeenClicked, intl]
  );

  const handleAddTestClick = () => {
    fireGAEvent({ name: GAEventName.ButtonAddNewTest });
  };

  /** Reset the delete id. */
  const handleCloseDelete = () => {
    navigate("/admin/sessions");
  };

  /** Delete the selected test. */
  const handleConfirmDelete = () => {
    if (!sessionId) return;

    fireGAEvent({ name: GAEventName.ButtonDeleteTest });

    deleteTest({
      pathParams: {
        id: sessionId
      }
    });
  };

  const ctaButtonProps = {
    to: "/admin/sessions/create",
    onClick: handleAddTestClick,
    linkTextProps: { id: "admin.sessions.add.button" }
  };

  const isLoading = getLoading || deleteLoading;

  return (
    <LoadingWrapper isLoading={isLoading}>
      {/* Delete confirmation */}
      <Modal
        title={intl.formatMessage({ id: "admin.sessions.delete.modal.title" })}
        description={intl.formatMessage({ id: "admin.sessions.delete.modal.desc" })}
        open={!!isDeleteOpen}
        onClose={handleCloseDelete}
      >
        <ModalButtonContainer>
          <Button className="px-9" onClick={handleConfirmDelete}>
            <FormattedMessage id="general.delete" />
          </Button>
          <Button variant="secondary" className="px-9" onClick={handleCloseDelete}>
            <FormattedMessage id="general.exit" />
          </Button>
        </ModalButtonContainer>
      </Modal>

      {/* Title and controls */}
      <ActionBar titleProps={{ id: "admin.sessions.title" }} linkButtonProps={ctaButtonProps} className="mb-9" />

      <Card hasShadow>
        <Table<DetailedTestResponseDto>
          autoTableWidth={true}
          data={data ?? []}
          columns={tableHeaders}
          emptyElement={
            <TableEmpty
              title={intl.formatMessage({ id: "admin.sessions.empty.title" })}
              description={intl.formatMessage({ id: "admin.sessions.empty.description" })}
            >
              <LinkBtn to={ctaButtonProps.to} onClick={ctaButtonProps.onClick} className="mx-auto w-fit">
                <FormattedMessage {...ctaButtonProps.linkTextProps} />
              </LinkBtn>
            </TableEmpty>
          }
        />
      </Card>

      <ScenarioDifficultyModal
        title={intl.formatMessage({ id: "admin.sessions.modal.difficulty.title" })}
        open={showDifficultyModal}
        onClose={() => setShowDifficultyModal(false)}
      ></ScenarioDifficultyModal>

      <ScenarioDifficultyModal
        title={intl.formatMessage({ id: "admin.sessions.table.scenario" })}
        open={showScenarioModal}
        onClose={() => setShowScenarioModal(false)}
        isDifficultyDescription={false}
      ></ScenarioDifficultyModal>
    </LoadingWrapper>
  );
};

export default TestsPage;

import { FC } from "react";
import { useIntl } from "react-intl";
import LoginForm from "components/Login/LoginForm";
import { useLocation } from "react-router-dom";
import useFireGAEventOnMount from "hooks/useFireGAEventOnMount";
import { GAEventName } from "utils/gtag";

export interface IProps {}

const ImplementerLogin: FC<IProps> = props => {
  // i18n
  const intl = useIntl();

  // Router
  const location = useLocation();

  useFireGAEventOnMount({ name: GAEventName.ScreenView, param: "Implementer - Login" });

  return (
    <LoginForm
      formTitle={intl.formatMessage({ id: "admin.signIn.page.title" })}
      // Redirect to Admin Landing Page
      successLink="../"
      resetPasswordLink="../forgotten-password"
      userNameOrgValidation
      userNameLabel={intl.formatMessage({ id: "general.email.or.org.code" })}
      userNamePlaceholder={intl.formatMessage({ id: "general.email.or.org.code" })}
      // If logging back in after verifying a new email,
      // prepopulate username with that email address
      defaultEmail={location.state?.email}
      type="implementer"
    />
  );
};

export default ImplementerLogin;

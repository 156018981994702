import classNames from "classnames";
import { ButtonHTMLAttributes, DetailedHTMLProps, FC, PropsWithChildren } from "react";

export type TButtonVariants = "primary" | "icon" | "email-action" | "reply-action";

export interface IProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  variant?: TButtonVariants;
}

const SNButton: FC<PropsWithChildren<IProps>> = props => {
  const { variant = "primary", className, children, ...rest } = props;

  return (
    <button
      className={classNames(
        className,
        "c-sn-button",
        variant === "primary" && "c-sn-button--primary",
        variant === "icon" && "c-sn-button--icon",
        variant === "email-action" && "c-sn-button--email-action",
        variant === "reply-action" && "c-sn-button--reply-action"
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

export default SNButton;

import { FullPageContent, ScenarioLayout } from "layouts";
import HomeLayout from "layouts/Home";
import ScenarioResultsLayout from "layouts/ScenarioResults";
import ScrollToTop from "layouts/ScrollToTop";
import { createBrowserRouter, Navigate } from "react-router-dom";
import AdminLayout from "../layouts/Admin";
import ImplementerTesteeAuth from "./admin/auth/ImplementerAuth";
import DashboardPage from "./admin/dashboard";
import SettingsPage from "./admin/settings";
import TestsPage from "./admin/tests";
import TestCreatePage from "./admin/tests/create";
import TestDetailsPage from "./admin/tests/details";
import UsersPage from "./admin/users";
import {
  Demo,
  ErrorWrapper,
  Home,
  ImplementerLogin,
  ImplementerResetPassword,
  OfflineWrapper,
  ScenarioAccounts,
  ScenarioResultsShareInfo,
  ScenarioEmailDetail,
  ScenarioEmailInbox,
  ScenarioFiles,
  ScenarioLandingPage,
  ScenarioPhishingEmailResults,
  ScenarioStaff,
  ScenarioStaffDetail,
  ScenarioTesteeAuth,
  ScenarioWeb,
  ScenarioWelcome,
  SignUp,
  SignUpConfirm,
  TesteeForgottenPassword,
  TesteeLogin,
  TesteeSignUp,
  ThankYouSignUp,
  ScenarioResults,
  ScenarioResultsSummary,
  WorkStyleFeedbackResults,
  TermsConditionsPage,
  ScenarioWebBrowser,
  ScenarioFilesDetails
} from "pages";
import ScenarioAccountDetail from "./scenario/accounts/AccountDetail";

const router = createBrowserRouter([
  {
    element: (
      <OfflineWrapper>
        <ScrollToTop />
      </OfflineWrapper>
    ),
    errorElement: <ErrorWrapper />,
    children: [
      {
        element: <HomeLayout />,
        children: [
          {
            index: true,
            element: <Home />
          },
          {
            path: "/terms-conditions",
            element: <TermsConditionsPage />
          },
          {
            path: "/demo",
            element: <Demo />
          },
          {
            path: "/sign-up/email",
            element: <SignUp />
          },
          {
            path: "/sign-up/code",
            element: <SignUp />
          },
          {
            path: "/sign-up/confirm",
            element: <SignUpConfirm />
          },
          {
            path: "/sign-up/thank-you",
            element: (
              <FullPageContent mainClassName="my-28 min-h-[358px]" isFullHeight={false}>
                <ThankYouSignUp />
              </FullPageContent>
            )
          }
        ]
      },
      {
        path: "admin",
        children: [
          {
            element: (
              <HomeLayout>
                <FullPageContent mainClassName="my-24" isFullHeight={false} />
              </HomeLayout>
            ),
            children: [
              {
                path: "login",
                element: <ImplementerLogin />
              },
              {
                path: "forgotten-password",
                element: <ImplementerResetPassword />
              }
            ]
          },
          {
            element: <ImplementerTesteeAuth />,
            children: [
              {
                element: <AdminLayout />,
                children: [
                  {
                    path: "",
                    element: <Navigate to="dashboard" />
                  },
                  {
                    path: "dashboard",
                    element: <DashboardPage />
                  },
                  {
                    path: "sessions",
                    children: [
                      {
                        index: true,
                        element: <TestsPage />
                      },
                      {
                        path: "delete/:sessionId",
                        element: <TestsPage />
                      },
                      {
                        path: "create",
                        element: <TestCreatePage />
                      },
                      {
                        path: ":sessionId",
                        element: <TestDetailsPage />
                      }
                    ]
                  },
                  {
                    path: "users",
                    children: [
                      {
                        index: true,
                        element: <UsersPage />
                      },
                      {
                        path: "delete/:traineeId",
                        element: <UsersPage />
                      },
                      {
                        path: "reset/:traineeId",
                        element: <UsersPage />
                      }
                    ]
                  },
                  {
                    path: "settings",
                    children: [
                      {
                        index: true,
                        element: <SettingsPage />
                      },
                      {
                        path: "verify",
                        element: <SettingsPage />
                      },
                      {
                        path: "delete",
                        element: <SettingsPage />
                      }
                    ]
                  },
                  {
                    path: "settings",
                    children: [
                      {
                        index: true,
                        element: <SettingsPage />
                      },
                      {
                        path: "verify",
                        element: <SettingsPage />
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: "scenario/:scenarioId",
        children: [
          {
            element: <FullPageContent />,
            children: [
              // Scenario Landing Page
              {
                index: true,
                element: <ScenarioLandingPage />
              },
              // Testee Login for Scenario
              {
                path: "login",
                element: <TesteeLogin />
              },
              // Testee Sign Up for Scenario
              {
                path: "sign-up",
                element: <TesteeSignUp />
              }
            ]
          },
          {
            element: <FullPageContent mainClassName="min-h-[auto]" />,
            children: [
              // Testee Forgotten Password
              {
                path: "forgotten-password",
                element: <TesteeForgottenPassword />
              }
            ]
          },
          // Authenticated Scenario
          {
            element: <ScenarioTesteeAuth />,
            children: [
              {
                element: <FullPageContent maxWidthClass="max-w-[805px]" mainClassName="!p-15" />,
                children: [
                  {
                    path: "welcome",
                    element: <ScenarioWelcome />
                  }
                ]
              },
              {
                element: <ScenarioLayout />,
                children: [
                  {
                    path: "inbox",
                    element: <ScenarioEmailInbox />,
                    children: [
                      {
                        path: ":emailId",
                        element: <ScenarioEmailDetail />
                      }
                    ]
                  },
                  {
                    path: "staff",
                    element: <ScenarioStaff />,
                    children: [
                      {
                        path: ":staffId",
                        element: <ScenarioStaffDetail />
                      }
                    ]
                  },
                  {
                    path: "accounts",
                    element: <ScenarioAccounts />,
                    children: [
                      {
                        path: ":accountId",
                        element: <ScenarioAccountDetail />
                      }
                    ]
                  },
                  {
                    path: "web",
                    element: <ScenarioWeb />,
                    children: [
                      {
                        path: ":webId",
                        element: <ScenarioWebBrowser />
                      }
                    ]
                  },
                  {
                    path: "files",
                    element: <ScenarioFiles />,
                    children: [
                      {
                        path: ":fileId",
                        element: <ScenarioFilesDetails />
                      }
                    ]
                  }
                ]
              },
              // Scenario Results
              {
                path: "results",
                children: [
                  {
                    element: <FullPageContent mainClassName="!p-15" maxWidthClass="max-w-[683px]" />,
                    children: [
                      {
                        index: true,
                        element: <ScenarioResults />
                      }
                    ]
                  },
                  {
                    path: "summary",
                    element: <ScenarioResultsLayout maxWidthClassName="max-w-[876px]" stage="scores" />,
                    children: [
                      {
                        index: true,
                        element: <ScenarioResultsSummary />
                      }
                    ]
                  },
                  {
                    path: "phishing-emails/:emailId",
                    element: <ScenarioPhishingEmailResults />
                  },
                  {
                    path: "work-style-feedback",
                    element: <FullPageContent mainClassName="px-15 pt-15 pb-9" maxWidthClass="max-w-[860px]" />,
                    children: [
                      {
                        index: true,
                        element: <WorkStyleFeedbackResults />
                      }
                    ]
                  },
                  {
                    path: "share-info",
                    element: <ScenarioResultsShareInfo />
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
]);
export default router;

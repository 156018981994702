import {
  useReactTable,
  type ColumnDef,
  getCoreRowModel,
  flexRender,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  TableState,
  SortingState,
  ColumnFiltersState,
  RowSelectionState
} from "@tanstack/react-table";
import { FC, HTMLAttributes, ReactNode, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";

import ChevronUpIcon from "assets/icons/chevron-up.svg";
import Pagination from "./Pagination";

export interface ServerSidePaginationResponse {
  page: number;
  pageCount: number;
  itemCount: number;
  take: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export const TABLE_ACTION_CELL_SIZE = 100;

export const TableEmpty: FC<
  {
    title?: string;
    description?: string;
  } & HTMLAttributes<HTMLDivElement>
> = ({ title, description, children, className, ...rest }) => {
  const intl = useIntl();
  const _title = title ?? intl.formatMessage({ id: "table.empty.title" });
  const _description = description ?? intl.formatMessage({ id: "table.empty.description" });

  return (
    <div className={classNames(className, "mx-auto mt-9 w-full max-w-[784px] px-9 text-center")} {...rest}>
      <p className="header-800 mb-9">{_title}</p>
      <p className={classNames("body-900", { "mb-9": !!children })}>{_description}</p>
      {children}
    </div>
  );
};

export interface ReactTableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T>[];
  isServerSide?: boolean;
  onPageChange?: (page: number) => void;
  serverSidePaginationResponse?: ServerSidePaginationResponse;
  onTableStateChange?: (state: TableState) => void;
  emptyElement?: ReactNode;
  autoTableWidth?: boolean;
}

const Table = <T extends object>({
  data,
  columns,
  isServerSide = false,
  onPageChange,
  serverSidePaginationResponse,
  onTableStateChange,
  autoTableWidth = false,
  emptyElement = <TableEmpty />
}: ReactTableProps<T>) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filter, setFilter] = useState<ColumnFiltersState>([]);
  const intl = useIntl();

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: !isServerSide ? getFilteredRowModel() : undefined,
    getSortedRowModel: !isServerSide ? getSortedRowModel() : undefined,
    getPaginationRowModel: !isServerSide ? getPaginationRowModel() : undefined,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    manualPagination: isServerSide,
    manualSorting: isServerSide,
    manualFiltering: isServerSide,
    state: {
      sorting,
      columnFilters: filter,
      rowSelection
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setFilter,
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER
    }
  });

  useEffect(() => {
    onTableStateChange?.({ ...table.getState(), sorting, columnFilters: filter });
  }, [filter, onTableStateChange, sorting, table]);

  const { pagination } = table.getState();

  const canGetNextPage = () => {
    return isServerSide && serverSidePaginationResponse
      ? serverSidePaginationResponse.hasNextPage
      : table.getCanNextPage();
  };

  const canGetPreviousPage = () => {
    return isServerSide && serverSidePaginationResponse
      ? serverSidePaginationResponse.hasPreviousPage
      : table.getCanPreviousPage();
  };

  const getPageCount = () => {
    return isServerSide ? serverSidePaginationResponse?.pageCount ?? 0 : table.getPageCount();
  };

  const getPageIndex = () => {
    return isServerSide ? (serverSidePaginationResponse?.page ?? 2) - 1 : pagination.pageIndex;
  };

  const setPageIndex = (page: number) => {
    if (isServerSide) {
      onPageChange?.(page);
    } else {
      table.setPageIndex(page - 1);
    }
  };

  return (
    <>
      <table
        className={classNames("w-full border-separate border-spacing-0", autoTableWidth ? "table-auto" : "table-fixed")}
      >
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                const isSorted = header.column.getIsSorted();

                return (
                  <th
                    key={header.id}
                    className="border-b-[0.2px] border-neutral-500"
                    style={{ width: header.getSize() === Number.MAX_SAFE_INTEGER ? "auto" : header.getSize() }}
                  >
                    <div className="header-500 relative flex items-center gap-3 px-6 pb-9 text-primary-700">
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      {header.column.getCanSort() && (
                        <button
                          onClick={() => {
                            if (isSorted === "asc") {
                              header.column.toggleSorting(true);
                            } else if (isSorted === "desc") {
                              header.column.clearSorting();
                            } else {
                              header.column.toggleSorting(false);
                            }
                          }}
                          className={classNames(!isSorted && "opacity-50", "min-w-[1.125rem]")}
                          aria-label={intl.formatMessage({ id: "table.sort" })}
                        >
                          <img
                            src={ChevronUpIcon}
                            alt=""
                            role="presentation"
                            className={classNames((!isSorted || isSorted === "asc") && "rotate-180")}
                          />
                        </button>
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, i) => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td
                  key={cell.id}
                  className="border-t-[36px] border-white bg-primary-300 py-9 px-6"
                  style={{ width: cell.column.getSize() === Number.MAX_SAFE_INTEGER ? "auto" : cell.column.getSize() }}
                >
                  <div className="header-500">{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {data.length === 0 && emptyElement}

      <Pagination
        className="mt-9 w-full justify-center"
        getCanNextPage={canGetNextPage}
        getCanPreviousPage={canGetPreviousPage}
        getPageCount={getPageCount}
        nextPage={() => {
          isServerSide ? onPageChange?.((serverSidePaginationResponse?.page ?? 0) + 1) : table.nextPage();
        }}
        pageIndex={getPageIndex()}
        previousPage={() =>
          isServerSide ? onPageChange?.((serverSidePaginationResponse?.page ?? 0) - 1) : table.previousPage()
        }
        setPageIndex={setPageIndex}
      />
    </>
  );
};

export default Table;

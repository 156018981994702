import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/ui/Button/Button";
import Form from "components/ui/Form/Form";
import Input from "components/ui/Form/Input/Input";
import LoadingWrapper from "components/ui/Loading/LoadingWrapper";
import Modal from "components/ui/Modal/Modal";
import yup from "config/yup";
import { useOrganisationControllerDeleteOrganisation } from "generated/api/apiComponents";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { fireGAEvent, GAEventName } from "utils/gtag";

type DeleteUserInputs = {
  confirm: string;
};

interface IProps {
  show: boolean;
  onClose: () => void;
}

/** Modal to confirm user account deletion. */
const DeleteUserModal: FC<IProps> = ({ show, onClose }) => {
  const [deleted, setDeleted] = useState<boolean>(false);

  // i18n
  const intl = useIntl();

  // Router
  const navigate = useNavigate();

  useEffect(() => {
    fireGAEvent({
      name: GAEventName.ScreenView,
      param: `Admin - Delete Organisation (${deleted ? "Deleted" : "Warning"})`
    });
  }, [deleted]);

  // Mutation
  const { mutate: deleteUser, isLoading } = useOrganisationControllerDeleteOrganisation({
    onSuccess: () => {
      setDeleted(true);
    }
  });

  /** Translates and creates a RegExp for I understand. */
  const confirmRegex = (() => {
    const confirmText = intl.formatMessage({ id: "admin.settings.delete.input.value" });

    return new RegExp(`^${confirmText}$`);
  })();

  // Form
  const DeleteUserSchema = yup
    .object(
      !deleted
        ? {
            confirm: yup
              .string()
              .required()
              .matches(confirmRegex, {
                message: {
                  id: "admin.settings.delete.input.error"
                }
              })
          }
        : {}
    )
    .required();
  const methods = useForm<DeleteUserInputs>({
    resolver: yupResolver(DeleteUserSchema)
  });

  /** Delete the current user. */
  const onSubmit = () => {
    if (deleted) return navigate("/");

    deleteUser({});
  };

  /** Closes the modal. */
  const handleCloseModal = () => {
    navigate("../");
  };

  if (!show) return null;

  return (
    <Modal
      open={show}
      title={intl.formatMessage({
        id: !deleted ? "admin.settings.delete.title" : "admin.settings.delete.confirmed.title"
      })}
      description={intl.formatMessage({
        id: !deleted ? "admin.settings.delete.desc" : "admin.settings.delete.confirmed.desc"
      })}
      onClose={!deleted ? onClose : () => null}
    >
      <LoadingWrapper isLoading={isLoading}>
        <Form methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
          {!deleted && (
            <Input
              placeholder={intl.formatMessage({ id: "admin.settings.delete.input.placeholder" })}
              wrapperClassName="mb-9 text-left"
              {...methods.register("confirm")}
            />
          )}

          <div className="flex flex-col space-y-6">
            <Button type="submit">
              <FormattedMessage id={!deleted ? "admin.settings.delete.submit" : "general.continue"} />
            </Button>
            {!deleted && (
              <Button variant="secondary" onClick={handleCloseModal}>
                <FormattedMessage id="general.cancel" />
              </Button>
            )}
          </div>
        </Form>
      </LoadingWrapper>
    </Modal>
  );
};

export default DeleteUserModal;

import { FC } from "react";
import { FormattedMessage } from "react-intl";

export interface IProps {
  num?: number;
  phishingRate: "good" | "okay" | "bad";
}

const GoodIcon = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="40" cy="40" r="40" fill="#1E7200" />
    <circle cx="40" cy="40" r="18" fill="#1E7200" />
    <path
      d="M48.1222 31L50.8004 33.7319L35.4732 49L27.4004 40.9595L30.1482 38.2224L35.4723 43.6038L48.1222 31Z"
      fill="white"
    />
  </svg>
);

const OkayIcon = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="40" cy="40" r="40" fill="#FFAA05" />
    <circle cx="40" cy="40" r="18" fill="#FFAA05" />
    <rect x="27.4004" y="38.2002" width="25.2" height="3.6" fill="white" />
  </svg>
);

const BadIcon = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="40" cy="40" r="40" fill="#D7004D" />
    <circle cx="40" cy="40" r="17" fill="#D7004D" stroke="#D7004D" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.5 34.7766L45.2612 32.5L40 37.8006L34.7388 32.5L32.5 34.7766L37.7887 40.0284L32.5 45.3567L34.6872 47.5L40 42.2243L45.3128 47.5L47.5 45.3567L42.2113 40.0284L47.5 34.7766Z"
      fill="white"
    />
  </svg>
);

const NumberCard: FC<IProps> = props => {
  const { num = 0, phishingRate } = props;

  let Icon;
  switch (phishingRate) {
    case "good":
      Icon = GoodIcon;
      break;
    case "okay":
      Icon = OkayIcon;
      break;
    case "bad":
      Icon = BadIcon;
      break;
  }

  return (
    <div className="flex w-1/3 flex-col items-center">
      <div className="mb-6 h-20 w-20">
        <Icon />
      </div>

      <h4 className="header-900">{num}</h4>

      <p className="body-1000">
        <FormattedMessage id={`scenarios.results.summary.num.card.${phishingRate}`} />
      </p>
    </div>
  );
};

export default NumberCard;

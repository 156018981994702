import { FC } from "react";
import { ErrorWrapper } from "generated/api/apiFetcher";
import classNames from "classnames";

interface IProps {
  error?: ErrorWrapper<any> | string | null;
  className?: string;
}

const ErrorMessage: FC<IProps> = ({ error, className }) =>
  error ? (
    <p className={classNames("body-700 text-error-500", className)}>
      {typeof error === "string" ? error : error.payload ?? error.message ?? JSON.stringify(error)}
    </p>
  ) : (
    <></>
  );

export default ErrorMessage;

import { FC } from "react";

interface IProps {
  className?: string;
}

const CopyFillIcon: FC<IProps> = ({ className }) => (
  <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.13445 3.74063C3.89312 3.74063 3.65177 3.52949 3.65177 3.25794V1.44794H1.6306C1.23842 1.44794 0.876434 1.62893 0.60495 1.90041C0.333467 2.14174 0.182617 2.53388 0.182617 2.92606V18.5219C0.182617 18.9141 0.333464 19.3062 0.60495 19.5475C0.876434 19.819 1.23842 20 1.6306 20H12.3696C12.7618 20 13.1238 19.819 13.3953 19.5475C13.6667 19.3062 13.8176 18.9141 13.8176 18.5219V2.92606C13.8176 2.53388 13.6667 2.14174 13.3953 1.90041C13.1238 1.62893 12.7618 1.44794 12.3696 1.44794H10.3484V3.25794C10.3484 3.52943 10.1373 3.74063 9.86575 3.74063H4.13445ZM4.64728 2.65463H9.38328V1.56863H8.56883C8.29735 1.56863 8.08615 1.32729 8.08615 1.08594C8.08615 0.78431 7.96551 0.51276 7.78452 0.331777L7.75437 0.301633C7.57339 0.12065 7.30191 0 7.00021 0C6.69857 0 6.45724 0.120638 6.24604 0.301633C6.06506 0.512767 5.94441 0.784317 5.94441 1.08595C5.94441 1.32728 5.70307 1.56863 5.46172 1.56863H4.64727V2.65463L4.64728 2.65463ZM2.44528 6.00313H3.77257V6.99858H2.44528V6.00313ZM2.44528 9.26113H3.77257V10.2264H2.44528V9.26113ZM2.44528 12.5191H3.77257V13.4844H2.44528V12.5191ZM2.44528 15.7771H3.77257V16.7424H2.44528V15.7771ZM5.06962 12.5191H11.5553V13.4844H5.06962V12.5191ZM5.06962 15.7771H11.5553V16.7424H5.06962V15.7771ZM5.06962 9.26129H11.5553V10.2266H5.06962V9.26129ZM5.06962 6.00329H11.5553V6.99874H5.06962V6.00329Z"
      fill="currentcolor"
    />
  </svg>
);

export default CopyFillIcon;

import { Combobox } from "@headlessui/react";
import Chevron from "components/ui/icons/Chevron";
import { SelectOption } from "constants/form";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export interface IComboBoxProps {
  options: SelectOption<string>[];
  placeholder?: string;
  onChange?: (value: string) => void;
}

const ComboBox = (props: IComboBoxProps) => {
  const { options, placeholder = "", onChange } = props;
  const [selected, setSelected] = useState<string>();
  const intl = useIntl();

  const onChangeHandler = (value: string) => {
    setSelected(value);
    onChange?.(value);
  };

  return (
    <Combobox value={selected} onChange={onChangeHandler}>
      {({ open }) => (
        <div className="pointer-events-auto relative mt-1 cursor-pointer">
          <Combobox.Button className="body-600 placeholder:body-600 relative z-10 block w-full rounded border border-neutral-500 py-[0.313rem] pl-[9px] text-neutral-700">
            <Combobox.Input
              onClick={() => {}}
              onChange={() => {}}
              readOnly
              placeholder={intl.formatMessage({ id: "general.test.selectDifficulty" })}
              className="w-full cursor-pointer placeholder:text-neutral-500 focus:outline-none"
              displayValue={(value: any) => {
                const label = options.find(option => option.value === value)?.label;
                return label ? intl.formatMessage({ id: label }) : placeholder;
              }}
            />
            <Chevron
              direction={open ? "up" : "down"}
              className="absolute right-0 top-1/2 mr-2 -translate-y-1/2 text-primary-500"
            />
          </Combobox.Button>
          <Combobox.Options className="mt-1 max-h-60 w-full divide-y divide-neutral-500 overflow-auto rounded-md border border-neutral-500 bg-white">
            {options.length === 0 ? (
              <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                <FormattedMessage id="general.comboBox" />
              </div>
            ) : (
              options.map((option: any) => (
                <Combobox.Option
                  key={option.value}
                  className={({ active }) =>
                    `relative cursor-pointer select-none p-1 pl-2 ${active ? "body-650 bg-primary-300" : "body-600"}`
                  }
                  value={option.value}
                >
                  {({ selected }) => (
                    <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>
                      <FormattedMessage id={option.label} />
                    </span>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </div>
      )}
    </Combobox>
  );
};

export default ComboBox;

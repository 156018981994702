import LinkBtn from "components/ui/LinkBtn/LinkBtn";
import useGetSessionResults from "hooks/useGetSessionResults";
import LoadingWrapper from "components/ui/Loading/LoadingWrapper";
import NumberCard from "pages/scenario/results/summary/components/NumberCard";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useEffectOnce } from "react-use";
import { fireGAEvent, GAEventName } from "utils/gtag";
import { ColourType } from "constants/colourType";

export interface IProps {}

const ResultsSummary: FC<IProps> = props => {
  const { data, isLoading } = useGetSessionResults();

  useEffectOnce(() => {
    fireGAEvent({ name: GAEventName.ScreenView, param: "Testee - Phishing Results Overview" });
  });

  const linkTo = useMemo(() => {
    if (data?.phishingEmails?.length) {
      const sortedPhishingEmails = [...data.phishingEmails].sort((a, b) => a.delta - b.delta);
      return `../phishing-emails/${sortedPhishingEmails[0].id}`;
    }

    return data?.feedback ? "../work-style-feedback" : "../share-info";
  }, [data?.feedback, data?.phishingEmails]);

  return (
    <LoadingWrapper className="m-auto" isLoading={isLoading}>
      <div className="text-center">
        <h1 className="header-900 mb-4">
          <FormattedMessage id="scenarios.results.summary.title" />
        </h1>

        <h2 className="header-700 mb-7">
          <FormattedMessage id="scenarios.results.summary.subTitle" />
        </h2>

        <div className="mb-9 bg-primary-400 p-6">
          <h3 className="header-700">
            <FormattedMessage
              id="scenarios.results.summary.total.phishing.emails"
              values={{
                num: data?.phishingEmails.length
              }}
            />
          </h3>
        </div>

        <div className="mb-15 flex gap-x-[42px] px-15">
          <NumberCard
            num={data?.phishingEmails.filter(email => email.responseResult.toLowerCase() === ColourType.green).length}
            phishingRate="good"
          />
          <NumberCard
            num={data?.phishingEmails.filter(email => email.responseResult.toLowerCase() === ColourType.red).length}
            phishingRate="bad"
          />
          <NumberCard
            num={data?.phishingEmails.filter(email => email.responseResult.toLowerCase() === ColourType.amber).length}
            phishingRate="okay"
          />
        </div>

        <div className="flex justify-center">
          <LinkBtn
            onClick={() => {
              fireGAEvent({ name: GAEventName.ButtonSeeDetails });
            }}
            to={linkTo}
          >
            <FormattedMessage id="scenarios.results.summary.view.details" />
          </LinkBtn>
        </div>
      </div>
    </LoadingWrapper>
  );
};

export default ResultsSummary;

import classNames from "classnames";
import { FC } from "react";

interface IProps {
  direction: "up" | "down" | "left" | "right";
  className?: string;
}

const Chevron: FC<IProps> = ({ direction, className }) => {
  return (
    <svg
      width="17"
      height="10"
      viewBox="0 0 17 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(
        "fill-[#076EB2] transition-transform duration-500 ease-in-out",
        {
          "-rotate-90": direction === "left",
          "rotate-90": direction === "right",
          "rotate-0": direction === "down",
          "rotate-180": direction === "up"
        },
        className
      )}
    >
      <path
        d="M16.0313 0.488349C16.3438 0.813865 16.5 1.23994 16.5 1.66673C16.5 2.09352 16.3437 2.51959 16.0313 2.84511L9.63128 9.51173C9.00698 10.1628 7.9931 10.1628 7.36872 9.51173L0.968745 2.84511C0.343752 2.19408 0.343752 1.1394 0.968745 0.488274C1.59304 -0.162759 2.60692 -0.162759 3.23131 0.488274L8.50009 5.97657L13.7687 0.488349C14.393 -0.162683 15.4069 -0.162683 16.0313 0.488349Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Chevron;

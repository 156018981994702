import { ComponentProps, FC, HTMLAttributes } from "react";
import { FormattedMessage } from "react-intl";
import LinkBtn, { IProps as LinkButtonProps } from "components/ui/LinkBtn/LinkBtn";
import classNames from "classnames";

interface IProps extends HTMLAttributes<HTMLDivElement> {
  linkButtonProps?: LinkButtonProps & { linkTextProps: ComponentProps<typeof FormattedMessage> };
  titleProps?: ComponentProps<typeof FormattedMessage>;
  headerProps?: HTMLAttributes<HTMLHeadingElement>;
}

const LinkButton = (props: IProps["linkButtonProps"]) => {
  if (!props) return null;

  const { linkTextProps, ...rest } = props;

  return (
    <div className="flex items-center justify-end">
      <LinkBtn {...rest}>
        <FormattedMessage {...linkTextProps} />
      </LinkBtn>
    </div>
  );
};

const ActionBar: FC<IProps> = ({ className, linkButtonProps, titleProps, headerProps }) => {
  return (
    <div className={className}>
      {titleProps && (
        <h1 {...headerProps} className={classNames("header-900", headerProps?.className, linkButtonProps && "mb-9")}>
          <FormattedMessage {...titleProps} />
        </h1>
      )}

      {linkButtonProps && <LinkButton {...linkButtonProps} className={classNames(linkButtonProps.className, "px-9")} />}
    </div>
  );
};

export default ActionBar;

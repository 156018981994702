import Link from "components/ui/Link/Link";
import { MOTHERLOAD_CHEAT } from "constants/scenario";
import { useAppSelector } from "hooks/useRedux";
import { FC } from "react";
import { selectClockTick } from "redux/modules/clockTick";

export interface IProps {
  totalTime: number;
  className?: string;
}

const TIMER_DIAMETER = 282.743;

const SNTimer: FC<IProps> = props => {
  const { totalTime, className } = props;
  const delta = useAppSelector(selectClockTick);

  const remainingTime = totalTime - delta;

  let diameterOffset: number;
  let timerDisplay: string;
  if (remainingTime >= 0) {
    diameterOffset = (TIMER_DIAMETER / totalTime) * remainingTime;

    const remainingMinutes = remainingTime / (1000 * 60);

    if (remainingMinutes >= 1) {
      timerDisplay = Math.ceil(remainingMinutes) + "m";
    } else {
      timerDisplay = remainingMinutes * 60 + "s";
    }
  } else {
    diameterOffset = 0;
    timerDisplay = "0s";
  }

  if (MOTHERLOAD_CHEAT) {
    return <Link to="results">Finish</Link>;
  }

  return (
    <div className={className}>
      <svg className="w-15" viewBox="0 0 100 100">
        <path
          className="stroke-neutral-450"
          d="M 50,50 m 0,-45 a 45,45 0 1 1 0,90 a 45,45 0 1 1 0,-90"
          strokeWidth="10"
          fillOpacity="0"
        />
        <path
          className="stroke-primary-500 transition-all duration-500"
          d="M 50,50 m 0,-45 a 45,45 0 1 1 0,90 a 45,45 0 1 1 0,-90"
          strokeWidth="10"
          fillOpacity="0"
          strokeLinecap="butt"
          style={{
            strokeDasharray: `${TIMER_DIAMETER}px, ${TIMER_DIAMETER}px`,
            strokeDashoffset: `${diameterOffset}px`
          }}
        />
        <text
          className="fill-primary-500 text-2xl font-normal"
          x="50"
          y="50"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          <tspan dy="0">{timerDisplay}</tspan>
        </text>
      </svg>
    </div>
  );
};

export default SNTimer;

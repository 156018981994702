import Button from "components/ui/Button/Button";
import ErrorMessage from "components/ui/ErrorMessage/ErrorMessage";
import CognitoService from "helpers/cognito.service";
import { getSignUpErrorMessage } from "helpers/getters";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingWrapper from "components/ui/Loading/LoadingWrapper";

export interface IProps {}

const ThankYouSignUpPage: FC<IProps> = props => {
  // React Hooks
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);

  // Router
  const { state } = useLocation();
  const navigate = useNavigate();

  // i18n
  const intl = useIntl();

  // Check we have a username within the router state
  // If not, the user shouldn't be on this page!
  useEffect(() => {
    if (!state?.username) {
      navigate("/sign-up/email");
    }
  }, [navigate, state]);

  const handleResendEmailClick = async () => {
    setIsLoading(true);
    try {
      await CognitoService.resendConfirmationCode(state.username);
    } catch (e) {
      setError(e as Error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="m-auto max-w-[600px] text-center">
      <LoadingWrapper isLoading={isLoading}>
        <h1 className="header-900 mb-8">
          <FormattedMessage id="admin.signUp.thank.you.title" />
        </h1>
        <p className="body-900 mb-8">
          <FormattedMessage id="admin.signUp.thank.you.desc" />
        </p>
        <p className="body-900 mb-8">
          <FormattedMessage id="admin.signUp.thank.you.did.not.receive" />
        </p>
        <Button onClick={handleResendEmailClick} className="mx-auto">
          <FormattedMessage id="admin.signUp.thank.you.did.not.receive.btn" />
        </Button>

        {error && <ErrorMessage error={intl.formatMessage({ id: getSignUpErrorMessage(error) })} />}
      </LoadingWrapper>
    </div>
  );
};

export default ThankYouSignUpPage;

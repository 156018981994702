import { useQuery } from "@tanstack/react-query";
import { CognitoUser } from "amazon-cognito-identity-js";
import CognitoService from "helpers/cognito.service";
import TesteeResetPassword from "pages/scenario/auth/components/ResetPassword";
import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { Navigate } from "react-router-dom";
import LoginForm from "components/Login/LoginForm";
import useFireGAEventOnMount from "hooks/useFireGAEventOnMount";
import { GAEventName } from "utils/gtag";
import { If, Then, Else } from "react-if";

export interface IProps {}

const TesteeLogin: FC<IProps> = props => {
  const [showResetPasswordScreen, setShowResetPasswordScreen] = useState(false);
  const [currentCognitoUser, setCurrentCognitoUser] = useState<CognitoUser>();

  // i18n
  const intl = useIntl();

  useFireGAEventOnMount({ name: GAEventName.ScreenView, param: "Testee - Login" });

  // The 'isAuthenticated' call
  const { data: isAuthenticated, isLoading: isLoadingAuthenticated } = useQuery(["isAuthenticated"], () =>
    CognitoService.isAuthenticated()
  );

  const handleLoginChallenge = (user: CognitoUser) => {
    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      setShowResetPasswordScreen(true);
      setCurrentCognitoUser(user);
    }
  };

  if (isLoadingAuthenticated) {
    return null;
  }

  if (isAuthenticated) {
    return <Navigate to="../welcome" replace={true} />;
  }

  return (
    <If condition={showResetPasswordScreen}>
      <Then>
        <TesteeResetPassword cognitoUser={currentCognitoUser} />
      </Then>
      <Else>
        <LoginForm
          formTitle={intl.formatMessage({ id: "trainee.signIn.page.title" })}
          // Redirect to Test Welcome Page
          successLink="../welcome"
          resetPasswordLink="../forgotten-password"
          handleLoginChallenge={handleLoginChallenge}
          type="testee"
        />
      </Else>
    </If>
  );
};

export default TesteeLogin;

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { CognitoUser } from "amazon-cognito-identity-js";
import Button from "components/ui/Button/Button";
import Form from "components/ui/Form/Form";
import Input from "components/ui/Form/Input/Input";
import Link from "components/ui/Link/Link";
import ErrorMessage from "components/ui/ErrorMessage/ErrorMessage";
import yup from "config/yup";
import CognitoService from "helpers/cognito.service";
import { FC, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { To, useNavigate } from "react-router-dom";
import { fireGAEvent, GAEventName } from "utils/gtag";
import LoadingWrapper from "components/ui/Loading/LoadingWrapper";

export type TLoginInputs = {
  username: string;
  password: string;
};

export interface IProps {
  formTitle: string;
  successLink: To;
  resetPasswordLink: To;
  handleLoginChallenge?: (user: CognitoUser) => void;
  userNameLabel?: string;
  userNamePlaceholder?: string;
  userNameOrgValidation?: boolean;
  defaultEmail?: string;
  type: "testee" | "implementer";
}

const LoginForm: FC<IProps> = props => {
  const {
    formTitle,
    userNameLabel,
    userNamePlaceholder,
    successLink,
    resetPasswordLink,
    handleLoginChallenge,
    userNameOrgValidation = false,
    defaultEmail
  } = props;
  const navigate = useNavigate();
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();

  // Yup Validation
  const LoginSchema = useMemo(
    () =>
      yup
        .object({
          username: userNameOrgValidation
            ? yup.lazy(value => {
                // Does the username start with an '#'
                // validate it as an Org code
                if (value.startsWith("#")) {
                  return yup.string().required();
                } else {
                  return yup.string().email().required();
                }
              })
            : yup.string().required(),
          password: yup.string().min(8).required()
        })
        .required(),
    [userNameOrgValidation]
  );

  // Use Form
  const methods = useForm<TLoginInputs>({
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      username: defaultEmail
    }
  });

  const onSubmit: SubmitHandler<TLoginInputs> = async data => {
    setIsLoading(true);

    fireGAEvent({ name: GAEventName.ButtonLogInTestee });

    try {
      const res = await CognitoService.signIn(data);

      if (res?.challengeName) {
        handleLoginChallenge?.(res);
      } else {
        navigate(successLink, { replace: true });
      }
    } catch (e) {
      methods.reset();
      setError(e as Error);
    }

    setIsLoading(false);
  };

  return (
    <LoadingWrapper isLoading={isLoading}>
      <div className="m-auto w-[530px]">
        <h1 id="login-form-title" className="header-900 mb-9 text-center">
          {formTitle}
        </h1>

        <Form
          aria-labelledby="login-form-title"
          className="flex flex-col gap-y-9"
          methods={methods}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Input
            label={userNameLabel ?? intl.formatMessage({ id: "general.username" })}
            placeholder={userNamePlaceholder ?? intl.formatMessage({ id: "general.username" })}
            required
            {...methods.register("username")}
          />
          <Input
            label={intl.formatMessage({ id: "general.password" })}
            placeholder={intl.formatMessage({ id: "general.password" })}
            type="password"
            required
            {...methods.register("password")}
          />

          <ErrorMessage className="-mt-5" error={error} />

          <div className="text-center">
            <Link to={resetPasswordLink}>
              <FormattedMessage id="general.reset.password" />
            </Link>
          </div>

          <div className="text-center">
            <Button className="mx-auto">
              <FormattedMessage id="general.login" />
            </Button>
          </div>
        </Form>
      </div>
    </LoadingWrapper>
  );
};

export default LoginForm;

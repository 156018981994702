import classNames from "classnames";
import { FC, useRef, useState } from "react";

interface IProps {
  image?: string;
  video?: string;
  alt: string;
  title: string;
  description: string;
  question: string;
  reverse?: boolean;
  className?: string;
}

/** A responsive horizontal section with an image. */
const ImageCardSection: FC<IProps> = ({ image, video, alt, title, description, question, reverse, className }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [hasReset, setHasReset] = useState(false);

  const handleOnPlay = () => {
    if (videoRef.current && !hasReset) {
      videoRef.current.currentTime = 0;
      setHasReset(true);
    }
  };

  return (
    <div className={classNames("bg-white", className)}>
      <div
        className={classNames("flex flex-col lg:flex-row 2k:mx-auto 2k:max-w-5xl", { "lg:flex-row-reverse": reverse })}
      >
        {/* Text */}
        <div className="flex justify-center px-[49px] py-9 sm:px-0 sm:py-20 lg:w-1/2 lg:py-0">
          <div className="flex h-full max-w-[400px] flex-col justify-center space-y-[18px]">
            <p className="text-sm font-bold text-primary-500">{question}</p>
            <h2 className="header-900">{title}</h2>
            <p className="body-900 whitespace-pre-line">{description}</p>
          </div>
        </div>

        {/* Image */}
        <div className="lg:w-1/2">
          {image && <img src={image} alt={alt} className="h-full w-full" loading="lazy" />}
          {video && !image && (
            <video controls preload="metadata" onPlay={handleOnPlay} ref={videoRef}>
              <source src={`${video}#t=0.5`} type="video/mp4" />
            </video>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageCardSection;

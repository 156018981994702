import { FC } from "react";
import { useIntl } from "react-intl";
import Button from "components/ui/Button/Button";
import BinFillIcon from "components/ui/icons/BinFill";

interface IProps {
  onDeleteClick: () => void;
}

const DeleteUserButton: FC<IProps> = ({ onDeleteClick }) => {
  const intl = useIntl();

  return (
    <Button
      variant="primary"
      className="flex h-9 w-9 min-w-fit items-center justify-center px-3"
      aria-label={intl.formatMessage({ id: "admin.settings.delete.alt" })}
      onClick={onDeleteClick}
      type="button"
    >
      <BinFillIcon />
    </Button>
  );
};

export default DeleteUserButton;

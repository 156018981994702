import { GET_STARTED_EMAIL_ID } from "constants/scenario";
import { useCallback } from "react";
import { useInteractionControllerInteraction } from "../generated/api/apiComponents";
import { InteractionType, RegisterInteractionDto } from "../generated/api/apiSchemas";
import { store } from "../redux/store";
import useScenarioSessionId from "./useScenarioSessionId";

const useInteraction = () => {
  const sessionId = useScenarioSessionId();

  // Mutation
  const { mutate: interactionMutate } = useInteractionControllerInteraction();

  return useCallback(
    (type: InteractionType, body: Omit<RegisterInteractionDto, "type" | "delta">) => {
      if (!sessionId || body.emailId === GET_STARTED_EMAIL_ID) return;

      return interactionMutate({
        pathParams: {
          sessionId
        },
        body: {
          // Avoid listening to state changes, just get it direct
          delta: store.getState().clockTick.delta / 1000,
          type,
          ...body
        }
      });
    },
    [interactionMutate, sessionId]
  );
};

export default useInteraction;

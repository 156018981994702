import { useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "hooks/useRedux";
import { setCompletedAction } from "redux/modules/scenario";

export default function MoneyTransfer() {
  const [loggedIn, setLoggedIn] = useState(false);
  const intl = useIntl();

  const dispatch = useAppDispatch();

  const { webId } = useParams<{ webId: string }>();

  const login = () => {
    if (webId) {
      dispatch(setCompletedAction(webId));
    }
    setLoggedIn(true);
  };

  return (
    <div className="mx-auto grid w-[500px] place-items-center border-[1px] border-neutral-450 py-4">
      {loggedIn ? (
        <p className="max-w-sm py-8 text-center text-3xl font-normal leading-normal text-neutral-600">
          {intl.formatMessage({ id: "scenarios.web.money.thankyou" })}
        </p>
      ) : (
        <div className="flex w-[80%] flex-col items-center space-y-8">
          <img src="/web-items/money.png" alt="Money Transfer" />
          <input className="w-full border-b-[1px] border-black  bg-neutral-450 py-2 pl-2" placeholder="Email Address" />
          <button
            className="mb-4 w-full bg-primary-500 py-2 text-white transition-colors duration-300 hover:bg-primary-700"
            onClick={login}
          >
            Next
          </button>
          <button className="self-start font-normal hover:underline">Having trouble logging in?</button>
          <button className="w-full border-2 border-primary-500 bg-transparent py-2 text-primary-500 transition-colors duration-300 hover:bg-primary-500 hover:text-white">
            Create an account
          </button>
        </div>
      )}
    </div>
  );
}

import colours from "components/ui/admin/Charts/constants/colours";
import { Scenario } from "generated/api/apiSchemas";

export const getBackgroundColour = (difficulty: string) => {
  const colourMapping = {
    Easier: colours.green,
    Medium: colours.amber,
    Harder: colours.red
  };
  return (colourMapping as Record<string, string>)[difficulty];
};

export const mapScenario = <T>(mapping: Record<Scenario, T>, scenario?: Scenario): T | undefined => {
  return scenario ? mapping[scenario] : undefined;
};

/** Get the label of the given scenario enum. */
export const getScenarioName = (scenario?: Scenario): string | undefined => {
  const nameMapping: Record<Scenario, string> = {
    HR_ASSISTANT: "HR",
    IT_MANAGER: "IT",
    PROCUREMENT: "Procurement"
  };
  return mapScenario(nameMapping, scenario);
};

export const getScenarioBackgroundColour = (scenario?: Scenario): string | undefined => {
  const nameMapping: Record<Scenario, string> = {
    HR_ASSISTANT: colours.green,
    IT_MANAGER: colours.red,
    PROCUREMENT: colours.amber
  };
  return mapScenario(nameMapping, scenario);
};

export const getScenarioDifficulty = (scenario?: Scenario): string | undefined => {
  const difficultyMapping: Record<Scenario, string> = {
    HR_ASSISTANT: "general.test.scenario.hr.difficulty",
    IT_MANAGER: "general.test.scenario.it.difficulty",
    PROCUREMENT: "general.test.scenario.procurement.difficulty"
  };
  return mapScenario(difficultyMapping, scenario);
};

export const getScenarioDescription = (scenario?: Scenario): string | undefined => {
  const descriptionMapping: Record<Scenario, string> = {
    HR_ASSISTANT: "general.test.scenario.hr.description",
    IT_MANAGER: "general.test.scenario.it.description",
    PROCUREMENT: "general.test.scenario.procurement.description"
  };
  return mapScenario(descriptionMapping, scenario);
};

export const getEmail = (scenario?: Scenario): string | undefined => {
  const emailMapping: Record<Scenario, string> = {
    HR_ASSISTANT: "jules.duncan@techwhyit.com",
    IT_MANAGER: "ashley.temel@techwhyit.com",
    PROCUREMENT: "alex.johnson@techwhyit.com"
  };
  return mapScenario(emailMapping, scenario);
};

export const getTraineeNameForScenario = (scenario?: Scenario): string | undefined => {
  const traineeMapping: Record<Scenario, string> = {
    HR_ASSISTANT: "Jules Duncan",
    IT_MANAGER: "Ashley Temel",
    PROCUREMENT: "Alex Johnson"
  };
  return mapScenario(traineeMapping, scenario);
};

export type SignUpErrorCode =
  | "TEST_FULL"
  | "TEST_MISSING"
  | "MISSING_USERNAME"
  | "TEST_NOT_FOUND"
  | "INVALID_ORG_CODE"
  | "UserLambdaValidationException"
  | "UsernameExistsException"
  | "InvalidParameterException";

/** Get the pretty sign up error message by code. */
export const getSignUpErrorMessage = (error: Error) => {
  // Cognito errors (username or password related) are in a different format to API errors,
  // under the `code` property
  const code = /[a-zA-Z]{2,}/.exec(error.name)?.[0] as unknown as SignUpErrorCode;

  // API errors returned from PreSignUp Lambda are in the format 'PreSignUp trigger failed with error CODE_NAME',
  // under the `message` property
  const message = /[A-Z_]{2,}/.exec(error.message)?.[0] as unknown as SignUpErrorCode;

  switch (message || code) {
    case "TEST_FULL":
      return "signup.error.test.full";
    case "TEST_MISSING":
      return "signup.error.test.missing";
    case "MISSING_USERNAME":
      return "signup.error.username.missing";
    case "TEST_NOT_FOUND":
      return "signup.error.test.not.found";
    case "INVALID_ORG_CODE":
      return "signup.error.invalid.org.code";
    case "UserLambdaValidationException":
    case "UsernameExistsException":
      return "signup.error.username.exists";
    case "InvalidParameterException":
      return "signup.error.username.invalid";
    default:
      return "signup.error.something.went.wrong";
  }
};

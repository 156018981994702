import { HomeFooter, HomeHeader } from "components/ui/home";
import { FC, PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

/** Layout for the home page. */
const HomeLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <main className="flex min-h-screen flex-col">
      <HomeHeader />
      {children ?? <Outlet />}
      <HomeFooter className="mt-auto" />
    </main>
  );
};

export default HomeLayout;

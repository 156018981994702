/**
 * Validation for yup, where one field must match another
 *
 * E.g.
 * .when("newPassword", matchesCurrentField({ id: "errors.password.does.not.match" }))
 *
 * @param message the error message to pass to the component, when fields don't match
 */
export const matchesCurrentField = (message: string | Record<string, any>) => (a: string, schema: any) => {
  return schema.test({
    test: (b: string) => !!a && a === b,
    message
  });
};

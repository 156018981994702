import { FC } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import classNames from "classnames";

export interface IProps {
  className?: string;
  items: ({ key: string; label: string } & NavLinkProps)[];
}

const IMMainNav: FC<IProps> = props => {
  const { className, items } = props;

  return (
    <ul
      className={classNames(
        className,
        "c-implementer-nav",
        "flex h-full flex-col gap-y-9 pl-9 pt-9 shadow-sm shadow-neutral-450"
      )}
    >
      {items.map(({ key, label, ...rest }) => (
        <li key={key}>
          <NavLink
            className={({ isActive }) =>
              classNames("block text-lg tracking-wider text-white", isActive ? "font-bold" : "font-light")
            }
            {...rest}
          >
            {label}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default IMMainNav;

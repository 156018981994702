import { ComponentProps, FC, HTMLAttributes, JSXElementConstructor, ReactNode } from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import PhishingScoreImage from "assets/phishing-score.webp";
import TotalResponsesImage from "assets/total-responses.webp";
import TotalSessionsSentImage from "assets/total-sessions-sent.webp";
import WorkStyleScoreImage from "assets/workstyle-score.webp";

interface IProps extends HTMLAttributes<HTMLDivElement> {
  titleProps: ComponentProps<typeof FormattedMessage>;
  titleAs?: JSXElementConstructor<any> | keyof JSX.IntrinsicElements;
  value?: ReactNode;
  backgroundImage?: "phishing-score" | "total-responses" | "total-sessions-sent" | "workstyle-score";
}

const getImage = (key: IProps["backgroundImage"]) => {
  switch (key) {
    case "phishing-score":
      return PhishingScoreImage;
    case "total-responses":
      return TotalResponsesImage;
    case "total-sessions-sent":
      return TotalSessionsSentImage;
    case "workstyle-score":
      return WorkStyleScoreImage;
  }
};

const StatCard: FC<IProps> = ({ className, children, titleProps, titleAs, value, backgroundImage, ...rest }) => {
  const TitleComponent = titleAs ?? "h3";
  const image = getImage(backgroundImage);

  return (
    <div
      className={classNames(className, "rounded bg-primary-500 bg-cover py-10 px-2 text-center text-white")}
      style={{ backgroundImage: image ? `url(${image})` : undefined }}
      {...rest}
    >
      <p className="header-1000 mb-6">{value}</p>

      <TitleComponent className="header-600 text-ellipsis whitespace-nowrap">
        <FormattedMessage {...titleProps} />
      </TitleComponent>
    </div>
  );
};

export default StatCard;

import React, { DetailedHTMLProps, HTMLAttributes } from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import Button, { IProps as IButtonProps } from "components/ui/scenarios/Button/Button";

interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  options?: ({ label: string; id: string } & Omit<IButtonProps, "variant" | "children">)[];
  selectedOptionId?: string;
}

const Footer = React.forwardRef<HTMLDivElement, IProps>((props: IProps, ref) => {
  const { options = [], className, selectedOptionId, ...rest } = props;

  return (
    <div className={classNames(className, "flex flex-col gap-8 bg-primary-300 p-8")} {...rest} ref={ref}>
      <span className="header-500">
        <FormattedMessage
          id={`scenarios.inbox.footer.reply.options${selectedOptionId ? ".replied" : ""}`}
          values={{ replyOptions: options.length }}
        />
      </span>
      {selectedOptionId ? (
        <span className="body-800">{options.find(option => option.id === selectedOptionId)?.label}</span>
      ) : (
        <div className=" flex flex-col items-center gap-8 rounded">
          {options.map(({ id, label, ...rest }) => (
            <Button key={id} className=" w-full rounded p-[0.75rem] text-left" variant="reply-action" {...rest}>
              {label}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
});

export default Footer;

import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth, Hub } from "aws-amplify";
import { TSignUpInputs } from "pages/scenario/auth/TesteeSignUp";
import { TLoginInputs } from "components/Login/LoginForm";
import { ImplementerSignUpInputs } from "pages/home/sign-up";
import { EmailsDoNotMatchException } from "./exceptions/emails-do-not-match.exception";
import { AuthException } from "./exceptions/auth.exception";
import { EmailIsSameException } from "./exceptions/email-is-same.exception";
import { IncorrectPasswordException } from "./exceptions/incorrect-password.exception";

export default class CognitoService {
  /**
   * Determine if a user is logged into the current session
   * @returns Authenticated predicate
   */
  static async isAuthenticated() {
    const user = await this.getCurrentUser();
    return !!user;
  }

  /**
   * Get the current logged in user
   * @returns User if logged in
   */
  static async getCurrentUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return user;
    } catch (_) {
      return undefined;
    }
  }

  /**
   * Get the current access token
   * @returns Access token if set
   */
  static async getToken(): Promise<string | undefined> {
    try {
      return (await Auth.currentSession())?.getIdToken()?.getJwtToken();
    } catch (_) {
      return undefined;
    }
  }

  /**
   * Sign in user using Cognito
   * @param data username and password
   * @return Promise - User Details
   */
  static signIn(data: TLoginInputs) {
    return Auth.signIn({
      username: data.username,
      password: data.password
    });
  }

  /** Confirm the new user sign-up with given username and code. */
  static confirmSignUp(username: string, code: string) {
    return Auth.confirmSignUp(username, code);
  }

  /**
   * Sign Up user as an implementer and automatically sign them in afterwards
   * The Promised returned will only resolve after the new user has been signed in
   * @param data
   * @return Promise - User Details
   */
  static async implementerSignUp({
    username,
    email,
    password,
    role,
    organisation
  }: ImplementerSignUpInputs & { username: string }) {
    await Auth.signUp({
      username,
      password,
      attributes: {
        email,
        "custom:org_role": role,
        "custom:org_name": organisation,
        "custom:role": "implementer"
      }
    });
  }

  /**
   * Use AWS Cognito to resend a user's confirmation code
   * @param username string
   */
  static async resendConfirmationCode(username: string) {
    await Auth.resendSignUp(username);
  }

  /**
   * Sign Up user and automatically sign them in afterwards
   * The Promised returned will only resolve after the new user has been signed in
   * @param data
   * @return Promise - User Details
   */
  static traineeSignUp(data: TSignUpInputs) {
    return new Promise((resolve, reject) => {
      // https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/#auto-sign-in-after-sign-up
      const unListen = Hub.listen("auth", ({ payload }) => {
        const { event } = payload;

        if (event === "autoSignIn") {
          const user = payload.data;

          resolve(user);
          unListen();
        } else if (event === "autoSignIn_failure") {
          reject(new Error("autoSignIn_failure"));
          unListen();
        }
      });

      // https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/#sign-up
      Auth.signUp({
        username: data.username,
        password: data.password,
        attributes: {
          "custom:role": "trainee",
          "custom:test_id": data.testId
        },
        autoSignIn: {
          enabled: true
        }
      }).catch(e => reject(e));
    });
  }

  /**
   * Send confirmation code to user's email
   * @param email User's email
   * @returns Response
   */
  static async forgotPassword(email: string) {
    return Auth.forgotPassword(email);
  }

  /**
   * Collect confirmation code and new password, then update password if all correct
   * @param email User's email
   * @param code User's received code
   * @param newPassword User's new password
   * @returns Response
   */
  static async forgotPasswordSubmit(email: string, code: string, newPassword: string) {
    return Auth.forgotPasswordSubmit(email, code, newPassword);
  }

  static async completeNewPassword(user: CognitoUser, newPassword: string) {
    return Auth.completeNewPassword(user, newPassword);
  }

  /**
   * Update a user's attributes
   * @param input New attributes
   * @returns Success
   */
  static async updateUser(input: {
    email?: {
      current: string;
      new: string;
    };
    password?: {
      current: string;
      new: string;
    };
  }): Promise<{ email: boolean; password: boolean }> {
    const user = await this.getCurrentUser();
    if (!user) throw new AuthException();

    const res = { email: false, password: false };

    if (input.email) {
      if (input.email.current !== user.attributes.email) throw new EmailsDoNotMatchException();
      if (input.email.new === user.attributes.email) throw new EmailIsSameException();

      await Auth.updateUserAttributes(user, {
        email: input.email.new
      });
      res.email = true;
    }

    if (input.password) {
      try {
        await Auth.changePassword(user, input.password.current, input.password.new);
      } catch (err) {
        if ((err as Error).name === "NotAuthorizedException") throw new IncorrectPasswordException();
        else throw err;
      }
      res.password = true;
    }

    return res;
  }

  /**
   * Verify a user's email after updaing it
   * @param code Verification code sent to user
   */
  static async verifyEmail(code: string) {
    const success = await Auth.verifyCurrentUserAttributeSubmit("email", code);

    return success === "SUCCESS";
  }

  /**
   * Sign out the current user session
   * @returns Response
   */
  static async signOut() {
    return Auth.signOut();
  }
}

import HyphenBox from "components/ui/icons/HyphenBox";
import useBundle from "hooks/useBundle";
import useInteraction from "hooks/useInteraction";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Link, Outlet } from "react-router-dom";

export interface IProps {}

const ScenarioFilesPage: FC<IProps> = props => {
  const { data } = useBundle();
  const registerInteraction = useInteraction();

  if (!data) return null;

  return (
    <div className="col-span-2">
      <table className="ml-[50px] mt-[50px]">
        <thead className="table w-full table-fixed text-left text-sm font-light text-[#5596E6]">
          <tr className="table w-full table-fixed">
            <th className="w-1/4" />
            <th className="w-1/4 text-base font-medium">
              <FormattedMessage id="scenarios.results.scores.table.description" />
            </th>
            <th className="w-1/4 text-base font-medium">
              <FormattedMessage id="scenarios.results.scores.table.date" />
            </th>
          </tr>
        </thead>
        <tbody className="table w-full table-fixed text-left text-xl">
          {data.files.map(({ id, name, description, url, dateCreated }) => {
            const handleOpenFile = () => {
              registerInteraction("FILE_OPEN", {
                payload: {
                  id,
                  url,
                  name,
                  description,
                  dateCreated
                }
              });
            };

            return (
              <tr key={id} className="align-top font-normal text-neutral-500">
                <td>
                  <Link className="flex w-full items-center space-x-2 py-4" to={`./${id}`} onClick={handleOpenFile}>
                    <HyphenBox />
                    <span>{name}</span>
                  </Link>
                </td>
                <td className="py-4">{description}</td>
                <td className="py-4">{dateCreated}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Outlet />
    </div>
  );
};

export default ScenarioFilesPage;

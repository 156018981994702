import { Scenario, TestType } from "generated/api/apiSchemas";

export type SelectOption<T> = {
  label: string;
  value: T;
};

/** List of all available scenarios. */
export const SCENARIOS: SelectOption<Scenario>[] = [
  {
    label: "general.test.difficulty.hr",
    value: "HR_ASSISTANT"
  },
  {
    label: "general.test.difficulty.procurement",
    value: "PROCUREMENT"
  },
  {
    label: "general.test.difficulty.it",
    value: "IT_MANAGER"
  }
];

/** List of all available test types. */
export const TEST_TYPES: SelectOption<TestType>[] = [
  {
    label: "general.test.type.covert",
    value: "COVERT"
  },
  {
    label: "general.test.type.overt",
    value: "OVERT"
  }
];

export const PHONE_NUMBER_REGEX = /^\+\d*$/g;

import { useState } from "react";
import { FormattedMessage } from "react-intl";
import Button from "../../Button/Button";
import LinkBtn from "components/ui/LinkBtn/LinkBtn";

export default function Browser254() {
  const [buttonClicked, setButtonClicked] = useState(false);
  const [currentLoad, setCurrentLoad] = useState(0);

  if (buttonClicked) {
    setTimeout(() => {
      if (currentLoad < 348) {
        setCurrentLoad(currentLoad + 1);
      }
    }, 10);
  }

  return (
    <div className="flex flex-col space-y-4 pt-16 text-center">
      <h1 className="text-2xl">
        <FormattedMessage id="scenarios.web.browser.title" />
      </h1>
      {currentLoad < 348 ? (
        <div className="relative mx-auto w-[348px] pb-4">
          <div id="progress-bar" className="absolute h-4 w-[348px] rounded-md bg-slate-600" />
          <div id="progress" className="absolute h-4 rounded-md bg-lime-500" style={{ width: `${currentLoad}px` }} />
        </div>
      ) : (
        <>
          <h1 className="text-2xl text-white">
            <FormattedMessage id="scenarios.web.browser.complete" />
          </h1>
          <p>
            <FormattedMessage id="scenarios.web.complete.message" values={{ type: "Updating", name: "Browser" }} />
          </p>
          <LinkBtn className="mx-auto rounded-md border-0 bg-[#4A5567] px-8 py-2 text-white" to="../">
            <FormattedMessage id="general.close" />
          </LinkBtn>
        </>
      )}
      {!buttonClicked && (
        <Button
          className="mx-auto rounded-md border-0 bg-[#4A5567] px-8 py-2 text-white"
          onClick={() => setButtonClicked(true)}
        >
          <FormattedMessage id="scenarios.web.update" />
        </Button>
      )}
    </div>
  );
}

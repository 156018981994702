import { FC, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useIntl } from "react-intl";

interface INotification {
  open: boolean;
  setOpen: (open: boolean) => void;
  children: React.ReactNode;
}

const Notification: FC<INotification> = ({ open, setOpen, children }) => {
  const intl = useIntl();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (open) {
      timer = setTimeout(() => {
        setOpen(false);
      }, 5000);
    }

    return () => {
      window.clearTimeout(timer);
    };
  }, [open, setOpen]);

  if (!open) return null;

  return (
    <div
      role="alert"
      className="absolute bottom-1 right-1 flex animate-fade-in items-center space-x-2 border-2 border-l-8 border-lime-600 bg-[rgb(29,_27,_28)] p-2 text-sm font-normal text-white"
    >
      <FaCheckCircle className="text-lime-600" />
      <span>{children}</span>
      <button
        className="-mt-1 text-xl font-bold"
        onClick={() => setOpen(false)}
        aria-label={intl.formatMessage({ id: "general.close" })}
      >
        ×
      </button>
    </div>
  );
};

export default Notification;

import { yupResolver } from "@hookform/resolvers/yup";
import { CognitoUser } from "amazon-cognito-identity-js";
import Button from "components/ui/Button/Button";
import Form from "components/ui/Form/Form";
import Input from "components/ui/Form/Input/Input";
import LoadingSpinner from "components/ui/Loading/Spinner";
import yup from "config/yup";
import CognitoService from "helpers/cognito.service";
import { matchesCurrentField } from "helpers/yup";
import { FC, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { GAEventName } from "utils/gtag";
import useFireGAEventOnMount from "hooks/useFireGAEventOnMount";

export interface IProps {
  cognitoUser?: CognitoUser;
}

type TNewPasswordForm = {
  newPassword: string;
  confirmNewPassword: string;
};

const newPasswordSchema = yup
  .object({
    newPassword: yup.string().required(),
    confirmNewPassword: yup
      .string()
      .when("newPassword", matchesCurrentField({ id: "errors.password.does.not.match" }))
      .required()
  })
  .required();

const TesteeResetPassword: FC<IProps> = props => {
  const { cognitoUser } = props;
  const methods = useForm<TNewPasswordForm>({
    resolver: yupResolver(newPasswordSchema)
  });
  const intl = useIntl();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useFireGAEventOnMount({ name: GAEventName.ScreenView, param: "Testee - Reset Temporary Password" });

  const onSubmit: SubmitHandler<TNewPasswordForm> = async data => {
    if (!cognitoUser) return;
    setIsLoading(true);

    try {
      await CognitoService.completeNewPassword(cognitoUser, data.newPassword);

      navigate("../welcome", { replace: true });
    } catch (e: any) {
      methods.reset();
      methods.setError("confirmNewPassword", { message: e.message });
    }

    setIsLoading(false);
  };

  if (isLoading) {
    return <LoadingSpinner className="m-auto" />;
  }

  return (
    <div className="m-auto w-[530px] text-center">
      <h1 className="header-900 mb-9">
        <FormattedMessage id="trainee.reset.password.title" />
      </h1>

      <Form methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <Input
          wrapperClassName="mb-9"
          labelClassName="text-left"
          label={intl.formatMessage({ id: "admin.forgotten.password.new.password.label" })}
          placeholder={intl.formatMessage({ id: "admin.forgotten.password.new.password.label" })}
          required
          type="password"
          {...methods.register("newPassword")}
        />

        <Input
          wrapperClassName="mb-9"
          labelClassName="text-left"
          label={intl.formatMessage({ id: "admin.forgotten.password.new.password.confirm.label" })}
          placeholder={intl.formatMessage({ id: "admin.forgotten.password.new.password.confirm.label" })}
          required
          type="password"
          {...methods.register("confirmNewPassword")}
        />

        <Button type="submit">
          <FormattedMessage id="general.confirm" />
        </Button>
      </Form>
    </div>
  );
};

export default TesteeResetPassword;

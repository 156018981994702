import { useQuery, useQueryClient } from "@tanstack/react-query";
import LinkBtn from "components/ui/LinkBtn/LinkBtn";
import { useApiContext } from "generated/api/apiContext";
import CognitoService from "helpers/cognito.service";
import useFireGAEventOnMount from "hooks/useFireGAEventOnMount";
import useScenarioSessionId from "hooks/useScenarioSessionId";
import FullPageContentLayout from "layouts/FullPageContent";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { fireGAEvent, GAEventName } from "utils/gtag";

const ScenarioResultsShareInfo: FC = () => {
  // Router
  const { scenarioId } = useParams<{ scenarioId: string }>();

  const { data } = useQuery(["authUser"], () => CognitoService.getCurrentUser());
  const sessionId = useScenarioSessionId();

  // API
  const queryClient = useQueryClient();
  const { queryKeyFn } = useApiContext();

  useFireGAEventOnMount({ name: GAEventName.ScreenView, param: "Testee - Share Additional Info" });

  const handleShareClick = () => {
    fireGAEvent({ name: GAEventName.ButtonShareDetails });
  };

  /**
   * When the user exits the test for the last time
   * Invalid the bundle and results caches
   */
  const handleExit = () => {
    if (!scenarioId || !sessionId) return; // Should never get here

    sessionStorage.removeItem("hasCompletedWalkthrough");

    queryClient.invalidateQueries({
      queryKey: queryKeyFn({
        path: "/trainee/test/{id}",
        operationId: "testControllerTraineeTest",
        variables: { pathParams: { id: scenarioId } }
      })
    });

    queryClient.invalidateQueries({
      queryKey: queryKeyFn({
        path: "/trainee/session/{id}",
        operationId: "sessionControllerSession",
        variables: { pathParams: { id: sessionId } }
      })
    });
  };

  return (
    <FullPageContentLayout mainClassName="text-center flex flex-col items-center !p-15" maxWidthClass="max-w-[638px]">
      {/* Title */}
      <h1 className="header-900 mb-9 max-w-[504px]">
        <FormattedMessage id="scenario.results.share.info.title" />
      </h1>

      {/* Desc */}
      <p className="body-900 mb-15">
        <FormattedMessage
          id="scenario.results.share.info.desc"
          values={{
            a: chunks => (
              <a
                href="https://www.cpni.gov.uk/privacy-cookies-legal-information"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                {chunks}
              </a>
            )
          }}
        />
      </p>

      {/* Buttons */}
      <div className="w-[200px]">
        <a
          href={`https://questionpro.eu/t/AB3uxvFZB3vc7c?custom1=${data?.attributes["sub"]}&custom2=${sessionId}`}
          target="_blank"
          rel="noreferrer"
          className="c-button c-button--primary mb-9 w-full"
          onClick={handleShareClick}
        >
          <FormattedMessage id="scenario.results.share.info.button.share" />
        </a>
        <LinkBtn to={`/scenario/${scenarioId}`} onClick={handleExit} variant="secondary">
          <FormattedMessage id="general.exit" />
        </LinkBtn>
      </div>
    </FullPageContentLayout>
  );
};

export default ScenarioResultsShareInfo;

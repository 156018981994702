/**
 * Generated by @openapi-codegen
 *
 * @version 0.1
 */
import * as reactQuery from "@tanstack/react-query";
import { useApiContext, ApiContext } from "./apiContext";
import type * as Fetcher from "./apiFetcher";
import { apiFetch } from "./apiFetcher";
import type * as Schemas from "./apiSchemas";

export type PortalControllerGetPortalUrlError = Fetcher.ErrorWrapper<undefined>;

export type PortalControllerGetPortalUrlVariables = ApiContext["fetcherOptions"];

export const fetchPortalControllerGetPortalUrl = (
  variables: PortalControllerGetPortalUrlVariables,
  signal?: AbortSignal
) =>
  apiFetch<Record<string, any>, PortalControllerGetPortalUrlError, undefined, {}, {}, {}>({
    url: "/dev/portal/url",
    method: "get",
    ...variables,
    signal
  });

export const usePortalControllerGetPortalUrl = <TData = Record<string, any>>(
  variables: PortalControllerGetPortalUrlVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Record<string, any>, PortalControllerGetPortalUrlError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Record<string, any>, PortalControllerGetPortalUrlError, TData>(
    queryKeyFn({ path: "/dev/portal/url", operationId: "portalControllerGetPortalUrl", variables }),
    ({ signal }) => fetchPortalControllerGetPortalUrl({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type AuthControllerAuthPageError = Fetcher.ErrorWrapper<undefined>;

export type AuthControllerAuthPageVariables = ApiContext["fetcherOptions"];

export const fetchAuthControllerAuthPage = (variables: AuthControllerAuthPageVariables, signal?: AbortSignal) =>
  apiFetch<string, AuthControllerAuthPageError, undefined, {}, {}, {}>({
    url: "/dev/auth",
    method: "get",
    ...variables,
    signal
  });

export const useAuthControllerAuthPage = <TData = string>(
  variables: AuthControllerAuthPageVariables,
  options?: Omit<reactQuery.UseQueryOptions<string, AuthControllerAuthPageError, TData>, "queryKey" | "queryFn">
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<string, AuthControllerAuthPageError, TData>(
    queryKeyFn({ path: "/dev/auth", operationId: "authControllerAuthPage", variables }),
    ({ signal }) => fetchAuthControllerAuthPage({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SuccessControllerSuccessPageError = Fetcher.ErrorWrapper<undefined>;

export type SuccessControllerSuccessPageVariables = ApiContext["fetcherOptions"];

export const fetchSuccessControllerSuccessPage = (
  variables: SuccessControllerSuccessPageVariables,
  signal?: AbortSignal
) =>
  apiFetch<string, SuccessControllerSuccessPageError, undefined, {}, {}, {}>({
    url: "/dev/auth/success",
    method: "get",
    ...variables,
    signal
  });

export const useSuccessControllerSuccessPage = <TData = string>(
  variables: SuccessControllerSuccessPageVariables,
  options?: Omit<reactQuery.UseQueryOptions<string, SuccessControllerSuccessPageError, TData>, "queryKey" | "queryFn">
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<string, SuccessControllerSuccessPageError, TData>(
    queryKeyFn({ path: "/dev/auth/success", operationId: "successControllerSuccessPage", variables }),
    ({ signal }) => fetchSuccessControllerSuccessPage({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TestControllerTraineeTestPathParams = {
  id: string;
};

export type TestControllerTraineeTestError = Fetcher.ErrorWrapper<undefined>;

export type TestControllerTraineeTestVariables = {
  pathParams: TestControllerTraineeTestPathParams;
} & ApiContext["fetcherOptions"];

/**
 * Get a single test by its ID. Returns test details and all content
 */
export const fetchTestControllerTraineeTest = (variables: TestControllerTraineeTestVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.TestResponseDto,
    TestControllerTraineeTestError,
    undefined,
    {},
    {},
    TestControllerTraineeTestPathParams
  >({ url: "/trainee/test/{id}", method: "get", ...variables, signal });

/**
 * Get a single test by its ID. Returns test details and all content
 */
export const useTestControllerTraineeTest = <TData = Schemas.TestResponseDto>(
  variables: TestControllerTraineeTestVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.TestResponseDto, TestControllerTraineeTestError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TestResponseDto, TestControllerTraineeTestError, TData>(
    queryKeyFn({ path: "/trainee/test/{id}", operationId: "testControllerTraineeTest", variables }),
    ({ signal }) => fetchTestControllerTraineeTest({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type CreateControllerCreateUserError = Fetcher.ErrorWrapper<undefined>;

export type CreateControllerCreateUserVariables = {
  body: Schemas.CreateUserDTO;
} & ApiContext["fetcherOptions"];

/**
 * Creates a new user
 */
export const fetchCreateControllerCreateUser = (variables: CreateControllerCreateUserVariables, signal?: AbortSignal) =>
  apiFetch<Schemas.CreateUserResponse, CreateControllerCreateUserError, Schemas.CreateUserDTO, {}, {}, {}>({
    url: "/trainee/user/create",
    method: "post",
    ...variables,
    signal
  });

/**
 * Creates a new user
 */
export const useCreateControllerCreateUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CreateUserResponse,
      CreateControllerCreateUserError,
      CreateControllerCreateUserVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.CreateUserResponse,
    CreateControllerCreateUserError,
    CreateControllerCreateUserVariables
  >(
    (variables: CreateControllerCreateUserVariables) =>
      fetchCreateControllerCreateUser({ ...fetcherOptions, ...variables }),
    options
  );
};

export type InteractionControllerInteractionPathParams = {
  /**
   * ID of the currently running session
   */
  sessionId: string;
};

export type InteractionControllerInteractionError = Fetcher.ErrorWrapper<undefined>;

export type InteractionControllerInteractionVariables = {
  body: Schemas.RegisterInteractionDto;
  pathParams: InteractionControllerInteractionPathParams;
} & ApiContext["fetcherOptions"];

/**
 * Register an interaction performed by a user on an email.
 *       Can be either a suggested action (from the email's actions properties), or general actions, such as mouse movement.
 */
export const fetchInteractionControllerInteraction = (
  variables: InteractionControllerInteractionVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.InteractionResponseDto,
    InteractionControllerInteractionError,
    Schemas.RegisterInteractionDto,
    {},
    {},
    InteractionControllerInteractionPathParams
  >({ url: "/trainee/session/{sessionId}/interaction", method: "put", ...variables, signal });

/**
 * Register an interaction performed by a user on an email.
 *       Can be either a suggested action (from the email's actions properties), or general actions, such as mouse movement.
 */
export const useInteractionControllerInteraction = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.InteractionResponseDto,
      InteractionControllerInteractionError,
      InteractionControllerInteractionVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.InteractionResponseDto,
    InteractionControllerInteractionError,
    InteractionControllerInteractionVariables
  >(
    (variables: InteractionControllerInteractionVariables) =>
      fetchInteractionControllerInteraction({ ...fetcherOptions, ...variables }),
    options
  );
};

export type SessionControllerSessionPathParams = {
  id: string;
};

export type SessionControllerSessionError = Fetcher.ErrorWrapper<undefined>;

export type SessionControllerSessionVariables = {
  pathParams: SessionControllerSessionPathParams;
} & ApiContext["fetcherOptions"];

/**
 * Get the results of a test by the session ID. Returns Phishing Rate, Scores, Phishing emails and Interactions
 */
export const fetchSessionControllerSession = (variables: SessionControllerSessionVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.SessionResponseDto,
    SessionControllerSessionError,
    undefined,
    {},
    {},
    SessionControllerSessionPathParams
  >({ url: "/trainee/session/{id}", method: "get", ...variables, signal });

/**
 * Get the results of a test by the session ID. Returns Phishing Rate, Scores, Phishing emails and Interactions
 */
export const useSessionControllerSession = <TData = Schemas.SessionResponseDto>(
  variables: SessionControllerSessionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.SessionResponseDto, SessionControllerSessionError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.SessionResponseDto, SessionControllerSessionError, TData>(
    queryKeyFn({ path: "/trainee/session/{id}", operationId: "sessionControllerSession", variables }),
    ({ signal }) => fetchSessionControllerSession({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SessionControllerUpdateSessionPathParams = {
  id: string;
};

export type SessionControllerUpdateSessionError = Fetcher.ErrorWrapper<undefined>;

export type SessionControllerUpdateSessionVariables = {
  body?: Schemas.UpdateSessionInputDto;
  pathParams: SessionControllerUpdateSessionPathParams;
} & ApiContext["fetcherOptions"];

/**
 * Updates a Session
 */
export const fetchSessionControllerUpdateSession = (
  variables: SessionControllerUpdateSessionVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.SessionResponseDto,
    SessionControllerUpdateSessionError,
    Schemas.UpdateSessionInputDto,
    {},
    {},
    SessionControllerUpdateSessionPathParams
  >({ url: "/trainee/session/{id}", method: "patch", ...variables, signal });

/**
 * Updates a Session
 */
export const useSessionControllerUpdateSession = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.SessionResponseDto,
      SessionControllerUpdateSessionError,
      SessionControllerUpdateSessionVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.SessionResponseDto,
    SessionControllerUpdateSessionError,
    SessionControllerUpdateSessionVariables
  >(
    (variables: SessionControllerUpdateSessionVariables) =>
      fetchSessionControllerUpdateSession({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TraineeControllerTraineesError = Fetcher.ErrorWrapper<undefined>;

export type TraineeControllerTraineesResponse = Schemas.TraineeResponseDto[];

export type TraineeControllerTraineesVariables = ApiContext["fetcherOptions"];

/**
 * Lists all Trainees by their usernames
 */
export const fetchTraineeControllerTrainees = (variables: TraineeControllerTraineesVariables, signal?: AbortSignal) =>
  apiFetch<TraineeControllerTraineesResponse, TraineeControllerTraineesError, undefined, {}, {}, {}>({
    url: "/implementer/organisation/trainees",
    method: "get",
    ...variables,
    signal
  });

/**
 * Lists all Trainees by their usernames
 */
export const useTraineeControllerTrainees = <TData = TraineeControllerTraineesResponse>(
  variables: TraineeControllerTraineesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<TraineeControllerTraineesResponse, TraineeControllerTraineesError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<TraineeControllerTraineesResponse, TraineeControllerTraineesError, TData>(
    queryKeyFn({ path: "/implementer/organisation/trainees", operationId: "traineeControllerTrainees", variables }),
    ({ signal }) => fetchTraineeControllerTrainees({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TraineeControllerDeleteUserPathParams = {
  id: string;
};

export type TraineeControllerDeleteUserError = Fetcher.ErrorWrapper<undefined>;

export type TraineeControllerDeleteUserVariables = {
  pathParams: TraineeControllerDeleteUserPathParams;
} & ApiContext["fetcherOptions"];

/**
 * Deletes a user from the database and triggers Cognito to remove the user from the user pool
 */
export const fetchTraineeControllerDeleteUser = (
  variables: TraineeControllerDeleteUserVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.DeleteTraineeResponseDto,
    TraineeControllerDeleteUserError,
    undefined,
    {},
    {},
    TraineeControllerDeleteUserPathParams
  >({ url: "/implementer/organisation/trainee/{id}", method: "delete", ...variables, signal });

/**
 * Deletes a user from the database and triggers Cognito to remove the user from the user pool
 */
export const useTraineeControllerDeleteUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.DeleteTraineeResponseDto,
      TraineeControllerDeleteUserError,
      TraineeControllerDeleteUserVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.DeleteTraineeResponseDto,
    TraineeControllerDeleteUserError,
    TraineeControllerDeleteUserVariables
  >(
    (variables: TraineeControllerDeleteUserVariables) =>
      fetchTraineeControllerDeleteUser({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TraineeControllerResetPasswordPathParams = {
  id: string;
};

export type TraineeControllerResetPasswordError = Fetcher.ErrorWrapper<undefined>;

export type TraineeControllerResetPasswordVariables = {
  pathParams: TraineeControllerResetPasswordPathParams;
} & ApiContext["fetcherOptions"];

/**
 * Resets a user password
 */
export const fetchTraineeControllerResetPassword = (
  variables: TraineeControllerResetPasswordVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.ResetPasswordResponseDto,
    TraineeControllerResetPasswordError,
    undefined,
    {},
    {},
    TraineeControllerResetPasswordPathParams
  >({ url: "/implementer/organisation/trainee/{id}/resetPassword", method: "post", ...variables, signal });

/**
 * Resets a user password
 */
export const useTraineeControllerResetPassword = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ResetPasswordResponseDto,
      TraineeControllerResetPasswordError,
      TraineeControllerResetPasswordVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ResetPasswordResponseDto,
    TraineeControllerResetPasswordError,
    TraineeControllerResetPasswordVariables
  >(
    (variables: TraineeControllerResetPasswordVariables) =>
      fetchTraineeControllerResetPassword({ ...fetcherOptions, ...variables }),
    options
  );
};

export type CreateControllerCreateTestError = Fetcher.ErrorWrapper<undefined>;

export type CreateControllerCreateTestVariables = {
  body: Schemas.CreateTestDTO;
} & ApiContext["fetcherOptions"];

/**
 * Creates a test and returns it
 */
export const fetchCreateControllerCreateTest = (variables: CreateControllerCreateTestVariables, signal?: AbortSignal) =>
  apiFetch<Schemas.CreateTestResponseDto, CreateControllerCreateTestError, Schemas.CreateTestDTO, {}, {}, {}>({
    url: "/implementer/test/create",
    method: "post",
    ...variables,
    signal
  });

/**
 * Creates a test and returns it
 */
export const useCreateControllerCreateTest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CreateTestResponseDto,
      CreateControllerCreateTestError,
      CreateControllerCreateTestVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.CreateTestResponseDto,
    CreateControllerCreateTestError,
    CreateControllerCreateTestVariables
  >(
    (variables: CreateControllerCreateTestVariables) =>
      fetchCreateControllerCreateTest({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DashboardControllerTestUserListPathParams = {
  testId: string;
};

export type DashboardControllerTestUserListQueryParams = {
  /**
   * @default completionDate
   */
  sortBy?: string;
  /**
   * @default ASC
   */
  order?: "ASC" | "DESC";
  /**
   * @minimum 1
   * @default 1
   */
  page?: number;
  /**
   * @minimum 1
   * @maximum 50
   * @default 10
   */
  take?: number;
};

export type DashboardControllerTestUserListError = Fetcher.ErrorWrapper<undefined>;

export type DashboardControllerTestUserListResponse = {
  data: Schemas.TestUsersResponseDto[];
  meta: Schemas.PageMetaDto;
};

export type DashboardControllerTestUserListVariables = {
  pathParams: DashboardControllerTestUserListPathParams;
  queryParams?: DashboardControllerTestUserListQueryParams;
} & ApiContext["fetcherOptions"];

/**
 * Get a list of users who have completed the test
 */
export const fetchDashboardControllerTestUserList = (
  variables: DashboardControllerTestUserListVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    DashboardControllerTestUserListResponse,
    DashboardControllerTestUserListError,
    undefined,
    {},
    DashboardControllerTestUserListQueryParams,
    DashboardControllerTestUserListPathParams
  >({ url: "/implementer/dashboard/test/{testId}/trainees", method: "get", ...variables, signal });

/**
 * Get a list of users who have completed the test
 */
export const useDashboardControllerTestUserList = <TData = DashboardControllerTestUserListResponse>(
  variables: DashboardControllerTestUserListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<DashboardControllerTestUserListResponse, DashboardControllerTestUserListError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<DashboardControllerTestUserListResponse, DashboardControllerTestUserListError, TData>(
    queryKeyFn({
      path: "/implementer/dashboard/test/{testId}/trainees",
      operationId: "dashboardControllerTestUserList",
      variables
    }),
    ({ signal }) => fetchDashboardControllerTestUserList({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DashboardControllerDashboardQueryParams = {
  /**
   * @default completionDate
   */
  sortBy?: string;
  /**
   * @default ASC
   */
  order?: "ASC" | "DESC";
  /**
   * @minimum 1
   * @default 1
   */
  page?: number;
  /**
   * @minimum 1
   * @maximum 50
   * @default 10
   */
  take?: number;
};

export type DashboardControllerDashboardError = Fetcher.ErrorWrapper<undefined>;

export type DashboardControllerDashboardResponse = {
  data: Schemas.DashboardResponseDto;
  meta: Schemas.PageMetaDto;
};

export type DashboardControllerDashboardVariables = {
  queryParams?: DashboardControllerDashboardQueryParams;
} & ApiContext["fetcherOptions"];

/**
 * Get average scores for all trainees for each test
 */
export const fetchDashboardControllerDashboard = (
  variables: DashboardControllerDashboardVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    DashboardControllerDashboardResponse,
    DashboardControllerDashboardError,
    undefined,
    {},
    DashboardControllerDashboardQueryParams,
    {}
  >({ url: "/implementer/dashboard", method: "get", ...variables, signal });

/**
 * Get average scores for all trainees for each test
 */
export const useDashboardControllerDashboard = <TData = DashboardControllerDashboardResponse>(
  variables: DashboardControllerDashboardVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<DashboardControllerDashboardResponse, DashboardControllerDashboardError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<DashboardControllerDashboardResponse, DashboardControllerDashboardError, TData>(
    queryKeyFn({ path: "/implementer/dashboard", operationId: "dashboardControllerDashboard", variables }),
    ({ signal }) => fetchDashboardControllerDashboard({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type OrganisationControllerDeleteOrganisationError = Fetcher.ErrorWrapper<undefined>;

export type OrganisationControllerDeleteOrganisationVariables = ApiContext["fetcherOptions"];

/**
 * Deletes an Organisation
 */
export const fetchOrganisationControllerDeleteOrganisation = (
  variables: OrganisationControllerDeleteOrganisationVariables,
  signal?: AbortSignal
) =>
  apiFetch<Schemas.OrganisationResponseDto, OrganisationControllerDeleteOrganisationError, undefined, {}, {}, {}>({
    url: "/implementer/organisation/delete",
    method: "delete",
    ...variables,
    signal
  });

/**
 * Deletes an Organisation
 */
export const useOrganisationControllerDeleteOrganisation = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OrganisationResponseDto,
      OrganisationControllerDeleteOrganisationError,
      OrganisationControllerDeleteOrganisationVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.OrganisationResponseDto,
    OrganisationControllerDeleteOrganisationError,
    OrganisationControllerDeleteOrganisationVariables
  >(
    (variables: OrganisationControllerDeleteOrganisationVariables) =>
      fetchOrganisationControllerDeleteOrganisation({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UserControllerUpdateUserError = Fetcher.ErrorWrapper<undefined>;

export type UserControllerUpdateUserVariables = {
  body: Schemas.UpdateUserDTO;
} & ApiContext["fetcherOptions"];

export const fetchUserControllerUpdateUser = (variables: UserControllerUpdateUserVariables, signal?: AbortSignal) =>
  apiFetch<Schemas.UserResponseDto, UserControllerUpdateUserError, Schemas.UpdateUserDTO, {}, {}, {}>({
    url: "/implementer/user",
    method: "patch",
    ...variables,
    signal
  });

export const useUserControllerUpdateUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.UserResponseDto,
      UserControllerUpdateUserError,
      UserControllerUpdateUserVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.UserResponseDto,
    UserControllerUpdateUserError,
    UserControllerUpdateUserVariables
  >(
    (variables: UserControllerUpdateUserVariables) =>
      fetchUserControllerUpdateUser({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TestControllerImplementerGetAllTestsError = Fetcher.ErrorWrapper<undefined>;

export type TestControllerImplementerGetAllTestsResponse = Schemas.DetailedTestResponseDto[];

export type TestControllerImplementerGetAllTestsVariables = ApiContext["fetcherOptions"];

/**
 * Returns all the tests
 */
export const fetchTestControllerImplementerGetAllTests = (
  variables: TestControllerImplementerGetAllTestsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    TestControllerImplementerGetAllTestsResponse,
    TestControllerImplementerGetAllTestsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/implementer/tests", method: "get", ...variables, signal });

/**
 * Returns all the tests
 */
export const useTestControllerImplementerGetAllTests = <TData = TestControllerImplementerGetAllTestsResponse>(
  variables: TestControllerImplementerGetAllTestsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      TestControllerImplementerGetAllTestsResponse,
      TestControllerImplementerGetAllTestsError,
      TData
    >,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    TestControllerImplementerGetAllTestsResponse,
    TestControllerImplementerGetAllTestsError,
    TData
  >(
    queryKeyFn({ path: "/implementer/tests", operationId: "testControllerImplementerGetAllTests", variables }),
    ({ signal }) => fetchTestControllerImplementerGetAllTests({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TestControllerImplementerViewTestPathParams = {
  id: string;
};

export type TestControllerImplementerViewTestError = Fetcher.ErrorWrapper<undefined>;

export type TestControllerImplementerViewTestVariables = {
  pathParams: TestControllerImplementerViewTestPathParams;
} & ApiContext["fetcherOptions"];

/**
 * Views test in greater detail
 */
export const fetchTestControllerImplementerViewTest = (
  variables: TestControllerImplementerViewTestVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.DetailedTestResponseDto,
    TestControllerImplementerViewTestError,
    undefined,
    {},
    {},
    TestControllerImplementerViewTestPathParams
  >({ url: "/implementer/test/{id}/detail", method: "get", ...variables, signal });

/**
 * Views test in greater detail
 */
export const useTestControllerImplementerViewTest = <TData = Schemas.DetailedTestResponseDto>(
  variables: TestControllerImplementerViewTestVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DetailedTestResponseDto, TestControllerImplementerViewTestError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DetailedTestResponseDto, TestControllerImplementerViewTestError, TData>(
    queryKeyFn({ path: "/implementer/test/{id}/detail", operationId: "testControllerImplementerViewTest", variables }),
    ({ signal }) => fetchTestControllerImplementerViewTest({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TestControllerImplementerTestPathParams = {
  id: string;
};

export type TestControllerImplementerTestError = Fetcher.ErrorWrapper<undefined>;

export type TestControllerImplementerTestVariables = {
  pathParams: TestControllerImplementerTestPathParams;
} & ApiContext["fetcherOptions"];

/**
 * Get a single test by its ID. Returns test details and all content
 */
export const fetchTestControllerImplementerTest = (
  variables: TestControllerImplementerTestVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TestResponseDto,
    TestControllerImplementerTestError,
    undefined,
    {},
    {},
    TestControllerImplementerTestPathParams
  >({ url: "/implementer/test/{id}/full", method: "get", ...variables, signal });

/**
 * Get a single test by its ID. Returns test details and all content
 */
export const useTestControllerImplementerTest = <TData = Schemas.TestResponseDto>(
  variables: TestControllerImplementerTestVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.TestResponseDto, TestControllerImplementerTestError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TestResponseDto, TestControllerImplementerTestError, TData>(
    queryKeyFn({ path: "/implementer/test/{id}/full", operationId: "testControllerImplementerTest", variables }),
    ({ signal }) => fetchTestControllerImplementerTest({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TestControllerImplementerDeleteTestPathParams = {
  id: string;
};

export type TestControllerImplementerDeleteTestError = Fetcher.ErrorWrapper<undefined>;

export type TestControllerImplementerDeleteTestVariables = {
  pathParams: TestControllerImplementerDeleteTestPathParams;
} & ApiContext["fetcherOptions"];

/**
 * Deletes a Test
 */
export const fetchTestControllerImplementerDeleteTest = (
  variables: TestControllerImplementerDeleteTestVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.DetailedTestResponseDto,
    TestControllerImplementerDeleteTestError,
    undefined,
    {},
    {},
    TestControllerImplementerDeleteTestPathParams
  >({ url: "/implementer/test/{id}/delete", method: "delete", ...variables, signal });

/**
 * Deletes a Test
 */
export const useTestControllerImplementerDeleteTest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.DetailedTestResponseDto,
      TestControllerImplementerDeleteTestError,
      TestControllerImplementerDeleteTestVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.DetailedTestResponseDto,
    TestControllerImplementerDeleteTestError,
    TestControllerImplementerDeleteTestVariables
  >(
    (variables: TestControllerImplementerDeleteTestVariables) =>
      fetchTestControllerImplementerDeleteTest({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ScenarioControllerScenarioPhishingEmailsPathParams = {
  scenario: string;
};

export type ScenarioControllerScenarioPhishingEmailsError = Fetcher.ErrorWrapper<undefined>;

export type ScenarioControllerScenarioPhishingEmailsVariables = {
  pathParams: ScenarioControllerScenarioPhishingEmailsPathParams;
} & ApiContext["fetcherOptions"];

/**
 * Supplies all phishing emails for a given scenario
 */
export const fetchScenarioControllerScenarioPhishingEmails = (
  variables: ScenarioControllerScenarioPhishingEmailsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.ScenarioResponseDto,
    ScenarioControllerScenarioPhishingEmailsError,
    undefined,
    {},
    {},
    ScenarioControllerScenarioPhishingEmailsPathParams
  >({ url: "/implementer/scenario/{scenario}/phishing", method: "get", ...variables, signal });

/**
 * Supplies all phishing emails for a given scenario
 */
export const useScenarioControllerScenarioPhishingEmails = <TData = Schemas.ScenarioResponseDto>(
  variables: ScenarioControllerScenarioPhishingEmailsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ScenarioResponseDto, ScenarioControllerScenarioPhishingEmailsError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.ScenarioResponseDto, ScenarioControllerScenarioPhishingEmailsError, TData>(
    queryKeyFn({
      path: "/implementer/scenario/{scenario}/phishing",
      operationId: "scenarioControllerScenarioPhishingEmails",
      variables
    }),
    ({ signal }) => fetchScenarioControllerScenarioPhishingEmails({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type InviteControllerInviteOrganisationError = Fetcher.ErrorWrapper<undefined>;

export type InviteControllerInviteOrganisationVariables = {
  body: Schemas.InviteOrganisationDto;
} & ApiContext["fetcherOptions"];

/**
 * Creates an organisation, and generates an invite code for a user signup
 */
export const fetchInviteControllerInviteOrganisation = (
  variables: InviteControllerInviteOrganisationVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.InviteOrganisationResponseDto,
    InviteControllerInviteOrganisationError,
    Schemas.InviteOrganisationDto,
    {},
    {},
    {}
  >({ url: "/admin/invite", method: "post", ...variables, signal });

/**
 * Creates an organisation, and generates an invite code for a user signup
 */
export const useInviteControllerInviteOrganisation = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.InviteOrganisationResponseDto,
      InviteControllerInviteOrganisationError,
      InviteControllerInviteOrganisationVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.InviteOrganisationResponseDto,
    InviteControllerInviteOrganisationError,
    InviteControllerInviteOrganisationVariables
  >(
    (variables: InviteControllerInviteOrganisationVariables) =>
      fetchInviteControllerInviteOrganisation({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TestControllerCheckPathParams = {
  id: string;
};

export type TestControllerCheckError = Fetcher.ErrorWrapper<undefined>;

export type TestControllerCheckVariables = {
  pathParams: TestControllerCheckPathParams;
} & ApiContext["fetcherOptions"];

/**
 * Check if a test exists before the user is authenticated. Returns a boolean value only.
 */
export const fetchTestControllerCheck = (variables: TestControllerCheckVariables, signal?: AbortSignal) =>
  apiFetch<Schemas.TestCheckResponseDTO, TestControllerCheckError, undefined, {}, {}, TestControllerCheckPathParams>({
    url: "/public/test/check/{id}",
    method: "get",
    ...variables,
    signal
  });

/**
 * Check if a test exists before the user is authenticated. Returns a boolean value only.
 */
export const useTestControllerCheck = <TData = Schemas.TestCheckResponseDTO>(
  variables: TestControllerCheckVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.TestCheckResponseDTO, TestControllerCheckError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TestCheckResponseDTO, TestControllerCheckError, TData>(
    queryKeyFn({ path: "/public/test/check/{id}", operationId: "testControllerCheck", variables }),
    ({ signal }) => fetchTestControllerCheck({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type QueryOperation =
  | {
      path: "/dev/portal/url";
      operationId: "portalControllerGetPortalUrl";
      variables: PortalControllerGetPortalUrlVariables;
    }
  | {
      path: "/dev/auth";
      operationId: "authControllerAuthPage";
      variables: AuthControllerAuthPageVariables;
    }
  | {
      path: "/dev/auth/success";
      operationId: "successControllerSuccessPage";
      variables: SuccessControllerSuccessPageVariables;
    }
  | {
      path: "/trainee/test/{id}";
      operationId: "testControllerTraineeTest";
      variables: TestControllerTraineeTestVariables;
    }
  | {
      path: "/trainee/session/{id}";
      operationId: "sessionControllerSession";
      variables: SessionControllerSessionVariables;
    }
  | {
      path: "/implementer/organisation/trainees";
      operationId: "traineeControllerTrainees";
      variables: TraineeControllerTraineesVariables;
    }
  | {
      path: "/implementer/dashboard/test/{testId}/trainees";
      operationId: "dashboardControllerTestUserList";
      variables: DashboardControllerTestUserListVariables;
    }
  | {
      path: "/implementer/dashboard";
      operationId: "dashboardControllerDashboard";
      variables: DashboardControllerDashboardVariables;
    }
  | {
      path: "/implementer/tests";
      operationId: "testControllerImplementerGetAllTests";
      variables: TestControllerImplementerGetAllTestsVariables;
    }
  | {
      path: "/implementer/test/{id}/detail";
      operationId: "testControllerImplementerViewTest";
      variables: TestControllerImplementerViewTestVariables;
    }
  | {
      path: "/implementer/test/{id}/full";
      operationId: "testControllerImplementerTest";
      variables: TestControllerImplementerTestVariables;
    }
  | {
      path: "/implementer/scenario/{scenario}/phishing";
      operationId: "scenarioControllerScenarioPhishingEmails";
      variables: ScenarioControllerScenarioPhishingEmailsVariables;
    }
  | {
      path: "/public/test/check/{id}";
      operationId: "testControllerCheck";
      variables: TestControllerCheckVariables;
    };

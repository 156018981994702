import InputError from "components/ui/Form/InputError/InputError";
import ComboBox, { IComboBoxProps } from "./ComboBox";
import { UseControllerProps, useController } from "react-hook-form";

interface RHFComboBoxProps extends Omit<IComboBoxProps, "value" | "onChange">, UseControllerProps<any> {}

const RHFComboBox = ({ options, placeholder, ...useControlProps }: RHFComboBoxProps) => {
  const { field } = useController(useControlProps);
  return (
    <div>
      <ComboBox onChange={field.onChange} options={options} placeholder={placeholder} />
      <InputError name={useControlProps.name} />
    </div>
  );
};

export default RHFComboBox;

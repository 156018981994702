import FullPageContentLayout from "layouts/FullPageContent";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

export interface IProps {}

const TakenTestErrorWrapper: FC<IProps> = props => {
  return (
    <FullPageContentLayout hasError>
      <div className="m-auto max-w-[610px] text-center">
        <h1 className="header-900 mb-9">
          <FormattedMessage id={"errors.already.taken.test.title"} />
        </h1>

        <p className="body-900">
          <FormattedMessage id={"errors.already.taken.test.desc"} />
        </p>
      </div>
    </FullPageContentLayout>
  );
};

export default TakenTestErrorWrapper;

import StepModal, { IStepProps } from "components/ui/StepModal/StepModal";
import { Scenario } from "generated/api/apiSchemas";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import HallmarkTask, { IHallmarkTaskProps } from "./HallmarkTask";
import { useScenarioControllerScenarioPhishingEmails } from "generated/api/apiComponents";

interface IProps {
  setShowModal: (value: boolean) => void;
  scenario?: Scenario | "";
}

interface IExplainerProps {
  title: string;
  description?: string[];
  bullets?: string[];
}

const ExplainerSlide: FC<IExplainerProps> = ({ title, description, bullets = [] }) => {
  return (
    <div className="p-9">
      <h2 className="header-900 mb-9">
        <FormattedMessage id={title} />
      </h2>
      <div className="body-800 text-left">
        {description?.[0] && (
          <p className="mb-9">
            <FormattedMessage id={description[0]} />
          </p>
        )}
        {!!bullets.length && (
          <ul className="list-inside list-disc">
            {bullets.map(bullet => (
              <li className="mb-1" key={bullet}>
                <FormattedMessage id={bullet} />
              </li>
            ))}
          </ul>
        )}
        {description?.[1] && (
          <p className="my-9">
            <FormattedMessage id="welcome.HR_ASSISTANT.story.desc.2" />
          </p>
        )}
        <div className="text-center">
          <p>
            <FormattedMessage id="admin.dashboard.scenarioExplainer.task" />
          </p>
        </div>
      </div>
    </div>
  );
};

const HallMarkTestWrapper: FC<IHallmarkTaskProps> = props => {
  return (
    <div className="bg-primary-300 p-9">
      <p className="header-300 mt-[68px] mb-9 !font-light">
        <FormattedMessage
          id="admin.dashboard.scenarioExplainer.hallmark.task"
          values={{
            b: chunks => <b className="header-300">{chunks}</b>
          }}
        />
      </p>
      <HallmarkTask className="text-left" {...props} />
    </div>
  );
};

const STEPS: Record<Scenario, IStepProps[]> = {
  HR_ASSISTANT: [
    {
      children: (
        <ExplainerSlide
          title="admin.dashboard.resultsByType.hrAssistant.title"
          description={["welcome.HR_ASSISTANT.story.desc.1", "welcome.HR_ASSISTANT.story.desc.2"]}
          bullets={[
            "welcome.HR_ASSISTANT.story.bullet.1",
            "welcome.HR_ASSISTANT.story.bullet.2",
            "welcome.HR_ASSISTANT.story.bullet.3",
            "welcome.HR_ASSISTANT.story.bullet.4",
            "welcome.HR_ASSISTANT.story.bullet.5"
          ]}
        />
      )
    }
  ],
  PROCUREMENT: [
    {
      children: (
        <ExplainerSlide
          title="admin.dashboard.resultsByType.procurement.title"
          description={["welcome.PROCUREMENT.story.desc.1", "welcome.PROCUREMENT.story.desc.2"]}
          bullets={[
            "welcome.PROCUREMENT.story.bullet.1",
            "welcome.PROCUREMENT.story.bullet.2",
            "welcome.PROCUREMENT.story.bullet.3",
            "welcome.PROCUREMENT.story.bullet.4",
            "welcome.PROCUREMENT.story.bullet.5"
          ]}
        />
      )
    }
  ],
  IT_MANAGER: [
    {
      children: (
        <ExplainerSlide
          title="admin.dashboard.resultsByType.itManager.title"
          description={["welcome.IT_MANAGER.story.desc.1", "welcome.IT_MANAGER.story.desc.2"]}
          bullets={[
            "welcome.IT_MANAGER.story.bullet.1",
            "welcome.IT_MANAGER.story.bullet.2",
            "welcome.IT_MANAGER.story.bullet.3",
            "welcome.IT_MANAGER.story.bullet.4"
          ]}
        />
      )
    }
  ]
};

const ScenarioExplainerModal: FC<IProps> = ({ setShowModal, scenario = "" }) => {
  const { data, isLoading } = useScenarioControllerScenarioPhishingEmails(
    { pathParams: { scenario } },
    { enabled: !!scenario }
  );

  const steps = useMemo(() => {
    if (!scenario) return [];

    const mappedEmails = data?.phishingEmails.map(email => ({
      children: <HallMarkTestWrapper email={email} hallmarks={data.hallmarks} scenario={scenario} />
    }));

    if (!mappedEmails) return [];

    const _steps: IStepProps[] = [...STEPS[scenario], ...mappedEmails];

    return _steps;
  }, [data?.hallmarks, data?.phishingEmails, scenario]);

  return <StepModal isLoading={isLoading} steps={steps} open={!!scenario} onClose={() => setShowModal(false)} />;
};

export default ScenarioExplainerModal;

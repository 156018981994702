import { FC } from "react";
import Percentage from "../../Percentage/Percentage";
import { isNumber } from "helpers/number";

export interface IProps {
  totalInvited?: number;
  totalInvitedLabel: string;
  totalCompleted?: number;
  totalCompletedLabel: string;
}

const AttendanceScores: FC<IProps> = props => {
  const { totalInvited, totalCompleted, totalInvitedLabel, totalCompletedLabel } = props;

  if (!isNumber(totalCompleted) || !isNumber(totalInvited)) {
    return null;
  }

  const isNumericNonZeroRate = !!totalCompleted && !!totalInvited && isNumber(totalCompleted / totalInvited);
  const percentage = isNumericNonZeroRate ? totalCompleted / totalInvited : 0;

  return (
    <div>
      <div className="header-600 mb-8 flex justify-between">
        <span>{totalCompletedLabel}</span>
        <span>{totalInvitedLabel}</span>
      </div>

      <div
        className="header-900 flex justify-between px-4 py-1.5"
        style={{
          background: `linear-gradient(90deg, rgba(208,238,250,1) ${percentage * 100}%, rgba(7,110,178,1) ${
            percentage * 100
          }%)`
        }}
        role="progressbar"
        aria-valuemin={0}
        aria-valuenow={Number((percentage * 100).toFixed(2))}
        aria-valuemax={100}
      >
        <div className="flex items-center gap-1.5">
          <span className="header-900">{totalCompleted?.toLocaleString()}</span>
          <Percentage value={percentage} className="body-1000" />
        </div>
        <div className="flex items-center gap-1.5 text-white">
          <Percentage value={1 - percentage} className="body-1000" />
          <span className="header-900">{totalInvited?.toLocaleString()}</span>
        </div>
      </div>
    </div>
  );
};

export default AttendanceScores;

import Button from "components/ui/Button/Button";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Navigate, useNavigate } from "react-router-dom";
import useFireGAEventOnMount from "hooks/useFireGAEventOnMount";
import { GAEventName } from "utils/gtag";
import { DemoSlideName, DEMO_SLIDES } from "./constants";

// INFO: Did not use the pathname to set which slide to show
// becasue it would cause scroll position to be jumpy

const DemoPage: FC = () => {
  const [slideName, setSlideName] = useState<DemoSlideName>("organisation");

  // i18n
  const intl = useIntl();

  useFireGAEventOnMount({ name: GAEventName.ScreenView, param: "Demo Walkthrough" });

  // Router
  const navigate = useNavigate();

  const slide = DEMO_SLIDES.find(slide => slide.name === slideName);

  if (!slide) return <Navigate to="/404" />;

  const { image, alt, title, desc, name } = slide;

  const currentSlideNumber = DEMO_SLIDES.findIndex(slide => slide.name === name) + 1;
  const totalSlides = DEMO_SLIDES.length;
  const isFirstSlide = currentSlideNumber === 1;
  const isLastSlide = currentSlideNumber === totalSlides;

  /** Navigates back to previous slide or home. */
  const handleBack = () => {
    const lastSlide = DEMO_SLIDES.at(currentSlideNumber - 2);

    if (!lastSlide || isFirstSlide) return navigate(-1);

    setSlideName(lastSlide.name);
  };

  /** Navigate to next slide or home. */
  const handleNext = () => {
    const nextSlide = DEMO_SLIDES.at(currentSlideNumber);

    if (!nextSlide) return navigate("/sign-up/email");

    setSlideName(nextSlide.name);
  };

  return (
    <div className="py-15">
      {/* Card */}
      <div className="c-card mx-auto w-full max-w-[804px] p-[42px] pb-15 text-center">
        {/* Image */}
        <img src={image} alt={intl.formatMessage({ id: alt })} className="m-auto mb-15 w-[564px]" />

        {/* Info */}
        <p className="body-900 mb-4">
          Step {currentSlideNumber}/{totalSlides}
        </p>
        <h1 className="header-900 mb-9">
          <FormattedMessage id={title} />
        </h1>
        <p className="body-900 mb-15 min-h-[84px]">
          <FormattedMessage id={desc} />
        </p>

        {/* Button group */}
        <div className="flex items-center justify-between">
          <Button variant="secondary" onClick={handleBack}>
            <FormattedMessage id="demo.back" />
          </Button>

          <Button onClick={handleNext}>
            <FormattedMessage id={isLastSlide ? "demo.finish" : "demo.next"} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DemoPage;

import classNames from "classnames";
import Button from "components/ui/Button/Button";
import Modal, { ModalButtonContainer } from "components/ui/Modal/Modal";
import { Scenario } from "generated/api/apiSchemas";
import { getBackgroundColour, getScenarioBackgroundColour, getScenarioName } from "helpers/getters";
import { FC, PropsWithChildren } from "react";
import { FormattedMessage, useIntl } from "react-intl";

interface IProps {
  open: boolean;
  onClose: () => void;
  title: string;
  isDifficultyDescription?: boolean;
}

const difficultyGuidance = [
  {
    label: "Easier",
    description: "admin.sessions.difficultyGuidance.easier"
  },
  {
    label: "Medium",
    description: "admin.sessions.difficultyGuidance.medium"
  },
  {
    label: "Harder",
    description: "admin.sessions.difficultyGuidance.harder"
  }
];

const scenarioGuidance = [
  {
    label: "HR_ASSISTANT",
    description: "admin.sessions.difficultyGuidance.easier"
  },
  {
    label: "PROCUREMENT",
    description: "admin.sessions.difficultyGuidance.medium"
  },
  {
    label: "IT_MANAGER",
    description: "admin.sessions.difficultyGuidance.harder"
  }
];

const ScenarioDifficultyModal: FC<PropsWithChildren<IProps>> = props => {
  const { open, onClose, title, isDifficultyDescription = true } = props;
  const guidance = isDifficultyDescription ? difficultyGuidance : scenarioGuidance;
  const intl = useIntl();
  return (
    <Modal
      wide
      title={title}
      open={open}
      onClose={onClose}
      description={
        !isDifficultyDescription
          ? "Sessions of each of the three scenarios are suitable for any participant."
          : undefined
      }
    >
      <div className="flex flex-col gap-12">
        <div className="flex flex-col gap-9">
          {guidance.map(item => {
            return (
              <div className={classNames("flex items-center gap-9 bg-primary-300 py-9 px-6")} key={item.label}>
                <p
                  style={{
                    backgroundColor: isDifficultyDescription
                      ? getBackgroundColour(item.label)
                      : getScenarioBackgroundColour(item.label as Scenario | undefined),
                    color: item.label === "PROCUREMENT" || item.label === "Medium" ? "black" : "white"
                  }}
                  className="header-300 flex h-fit min-w-[140px] items-center justify-center py-1"
                >
                  {isDifficultyDescription ? item.label : getScenarioName(item.label as Scenario | undefined)}
                </p>
                <p className="body-900 text-left">{intl.formatMessage({ id: item.description })}</p>
              </div>
            );
          })}
        </div>
        <ModalButtonContainer>
          <Button onClick={onClose}>
            <FormattedMessage id="admin.sessions.modal.close" />
          </Button>
        </ModalButtonContainer>
      </div>
    </Modal>
  );
};

export default ScenarioDifficultyModal;

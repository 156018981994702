import LinkBtn from "components/ui/LinkBtn/LinkBtn";
import LoadingWrapper from "components/ui/Loading/LoadingWrapper";
import ResultsEmailPreview from "components/ui/admin/PhishingEmail/EmailPreview";
import ResultsEmailTimeline from "components/ui/admin/PhishingEmail/EmailTimeline";
import { ColourType } from "constants/colourType";
import useFireGAEventOnMount from "hooks/useFireGAEventOnMount";
import useGetSessionResults from "hooks/useGetSessionResults";
import ScenarioResultsLayout from "layouts/ScenarioResults";
import { FC, useContext, useEffect, useMemo, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { Navigate, useParams } from "react-router-dom";
import FinalEmailContext from "state-management/contexts/finalEmailContext";
import { GAEventName, fireGAEvent } from "utils/gtag";

export interface IProps {}

const ScenarioPhishingEmailResults: FC<IProps> = props => {
  const { data, isLoading } = useGetSessionResults();
  const { emailId } = useParams<{ emailId: string }>();
  const hasFireGAEventFor = useRef<string>();
  const { emailDispatch } = useContext(FinalEmailContext);

  useFireGAEventOnMount({ name: GAEventName.ScreenView, param: "Testee - Results (First Email)" });

  const [currentIndex, phishingEmailDetails, phishingRate, previousPhishingEmailId, nextPhishingEmailId] =
    useMemo(() => {
      const overtResults = data;

      if (!overtResults) {
        return [0];
      }

      const sortedPhishingEmails = [...overtResults.phishingEmails].sort((a, b) => a.delta - b.delta);
      const emailIndex = sortedPhishingEmails.findIndex(e => e.id === emailId);
      const previousIndex = emailIndex - 1;
      const nextIndex = emailIndex + 1;

      let responseResult: "emails-bad" | "emails-okay" | "emails-good" = "emails-bad"; // Case 'red' is emails-bad
      switch (sortedPhishingEmails[emailIndex].responseResult.toLowerCase()) {
        case ColourType.amber:
          responseResult = "emails-okay";
          break;
        case ColourType.green:
          responseResult = "emails-good";
      }

      return [
        emailIndex,
        sortedPhishingEmails[emailIndex],
        responseResult,
        previousIndex >= 0 ? sortedPhishingEmails[previousIndex].id : undefined,
        nextIndex !== sortedPhishingEmails.length ? sortedPhishingEmails[nextIndex].id : undefined
      ];
    }, [data, emailId]);

  const nextLinkTo = useMemo(() => {
    if (nextPhishingEmailId) {
      return `../phishing-emails/${nextPhishingEmailId}`;
    }

    if (!nextPhishingEmailId) {
      emailDispatch({
        type: "update",
        task: {
          finalEmailId: emailId ?? ""
        }
      });
    }
    return data?.feedback ? "../work-style-feedback" : "../share-info";
  }, [data?.feedback, nextPhishingEmailId, emailDispatch, emailId]);

  useEffect(() => {
    if (!phishingRate || hasFireGAEventFor.current === emailId) return;

    switch (phishingRate) {
      case "emails-good":
        fireGAEvent({ name: GAEventName.ScreenView, param: "Testee - Phish Result Correct" });
        break;
      case "emails-okay":
        fireGAEvent({ name: GAEventName.ScreenView, param: "Testee - Phish Result Neutral" });
        break;
      case "emails-bad":
        fireGAEvent({ name: GAEventName.ScreenView, param: "Testee - Phish Result Incorrect" });
        break;
    }

    hasFireGAEventFor.current = emailId;
  }, [phishingRate, emailId]);

  // @ts-ignore
  if (!isLoading && data?.phishingEmails.length === 0) {
    // Shouldn't get here
    return <Navigate to="../share-info" replace />;
  }

  return (
    <ScenarioResultsLayout stage={phishingRate}>
      <LoadingWrapper isLoading={isLoading}>
        <div className="mb-7 border-b border-neutral-500/20 pb-7">
          <h1 className="header-800 mb-4 text-center">
            <FormattedMessage id="scenarios.results.phishing.emails.title" values={{ num: currentIndex + 1 }} />
          </h1>

          <h2 className="body-700 text-center">
            <FormattedMessage id={`scenarios.results.phishing.emails.subTitle.${phishingRate}`} />
          </h2>
        </div>

        <div className="px-10">
          <p className="body-600 mb-8 leading-[22px]">
            <span className="font-bold">
              <FormattedMessage id="scenarios.results.phishing.emails.desc" />
            </span>{" "}
            {phishingEmailDetails?.phishingDescription}
          </p>

          <ResultsEmailPreview
            className="mb-9"
            phishingRate={phishingRate}
            subject={phishingEmailDetails?.subject}
            sender={phishingEmailDetails?.sender}
            body={phishingEmailDetails?.body}
            cc={phishingEmailDetails?.cc}
          />

          {/*@ts-ignore*/}
          <ResultsEmailTimeline interactions={phishingEmailDetails?.interactions} />

          <div className="mt-9 flex justify-between">
            {previousPhishingEmailId ? (
              <LinkBtn variant="secondary" to={`../phishing-emails/${previousPhishingEmailId}`}>
                <FormattedMessage id="scenarios.results.scores.more.previous.email" />
              </LinkBtn>
            ) : (
              <LinkBtn variant="secondary" className="px-9" to="../../results/summary">
                <FormattedMessage id="scenarios.results.phishing.emails.returnToResults" />
              </LinkBtn>
            )}

            <LinkBtn className="ml-auto px-9" to={nextLinkTo}>
              <FormattedMessage
                id={
                  nextPhishingEmailId
                    ? "scenarios.results.phishing.emails.next"
                    : "scenarios.results.scores.more.information"
                }
              />
            </LinkBtn>
          </div>
        </div>
      </LoadingWrapper>
    </ScenarioResultsLayout>
  );
};

export default ScenarioPhishingEmailResults;

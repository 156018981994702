import { FC } from "react";

interface IProps {
  className?: string;
}

const BinWhite: FC<IProps> = ({ className }) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="9.5" cy="9" r="9" fill="#076EB2" />
      <path
        d="M12.5002 5.87999C12.6659 5.87999 12.8002 6.0143 12.8002 6.17999C12.8002 6.34567 12.6659 6.47999 12.5002 6.47999H12.496C12.4628 6.47999 12.435 6.50625 12.4337 6.54022L12.2177 12.372C12.202 12.7977 11.8461 13.14 11.4202 13.14H7.5802C7.15409 13.14 6.7984 12.7971 6.78266 12.372L6.56666 6.54022C6.56543 6.50695 6.53831 6.47999 6.50441 6.47999H6.5002C6.33451 6.47999 6.2002 6.34567 6.2002 6.17999C6.2002 6.0143 6.33451 5.87999 6.5002 5.87999H7.9628C7.99576 5.87999 8.02997 5.85376 8.03884 5.82273L8.13666 5.48035C8.23701 5.1291 8.59385 4.85999 8.95917 4.85999H10.0412C10.4066 4.85999 10.7633 5.12901 10.8637 5.48035L10.9616 5.82273C10.9706 5.85435 11.0043 5.87999 11.0376 5.87999H12.5002ZM9.2602 7.55999V11.64C9.2602 11.7725 9.36765 11.88 9.5002 11.88C9.63274 11.88 9.7402 11.7725 9.7402 11.64V7.55999C9.7402 7.42744 9.63274 7.31999 9.5002 7.31999C9.36765 7.31999 9.2602 7.42744 9.2602 7.55999ZM7.9403 7.56704L8.0603 11.647C8.0642 11.7795 8.17476 11.8838 8.30725 11.8799C8.43974 11.876 8.54399 11.7654 8.54009 11.6329L8.42009 7.55293C8.41619 7.42044 8.30563 7.31619 8.17314 7.32009C8.04065 7.32399 7.9364 7.43455 7.9403 7.56704ZM10.5803 7.55293L10.4603 11.6329C10.4564 11.7654 10.5606 11.876 10.6931 11.8799C10.8256 11.8838 10.9362 11.7795 10.9401 11.647L11.0601 7.56704C11.064 7.43455 10.9597 7.32399 10.8273 7.32009C10.6948 7.31619 10.5842 7.42044 10.5803 7.55293ZM8.70725 5.87999H10.2931C10.3271 5.87999 10.3466 5.85454 10.3377 5.82315L10.2868 5.64518C10.26 5.55146 10.1387 5.45999 10.0412 5.45999H8.95917C8.86172 5.45999 8.74033 5.55153 8.71357 5.64518L8.66273 5.82315C8.65367 5.85486 8.67369 5.87999 8.70725 5.87999Z"
        fill="white"
      />
    </svg>
  );
};

export default BinWhite;

import { SortingState } from "@tanstack/react-table";
import colours from "components/ui/admin/Charts/constants/colours";
import DoughnutChart from "components/ui/admin/Charts/DoughnutChart";
import AttendanceScores from "components/ui/admin/Dashboard/AttendanceScores";
import EmptyResult from "components/ui/admin/Dashboard/EmptyResult";
import ActionBar from "components/ui/admin/Elements/ActionBar";
import Card from "components/ui/admin/Elements/Card";
import StatCard from "components/ui/admin/Elements/StatCard";
import LoadingWrapper from "components/ui/Loading/LoadingWrapper";
import Percentage from "components/ui/Percentage/Percentage";
import { DashboardControllerDashboardQueryParams, useDashboardControllerDashboard } from "generated/api/apiComponents";
import { PhishingResultsResponseDto, Scenario } from "generated/api/apiSchemas";
import useFireGAEventOnMount from "hooks/useFireGAEventOnMount";
import { FC, useMemo, useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { PopoverContent } from "../tests/details";
import DashboardTestTable from "./components/TestTable";
import WorkstyleExplainerModal from "./components/WorkstyleExplainerModal";

import classNames from "classnames";
import { ColourType } from "constants/colourType";
import { GAEventName } from "utils/gtag";
import ScenarioDoughnutCard from "./components/ScenarioDoughnutCard";
import ScenarioExplainerModal from "./components/ScenarioExplainerModal";

export const getPhishingResults = (intl: IntlShape, phishingCounts?: PhishingResultsResponseDto) => {
  if (!phishingCounts) return;

  const { red, amber, green } = phishingCounts;

  return {
    labels: [
      intl.formatMessage({ id: "admin.dashboard.doughnut.identified" }),
      intl.formatMessage({ id: "admin.dashboard.doughnut.identifiedIgnored" }),
      intl.formatMessage({ id: "admin.dashboard.doughnut.notIdentified" })
    ],
    datasets: [
      {
        data: [green?.percentage, amber?.percentage, red?.percentage],
        backgroundColor: [colours.green, colours.amber, colours.red],
        borderWidth: 0
      }
    ]
  };
};

export const getTooltipContentForPhishingResults = (
  args: { dataset: any; raw: string },
  data?: PhishingResultsResponseDto
) => {
  let type: ColourType = ColourType.red;
  switch (args.dataset.backgroundColor) {
    case colours.red:
      type = ColourType.red;
      break;
    case colours.green:
      type = ColourType.green;
      break;

    case colours.amber:
      type = ColourType.amber;
      break;
  }

  let value = data?.[type].value ?? 0;

  return <PopoverContent {...args} raw={value?.toString()} />;
};

const DashboardPage: FC = () => {
  const [page, setPage] = useState(1);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [showExplainerModal, setShowExplainerModal] = useState(false);
  const [sessionToExplain, setSessionToExplain] = useState<Scenario | undefined>(undefined);

  const getQueryParams = () => {
    let queryParams: DashboardControllerDashboardQueryParams = { page };

    if (sorting?.[0]) {
      queryParams.sortBy = sorting[0].id;
      queryParams.order = sorting[0].desc ? "DESC" : "ASC";
    }

    return queryParams;
  };

  const { data, isLoading, isFetching } = useDashboardControllerDashboard(
    {
      queryParams: getQueryParams()
    },
    { keepPreviousData: true }
  );

  const dashboardData = data?.data;
  const dashboardMeta = data?.meta;

  // i18n
  const intl = useIntl();

  const doughnutData = useMemo(
    () => getPhishingResults(intl, dashboardData?.organisation?.phishingResults?.overall),
    [dashboardData?.organisation?.phishingResults, intl]
  );

  useFireGAEventOnMount({ name: GAEventName.ScreenView, param: "Implementer - Dashboard Home" });

  return (
    <>
      <LoadingWrapper isLoading={isLoading && !data}>
        <div className="flex justify-between">
          <ActionBar titleProps={{ id: "admin.dashboard.title" }} />
        </div>

        <div className="mb-3 flex w-full flex-col gap-3 pt-9 md:flex-row">
          <Card className="flex-1" titleProps={{ id: "admin.dashboard.activeSessions" }}>
            <div className="flex flex-col gap-9 md:flex-row">
              <StatCard
                backgroundImage="total-sessions-sent"
                titleProps={{
                  id: "admin.dashboard.totalSessionsSent",
                  values: { count: dashboardData?.sessions.totalInvited }
                }}
                value={dashboardData?.sessions.totalInvited}
                className="min-w-[100px] flex-1 md:min-w-[180px]"
              />
              <StatCard
                backgroundImage="total-responses"
                titleProps={{
                  id: "admin.dashboard.totalResponses",
                  values: { count: dashboardData?.sessions.totalCompleted }
                }}
                value={dashboardData?.sessions.totalCompleted}
                className="min-w-[100px] flex-1 md:min-w-[180px]"
              />
            </div>
          </Card>
          <Card
            className="flex-1"
            titleProps={{ id: "admin.dashboard.scores" }}
            cta={{ textProps: { id: "general.whatDoesThisMean" }, onClick: () => setShowExplainerModal(true) }}
          >
            <div className="flex flex-col gap-9 md:flex-row">
              <StatCard
                backgroundImage="workstyle-score"
                titleProps={{ id: "admin.dashboard.workstyleScore" }}
                value={<Percentage value={dashboardData?.organisation?.averageWorkAccuracy ?? 0} />}
                className="min-w-[100px] flex-1 md:min-w-[180px]"
              />
              <StatCard
                backgroundImage="phishing-score"
                titleProps={{ id: "admin.dashboard.phishingScore" }}
                value={<Percentage value={dashboardData?.organisation?.averagePhishingRate ?? 0} />}
                className="min-w-[100px] flex-1 md:min-w-[180px]"
              />
            </div>
          </Card>
        </div>
        <Card className="mb-3 w-full" titleProps={{ id: "admin.dashboard.completionRate" }}>
          <div className="bg-primary-300 p-8">
            <AttendanceScores
              totalCompleted={dashboardData?.sessions?.totalCompleted}
              totalInvited={dashboardData?.sessions?.totalInvited}
              totalCompletedLabel={intl.formatMessage({ id: "admin.dashboard.attendance.scores.total.completed" })}
              totalInvitedLabel={intl.formatMessage({ id: "admin.dashboard.attendance.scores.total.invited" })}
            />
          </div>
        </Card>
        <Card
          className={classNames("mb-3 flex w-full flex-col", doughnutData ? "min-h-[554px]" : "h-[260px]")}
          titleProps={{ id: "admin.dashboard.overallPhishingResults" }}
        >
          <div className="grow">
            {doughnutData ? (
              <DoughnutChart
                chartProps={{ data: doughnutData }}
                getTooltipContent={args =>
                  getTooltipContentForPhishingResults(args, dashboardData?.organisation?.phishingResults?.overall)
                }
              />
            ) : (
              <EmptyResult />
            )}
          </div>
          {doughnutData && (
            <p className="body-600 mt-auto max-w-[720px]">
              <FormattedMessage id="admin.dashboard.doughnut.help" />
            </p>
          )}
        </Card>
        <Card className="mb-3 w-full" titleProps={{ id: "admin.dashboard.resultsByType" }}>
          <p className="body-500 -mt-6 mb-6">
            <FormattedMessage id="admin.dashboard.resultsByType.help" />
          </p>
          <div className="flex justify-between gap-[46px]">
            <ScenarioDoughnutCard
              scenario="hrAssistant"
              onScenarioSelection={setSessionToExplain}
              phishingResults={dashboardData?.organisation?.phishingResults}
              tooltipArrowPlacement="left"
            />
            <ScenarioDoughnutCard
              scenario="procurement"
              onScenarioSelection={setSessionToExplain}
              phishingResults={dashboardData?.organisation?.phishingResults}
              tooltipArrowPlacement="left"
            />

            <ScenarioDoughnutCard
              scenario="itManager"
              onScenarioSelection={setSessionToExplain}
              phishingResults={dashboardData?.organisation?.phishingResults}
              tooltipArrowPlacement="right"
            />
          </div>
        </Card>
        <Card className="w-full">
          <DashboardTestTable
            tests={dashboardData?.tests}
            meta={dashboardMeta}
            onTablePageChange={setPage}
            onTableStateChange={state => {
              setSorting(state.sorting);
            }}
          />
          {isFetching && (
            <p className="body-600 text-center">
              <FormattedMessage id="general.loading" />
            </p>
          )}
        </Card>
      </LoadingWrapper>
      <ScenarioExplainerModal scenario={sessionToExplain} setShowModal={() => setSessionToExplain(undefined)} />
      <WorkstyleExplainerModal showModal={showExplainerModal} setShowModal={setShowExplainerModal} />
    </>
  );
};

export default DashboardPage;

import classNames from "classnames";
import { PropsWithChildren } from "react";

interface IProps {
  className?: string;
  title: string;
}

export default function Header({ className, title, children }: PropsWithChildren<IProps>) {
  return (
    <div className={classNames(className, "grid h-20 w-full grid-cols-[180px_284px_1fr] bg-white")}>
      <div className="h-full bg-black" />
      <div className="grid h-full place-content-center bg-primary-500">
        <h1 className="text-3xl font-bold uppercase text-white">{title}</h1>
      </div>
      <div className="flex items-center justify-end gap-5 px-4 shadow-xlight">{children}</div>
    </div>
  );
}

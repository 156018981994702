import Button from "components/ui/Button/Button";
import Modal from "components/ui/Modal/Modal";
import EmailActions from "components/ui/scenarios/Email/Actions";
import EmailBody from "components/ui/scenarios/Email/Body";
import EmailThread from "components/ui/scenarios/Email/EmailThread";
import EmailFooter from "components/ui/scenarios/Email/Footer";
import EmailHeader from "components/ui/scenarios/Email/Header";
import { GET_FINAL_EMAIL_ID, GET_STARTED_EMAIL_ID, GET_STARTED_EMAIL_REPLY_OPTIONS } from "constants/scenario";
import { ActionResponseDto } from "generated/api/apiSchemas";
import useBundle from "hooks/useBundle";
import useInteraction from "hooks/useInteraction";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useScenarioSessionId from "hooks/useScenarioSessionId";
import GetStartedEmailDetail from "pages/scenario/inbox/components/GetStartedEmailDetail";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteEmail,
  selectAvailableEmails,
  selectReplyOptions,
  setEmailAsRead,
  setReplyOption,
  setReplyDate,
  showNotificationWithTimeout
} from "redux/modules/scenario";
import { fireGAEvent, GAEventName } from "utils/gtag";
import GetFinalEmailDetail from "./components/GetFinalEmailDetail";
import { useToastContext } from "context/toast.provider";
import DOMPurify from "dompurify";

type TParams = {
  scenarioId: string;
  emailId: string;
};

const getOverrideEmailComponent = (id: string) => {
  switch (id) {
    case GET_STARTED_EMAIL_ID:
      return GetStartedEmailDetail;
    case GET_FINAL_EMAIL_ID:
      return GetFinalEmailDetail;
  }
};

const ScenarioEmailDetail: FC = () => {
  const { openToast } = useToastContext();
  const intl = useIntl();

  // React Hooks
  const scrollBox = useRef<HTMLDivElement | null>(null);
  const [isBadInteractionModalOpen, setIsBadInteractionModalOpen] = useState(false);

  // Router
  const { emailId } = useParams<TParams>();
  const navigate = useNavigate();

  // Redux
  const availableEmails = useAppSelector(selectAvailableEmails);
  const selectedReplyOptions = useAppSelector(selectReplyOptions);
  const dispatch = useAppDispatch();
  const { data } = useBundle();
  const sessionId = useScenarioSessionId();
  const emailDate = useMemo(
    () => availableEmails.find(available => available.id === emailId)?.date,
    [availableEmails, emailId]
  );

  // Email Actions
  const replySection = useRef<HTMLDivElement>(null);

  // Mutation
  const registerInteraction = useInteraction();

  /**
   * Use click's "reply" on an email
   */
  const handleReply = async () => {
    fireGAEvent({ name: GAEventName.PhishingEmailOutcome });

    replySection.current?.scrollIntoView({
      behavior: "smooth"
    });

    registerInteraction("EMAIL_ACTION_REPLY", {
      emailId
    });
  };

  /**
   * Get emails that are replies to the email with the given id
   */
  const getChainedEmails = (emailId: string | undefined) => {
    return data?.emails.filter(({ replyToEmailId }) => replyToEmailId === emailId) ?? [];
  };

  /**
   * Use click's "delete" on an email
   */
  const handleDelete = async () => {
    fireGAEvent({ name: GAEventName.PhishingEmailOutcome });

    openToast(intl.formatMessage({ id: "scenarios.inbox.reply.action.delete.text" }));

    if (emailId) dispatch(deleteEmail(emailId));

    registerInteraction("EMAIL_ACTION_DELETE", {
      emailId
    });

    // Use same response for chained emails
    const chainedEmails = getChainedEmails(emailId);
    chainedEmails.forEach(({ id }) => {
      registerInteraction("EMAIL_ACTION_DELETE", {
        emailId: id
      });
    });
  };

  /**
   * Use click's "report" on an email
   */
  const handleReport = async () => {
    fireGAEvent({ name: GAEventName.PhishingEmailOutcome });

    dispatch(
      showNotificationWithTimeout({
        name: `scenarios.inbox.reply.action.report.name`,
        text: `scenarios.inbox.reply.action.report.text`
      })
    );

    if (emailId) dispatch(deleteEmail(emailId));

    registerInteraction("EMAIL_ACTION_REPORT", {
      emailId
    });

    // Use same response for chained emails
    const chainedEmails = getChainedEmails(emailId);
    chainedEmails.forEach(({ id }) => {
      registerInteraction("EMAIL_ACTION_REPORT", {
        emailId: id
      });
    });
  };

  /**
   * User tries to trigger an action on the "Get Started" email
   */
  const handleGetStartedEmailActionTrigger = () => {
    setIsBadInteractionModalOpen(true);
  };

  /**
   * User replies to an email using the pre-defined responses
   */
  const triggerReplyAction = useCallback(
    async (action: ActionResponseDto) => {
      if (action.type !== "REPLY" || !sessionId || !emailId) return;

      fireGAEvent({ name: GAEventName.PhishingEmailOutcome });

      dispatch(setReplyOption([emailId, action.id]));
      dispatch(setReplyDate([action.id, new Date().toString()]));

      registerInteraction("EMAIL_REPLY", {
        emailId,
        actionId: action.id
      });
    },
    [sessionId, emailId, dispatch, registerInteraction]
  );

  useEffect(() => {
    if (!emailId) return;

    const availableEmail = availableEmails.find(available => available.id === emailId);

    // If the email shouldn't be shown yet, redirect user back to Inbox
    if (!availableEmail) {
      navigate("../", { replace: true });
    }

    if (availableEmail && !availableEmail.hasBeenRead) {
      dispatch(setEmailAsRead(emailId));
    }
  }, [availableEmails, dispatch, emailId, navigate]);

  const emailDetail = useMemo(
    () =>
      data?.emails.find(email => {
        return email.id === emailId;
      }),
    [data?.emails, emailId]
  );

  const emailFooterReplyOptions = useMemo(() => {
    if (emailId === GET_STARTED_EMAIL_ID) {
      return GET_STARTED_EMAIL_REPLY_OPTIONS(intl).map(option => ({
        ...option,
        onClick: handleGetStartedEmailActionTrigger
      }));
    }

    return emailDetail?.actions.map(action => ({
      id: action.id,
      label: action.value,
      onClick: () => triggerReplyAction(action)
    }));
  }, [emailDetail, emailId, intl, triggerReplyAction]);

  // Email open
  useEffect(() => {
    // Scroll Inbox Details to the top again, whenever an email is opened
    if (scrollBox.current) {
      scrollBox.current.scrollTop = 0;
    }

    const availableEmail = availableEmails.find(available => available.id === emailId);

    if (availableEmail?.hasBeenRead || emailId === GET_FINAL_EMAIL_ID) return;

    registerInteraction("EMAIL_OPEN", {
      emailId
    });
    // eslint-disable-next-line
  }, [emailId]);

  if (!emailDetail && emailId !== GET_STARTED_EMAIL_ID && emailId !== GET_FINAL_EMAIL_ID) return null;

  const OverrideEmailComponent = emailId && getOverrideEmailComponent(emailId);

  const emailSubject = emailDetail?.subject && DOMPurify.sanitize(emailDetail.subject);

  return (
    <>
      {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
      <div className="js-reactour-step-8 flex w-full flex-col overflow-hidden">
        <div className="flex justify-end bg-white p-2.5">
          <EmailActions
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className="js-reactour-step-9"
            onReply={emailId !== GET_STARTED_EMAIL_ID ? handleReply : handleGetStartedEmailActionTrigger}
            onReport={emailId !== GET_STARTED_EMAIL_ID ? handleReport : handleGetStartedEmailActionTrigger}
            onDelete={emailId !== GET_STARTED_EMAIL_ID ? handleDelete : handleGetStartedEmailActionTrigger}
          />
        </div>

        <div className="overflow-auto" ref={scrollBox}>
          <div className="mx-[3.75rem] my-9 w-[800px]">
            {!OverrideEmailComponent ? (
              <>
                {emailId && <EmailThread emailId={emailId} className="mb-9" />}
                <EmailHeader
                  subject={emailSubject}
                  className="mb-9"
                  emailId={emailId}
                  from={emailDetail?.sender}
                  date={emailDate ? new Date(emailDate) : new Date()}
                  cc={emailDetail?.cc}
                />

                <EmailBody subject={emailSubject} body={emailDetail?.body && DOMPurify.sanitize(emailDetail.body)} />
              </>
            ) : (
              // The "Get Started Email" / "Final Email" uses Custom HTML
              <OverrideEmailComponent scrollBoxRef={scrollBox} />
            )}

            {emailId !== GET_FINAL_EMAIL_ID && (
              <EmailFooter
                // eslint-disable-next-line tailwindcss/no-custom-classname
                className="js-reactour-step-10 my-8 border-t-2 pt-8"
                selectedOptionId={emailId ? selectedReplyOptions[emailId] : undefined}
                ref={replySection}
                options={emailFooterReplyOptions}
              />
            )}
          </div>
        </div>
      </div>

      {/* Will open if user tries to interact with actions on the "Get Started" email */}
      <Modal
        title={intl.formatMessage({ id: "scenarios.inbox.get.started.email.bad.interaction.title" })}
        description={intl.formatMessage({ id: "scenarios.inbox.get.started.email.bad.interaction.subTitle" })}
        open={isBadInteractionModalOpen}
        onClose={() => setIsBadInteractionModalOpen(false)}
      >
        <Button onClick={() => setIsBadInteractionModalOpen(false)}>
          <FormattedMessage id="general.close" />
        </Button>
      </Modal>
    </>
  );
};

export default ScenarioEmailDetail;

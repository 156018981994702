import LinkBtn from "components/ui/LinkBtn/LinkBtn";
import { useState } from "react";
import BookingForm from "./BookingForm";

export default function Browser254() {
  const [submitted, setSubmitted] = useState(false);

  return (
    <div className="flex h-full flex-col text-neutral-600">
      <header className="flex items-center justify-end bg-neutral-800 p-4">
        <h1 className="text-4xl font-normal text-white">
          Tri<span className="font-bold">Metis</span>
        </h1>
      </header>
      <div className="flex flex-col items-start space-y-10 py-4 pl-4">
        <h2 className="text-3xl">Booking form</h2>
        <span className="pb-4 text-3xl font-normal">Welcome to Berkeley Court Conference Rooms</span>
      </div>
      {submitted ? (
        <div className="pl-4">
          <h2 className="pb-[36px] text-xl">Thank you for your submission.</h2>
          <LinkBtn to="../">Close Window</LinkBtn>
        </div>
      ) : (
        <BookingForm setSubmitted={setSubmitted} />
      )}
      <footer className="absolute bottom-0 mt-4 flex w-full items-center justify-start bg-neutral-800 p-4 font-normal text-white">
        <small>© Trimeta Ltd. {new Date().getFullYear()} All rights reserved.</small>
      </footer>
    </div>
  );
}

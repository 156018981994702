import { GTM_DEBUG, NODE_ENV } from "constants/env";

type EventBuilder<T extends GAEventName, P = string, R extends Object = {}> = {
  name: T;
  param?: P;
  rest?: R;
};

export enum GAEventName {
  ButtonSignUpImplementer = "button_sign-up_implementer",
  TestStart = "test_start",
  TestFinish = "test_finish",
  PhishingEmailsSent = "phishing_emails_sent",
  PhishingEmailOutcome = "phishing_email_outcome",
  ButtonDownloadWelcomePack = "button_download_welcome_pack",
  ButtonHelp = "button_help",
  ButtonHelpSend = "button_help_send",
  ButtonSettings = "button_settings",
  ButtonLogout = "button_logout",
  ButtonAddNewTest = "button_add_new_test",
  CreatedTest = "created_test",
  ButtonDeleteTest = "button_delete_test",
  ButtonSignUpTesteeConfirm = "button_sign-up_testee_confirm",
  ButtonLogInTestee = "button_log-in_testee",
  ButtonExitTest = "button_exit_test",
  ButtonDeleteTestee = "button_delete_testee",
  ButtonFindOutMore = "button_find_out_more",
  ButtonSeeDetails = "button_see_details",
  ButtonShowMeDetails = "button_show_me_details",
  ButtonSignUpImplementerConfirm = "button_sign-up_implementer_confirm",
  ButtonShareDetails = "button_share_details",
  LinkFaqsSupport = "link_faqs_support",
  LinkTermsConditions = "link_terms_conditions",
  LinkPrivacyPolicy = "link_privacy_policy",
  LinkCookies = "link_cookies",
  ScreenView = "screen_view",
  WorkStyleOptIn = "workstyle_opt_in",
  ViewToolTip = "view_tool_tip",
  StartTestTooltip = "start_test_tooltip",
  RestartTooltip = "restart_tooltip",
  StartTestEmail = "start_test_email"
}

type ButtonSignUpImplementer = EventBuilder<GAEventName.ButtonSignUpImplementer>;
type TestStart = EventBuilder<GAEventName.TestStart>;
type TestFinish = EventBuilder<GAEventName.TestFinish>;
type PhishingEmailsSent = EventBuilder<GAEventName.PhishingEmailsSent>;
type PhishingEmailOutcome = EventBuilder<GAEventName.PhishingEmailOutcome>;
type ButtonDownloadWelcomePack = EventBuilder<GAEventName.ButtonDownloadWelcomePack>;
type ButtonHelp = EventBuilder<GAEventName.ButtonHelp>;
type ButtonHelpSend = EventBuilder<GAEventName.ButtonHelpSend>;
type ButtonSettings = EventBuilder<GAEventName.ButtonSettings>;
type ButtonLogout = EventBuilder<GAEventName.ButtonLogout>;
type ButtonAddNewTest = EventBuilder<GAEventName.ButtonAddNewTest>;
type CreatedTest = EventBuilder<GAEventName.CreatedTest>;
type ButtonDeleteTest = EventBuilder<GAEventName.ButtonDeleteTest>;
type ButtonSignUpTesteeConfirm = EventBuilder<GAEventName.ButtonSignUpTesteeConfirm>;
type ButtonLogInTestee = EventBuilder<GAEventName.ButtonLogInTestee>;
type ButtonExitTest = EventBuilder<GAEventName.ButtonExitTest>;
type ButtonDeleteTestee = EventBuilder<GAEventName.ButtonDeleteTestee>;
type ButtonFindOutMore = EventBuilder<GAEventName.ButtonFindOutMore>;
type ButtonSeeDetails = EventBuilder<GAEventName.ButtonSeeDetails>;
type ButtonShowMeDetails = EventBuilder<GAEventName.ButtonShowMeDetails>;
type ButtonSignUpImplementerConfirm = EventBuilder<GAEventName.ButtonSignUpImplementerConfirm>;
type ButtonShareDetails = EventBuilder<GAEventName.ButtonShareDetails>;
type LinkFaqsSupport = EventBuilder<GAEventName.LinkFaqsSupport>;
type LinkTermsConditions = EventBuilder<GAEventName.LinkTermsConditions>;
type LinkPrivacyPolicy = EventBuilder<GAEventName.LinkPrivacyPolicy>;
type LinkCookies = EventBuilder<GAEventName.LinkCookies>;
type ScreenView = EventBuilder<GAEventName.ScreenView>;
type WorkStyleOptIn = EventBuilder<GAEventName.WorkStyleOptIn, "opt in" | "opt out">;
type ViewToolTip = EventBuilder<GAEventName.ViewToolTip>;
type StartTestTooltip = EventBuilder<GAEventName.StartTestTooltip>;
type RestartTooltip = EventBuilder<GAEventName.RestartTooltip>;
type StartTestEmail = EventBuilder<GAEventName.StartTestEmail>;

export type GAEvent =
  | ButtonSignUpImplementer
  | TestStart
  | TestFinish
  | PhishingEmailsSent
  | PhishingEmailOutcome
  | ButtonDownloadWelcomePack
  | ButtonHelp
  | ButtonHelpSend
  | ButtonSettings
  | ButtonLogout
  | ButtonAddNewTest
  | CreatedTest
  | ButtonDeleteTest
  | ButtonSignUpTesteeConfirm
  | ButtonLogInTestee
  | ButtonExitTest
  | ButtonDeleteTestee
  | ButtonFindOutMore
  | ButtonSeeDetails
  | ButtonShowMeDetails
  | ButtonSignUpImplementerConfirm
  | ButtonShareDetails
  | LinkFaqsSupport
  | LinkTermsConditions
  | LinkPrivacyPolicy
  | LinkCookies
  | ScreenView
  | WorkStyleOptIn
  | ViewToolTip
  | StartTestTooltip
  | RestartTooltip
  | StartTestEmail;

interface IPayload extends Omit<GAEvent, "name"> {
  event: string;
}

export const fireGAEvent = ({ name, param, rest = {} }: GAEvent) => {
  const payload: IPayload = { ...rest, event: name };

  if (param) {
    payload.param = param;
  }

  if (NODE_ENV === "production" || GTM_DEBUG === "true") {
    // @ts-ignore - Google Tag manager will add this array to the 'window' object
    window.dataLayer?.push(payload);
  }

  if (GTM_DEBUG === "true") {
    console.log("GA Event", payload);
  }
};

import { configureStore, ConfigureStoreOptions } from "@reduxjs/toolkit";
import auth, { IAuthState } from "redux/modules/auth";
import clockTick, { IClockTickState } from "redux/modules/clockTick";
import scenario, { IScenarioState } from "redux/modules/scenario";

export type TPreLoadedState = ConfigureStoreOptions<{
  clockTick: IClockTickState;
  auth: IAuthState;
  scenario: IScenarioState;
}>["preloadedState"];

export const createStore = (preloadedState: TPreLoadedState) =>
  configureStore({
    reducer: {
      clockTick,
      auth,
      scenario
    },
    preloadedState
  });

export const store = createStore({});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

import { useEffect, useRef } from "react";
import { fireGAEvent, GAEvent } from "utils/gtag";

const useFireGAEventOnMount = (event: GAEvent) => {
  const eventFired = useRef(false);
  const eventRef = useRef(event);

  useEffect(() => {
    if (!eventFired.current) {
      eventFired.current = true;

      fireGAEvent(eventRef.current);
    }
  }, []);
};

export default useFireGAEventOnMount;

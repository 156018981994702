import Button from "components/ui/Button/Button";
import Input from "components/ui/Form/Input/Input";
import { TResetPasswordInputs } from "pages/admin/auth/reset-password-flow/ImplementerResetPassword";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import useFireGAEventOnMount from "hooks/useFireGAEventOnMount";
import { GAEventName } from "utils/gtag";

export interface IProps {}

const NewPasswordForm: FC<IProps> = props => {
  const methods = useFormContext<TResetPasswordInputs>();

  // i18n
  const intl = useIntl();

  useFireGAEventOnMount({ name: GAEventName.ScreenView, param: "Implementer - Create New Password" });

  return (
    <>
      <h1 className="header-900 mb-9">
        <FormattedMessage id="admin.forgotten.password.new.password.title" />
      </h1>

      <Input
        wrapperClassName="mb-9"
        labelClassName="text-left"
        label={intl.formatMessage({ id: "admin.forgotten.password.new.password.label" })}
        placeholder={intl.formatMessage({ id: "admin.forgotten.password.new.password.label" })}
        required
        type="password"
        {...methods.register("newPassword")}
      />

      <Input
        wrapperClassName="mb-9"
        labelClassName="text-left"
        label={intl.formatMessage({ id: "admin.forgotten.password.new.password.confirm.label" })}
        placeholder={intl.formatMessage({ id: "admin.forgotten.password.new.password.confirm.label" })}
        required
        type="password"
        {...methods.register("confirmNewPassword")}
      />

      <Button type="submit" className="mx-auto">
        <FormattedMessage id="general.login" />
      </Button>
    </>
  );
};

export default NewPasswordForm;

import { Disclosure } from "@headlessui/react";
import infoIcon from "assets/info-icon.svg";
import classNames from "classnames";
import { DetailedHTMLProps, InputHTMLAttributes, forwardRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import InputError from "../InputError/InputError";
import InputLabel from "../InputLabel/InputLabel";

export interface IProps
  extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, keyof UseFormRegisterReturn>,
    UseFormRegisterReturn {
  wrapperClassName?: string;
  errorClassName?: string;
  labelClassName?: string;
  label?: string;
  inlineLabel?: boolean;
  hideLabel?: boolean;
  isUnderlined?: boolean;
  moreInfo?: JSX.Element;
}

const FormInput = forwardRef<HTMLInputElement, IProps>((props, ref) => {
  const {
    className,
    wrapperClassName,
    errorClassName,
    labelClassName,
    label,
    inlineLabel,
    required,
    hideLabel = false,
    isUnderlined,
    moreInfo,
    ...inputProps
  } = props;

  const id = "form-input-" + inputProps.name;

  return (
    <div
      className={classNames(
        wrapperClassName,
        {
          "px-1.5": label
        },
        inlineLabel && "grid w-full grid-cols-2 items-center"
      )}
    >
      {/* Label */}
      <InputLabel
        id={id}
        label={label}
        inlineLabel={inlineLabel}
        hideLabel={hideLabel}
        required={required}
        className={labelClassName}
      />

      <div className="relative">
        {/* Text entry */}
        <input
          className={classNames(
            className,
            "body-600 placeholder:body-600 block w-full border-neutral-500 py-[0.313rem] pl-[9px] leading-[22px] text-neutral-700 placeholder:text-neutral-500",
            {
              "rounded border px-2": !isUnderlined,
              "border-b": isUnderlined,
              "pr-5": Boolean(moreInfo)
            }
          )}
          id={id}
          type="text"
          {...inputProps}
          ref={ref}
        />
        {moreInfo && (
          <Disclosure>
            <Disclosure.Button
              aria-label="More information"
              className={classNames("absolute", isUnderlined ? "right-0 top-[2px]" : "right-1 top-[7px]")}
            >
              <img src={infoIcon} alt="" role="presentation" />
            </Disclosure.Button>
            <Disclosure.Panel className="mt-3 rounded-[1.25rem] border-2  border-solid border-[#9B9B9B450] bg-white p-4 shadow-light-shadow">
              {moreInfo}
            </Disclosure.Panel>
          </Disclosure>
        )}
      </div>

      {/* Error message */}
      <InputError className={classNames(label && "-mx-1.5 px-1.5", errorClassName)} name={props.name} />
    </div>
  );
});

export default FormInput;

const EmailIcon = () => {
  return (
    <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9669 0.740234H1.83405C1.31713 0.740234 0.898438 1.15965 0.898438 1.67748V20.188C0.898438 20.7058 1.31713 21.1252 1.83405 21.1252H28.9669C29.4839 21.1252 29.9026 20.7058 29.9026 20.188V1.67748C29.9026 1.15965 29.4839 0.740234 28.9669 0.740234ZM4.55857 6.06705L14.8504 13.565C15.1783 13.8044 15.6227 13.8044 15.9506 13.565L26.2424 6.06705C26.6602 5.76291 26.7528 5.1762 26.4487 4.75819C26.1451 4.33971 25.5594 4.24692 25.1421 4.55153L15.4005 11.6483L5.65886 4.55153C5.24157 4.24692 4.65588 4.33971 4.35227 4.75819C4.04819 5.1762 4.14082 5.76291 4.55857 6.06705Z"
        fill="url(#paint0_linear_2699_3565)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2699_3565"
          x1="-26.5048"
          y1="14.2453"
          x2="-3.72102"
          y2="60.4761"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#076EB2" />
          <stop offset="1" stopColor="#25345F" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EmailIcon;

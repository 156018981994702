import Inbox from "assets/welcome/inbox.webp";
import Reading from "assets/welcome/reading.webp";
import Responding from "assets/welcome/responding.webp";
import Analysis from "assets/welcome/analysis.png";
import { WelcomeSlide } from "..";

export const IT_SLIDES: WelcomeSlide[] = [
  {
    name: "welcome",
    title: "welcome.welcome.title",
    subtitle: "welcome.welcome.subtitle",
    desc: [
      "welcome.IT_MANAGER.welcome.desc.1",
      "welcome.IT_MANAGER.welcome.desc.2",
      "welcome.IT_MANAGER.welcome.desc.3"
    ]
  },
  {
    name: "inbox",
    image: Inbox,
    alt: "welcome.inbox.alt",
    title: "welcome.inbox.title",
    desc: ["welcome.IT_MANAGER.inbox.desc.1"]
  },
  {
    name: "reading",
    image: Reading,
    alt: "welcome.reading.alt",
    title: "welcome.reading.title",
    desc: ["welcome.IT_MANAGER.reading.desc.1"]
  },
  {
    name: "responding",
    image: Responding,
    alt: "welcome.responding.alt",
    title: "welcome.responding.title",
    desc: ["welcome.IT_MANAGER.responding.desc.1"]
  },
  {
    name: "story",
    title: "welcome.story.title",
    desc: ["welcome.IT_MANAGER.story.desc.1", "welcome.IT_MANAGER.story.desc.2"],
    bullets: [
      "welcome.IT_MANAGER.story.bullet.1",
      "welcome.IT_MANAGER.story.bullet.2",
      "welcome.IT_MANAGER.story.bullet.3",
      "welcome.IT_MANAGER.story.bullet.4"
    ]
  },
  {
    name: "analysis",
    image: Analysis,
    alt: "welcome.analysis.alt",
    title: "welcome.analysis.title",
    desc: ["welcome.analysis.desc"],
    question: "welcome.analysis.question",
    answers: [
      { label: "welcome.analysis.option.1", value: true },
      { label: "welcome.analysis.option.2", value: false }
    ]
  }
];

import classNames from "classnames";
import { ButtonHTMLAttributes, DetailedHTMLProps, FC, PropsWithChildren } from "react";

export type TButtonVariants = "primary" | "primary-outline" | "secondary";

export interface IProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  variant?: TButtonVariants;
  rounded?: boolean;
  iconSrc?: string;
}

const Button: FC<PropsWithChildren<IProps>> = props => {
  const { variant = "primary", rounded = true, className, children, iconSrc, ...rest } = props;

  return (
    <button
      className={classNames(className, "c-button group/c-button", {
        "c-button--primary": variant === "primary",
        "c-button--primary-outline": variant === "primary-outline",
        "c-button--secondary": variant === "secondary",
        "px-3": !!iconSrc,
        "rounded-none": !rounded
      })}
      {...rest}
    >
      {children}
      {iconSrc && <img src={iconSrc} role="presentation" alt="" />}
    </button>
  );
};

export default Button;

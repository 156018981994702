import Macbook1Desktop from "assets/Images/Macbook1Desktop.png";
import Macbook2Desktop from "assets/Images/Macbook2Desktop.webp";
import Macbook1Mobile from "assets/Images/Macbook1Mobile.png";
import Macbook2Mobile from "assets/Images/Macbook2Mobile.webp";
import PersonLaptop from "assets/person-laptop.webp";
import TeamMeeting from "assets/team-meeting.webp";
import { ImageCardSection } from "components/ui/home";
import ChevronBold from "components/ui/icons/ChevronBold";
import LinkBtn from "components/ui/LinkBtn/LinkBtn";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useFireGAEventOnMount from "hooks/useFireGAEventOnMount";
import { fireGAEvent, GAEventName } from "utils/gtag";

const HomePage: FC = () => {
  // i18n
  const intl = useIntl();

  useFireGAEventOnMount({ name: GAEventName.ScreenView, param: "Landing Page" });

  const handleSignUpClick = () => {
    fireGAEvent({ name: GAEventName.ButtonSignUpImplementer, param: "body" });
  };

  const videoURL = `${process.env.REACT_APP_MEDIA_BASE}/PhIT_Promo_Video_V17.mp4`;

  return (
    <>
      {/* Understand section */}
      <div className="c-home-section flex flex-col items-center px-12 py-[36px] text-white md:pb-[262px] md:pt-[86px]">
        {/* Text */}
        <div className="flex flex-col items-center text-center">
          <h1 className="header-900 sm:header-1000 mx-auto mb-7 max-w-[558px]">
            <FormattedMessage id="home.section.understand.title" />
          </h1>
          <p className="body-900 mx-auto mb-6 max-w-[515px]">
            <FormattedMessage
              id="home.section.understand.desc"
              values={{
                b: chunks => <b className="font-bold">{chunks}</b>
              }}
            />
          </p>
          <LinkBtn to="/demo" variant="secondary">
            <FormattedMessage id="general.try" />
          </LinkBtn>
        </div>
      </div>

      {/* Macbook - Desktop */}
      <div className="relative flex justify-center px-[41px] md:h-[332px] md:px-0">
        <img
          src={Macbook1Desktop}
          alt={intl.formatMessage({ id: "home.section.understand.image" })}
          className="mb-[34px]  mt-[36px] hidden w-full min-w-[296px] max-w-[400px] self-center md:absolute md:top-[-176px] md:my-0 md:max-w-[704px] lg:block"
          loading="lazy"
        />

        {/* Macbook - Mobile */}
        <img
          src={Macbook1Mobile}
          alt={intl.formatMessage({ id: "home.section.understand.image" })}
          className="object-contain lg:hidden"
          loading="lazy"
        />
      </div>

      {/* Image card info sections */}
      <ImageCardSection
        image={PersonLaptop}
        alt={intl.formatMessage({ id: "home.section.why.title" })}
        title={intl.formatMessage({ id: "home.section.why.title" })}
        description={intl.formatMessage({ id: "home.section.why.desc" })}
        question={intl.formatMessage({ id: "home.section.why.question" })}
      />
      <ImageCardSection
        image={TeamMeeting}
        alt={intl.formatMessage({ id: "home.section.who.title" })}
        title={intl.formatMessage({ id: "home.section.who.title" })}
        description={intl.formatMessage({ id: "home.section.who.desc" })}
        question={intl.formatMessage({ id: "home.section.who.question" })}
        reverse
      />
      <ImageCardSection
        video={videoURL}
        alt={intl.formatMessage({ id: "home.section.what.title" })}
        title={intl.formatMessage({ id: "home.section.what.title" })}
        description={intl.formatMessage({ id: "home.section.what.desc" })}
        question={intl.formatMessage({ id: "home.section.what.question" })}
        className="mb-[35px] md:mb-[84px]"
      />

      {/* Sign up section */}
      <div className="c-home-section shadow-strong-shadow">
        <div className="mx-auto flex max-w-[1440px] flex-col-reverse overflow-hidden text-white lg:flex-row">
          {/* Laptop - Desktop */}
          <div className="hidden max-h-[442px] w-1/2 lg:block">
            <img
              src={Macbook2Desktop}
              alt={intl.formatMessage({ id: "home.section.signup.image" })}
              className="relative left-[-7vw] mt-[120px]  w-[156%] max-w-[1030px] 1300:left-[-3vw] 1300:mt-[19px]"
            />
          </div>

          {/* Laptop - Mobile */}
          <div className="overflow-hidden lg:hidden">
            <img
              src={Macbook2Mobile}
              alt={intl.formatMessage({ id: "home.section.signup.image" })}
              className="relative top-[20vw] mt-[-20vw] lg:hidden"
              loading="lazy"
            />
          </div>

          {/* Text and CTA */}
          <div className="z-10 flex flex-col items-center px-[49px] py-9 lg:ml-auto lg:items-start lg:py-20 lg:pl-0 lg:pr-[77px]">
            <h2 className="header-1000 lg:header-1000 mb-[28px] max-w-[561px] break-words !leading-[54px]">
              <FormattedMessage id="home.section.signup.title" />
            </h2>
            <p className="body-900 mb-6 max-w-[536px] ">
              <FormattedMessage id="home.section.signup.desc" />
            </p>

            {/* Mobile CTA */}
            <LinkBtn to="/demo" variant="secondary" className="w-[158px] lg:hidden">
              <FormattedMessage id="general.signup" />
            </LinkBtn>

            {/* Desktop CTA */}
            <LinkBtn
              to="/sign-up/email"
              variant="secondary"
              className="hidden w-[200px] items-center justify-between p-4 lg:flex"
              onClick={handleSignUpClick}
            >
              <p className="font-bold leading-none">
                <FormattedMessage id="general.signup" />
              </p>
              <ChevronBold direction="right" />
            </LinkBtn>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;

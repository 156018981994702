import SNSecondaryNav from "components/ui/scenarios/NavBar/SecondaryNav";
import useBundle from "hooks/useBundle";
import { FC, useMemo } from "react";
import { Outlet } from "react-router-dom";

export interface IProps {}

const ScenarioAccountsPage: FC<IProps> = props => {
  const { data } = useBundle();

  const accounts = useMemo(
    () =>
      data?.accounts.map(({ id, name }) => ({
        id,
        label: name,
        to: id
      })) ?? [],
    [data]
  );

  return (
    <>
      <SNSecondaryNav items={accounts} />
      <Outlet />
    </>
  );
};

export default ScenarioAccountsPage;

import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormContext } from "react-hook-form";
import Input from "components/ui/Form/Input/Input";
import DeleteUserButton from "./DeleteUserButton";

export type EmailUpdateInputs = {
  currentEmail: string;
  newEmail: string;
  confirmNewEmail: string;
};

interface IProps {
  onDeleteClick: () => void;
  headerButton?: React.ReactNode;
  show: boolean;
}

const EmailSection: FC<IProps> = ({ onDeleteClick, show }) => {
  const intl = useIntl();

  // Form
  const methods = useFormContext();

  if (!show) return <></>;

  return (
    <div className="mb-15">
      {/* Title section */}
      <div className="mb-9 flex items-center justify-between">
        <h1 id="email-update-form-title" className="header-900">
          <FormattedMessage id="admin.settings.email.title" />
        </h1>

        {/* Delete account */}
        <DeleteUserButton onDeleteClick={onDeleteClick} />
      </div>

      {/* Card form */}
      <div className="c-card flex flex-col space-y-9 p-9">
        {/* Current Email */}
        <div className="flex w-[531px] justify-between">
          <Input
            type="email"
            inlineLabel
            label={intl.formatMessage({ id: "admin.settings.email.form.currentEmail" })}
            placeholder={intl.formatMessage({ id: "admin.settings.email.form.currentEmail" })}
            className="w-[280px]"
            {...methods.register("currentEmail")}
          />
        </div>

        {/* New Email  */}
        <div className="flex w-[531px] justify-between">
          <Input
            type="email"
            inlineLabel
            label={intl.formatMessage({ id: "admin.settings.email.form.newEmail" })}
            placeholder={intl.formatMessage({ id: "admin.settings.email.form.newEmail" })}
            className="w-[280px]"
            {...methods.register("newEmail")}
          />
        </div>

        {/* Confirm New Email */}
        <div className="flex w-[531px] justify-between">
          <Input
            type="email"
            inlineLabel
            label={intl.formatMessage({ id: "admin.settings.email.form.confirmNewEmail" })}
            placeholder={intl.formatMessage({ id: "admin.settings.email.form.confirmNewEmail" })}
            className="w-[280px]"
            {...methods.register("confirmNewEmail")}
          />
        </div>
      </div>
    </div>
  );
};

export default EmailSection;

import { FC } from "react";

interface IProps {
  className?: string;
}

const BinFillIcon: FC<IProps> = ({ className }) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.3868 2.23979C14.7449 2.23979 15.0352 2.5308 15.0352 2.88979C15.0352 3.24877 14.7449 3.53979 14.3868 3.53979H14.3777C14.306 3.53979 14.2458 3.5967 14.2431 3.67029L13.7763 16.3057C13.7422 17.2283 12.973 17.9698 12.0525 17.9698H3.75294C2.83196 17.9698 2.0632 17.2269 2.02917 16.3057L1.56233 3.67029C1.55967 3.59822 1.50104 3.53979 1.42778 3.53979H1.41867C1.06056 3.53979 0.770264 3.24877 0.770264 2.88979C0.770264 2.5308 1.06056 2.23979 1.41867 2.23979H4.57987C4.65111 2.23979 4.72505 2.18297 4.74421 2.11573L4.95564 1.3739C5.17255 0.612871 5.9438 0.0297852 6.73339 0.0297852H9.07206C9.86175 0.0297852 10.6328 0.612662 10.8498 1.3739L11.0612 2.11573C11.0808 2.18424 11.1537 2.23979 11.2256 2.23979H14.3868ZM7.384 5.87979V14.7198C7.384 15.007 7.61624 15.2398 7.90272 15.2398C8.18921 15.2398 8.42145 15.007 8.42145 14.7198V5.87979C8.42145 5.5926 8.18921 5.35979 7.90272 5.35979C7.61624 5.35979 7.384 5.5926 7.384 5.87979ZM4.53124 5.89507L4.7906 14.7351C4.79902 15.0221 5.03799 15.248 5.32435 15.2396C5.61071 15.2311 5.83602 14.9916 5.8276 14.7045L5.56824 5.8645C5.55982 5.57743 5.32085 5.35157 5.03449 5.36001C4.74813 5.36845 4.52282 5.60801 4.53124 5.89507ZM10.2372 5.8645L9.97785 14.7045C9.96942 14.9916 10.1947 15.2311 10.4811 15.2396C10.7675 15.248 11.0064 15.0221 11.0148 14.7351L11.2742 5.89507C11.2826 5.60801 11.0573 5.36845 10.771 5.36001C10.4846 5.35157 10.2456 5.57743 10.2372 5.8645ZM6.1889 2.23979H9.61655C9.68992 2.23979 9.73217 2.18465 9.71279 2.11663L9.60289 1.73104C9.54501 1.52797 9.28283 1.32979 9.07206 1.32979H6.73339C6.52275 1.32979 6.26039 1.52814 6.20256 1.73104L6.09266 2.11663C6.07308 2.18534 6.11636 2.23979 6.1889 2.23979Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BinFillIcon;

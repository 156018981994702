import { FC } from "react";

interface IProps {
  className?: string;
}

const EyeFillIcon: FC<IProps> = ({ className }) => {
  return (
    <svg
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.03198 13C1.03198 13 6.01774 6 13.1385 6C20.3314 6 25.245 13 25.245 13C25.245 13 21.6839 20 13.1385 20C4.6638 20 1.03198 13 1.03198 13ZM13.139 8.54611C10.3618 8.54611 8.15466 10.5183 8.15466 13C8.15466 15.4817 10.3618 17.4539 13.139 17.4539C15.9163 17.4539 18.1234 15.4817 18.1234 13C18.1234 10.5183 15.9163 8.54611 13.139 8.54611Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1381 14.9093C11.9577 14.9093 11.0017 14.055 11.0017 13.0003C11.0017 11.9456 11.9577 11.0913 13.1381 11.0913C14.3184 11.0913 15.2744 11.9456 15.2744 13.0003C15.2744 14.055 14.3184 14.9093 13.1381 14.9093Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EyeFillIcon;

import Button from "components/ui/Button/Button";
import FullPageContentLayout from "layouts/FullPageContent";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { Else, If, Then } from "react-if";
import { FormattedMessage } from "react-intl";
import { Outlet } from "react-router-dom";

const OfflineWrapper: FC<PropsWithChildren> = ({ children }) => {
  const [isOffline, setIsOffline] = useState(!window.navigator.onLine);

  useEffect(() => {
    const handleBackOnline = () => {
      setIsOffline(false);

      window.removeEventListener("online", handleBackOnline);
    };

    const handleOffline = () => {
      setIsOffline(true);

      window.addEventListener("online", handleBackOnline);
    };

    window.addEventListener("offline", handleOffline);

    return () => window.removeEventListener("offline", handleOffline);
  }, []);

  const handleRefresh = async () => {
    window.location.reload();
  };

  if (isOffline) {
    return (
      <FullPageContentLayout hasError>
        <div className="m-auto max-w-[610px] text-center">
          <h1 className="header-900 mb-9">
            <FormattedMessage id="general.offline" />
          </h1>

          <p className="body-900 mb-9">
            <FormattedMessage id="general.offline.desc" />
          </p>

          <Button onClick={handleRefresh} className="mx-auto">
            <FormattedMessage id="general.refresh" />
          </Button>
        </div>
      </FullPageContentLayout>
    );
  }

  return (
    <If condition={!!children}>
      <Then>{children}</Then>
      <Else>
        <Outlet />
      </Else>
    </If>
  );
};

export default OfflineWrapper;

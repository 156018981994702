import { useQueryClient } from "@tanstack/react-query";
import { useTraineeControllerDeleteUser } from "generated/api/apiComponents";
import { useApiContext } from "generated/api/apiContext";

const useDeleteTrainee = () => {
  const queryClient = useQueryClient();
  const { queryKeyFn } = useApiContext();

  return useTraineeControllerDeleteUser({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeyFn({
          path: "/implementer/organisation/trainees",
          operationId: "traineeControllerTrainees",
          variables: {}
        })
      });
    }
  });
};

export default useDeleteTrainee;

import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Modal from "components/ui/Modal/Modal";
import Button from "components/ui/Button/Button";
import Form from "components/ui/Form/Form";
import { SubmitHandler, useForm } from "react-hook-form";
import yup from "config/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CognitoService from "helpers/cognito.service";
import Input from "components/ui/Form/Input/Input";
import useFireGAEventOnMount from "hooks/useFireGAEventOnMount";
import { GAEventName } from "utils/gtag";

type EmailVerifyInputs = {
  code: string;
};

const EmailVerifySchema = yup
  .object({
    code: yup.string().length(6).required()
  })
  .required();

interface IProps {
  show: boolean;
  onClose: (success: boolean) => void;
  resend: () => void;
}

const EmailVerifyModal: FC<IProps> = ({ show, onClose, resend }) => {
  const intl = useIntl();

  const methods = useForm<EmailVerifyInputs>({
    resolver: yupResolver(EmailVerifySchema)
  });

  useFireGAEventOnMount({ name: GAEventName.ScreenView, param: "Implementer - Edit Own Account (Verification Sent)" });

  /**
   * Verify entered code
   * @param data Code
   */
  const onSubmit: SubmitHandler<EmailVerifyInputs> = async data => {
    try {
      const success = await CognitoService.verifyEmail(data.code);
      return onClose(success);
    } catch (err) {
      window.alert(err);
    }
  };

  return (
    <Modal
      title={intl.formatMessage({ id: "admin.settings.email.modal.title" })}
      description={intl.formatMessage({ id: "admin.settings.email.modal.description" })}
      open={show}
      onClose={() => onClose(false)}
    >
      <Form methods={methods} onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col gap-y-8">
        <Input
          wrapperClassName="w-full px-0"
          label={intl.formatMessage({ id: "admin.settings.email.modal.input.label" })}
          hideLabel
          placeholder={intl.formatMessage({ id: "admin.settings.email.modal.input.label" })}
          required
          {...methods.register("code")}
        />

        <p className="font-medium text-neutral-700 opacity-60">
          <FormattedMessage id="admin.settings.email.modal.logoutInfo" />
        </p>

        <Button type="submit">
          <FormattedMessage id="general.continue" />
        </Button>

        <Button onClick={resend} variant="secondary">
          <FormattedMessage id="admin.settings.email.modal.resend" />
        </Button>
      </Form>
    </Modal>
  );
};

export default EmailVerifyModal;

import CpniIcon from "components/ui/icons/CPNI";
import LinkBtn from "components/ui/LinkBtn/LinkBtn";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { fireGAEvent, GAEventName } from "utils/gtag";

/** Main header for the landing page with auth buttons. */
const HomeHeader: FC = () => {
  const handleSignUpClick = () => {
    fireGAEvent({ name: GAEventName.ButtonSignUpImplementer, param: "nav" });
  };

  return (
    <header className="flex items-center justify-between bg-white p-2 shadow-xlight sm:px-[84px] sm:py-[29px]">
      {/* Logo */}
      <Link to="/" className="flex items-center">
        <CpniIcon className="mr-4 h-[35px] object-scale-down sm:h-[42px]" />
      </Link>

      {/* Auth */}
      <div className="flex pr-15">
        <LinkBtn
          to="/demo"
          variant="primary-outline"
          className="mx-[26px] hidden w-[84px] min-w-fit py-[5px] text-sm font-bold leading-[14px] sm:block sm:w-28 sm:text-base sm:leading-7"
        >
          <FormattedMessage id="general.demo" />
        </LinkBtn>
        <LinkBtn
          to="/sign-up/email"
          className="min-w-fit py-[5px] text-sm font-bold leading-[14px] sm:w-28 sm:text-base sm:leading-7"
          onClick={handleSignUpClick}
        >
          <FormattedMessage id="general.signup" />
        </LinkBtn>
        <LinkBtn to="/admin/login" className="ml-[26px] hidden w-28 min-w-fit sm:block">
          <FormattedMessage id="general.login" />
        </LinkBtn>
      </div>
    </header>
  );
};

export default HomeHeader;

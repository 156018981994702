import classNames from "classnames";
import { FC } from "react";

interface IProps {
  id: string;
  label?: string;
  className?: string;
  hideLabel?: boolean;
  required?: boolean;
  inlineLabel?: boolean;
}

/** Generic styled label with show/hide logic for inputs. */
const InputLabel: FC<IProps> = ({ id, label, inlineLabel, className, hideLabel, required }) => {
  if (!label) return null;

  return (
    <label
      htmlFor={id}
      className={classNames(className, hideLabel && "sr-only", "body-700 -mx-1.5 block", !inlineLabel && "mb-4")}
    >
      {label}
      {required && "*"}
    </label>
  );
};

export default InputLabel;

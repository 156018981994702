const EmailOrangeIcon = () => {
  return (
    <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.0323 0.75H0.967742C0.433065 0.75 0 1.21293 0 1.78448V22.2155C0 22.7871 0.433065 23.25 0.967742 23.25H29.0323C29.5669 23.25 30 22.7871 30 22.2155V1.78448C30 1.21293 29.5669 0.75 29.0323 0.75ZM3.78581 6.62948L14.431 14.9053C14.7702 15.1697 15.2298 15.1697 15.569 14.9053L26.2142 6.62948C26.6463 6.29379 26.7421 5.64621 26.4276 5.18483C26.1136 4.72293 25.5077 4.62052 25.0761 4.95672L15 12.7898L4.92387 4.95672C4.49226 4.62052 3.88645 4.72293 3.57242 5.18483C3.2579 5.64621 3.35371 6.29379 3.78581 6.62948Z"
        fill="#1E7200"
      />
    </svg>
  );
};

export default EmailOrangeIcon;

import { FormattedMessage } from "react-intl";

const PasswordMoreInfo = () => (
  <div className="text-center">
    <p className="body-700 mb-4">
      <FormattedMessage id="general.password.info" />
    </p>
    <p className="body-600 !font-light">
      <FormattedMessage
        id="general.moreInfo"
        values={{
          a: chunks => (
            <a
              href="https://www.ncsc.gov.uk/blog-post/three-random-words-or-thinkrandom-0"
              target="_blank"
              rel="noopenner noreferrer"
              className="italic underline"
            >
              {chunks}
            </a>
          )
        }}
      />
    </p>
  </div>
);

export default PasswordMoreInfo;

import { FormattedMessage } from "react-intl";
import infoIcon from "assets/info-icon.svg";
import classNames from "classnames";

const EmptyResult = ({ className }: { className?: string }) => {
  return (
    <div className={classNames("flex flex-col items-center gap-8", className)}>
      <img src={infoIcon} alt="" role="presentation" className="h-[60px] w-[60px]" />
      <p className="body-600">
        <FormattedMessage id="admin.dashboard.phishingResults.empty.description" />
      </p>
    </div>
  );
};

export default EmptyResult;

import { ColumnDef } from "@tanstack/react-table";
import LinkBtn from "components/ui/LinkBtn/LinkBtn";
import Percentage from "components/ui/Percentage/Percentage";
import Table, { ReactTableProps } from "components/ui/Table/Table";
import { DashboardControllerDashboardResponse } from "generated/api/apiComponents";
import { DetailedTestResponseDto } from "generated/api/apiSchemas";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export interface IProps {
  tests?: DetailedTestResponseDto[];
  meta?: DashboardControllerDashboardResponse["meta"];
  onTableStateChange?: ReactTableProps<DetailedTestResponseDto>["onTableStateChange"];
  onTablePageChange?: ReactTableProps<DetailedTestResponseDto>["onPageChange"];
}

const DashboardTestTable: FC<IProps> = props => {
  const { tests, meta, onTableStateChange, onTablePageChange } = props;
  const intl = useIntl();

  const tableHeaders = useMemo<ColumnDef<DetailedTestResponseDto>[]>(
    () => [
      {
        accessorKey: "scenario",
        header: intl.formatMessage({ id: "admin.dashboard.sessions.header.name" }),
        cell: value => {
          const scenario = value.getValue() as string;
          const row = value.row.original;

          if (value) {
            return (
              <div>
                <p className="underline">{row.name}</p>
                <p className="header-400">
                  <FormattedMessage id={`admin.dashboard.sessions.cells.scenario`} />{" "}
                  <FormattedMessage id={`general.test.scenario.${scenario}`} />
                </p>
                <p className="header-400">
                  <FormattedMessage id={`admin.dashboard.sessions.cells.invited`} values={{ count: row.maxTrainees }} />
                </p>
                <p className="header-400">
                  <FormattedMessage
                    id={`admin.dashboard.sessions.cells.completed`}
                    values={{ count: row.completedTrainees }}
                  />
                </p>
              </div>
            );
          }
        },
        enableSorting: false
      },
      {
        accessorKey: "created",
        header: intl.formatMessage({ id: "admin.dashboard.sessions.header.dateCreated" }),
        cell: value => {
          const dateStr = value.getValue() as string;
          const dateStrUK = new Date(dateStr).toLocaleDateString();

          if (value) {
            return dateStrUK;
          }
        },
        enableSorting: false
      },
      {
        accessorKey: "uptakeRate",
        header: intl.formatMessage({ id: "admin.dashboard.sessions.header.sessionCompleted" }),
        cell: value => {
          const rate = value.getValue() as number;

          if (value) {
            return <Percentage value={rate} />;
          }
        },
        enableSorting: false
      },
      {
        id: "menu",
        cell: value => (
          <LinkBtn to={`/admin/sessions/${value.row.original.id}`} className="ml-auto w-fit px-9">
            <FormattedMessage id="general.viewResults" />
          </LinkBtn>
        )
      }
    ],
    [intl]
  );

  return (
    <Table<DetailedTestResponseDto>
      data={tests ?? []}
      serverSidePaginationResponse={meta}
      onPageChange={onTablePageChange}
      columns={tableHeaders}
      isServerSide
      onTableStateChange={onTableStateChange}
    />
  );
};

export default DashboardTestTable;

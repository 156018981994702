import classNames from "classnames";
import { useAppSelector } from "hooks/useRedux";
import { FC } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { selectAvailableEmails } from "redux/modules/scenario";

export interface IProps {
  className?: string;
  items?: ({ id: string; label: string; subLabel?: string; className?: string } & Omit<NavLinkProps, "className">)[];
}

const SNSecondaryNav: FC<IProps> = props => {
  const { className, items } = props;
  // Remove the redux call out of a reusable component!
  const availableEmails = useAppSelector(selectAvailableEmails);

  return (
    <ul
      className={classNames(
        className,
        "scroll-bar-primary-500 flex h-full flex-col overflow-auto bg-primary-500 text-white"
      )}
    >
      {items?.map(({ id, label, subLabel, className, ...rest }) => {
        const hasBeenRead = Boolean(availableEmails.find(available => available.id === id && available.hasBeenRead));
        return (
          <li key={id} className={classNames("group", hasBeenRead && "")}>
            <NavLink
              className={({ isActive }) =>
                classNames(
                  className,
                  "block min-h-[80px] border-l-[10px] border-transparent px-7.5 py-4 group-hover:bg-primary-700",
                  isActive && "border-workstyle bg-primary-700"
                )
              }
              {...rest}
            >
              {({ isActive }) => (
                <>
                  <p
                    className={classNames(
                      hasBeenRead && !isActive ? "header-400 !leading-[22px]" : "header-500",
                      "group-hover:header-500 overflow-hidden text-ellipsis whitespace-nowrap pb-3"
                    )}
                  >
                    {label}
                  </p>
                  {subLabel && (
                    <p
                      className={classNames(
                        hasBeenRead && !isActive ? "body-600 !leading-[14px]" : "body-700",
                        "group-hover:body-700 overflow-hidden text-ellipsis whitespace-nowrap"
                      )}
                    >
                      {subLabel}
                    </p>
                  )}
                </>
              )}
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
};

export default SNSecondaryNav;

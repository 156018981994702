import { DetailedHTMLProps, FC, HTMLAttributes, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  subject?: string;
  body?: string;
}

const EmailBody: FC<IProps> = props => {
  const { subject, body } = props;
  const [bodyDev, setBodyDev] = useState<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const allEmailLinks = bodyDev?.querySelectorAll("a");

    const handleInnerHTMLAnchorClick: EventListener = e => {
      e.preventDefault();

      // https://stackoverflow.com/questions/28900077/why-is-event-target-not-element-in-typescript
      const target = e.target as HTMLAnchorElement | undefined;

      if (target?.attributes.getNamedItem("href")) {
        navigate("../.." + target.attributes.getNamedItem("href")!.value);
      }
    };

    allEmailLinks?.forEach(anchor => {
      anchor.addEventListener("click", handleInnerHTMLAnchorClick);
    });

    return () => {
      allEmailLinks?.forEach(anchor => {
        anchor.removeEventListener("click", handleInnerHTMLAnchorClick);
      });
    };
  }, [bodyDev, body, navigate]);

  return (
    <div className="flex flex-col gap-9">
      <h1 className="header-900">{subject}</h1>

      {body && (
        <div
          ref={setBodyDev}
          className="c-email-body header-400 flex flex-col gap-y-5"
          dangerouslySetInnerHTML={{ __html: body }}
        />
      )}
    </div>
  );
};

export default EmailBody;

import { FC } from "react";
import CopyIcon from "components/ui/icons/CopyFill";

interface IProps {
  password: string;
}

const TemporaryPassword: FC<IProps> = ({ password }) => {
  /** Copies the new password */
  const handleCopyPassword = () => {
    if (password) navigator.clipboard.writeText(password);
  };

  return (
    <div className="flex w-full rounded border border-neutral-500 px-2 py-1 leading-[22px]">
      <span className="flex-1 text-sm font-light">{password}</span>
      <button aria-label="Copy Password" onClick={handleCopyPassword}>
        <CopyIcon className="text-primary-500" />
      </button>
    </div>
  );
};

export default TemporaryPassword;

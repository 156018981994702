import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import Button from "components/ui/Button/Button";
import Form from "components/ui/Form/Form";
import Input from "components/ui/Form/Input/Input";
import LinkBtn from "components/ui/LinkBtn/LinkBtn";
import LoadingWrapper from "components/ui/Loading/LoadingWrapper";
import ActionBar from "components/ui/admin/Elements/ActionBar";
import yup from "config/yup";
import { SCENARIOS } from "constants/form";
import { TEST_DURATION_SECONDS } from "constants/test";
import { useCreateControllerCreateTest } from "generated/api/apiComponents";
import { Scenario } from "generated/api/apiSchemas";
import { getScenarioDescription } from "helpers/getters";
import useFireGAEventOnMount from "hooks/useFireGAEventOnMount";
import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { GAEventName, fireGAEvent } from "utils/gtag";
import ComboBox from "./RHFComboBox";
import ErrorMessage from "components/ui/ErrorMessage/ErrorMessage";

export type TestCreateInputs = {
  name: string;
  maxTrainees: number;
  scenario: Scenario;
};

const TestCreateSchema = yup
  .object({
    name: yup.string().max(50).required(),
    maxTrainees: yup.number().min(1).required(),
    scenario: yup.string().required()
  })
  .required();

interface IProps {}

const TestCreatePage: FC<IProps> = () => {
  // Router
  const navigate = useNavigate();

  // i18n
  const intl = useIntl();

  useFireGAEventOnMount({ name: GAEventName.ScreenView, param: "Implementer - Create Test" });

  // Mutation
  const {
    mutate: createTest,
    isLoading,
    error
  } = useCreateControllerCreateTest({
    onSuccess: () => {
      fireGAEvent({ name: GAEventName.CreatedTest });
      navigate("/admin/sessions");
    }
  });

  // Form
  const methods = useForm<TestCreateInputs>({
    resolver: yupResolver(TestCreateSchema)
  });

  const scenario = methods.watch("scenario") ?? "";

  /** Create a new test. */
  const onSubmit: SubmitHandler<TestCreateInputs> = data => {
    createTest({
      body: {
        ...data,
        durationSeconds: TEST_DURATION_SECONDS
      }
    });
  };

  const difficultyDescription = getScenarioDescription(scenario);

  return (
    <LoadingWrapper isLoading={isLoading}>
      <ActionBar
        titleProps={{ id: "admin.sessions.create.title" }}
        headerProps={{ id: "test-create-form-title" }}
        className="mb-9"
      />

      <Form methods={methods} onSubmit={methods.handleSubmit(onSubmit)} aria-labelledby="test-create-form-title">
        {/* Card form */}
        <div className="c-card flex flex-col space-y-9 p-9">
          {/* Name */}
          <div className="flex w-[531px] justify-between">
            <h2 className="body-700 mt-[9px] h-fit">
              <FormattedMessage id="admin.sessions.create.form.name" />
            </h2>

            <Input
              placeholder={intl.formatMessage({ id: "admin.sessions.create.form.name" })}
              wrapperClassName="w-[280px]"
              {...methods.register("name")}
            />
          </div>

          {/* Employees */}
          <div className="flex w-[531px] justify-between">
            <h2 className="body-700 mt-[9px] h-fit">
              <FormattedMessage id="admin.sessions.create.form.employees" />
            </h2>

            <Input
              type="number"
              placeholder={intl.formatMessage({ id: "admin.sessions.create.form.employees" })}
              wrapperClassName="w-[280px]"
              {...methods.register("maxTrainees")}
            />
          </div>
          {/* Scenario */}
          <div className="flex w-[531px] justify-between">
            <h2 className="body-700 mt-[9px] h-fit">
              <FormattedMessage id="admin.sessions.create.form.difficulty" />
            </h2>
            <div className="flex flex-col">
              <ComboBox name="scenario" options={SCENARIOS} control={methods.control} />
              <p className={classNames("body-600 flex w-[280px] pt-3 text-left")}>
                {difficultyDescription && <FormattedMessage id={difficultyDescription} />}
              </p>
            </div>
          </div>

          <ErrorMessage error={error} />
        </div>
        {/* Buttons */}
        <div className="mt-9 flex w-full justify-end gap-3">
          <LinkBtn to="/admin/sessions" variant="secondary">
            <FormattedMessage id="general.cancel" />
          </LinkBtn>
          <Button type="submit">
            <FormattedMessage id="admin.sessions.create.form.submit" />
          </Button>
        </div>
      </Form>
    </LoadingWrapper>
  );
};

export default TestCreatePage;

const Profile = ({ name, imageUrl }: { name: string; imageUrl: string }) => {
  return (
    <div className="flex flex-col space-y-24 pl-8 pt-[46px]">
      <h2 className="body-750 mx-auto w-[800px] text-neutral-600">{name}</h2>
      <img className="mx-auto h-[580px] w-[800px]" src={imageUrl} alt={`${name}'s Profile`} />
    </div>
  );
};

export default Profile;

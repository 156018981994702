import { FC } from "react";

interface IProps {
  className?: string;
}

const ReportWhite: FC<IProps> = ({ className }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="9" cy="9" r="9" fill="#076EB2" />
      <path
        d="M11.9999 5.85376C11.9995 5.62744 11.9095 5.41044 11.7495 5.25041C11.5894 5.09038 11.3725 5.00032 11.1461 5H6.99998C6.73476 5 6.48042 5.10534 6.29288 5.29289C6.10534 5.48043 6 5.73478 6 6V12.5C6 12.7761 6.22385 13 6.49999 13C6.77612 13 6.99998 12.7761 6.99998 12.5V10H11.1461C11.3726 10 11.5898 9.91008 11.7499 9.74996C11.91 9.58984 12 9.37268 12 9.14624C12 8.9198 11.91 8.70259 11.7499 8.54248L10.7074 7.49998L11.7499 6.45748C11.9104 6.29765 12.0004 6.08028 11.9999 5.85376Z"
        fill="white"
      />
    </svg>
  );
};

export default ReportWhite;

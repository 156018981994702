import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AWAITING_ACTION_DISPLAY_BUFFER, GET_STARTED_EMAIL_ID, UN_REPLIED_DISPLAY_BUFFER } from "constants/scenario";
import { ActionResponseDto, EmailResponseDto } from "generated/api/apiSchemas";
import type { AppDispatch, RootState } from "redux/store";

export interface INotification {
  /**
   * Intl ID of notification name (e.g. 'Success!' at notification.name)
   */
  name: string;
  /**
   * Intl ID of notification text (* Intl ID of notification name (e.g. 'Email deleted.' at notification.text)
   */
  text: string;
}

// Define a type for the slice state
export interface IScenarioState {
  hasStartedTest: boolean;
  /**
   * Email IDs that are available in the user's inbox, this inbox is display in
   * reverse order of this array
   */
  availableEmails: { id: string; hasBeenRead: boolean; date: string }[];
  /**
   * A record of emails that once replied to will trigger another email to display
   * { [emailId]: emailIDToTrigger }
   */
  unRepliedAvailableEmails: Record<string, string>;
  /**
   * A record of all emails that have a reply
   * [emailId]: replyActionId }
   */
  replyOptions: Record<EmailResponseDto["id"], ActionResponseDto["id"]>;
  /**
   * A record of all actions and the time they were registered
   * [emailId]: replyActionId }
   */
  replyDates: Record<ActionResponseDto["id"], string>;
  notification: INotification | null;
  notificationTimer?: NodeJS.Timeout;
  totalEmailsShown: number;
  /**
   * The actionIds of all completed actions that will triger the display of another email
   */
  completedActions: string[];
  /**
   * Emails that will only be displayed when the corresponding action has been carried out
   * [actionId]: emailIDToTrigger
   */
  awaitingActionAvailableEmails: Record<string, string>;
}

// Define the initial state using that type
const initialState: IScenarioState = {
  hasStartedTest: false,
  availableEmails: [
    {
      id: GET_STARTED_EMAIL_ID,
      hasBeenRead: false,
      date: new Date().toString()
    }
  ],
  unRepliedAvailableEmails: {},
  totalEmailsShown: 0,
  replyOptions: {},
  replyDates: {},
  notification: null,
  completedActions: [],
  awaitingActionAvailableEmails: {}
};

export const scenarioSlice = createSlice({
  name: "scenario",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setReplyOption: (state, action: PayloadAction<[EmailResponseDto["id"], ActionResponseDto["id"]]>) => {
      state.replyOptions = {
        ...state.replyOptions,
        [action.payload[0]]: action.payload[1]
      };
    },
    setReplyDate: (state, action: PayloadAction<[ActionResponseDto["id"], string]>) => {
      state.replyDates = {
        ...state.replyDates,
        [action.payload[0]]: action.payload[1]
      };
    },
    addAvailableEmail: (state, action: PayloadAction<[string, string]>) => {
      state.availableEmails.push({
        id: action.payload[0],
        hasBeenRead: false,
        date: action.payload[1]
      });
      state.totalEmailsShown = state.totalEmailsShown + 1;
    },
    clearAvailableEmails: state => {
      state.availableEmails = initialState.availableEmails;
    },
    addUnRepliedAvailableEmail: (state, action: PayloadAction<[string, string]>) => {
      state.unRepliedAvailableEmails = {
        ...state.unRepliedAvailableEmails,
        [action.payload[0]]: action.payload[1]
      };
    },
    removeUnRepliedAvailableEmail: (state, action: PayloadAction<string>) => {
      delete state.unRepliedAvailableEmails[action.payload];
    },
    setEmailAsRead: (state, action: PayloadAction<string>) => {
      const email = state.availableEmails.find(e => e.id === action.payload);

      if (email && state.hasStartedTest) {
        email.hasBeenRead = true;
      }
    },
    deleteEmail: (state, action: PayloadAction<string>) => {
      state.availableEmails = state.availableEmails.filter(e => e.id !== action.payload);
    },
    resetScenario: state => {
      state.availableEmails = initialState.availableEmails;
      state.replyOptions = initialState.replyOptions;
      state.hasStartedTest = false;
    },
    showNotification: (state, action: PayloadAction<INotification>) => {
      state.notification = action.payload;
    },
    clearNotification: state => {
      state.notification = null;
    },
    startTest: state => {
      state.hasStartedTest = true;
    },
    setNotificationTimer: (state, action: PayloadAction<NodeJS.Timeout>) => {
      state.notificationTimer = action.payload;
    },
    setActionAsCompleted: (state, action: PayloadAction<string>) => {
      state.completedActions = [...state.completedActions, action.payload];
    },
    addAwaitingActionAvailableEmail: (state, action: PayloadAction<[string, string]>) => {
      state.awaitingActionAvailableEmails = {
        ...state.awaitingActionAvailableEmails,
        [action.payload[0]]: action.payload[1]
      };
    },
    removeAwaitingActionAvailableEmail: (state, action: PayloadAction<string>) => {
      delete state.awaitingActionAvailableEmails[action.payload];
    },
    incrementTotalEmailsShown: state => {
      state.totalEmailsShown = state.totalEmailsShown + 1;
    }
  }
});

export const {
  clearAvailableEmails,
  setEmailAsRead,
  deleteEmail,
  resetScenario,
  showNotification,
  clearNotification,
  setReplyDate,
  setNotificationTimer
} = scenarioSlice.actions;

/**
 * Add the emailId to the available email list
 * Unless this emailId requires a previous email to have already been replied to and hasn't been
 * or it requires an action to have been carried out
 * @param emailId string
 * @param replyToEmailId string
 * @param actionId string
 */
export const addAvailableEmail =
  (emailId: string, replyToEmailId?: string, actionId?: string) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const { replyOptions, completedActions, availableEmails } = getState().scenario;

    // If the email to reply isn't in the available emails we can assume that it has been
    // reported or deleted, since reported or deleted emails are removed from available emails
    const replyEmailHasBeenDeletedOrReported =
      replyToEmailId && !availableEmails.map(({ id }) => id).includes(replyToEmailId);

    const isReplyRequired = replyToEmailId && !Object.keys(replyOptions).includes(replyToEmailId);
    const isActionRequired = actionId && !completedActions.includes(actionId);

    if (replyEmailHasBeenDeletedOrReported) {
      // Increase the total emails shown so that this email doesn't prevent the session from
      // completing once the user has attended to all emails
      dispatch(scenarioSlice.actions.incrementTotalEmailsShown());
    } else if (!isReplyRequired && !isActionRequired) {
      dispatch(scenarioSlice.actions.addAvailableEmail([emailId, new Date().toString()]));
    } else {
      if (isReplyRequired) {
        dispatch(scenarioSlice.actions.addUnRepliedAvailableEmail([replyToEmailId, emailId]));
      }
      if (isActionRequired) {
        dispatch(scenarioSlice.actions.addAwaitingActionAvailableEmail([actionId, emailId]));
      }
    }
  };

/**
 * Set the action as completed and check if an awaiting action email needs to become available as a result
 * @param actionId string
 */
export const setCompletedAction = (actionId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
  const { scenario } = getState();
  dispatch(scenarioSlice.actions.setActionAsCompleted(actionId));

  const nowAvailableEmailId = scenario.awaitingActionAvailableEmails[actionId];
  if (nowAvailableEmailId) {
    dispatch(scenarioSlice.actions.removeAwaitingActionAvailableEmail(actionId));

    setTimeout(() => {
      dispatch(scenarioSlice.actions.addAvailableEmail([nowAvailableEmailId, new Date().toString()]));
    }, AWAITING_ACTION_DISPLAY_BUFFER);
  }
};

/**
 * Set the reply options
 * Then check if an un-replied email needs to become available as a result
 * @param [emailId, replyId] [string, string]
 */
export const setReplyOption =
  ([emailId, replyId]: [EmailResponseDto["id"], ActionResponseDto["id"]]) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(scenarioSlice.actions.setReplyOption([emailId, replyId]));

    const { scenario } = getState();
    if (scenario.unRepliedAvailableEmails[emailId]) {
      const nowAvailableEmailId = scenario.unRepliedAvailableEmails[emailId];
      dispatch(scenarioSlice.actions.removeUnRepliedAvailableEmail(emailId));

      setTimeout(() => {
        dispatch(scenarioSlice.actions.addAvailableEmail([nowAvailableEmailId, new Date().toString()]));
      }, UN_REPLIED_DISPLAY_BUFFER);
    }
  };

export const startTest = () => (dispatch: AppDispatch) => {
  dispatch(scenarioSlice.actions.startTest());

  dispatch(setEmailAsRead(GET_STARTED_EMAIL_ID));
};

export const showNotificationWithTimeout =
  (notification: INotification) => (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(showNotification(notification));

    const { scenario } = getState();

    if (scenario.notificationTimer) window.clearTimeout(scenario.notificationTimer);

    const notificationTimer = setTimeout(() => {
      dispatch(clearNotification());
    }, 5000);
    dispatch(setNotificationTimer(notificationTimer));
  };

export const selectHasStartedTest = (state: RootState) => state.scenario.hasStartedTest;
export const selectReplyOptions = (state: RootState) => state.scenario.replyOptions;
export const selectReplyDates = (state: RootState) => state.scenario.replyDates;
export const selectAvailableEmails = (state: RootState) => state.scenario.availableEmails;
export const selectNotification = (state: RootState) => state.scenario.notification;
export const selectTotalEmailsShown = (state: RootState) => state.scenario.totalEmailsShown;

export default scenarioSlice.reducer;

import { ComponentProps, FC, HTMLAttributes, MouseEventHandler, ReactNode, useCallback, useRef, useState } from "react";
import classNames from "classnames";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, registerables, TooltipModel, TooltipItem } from "chart.js";
import { Bar } from "react-chartjs-2";
import { barScale, linkFont } from "./constants/style";
import _ from "lodash";
import Popover from "components/ui/Popover/Popover";

ChartJS.register(ArcElement, Tooltip, Legend, ...registerables);

// Override chart default for legend
ChartJS.overrides["doughnut"].plugins.legend = {
  ...ChartJS.overrides["doughnut"].plugins.legend,
  display: false
};

interface IProps extends HTMLAttributes<HTMLDivElement> {
  chartProps: ComponentProps<typeof Bar>;
  getTooltipContent?: (args: { dataset: TooltipItem<"bar">["dataset"]; raw: string }) => ReactNode;
  onYAxisClick?: (index: number) => void;
}

const StackedBarChart: FC<IProps> = ({ className, chartProps, getTooltipContent, onYAxisClick, ...rest }) => {
  const [tooltip, setTooltip] = useState<TooltipModel<"bar"> | null>(null);
  const [tooltipXY, setTooltipXY] = useState<{ x: number; y: number } | null>(null);
  const [isHoverLabel, setIsHoverLabel] = useState(false);
  const chartRef = useRef<ChartJS<"bar", any, any>>();

  /** Debounce to stop react having a heart attack */
  const updateTooltip = _.debounce(
    ({ tooltip, coords }: { tooltip: TooltipModel<"bar">; coords: { x: number; y: number } }) => {
      setTooltipXY(coords);
      setTooltip(tooltip);
    },
    200
  );

  /**
   * Get details required to determine the positioning of the y labels
   */
  const getLabelDetails = useCallback((e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (!chartRef.current) {
      return;
    }

    const {
      ctx,
      canvas,
      scales: { y }
    } = chartRef.current;

    const { top, left, right, height, width, ticks } = y;
    const labelHeight = height / ticks.length;

    // Mouse coordinates
    const rect = canvas.getBoundingClientRect();
    const xCoor = e.clientX - rect.left;
    const yCoor = e.clientY - rect.top;

    return { ctx, top, left, right, width, labelHeight, xCoor, yCoor, ticks };
  }, []);

  /**
   * Get the y axis that has been clicked on
   * Reference https://www.youtube.com/watch?v=WpqWOI3fTfY
   **/
  const getLabelIndex = useCallback(
    (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
      const details = getLabelDetails(e);

      if (!details) {
        return;
      }

      const { ticks, labelHeight, left, top, xCoor, right, yCoor } = details;

      for (let i = 0; i < ticks.length; i++) {
        const labelTop = labelHeight * i;

        // If the click event is within the y axis area (return the index of that label)
        if (xCoor >= left && xCoor <= right && yCoor >= top + labelTop && yCoor <= top + labelHeight + labelTop) {
          return i;
        }
      }
    },
    [getLabelDetails]
  );

  /**
   * Handle when the user clicks on the chart, get the label index and return it to the onYAxisClick function
   */
  const handleChartClick = useCallback<MouseEventHandler<HTMLCanvasElement>>(
    e => {
      const index = getLabelIndex(e);

      if (index !== undefined) {
        onYAxisClick?.(index);
      }
    },
    [getLabelIndex, onYAxisClick]
  );

  /**
   * Set the hover flag if the mouse is over a y axis label.
   */
  const handleChartMouseMove = useCallback<MouseEventHandler<HTMLCanvasElement>>(
    e => {
      const index = getLabelIndex(e);

      setIsHoverLabel(index !== undefined);
    },
    [getLabelIndex]
  );

  return (
    <div className={classNames("px-9", className)} {...rest}>
      <Bar
        options={{
          indexAxis: "y",
          scales: {
            y: {
              ...barScale,
              ticks: {
                ...barScale.ticks,
                ...(onYAxisClick ? linkFont : {})
              }
            },
            x: barScale
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: false,
              position: "nearest",
              external: ({ tooltip, chart }) => {
                const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
                // @ts-ignore - bad typings
                const widthOfBar: number = tooltip.dataPoints[0].element.width ?? 0;

                updateTooltip({
                  tooltip,
                  coords: { x: positionX + tooltip.caretX - widthOfBar / 2, y: positionY + tooltip.caretY }
                });
              }
            }
          }
        }}
        {...chartProps}
        ref={chartRef}
        onClick={handleChartClick}
        onMouseMove={handleChartMouseMove}
        className={classNames({ "cursor-pointer": onYAxisClick && isHoverLabel })}
      />
      {tooltip && tooltip?.opacity !== 0 && getTooltipContent && (
        <Popover
          className="pointer-events-none absolute translate-x-[-50%] rounded bg-white p-4 shadow transition-all"
          style={{ left: tooltipXY?.x, top: tooltipXY?.y }}
        >
          {getTooltipContent({ dataset: tooltip.dataPoints[0].dataset, raw: tooltip.dataPoints[0].raw as string })}
        </Popover>
      )}
    </div>
  );
};

export default StackedBarChart;

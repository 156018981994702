import classNames from "classnames";
import Button from "components/ui/Button/Button";
import { FC, useState, useEffect, useMemo, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Navigate, useNavigate } from "react-router-dom";
import { fireGAEvent, GAEventName } from "utils/gtag";
import { IT_SLIDES } from "./constants/it";
import { HR_SLIDES } from "./constants/hr";
import { PROC_SLIDES } from "./constants/pm";
import useBundle from "hooks/useBundle";
import Form from "components/ui/Form/Form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "config/yup";
import { useSessionControllerUpdateSession } from "generated/api/apiComponents";
import useScenarioSessionId from "hooks/useScenarioSessionId";
import RadioGr from "components/ui/Form/RadioGroup/RadioGroup";
import LoadingWrapper from "components/ui/Loading/LoadingWrapper";
import { If, Then, Else } from "react-if";
import { MOTHERLOAD_CHEAT } from "constants/scenario";

// INFO: Did not use the pathname to set which slide to show
// because it would cause scroll position to be jumpy

type WelcomeSlideName = "welcome" | "inbox" | "reading" | "responding" | "story" | "analysis";

export type WelcomeSlide = {
  name: WelcomeSlideName;
  image?: string;
  alt?: string;
  title: string;
  subtitle?: string;
  desc: Array<string>;
  bullets?: Array<string>;
  question?: string;
  answers?: { value: string | boolean; label: string }[];
};

export type WorkStyleAnalysisInputs = {
  workStyleFeedback?: boolean;
};

// Form
const WorkStyleAnalysisSchema = yup
  .object({
    feedback: yup.bool().isTrue({ id: "welcome.analysis.agree" })
  })
  .required();

const WelcomeFlow: FC = () => {
  const hasGAEventTriggered = useRef(false);
  const [slideName, setSlideName] = useState<WelcomeSlideName>("welcome");

  // Router
  const navigate = useNavigate();
  // i18n
  const intl = useIntl();
  const { data } = useBundle();
  const sessionId = useScenarioSessionId();

  const methods = useForm<WorkStyleAnalysisInputs>({
    resolver: yupResolver(WorkStyleAnalysisSchema),
    defaultValues: { workStyleFeedback: undefined }
  });

  const workStyleFeedback = methods.watch("workStyleFeedback");

  const { mutate: updateFeedbackChoice } = useSessionControllerUpdateSession();

  /** Sign up a new user. */
  const onSubmit = async () => {
    fireGAEvent({ name: GAEventName.WorkStyleOptIn, param: workStyleFeedback ? "opt in" : "opt out" });

    try {
      if (!sessionId) return;

      updateFeedbackChoice({
        pathParams: {
          id: sessionId
        },
        body: {
          feedback: workStyleFeedback!
        }
      });
      navigate("../inbox/get-started");
    } catch (e) {
      console.error(e);
    }
  };

  // Fire Initial Page load GA event
  // - "Testee - Welcome Page" | "Testee - Welcome Page (Overt)"
  useEffect(() => {
    if (!data || hasGAEventTriggered.current) return;

    fireGAEvent({
      name: GAEventName.ScreenView,
      param: `Testee - Welcome Page${data.type === "OVERT" ? " (Overt)" : ""}`
    });

    fireGAEvent({
      name: GAEventName.ScreenView,
      param: `Testee - Welcome Page 1`
    });

    hasGAEventTriggered.current = true;
  }, [data]);

  const scenarioType = data?.scenario;

  const SLIDES = useMemo(() => {
    switch (scenarioType) {
      case "HR_ASSISTANT":
        return HR_SLIDES;
      case "IT_MANAGER":
        return IT_SLIDES;
      case "PROCUREMENT":
        return PROC_SLIDES;
      default:
        return [];
    }
  }, [scenarioType]);

  const slide = useMemo(() => {
    if (SLIDES) {
      return SLIDES.find((s: WelcomeSlide) => s.name === slideName);
    }
  }, [SLIDES, slideName]);

  const currentSlideNumber = SLIDES.findIndex(item => item.name === slide?.name) + 1;
  const totalSlides = SLIDES.length;
  const isFirstSlide = currentSlideNumber === 1;
  const isLastSlide = currentSlideNumber === totalSlides;

  /** Navigates back to previous slide or home. */
  const handleBack = () => {
    const lastSlide = SLIDES.at(currentSlideNumber - 2);

    if (!lastSlide || isFirstSlide) return navigate(-1);

    setSlideName(lastSlide.name);
  };

  /** Navigate to next slide or home. */
  const handleNext = () => {
    MOTHERLOAD_CHEAT && onSubmit();
    const nextSlide = SLIDES.at(currentSlideNumber);

    if (!nextSlide) {
      onSubmit();
    } else {
      setSlideName(nextSlide.name);

      fireGAEvent({
        name: GAEventName.ScreenView,
        param: `Testee - Welcome Page ${currentSlideNumber + 1}`
      });
    }
  };

  return (
    <If condition={data && (!SLIDES || !slide)}>
      <Then>
        <Navigate to="/404" />
      </Then>
      <Else>
        <LoadingWrapper isLoading={!data}>
          <div className={classNames(!isFirstSlide && "min-h-[745px]", "flex h-full flex-col")}>
            {/* Normal Image */}
            {slideName !== "analysis" && slide?.image && (
              <img
                src={slide?.image}
                alt={intl.formatMessage({ id: slide?.alt })}
                className="m-auto mb-15 w-[564px] text-center"
              />
            )}

            {/* Info */}
            <p className="body-900 mb-4 text-center">
              Step {currentSlideNumber}/{totalSlides}
            </p>

            {/* Title */}
            <h1 className="header-900 mb-9 text-center">
              <FormattedMessage id={slide?.title} />
            </h1>

            {/* Subtitle */}
            {slide?.subtitle && (
              <p className="body-900 mb-10 text-center !font-bold">
                <FormattedMessage id={slide?.subtitle} />
              </p>
            )}

            {/* Work Style Analysis Image */}
            {slideName === "analysis" && (
              <div className="mb-10 flex justify-center rounded-[30px] bg-primary-300 p-[60px]">
                <img src={slide?.image} alt={intl.formatMessage({ id: slide?.alt })} className="m-auto min-w-[300px]" />
                <p className="body-600 flex items-center leading-relaxed">
                  <FormattedMessage id={slide?.desc[0]} />
                </p>
              </div>
            )}

            {/* If 'story' step display bullet points inbetween descriptions - hide all for 'analysis' slide */}
            {slideName !== "analysis" && (
              <>
                {slideName === "story" ? (
                  <>
                    <p className="body-900 mb-10">
                      <FormattedMessage id={slide?.desc[0]} />
                    </p>
                    <ul className="list-inside list-disc">
                      {slide?.bullets?.map((bullet, i) => {
                        return (
                          <li className="body-900 mb-1" key={`${i}-${bullet}`}>
                            <FormattedMessage id={bullet} />
                          </li>
                        );
                      })}
                    </ul>
                    <p className="body-900 my-10">
                      <FormattedMessage id={slide?.desc[1]} />
                    </p>
                  </>
                ) : (
                  <>
                    {slide?.desc?.map((desc, i) => {
                      return (
                        <p className="body-900 mb-10 text-center" key={`${i}-${desc}`}>
                          <FormattedMessage id={desc} values={{ limit: (data?.durationSeconds ?? 0) / 60 }} />
                        </p>
                      );
                    })}
                  </>
                )}
              </>
            )}

            {slideName === "analysis" && slide?.answers && slide?.question && (
              <Form
                methods={methods}
                onSubmit={methods.handleSubmit(onSubmit)}
                className="mx-auto mb-10 flex w-full flex-col space-y-4"
              >
                <RadioGr
                  options={slide?.answers.map(a => ({ ...a, label: intl.formatMessage({ id: a.label }) }))}
                  label={intl.formatMessage({ id: slide?.question })}
                  name="workStyleFeedback"
                />
              </Form>
            )}

            {/* Button group */}
            <div className="mt-auto flex items-center justify-between">
              {!isFirstSlide && (
                <Button variant="secondary" onClick={handleBack}>
                  <FormattedMessage id="demo.back" />
                </Button>
              )}

              <Button
                className={classNames(isFirstSlide && "mx-auto")}
                onClick={handleNext}
                disabled={isLastSlide && typeof workStyleFeedback === "undefined"}
              >
                <FormattedMessage id={isLastSlide ? "demo.finish" : "demo.next"} />
              </Button>
            </div>
          </div>
        </LoadingWrapper>
      </Else>
    </If>
  );
};

export default WelcomeFlow;

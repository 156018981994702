import classNames from "classnames";
import { PropsWithChildren } from "react";
import Profile from "./NavBar/Profile";
import Link from "components/ui/Link/Link";
import CpniIcon from "components/ui/icons/CPNI";

interface IProps {
  className?: string;
  organisationName?: string;
  organisationCode?: string;
  userName?: string;
  email?: string;
}

export default function Header({
  className,
  organisationName,
  organisationCode,
  userName,
  email
}: PropsWithChildren<IProps>) {
  let identity = organisationCode;
  if (email && organisationName) {
    identity = organisationName;
  } else if (email && !organisationName) {
    identity = email;
  }

  return (
    <div className={classNames(className, "z-10 flex h-15 w-full items-center justify-between bg-white px-4")}>
      <div className="flex items-center">
        <Link to="/" className="!no-underline">
          <CpniIcon className="mr-[32px] h-[35px] object-scale-down sm:h-[42px]" />
        </Link>
        <h2 className="text-base">{identity}</h2>
      </div>
      <Profile name={userName} />
    </div>
  );
}

import { Popover } from "@headlessui/react";
import classNames from "classnames";
import { HallmarkResponseDto } from "generated/api/apiSchemas";
import { Children, FC, PropsWithChildren, useEffect, useRef, useState } from "react";
import { HiXCircle } from "react-icons/hi";

interface IProps {
  hallmark?: HallmarkResponseDto;
  onClick?: (hallmarkId: string) => void;
}

const HallMark: FC<PropsWithChildren<IProps>> = ({ children, hallmark, onClick }) => {
  const count = Children.count(children);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const popoverRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const adjustPopoverHeight = () => {
      const popover = popoverRef.current;
      if (popover && popoverVisible) {
        const viewportHeight = window.innerHeight;
        const popoverBottom = popover.getBoundingClientRect().bottom;

        if (popoverBottom > viewportHeight) {
          const remainingSpace = viewportHeight - popover.getBoundingClientRect().top - 40;
          popover.style.maxHeight = `${remainingSpace}px`;
        }
      }
    };

    adjustPopoverHeight();

    window.addEventListener("resize", adjustPopoverHeight);

    return () => {
      window.removeEventListener("resize", adjustPopoverHeight);
    };
  }, [popoverVisible]);

  if (!hallmark) return <>{children}</>;

  return (
    <Popover as="span" className="inline-flex">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              "cursor-pointer text-left text-error-500 underline underline-offset-[3px] focus:outline-0",
              { "h-[14px] w-[100px] border-b border-b-error-500": count === 0, "bg-error-400": open }
            )}
            onClick={() => {
              onClick?.(hallmark.id);
              setPopoverVisible(true);
            }}
            onBlur={() => setPopoverVisible(false)}
          >
            {children}
          </Popover.Button>
          <Popover.Panel
            key={hallmark.id}
            as="span"
            className="absolute z-10 mt-6 w-64 break-words rounded-[20px] border border-primary-500 bg-white p-4 shadow-light-shadow"
          >
            <span className="mb-2 flex justify-between">
              <p className="header-700">{hallmark.title || ""}</p>
              <Popover.Button>
                <HiXCircle className="h-6 w-6 text-primary-500" />
              </Popover.Button>
            </span>
            <p ref={popoverRef} className="body-800 overflow-auto bg-primary-300 px-4 py-3 text-base leading-5">
              {hallmark.content || ""}
            </p>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default HallMark;

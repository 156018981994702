import LinkBtn from "components/ui/LinkBtn/LinkBtn";
import { useState } from "react";
import { FaDrawPolygon, FaLaptopCode, FaPencilRuler } from "react-icons/fa";
import { FormattedMessage } from "react-intl";

export default function Sketch() {
  const [buttonClicked, setButtonClicked] = useState(false);
  const [currentLoad, setCurrentLoad] = useState(0);

  if (buttonClicked) {
    setTimeout(() => {
      if (currentLoad < 348) {
        setCurrentLoad(currentLoad + 1);
      }
    }, 5);
  }

  return (
    <>
      {buttonClicked ? (
        <div className="flex flex-col space-y-4 pt-16 text-center">
          <h1 className="text-4xl ">
            <FormattedMessage id="scenarios.web.sketch.title" />
          </h1>
          {currentLoad < 348 ? (
            <div className="relative mx-auto w-[348px] pb-4">
              <div id="progress-bar" className="absolute h-4 w-[348px] rounded-md bg-slate-600" />
              <div
                id="progress"
                className="absolute h-4 rounded-md bg-blue-500"
                style={{ width: `${currentLoad}px` }}
              />
            </div>
          ) : (
            <>
              <h1 className="text-2xl text-white">
                <FormattedMessage id="scenarios.web.download.complete" />
              </h1>
              <p>
                <FormattedMessage
                  id="scenarios.web.complete.message"
                  values={{ type: "Downloading", name: "Sketch" }}
                />
              </p>
              <LinkBtn to="../" className="mx-auto rounded-md border-0 bg-[#4A5567] px-8 py-2 text-white">
                <FormattedMessage id="general.close" />
              </LinkBtn>
            </>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center space-y-8 pt-16 uppercase">
          <h1 className="text-4xl ">
            <FormattedMessage id="scenarios.web.sketch.title" />
          </h1>
          <div className="flex items-center space-x-4 text-4xl text-white">
            <div className="flex h-32 w-32 flex-col items-center justify-center space-y-2 rounded-md bg-slate-600">
              <FaPencilRuler />
              <span className="text-base font-light">
                <FormattedMessage id="scenarios.web.sketch.title" />
              </span>
            </div>
            <div className="flex h-32 w-32 flex-col items-center justify-center space-y-2 rounded-md bg-slate-600">
              <FaDrawPolygon />
              <span className="text-base font-light">
                <FormattedMessage id="scenarios.web.sketch.design" />
              </span>
            </div>
            <div className="flex h-32 w-32 flex-col items-center justify-center space-y-2 rounded-md bg-slate-600">
              <FaLaptopCode />
              <span className="text-base font-light">
                <FormattedMessage id="scenarios.web.sketch.render" />
              </span>
            </div>
          </div>
          <ul className="list-disc font-sans font-normal">
            <li>
              <FormattedMessage id="scenarios.web.sketch.list.1" />
            </li>
            <li>
              <FormattedMessage id="scenarios.web.sketch.list.2" />
            </li>
            <li>
              <FormattedMessage id="scenarios.web.sketch.list.3" />
            </li>
            <li>
              <FormattedMessage id="scenarios.web.sketch.list.4" />
            </li>
          </ul>
          <button className="rounded-md bg-[#4A5567] px-8 py-2 text-white" onClick={() => setButtonClicked(true)}>
            <FormattedMessage id="scenarios.web.download" />
          </button>
        </div>
      )}
    </>
  );
}

import { FC } from "react";
import { FaLock } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import CloseIcon from "assets/icons/CloseIcon.svg";
import ExpandIcon from "assets/icons/ExpandIcon.svg";
import MinimiseIcon from "assets/icons/MinimiseIcon.svg";

interface IProps {
  url: string;
  children: JSX.Element;
}

const Browser: FC<IProps> = ({ url = "www.apple.com", children }) => {
  return (
    <div className="absolute left-1/2 top-1/2 flex h-[90vh] w-[80vw] -translate-x-1/2 -translate-y-1/2 flex-col rounded-b-md shadow-md">
      <div className="flex h-8 w-full items-center justify-start bg-[rgb(232,_232,_232)] px-2 py-6">
        <div className="flex items-center space-x-2">
          <Link className="flex h-4 w-4 rounded-full bg-red-500" to="../">
            <img src={CloseIcon} alt="close" />
          </Link>
          <div className="flex h-4 w-4 rounded-full bg-yellow-500">
            <img src={MinimiseIcon} alt="" role="presentation" />
          </div>
          <div className="flex h-4 w-4 rounded-full bg-green-500">
            <img src={ExpandIcon} alt="" role="presentation" />
          </div>
        </div>
        <div className="ml-2 flex grow items-center justify-start rounded-lg bg-white pl-2 font-normal">
          <span className="flex items-center space-x-2 pr-2 text-green-700">
            <span className="mr-2 flex items-center space-x-2">
              <FaLock />{" "}
              <span>
                <FormattedMessage id="component.browser.secure" />
              </span>
            </span>
            <span>|</span>
          </span>
          <span className="text-black">
            <span className="text-green-700">https</span>://www.{url}
          </span>
        </div>
      </div>
      <div className="relative h-full w-full">
        <div className="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 bg-white">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Browser;

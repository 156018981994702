import classNames from "classnames";
import { FC, PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

export interface IProps {
  hasError?: boolean;
  isFullHeight?: boolean;
  mainClassName?: string;
  maxWidthClass?: string;
}

const FullPageContentLayout: FC<PropsWithChildren<IProps>> = props => {
  const { hasError = false, isFullHeight = true, maxWidthClass, mainClassName, children } = props;

  return (
    <div className={classNames("flex flex-col items-center justify-center", isFullHeight && "min-h-screen")}>
      <main
        className={classNames(
          mainClassName,
          "my-9 flex w-full flex-col border-2 bg-white px-9 py-15 shadow-light-shadow",
          maxWidthClass ?? "max-w-[800px]",
          hasError ? "min-h-[322px] border-error-500" : "min-h-[420px] border-[#9B9B9B40]"
        )}
      >
        {children ?? <Outlet />}
      </main>
    </div>
  );
};

export default FullPageContentLayout;

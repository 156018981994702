/** Match image file extension. */
import { TourStep } from "components/ui/Tour";
import { IntlShape } from "react-intl";
import { NODE_ENV } from "./env";

// Allow all emails to come in instantly and render a Finish Early button
export const MOTHERLOAD_CHEAT = NODE_ENV === "production" ? false : sessionStorage.getItem("motherlode") === "true";

export const IMAGE_REGEX = /\.(gif|jpg|jpeg|tiff|png)$/i;

// If an unReplied email becomes available, wait X seconds before user sees it in their inbox
export const UN_REPLIED_DISPLAY_BUFFER = 1000 * 30; // 30 seconds

// Number of seconds to wait before displaying an awaiting action email when it becomes available
export const AWAITING_ACTION_DISPLAY_BUFFER = 1000 * 30;

export enum WebBrowsers {
  "MyMoneyTransfer" = "My Money Transfer",
  "Browse254" = "Browser 254",
  "BookingForm" = "Booking Form",
  "ScribbleDesign" = "Scribble Design",
  "Sketch" = "Sketch"
}

export const GET_STARTED_EMAIL_ID = "get-started";
export const GET_FINAL_EMAIL_ID = "get-final-email";

export const GET_STARTED_VIDEO_URLS = {
  IT_MANAGER: `${process.env.REACT_APP_MEDIA_BASE}/IT+supporting+materials/Introduction.mp4`,
  HR_ASSISANT: `${process.env.REACT_APP_MEDIA_BASE}/HR+supporting+materials/Introduction.mp4`,
  PROCUREMENT: `${process.env.REACT_APP_MEDIA_BASE}/Procurement+Supporting+Materials/Introduction.mp4`
};

export const GET_STARTED_EMAIL_MENU_TITLE = (intl: IntlShape) => ({
  id: GET_STARTED_EMAIL_ID,
  label: intl.formatMessage({ id: "scenarios.inbox.get.started.email.label" }),
  subLabel: intl.formatMessage({ id: "scenarios.administrator" }),
  delta: 0,
  to: GET_STARTED_EMAIL_ID,
  className: "js-reactour-step-7"
});

export const GET_FINAL_EMAIL_MENU_TITLE = (intl: IntlShape) => ({
  id: GET_FINAL_EMAIL_ID,
  label: intl.formatMessage({ id: "scenarios.inbox.final.email.label" }),
  subLabel: intl.formatMessage({ id: "scenarios.administrator" }),
  delta: 0,
  to: GET_FINAL_EMAIL_ID
});

export const GET_STARTED_EMAIL_REPLY_OPTIONS = (intl: IntlShape) => [
  {
    id: "a",
    label: intl.formatMessage({ id: "scenarios.inbox.get.started.email.response.a" })
  },
  {
    id: "b",
    label: intl.formatMessage({ id: "scenarios.inbox.get.started.email.response.b" })
  }
];

export const GET_STARTED_MAIN_NAV_STEPS: Record<string, TourStep> = {
  staffProfiles: {
    className: "max-w-[480px]",
    selector: ".js-reactour-step-3",
    title: "scenarios.tour.step.3.title",
    desc: "scenarios.tour.step.3.desc",
    placement: "right",
    mask: [1, 10]
  },
  accounts: {
    className: "max-w-[480px]",
    selector: ".js-reactour-step-4",
    title: "scenarios.tour.step.4.title",
    desc: "scenarios.tour.step.4.desc",
    placement: "right",
    mask: [1, 10]
  },
  webOptions: {
    className: "max-w-[480px]",
    selector: ".js-reactour-step-5",
    title: "scenarios.tour.step.5.title",
    desc: "scenarios.tour.step.5.desc",
    placement: "right",
    mask: [1, 10]
  },
  files: {
    className: "max-w-[480px]",
    selector: ".js-reactour-step-6",
    title: "scenarios.tour.step.6.title",
    desc: "scenarios.tour.step.6.desc",
    placement: "right",
    mask: [1, 10]
  }
};

export const GET_START_TOUR_FIRST_STEPS: TourStep[] = [
  {
    className: "max-w-[480px]",
    selector: ".js-reactour-step-1",
    title: "scenarios.tour.step.1.title",
    desc: "scenarios.tour.step.1.desc",
    placement: "right",
    mask: 0
  },
  {
    className: "max-w-[480px]",
    selector: ".js-reactour-step-2",
    title: "scenarios.tour.step.2.title",
    desc: "scenarios.tour.step.2.desc",
    placement: "right",
    mask: [1, 10]
  }
];

export const GET_STARTED_TOUR_LAST_STEPS: TourStep[] = [
  {
    className: "max-w-[480px]",
    selector: ".js-reactour-step-7",
    title: "scenarios.tour.step.7.title",
    desc: "scenarios.tour.step.7.desc",
    placement: "right",
    mask: [1, 10]
  },
  {
    className: "max-w-[315px]",
    selector: ".js-reactour-step-8",
    title: "scenarios.tour.step.8.title",
    desc: "scenarios.tour.step.8.desc",
    placement: "left",
    mask: 0
  },
  {
    className: "max-w-[315px]",
    selector: ".js-reactour-step-9",
    title: "scenarios.tour.step.9.title",
    desc: "scenarios.tour.step.9.desc",
    placement: "bottom",
    mask: 10,
    offset: [0, 54]
  },
  {
    className: "max-w-[315px]",
    selector: ".js-reactour-step-10",
    title: "scenarios.tour.step.10.title",
    desc: "scenarios.tour.step.10.desc",
    placement: "top",
    mask: 10,
    offset: [0, 54]
  },
  {
    className: "max-w-[315px]",
    selector: ".js-reactour-step-11",
    title: "scenarios.tour.step.11.title",
    desc: "scenarios.tour.step.11.desc",
    placement: "bottom",
    mask: 5,
    offset: [0, 49]
  }
];

// https://github.com/elrumordelaluz/reactour/tree/main/packages/tour#styles-stylesobj--popoverstylesobj--maskstylesobj-1
export const GET_STARTED_TOUR_STYLES = {
  maskWrapper: (base: any) => ({
    ...base,
    opacity: 1,
    color: "#000000"
  }),
  maskRect: (base: any) => ({
    ...base,
    opacity: 0.5
  }),
  clickArea: (base: any) => ({
    ...base,
    opacity: 0.5
  }),
  highlightedArea: (base: any) => ({
    ...base,
    display: "unset",
    strokeWidth: 8,
    stroke: "rgb(255, 255, 255)"
  })
};

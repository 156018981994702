import classNames from "classnames";
import { formatDistance } from "date-fns";
import DOMPurify from "dompurify";
import { getTraineeNameForScenario } from "helpers/getters";
import useBundle from "hooks/useBundle";
import { DetailedHTMLProps, FC, HTMLAttributes } from "react";
import { FormattedMessage } from "react-intl";
import Avatar from "./Avatar";
import Profile, { IProps as IProfileProps } from "./Profile";
import { SenderResponseDto } from "generated/api/apiSchemas";

interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  subject?: string;
  emailId?: string;
  from?: IProfileProps;
  to?: IProfileProps;
  cc?: SenderResponseDto;
  date: Date;
}

const EmailHeader: FC<IProps> = ({ className, from, date, emailId, subject, to, cc }) => {
  const { data: { scenario } = {} } = useBundle();
  const user = getTraineeNameForScenario(scenario);

  const time = () => {
    return ` ${date.toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" })} (${formatDistance(
      date,
      new Date(),
      { addSuffix: true }
    )})`;
  };

  return (
    <div className={classNames(className, "flex items-start")}>
      <div className="flex items-center gap-2.5">
        <Avatar name={DOMPurify.sanitize(from?.name ?? user ?? "")} variant={from ? "primary" : "secondary"} />
        <div className="flex flex-col gap-2.5">
          <div className="flex items-center">
            <span className="header-300 mr-1 text-black">
              <FormattedMessage id={`scenarios.inbox.header.from`} />{" "}
              {(from?.name && DOMPurify.sanitize(from.name)) || <FormattedMessage id="scenarios.inbox.header.to.you" />}
            </span>
            {from?.email && <span className="body-500 flex leading-6">{`<${DOMPurify.sanitize(from.email)}>`}</span>}
            <span className="body-700 flex items-center text-primary-500 underline focus:outline-0"></span>
          </div>
          <div>{cc && <span className="body-700 mr-1 flex text-black">CC: {DOMPurify.sanitize(cc.email)}</span>}</div>
          <div className="flex items-center gap-2.5">
            <span className="body-700 flex text-black">
              <FormattedMessage id={`scenarios.inbox.header.to`} />
              &nbsp;
              {to?.name ? to?.name : <FormattedMessage id="scenarios.inbox.header.to.you" />}
            </span>
            <span>{from && <Profile emailId={emailId} {...from} subject={subject} date={date} time={time} />}</span>
            <span>
              {to && (
                <Profile reverseRecipient={true} emailId={emailId} {...to} subject={subject} date={date} time={time} />
              )}
            </span>
          </div>
        </div>
      </div>
      <span className="body-700 grow text-right text-neutral-500">{time()} </span>
    </div>
  );
};

export default EmailHeader;

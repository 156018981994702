import React, { Dispatch } from "react";
import { EmailAction, FinalEmail } from "state-management/reducers/finalEmailReducer";

interface FinalEmailContextType {
  finalEmail: FinalEmail;
  emailDispatch: Dispatch<EmailAction>;
}

const FinalEmailContext = React.createContext<FinalEmailContextType>({} as FinalEmailContextType);

export default FinalEmailContext;

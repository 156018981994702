import { Dialog } from "@headlessui/react";
import classNames from "classnames";
import { FC, HTMLAttributes, PropsWithChildren } from "react";

export interface IModalWrapperProps {
  open: boolean;
  onClose: () => void;
  backdrop?: "translucent" | "opaque";
  className?: string;
}
export interface IProps extends IModalWrapperProps {
  image?: string;
  title: string;
  description?: string;
  wide?: boolean;
}

export const ModalWrapper: FC<PropsWithChildren<IModalWrapperProps>> = props => {
  const { backdrop = "translucent", children, className, ...rest } = props;

  return (
    <Dialog {...rest} className={classNames("relative z-50", className)}>
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div
        className={`fixed inset-0 ${backdrop === "translucent" ? "bg-black/75" : "bg-neutral-300"}`}
        aria-hidden="true"
      />
      {children}
    </Dialog>
  );
};

const Modal: FC<PropsWithChildren<IProps>> = props => {
  const {
    open,
    onClose,
    title,
    image,
    description,
    children,
    className,
    backdrop = "translucent",
    wide = false
  } = props;
  return (
    <ModalWrapper open={open} onClose={onClose} backdrop={backdrop}>
      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex items-center justify-center p-2 font-medium">
        <Dialog.Panel
          className={classNames(
            className,
            !wide ? "max-w-3xl px-20 py-16" : "max-w-4xl p-9",
            "mx-auto flex w-full flex-col items-center gap-8 border-2 bg-white text-center shadow-light-shadow"
          )}
        >
          {image && <img className="flex" src={image} alt="" role="presentation" />}
          <Dialog.Title className="text-3xl font-bold leading-9">{title}</Dialog.Title>
          {description && (
            <Dialog.Description className="whitespace-pre-wrap text-base">{description}</Dialog.Description>
          )}
          <div className="flex items-center justify-center">{children}</div>
        </Dialog.Panel>
      </div>
    </ModalWrapper>
  );
};

export const ModalButtonContainer: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, ...rest }) => {
  return (
    <div className={classNames(className, "flex flex-col items-center justify-center gap-y-6")} {...rest}>
      {children}
    </div>
  );
};

export default Modal;

const EditWhite = () => {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.07039 1.49373L6.50635 2.92969L6.84902 2.58702L5.41306 1.15106L5.07039 1.49373Z" fill="white" />
      <path d="M1.24686 5.32165L2.68262 6.75781L6.2567 3.18474L4.82094 1.74858L1.24686 5.32165Z" fill="white" />
      <path
        d="M7.41534 2.03842C7.64611 1.80765 7.64611 1.43842 7.41534 1.20765L6.81534 0.607647C6.58457 0.376878 6.19226 0.376878 5.98457 0.607647L5.68457 0.907647L7.13842 2.36149L7.41534 2.03842Z"
        fill="white"
      />
      <path
        d="M0.423116 7.346C0.400039 7.41523 0.423116 7.48446 0.46927 7.53062C0.492346 7.5537 0.5385 7.57677 0.584654 7.57677C0.607731 7.57677 0.630808 7.57677 0.653885 7.57677L2.38465 6.9537L1.04619 5.61523L0.423116 7.346Z"
        fill="white"
      />
    </svg>
  );
};

export default EditWhite;

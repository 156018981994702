import { Popover } from "@headlessui/react";
import { useQueryClient } from "@tanstack/react-query";
import Chevron from "components/ui/icons/Chevron";
import CognitoService from "helpers/cognito.service";
import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { usePopper } from "react-popper";
import { Link } from "react-router-dom";
import { fireGAEvent, GAEventName } from "utils/gtag";

export interface IProps {
  name?: string;
}

const Profile: FC<IProps> = ({ name }) => {
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement);
  const queryClient = useQueryClient();

  const handleSettingsClick = () => {
    fireGAEvent({ name: GAEventName.ButtonSettings });
  };

  const handleLogout = () => {
    // Sign out of cognito
    CognitoService.signOut();
    // Clear all connected caches for react-query
    queryClient.clear();
    // Ga Event
    fireGAEvent({ name: GAEventName.ButtonLogout });
  };

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button ref={setReferenceElement} className="flex items-center text-primary-500 focus:outline-0">
            <span className="mr-2 text-base text-black">{name}</span>
            <Chevron direction={open ? "up" : "down"} />
          </Popover.Button>

          <Popover.Panel
            ref={setPopperElement}
            className="absolute z-20 mt-4 flex w-64 flex-col gap-y-7 border-2 bg-white p-6 text-center text-primary-500 shadow-light-shadow"
            style={styles.popper}
            {...attributes.popper}
          >
            <Link to="/admin/settings" className="py-2" onClick={handleSettingsClick}>
              <FormattedMessage id="admin.navbar.profile.settings" />
            </Link>
            <Link to="/admin/login" className="py-2" onClick={handleLogout}>
              <FormattedMessage id="admin.navbar.profile.logout" />
            </Link>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default Profile;

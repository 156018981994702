import { yupResolver } from "@hookform/resolvers/yup";
import Form from "components/ui/Form/Form";
import LinkBtn from "components/ui/LinkBtn/LinkBtn";
import LoadingSpinner from "components/ui/Loading/Spinner";
import Modal from "components/ui/Modal/Modal";
import yup from "config/yup";
import CognitoService from "helpers/cognito.service";
import { matchesCurrentField } from "helpers/yup";
import ConfirmCodeForm from "pages/admin/auth/reset-password-flow/components/ConfirmCodeForm";
import EmailForm from "pages/admin/auth/reset-password-flow/components/EmailForm";
import NewPasswordForm from "pages/admin/auth/reset-password-flow/components/NewPasswordForm";
import { FC, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

export interface IProps {}

export type TResetPasswordInputs = {
  email: string;
  confirmationCode: number;
  newPassword: string;
  confirmNewPassword: string;
};

enum EFlowStates {
  EMAIL,
  CODE,
  NEW_PASSWORD
}

const ImplementerRequestResetPassword: FC<IProps> = props => {
  // i18n
  const intl = useIntl();

  // Router
  const navigate = useNavigate();

  const [currentFlowState, setCurrentFlowState] = useState<EFlowStates>(EFlowStates.EMAIL);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const ResetPasswordSchema = useMemo(() => {
    let yupConfigObject: Record<string, any> = {
      email: yup.string().email().required()
    };

    if (currentFlowState === EFlowStates.CODE || currentFlowState === EFlowStates.NEW_PASSWORD) {
      yupConfigObject = {
        ...yupConfigObject,
        confirmationCode: yup.number().min(6).required()
      };
    }

    if (currentFlowState === EFlowStates.NEW_PASSWORD) {
      yupConfigObject = {
        ...yupConfigObject,
        newPassword: yup.string().min(8).required(),
        confirmNewPassword: yup
          .string()
          .when("newPassword", matchesCurrentField({ id: "errors.password.does.not.match" }))
          .required()
      };
    }

    return yup.object(yupConfigObject).required();
  }, [currentFlowState]);

  const methods = useForm<TResetPasswordInputs>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(ResetPasswordSchema)
  });

  const onSubmit: SubmitHandler<TResetPasswordInputs> = async data => {
    setIsLoading(true);

    switch (currentFlowState) {
      // Step 1 - Email User with confirmation code
      case EFlowStates.EMAIL: {
        try {
          await CognitoService.forgotPassword(data.email);

          setCurrentFlowState(EFlowStates.CODE);
        } catch (e: any) {
          methods.resetField("email");
          methods.setError("email", { message: e.message });
        }

        break;
      }
      // Step 2 - User confirms the confirmation code in their email
      case EFlowStates.CODE: {
        setCurrentFlowState(EFlowStates.NEW_PASSWORD);
        break;
      }
      // Step 3 - User confirms the new password they wish to use
      case EFlowStates.NEW_PASSWORD: {
        try {
          await CognitoService.forgotPasswordSubmit(
            data.email,
            data.confirmationCode.toString().trim(),
            data.newPassword
          );

          setShowConfirm(true);
        } catch (e: any) {
          methods.resetField("newPassword");
          methods.resetField("confirmNewPassword");
          methods.setError("confirmNewPassword", { message: e.message });
        }
      }
    }

    setIsLoading(false);
  };

  const handleConfirmDismiss = () => {
    navigate("../login");
  };

  if (isLoading) {
    return <LoadingSpinner className="m-auto" />;
  }

  return (
    <>
      <Modal
        className="py-24"
        title={intl.formatMessage({ id: "admin.forgotten.password.new.password.modal.title" })}
        onClose={handleConfirmDismiss}
        open={showConfirm}
      >
        <LinkBtn to="../login" className="mt-[45px]">
          <FormattedMessage id="general.close" />
        </LinkBtn>
      </Modal>

      <div className="m-auto w-[530px] text-center">
        <Form methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
          {/* Step 1 - Email User with confirmation code */}
          {currentFlowState === EFlowStates.EMAIL && <EmailForm />}

          {/* Step 2 - User confirms the confirmation code in their email */}
          {currentFlowState === EFlowStates.CODE && <ConfirmCodeForm />}

          {/* Step 3 - User confirms the new password they wish to use */}
          {currentFlowState === EFlowStates.NEW_PASSWORD && <NewPasswordForm />}
        </Form>
      </div>
    </>
  );
};

export default ImplementerRequestResetPassword;

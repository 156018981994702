import { Disclosure } from "@headlessui/react";
import { ActionResponseDto, EmailResponseDto } from "generated/api/apiSchemas";
import useBundle from "hooks/useBundle";
import { useAppSelector } from "hooks/useRedux";
import { FC, Fragment, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { selectAvailableEmails, selectReplyDates, selectReplyOptions } from "redux/modules/scenario";
import EmailHeader from "./Header";
import EmailBody from "./Body";
import classNames from "classnames";
import Chevron from "components/ui/icons/Chevron";
import { When } from "react-if";

interface IProps {
  emailId: string;
  className?: string;
}

type IThreadItem = EmailResponseDto & { action: ActionResponseDto | undefined; dateFrom: Date; replyDate: Date };

interface IEmailThreadItem {
  email: IThreadItem;
  isFrom: boolean;
}

const EmailThreadItem: FC<IEmailThreadItem> = ({ email, isFrom }) => {
  return (
    <Disclosure as="div" className="mt-8 border-b border-neutral-500 pb-8 last-of-type:border-none">
      {({ open: threadOpen }) => (
        <>
          <Disclosure.Button className="flex w-full gap-2.5">
            <EmailHeader
              subject={email.subject}
              emailId={email.id}
              cc={email.cc}
              from={isFrom ? email.sender : undefined}
              to={isFrom ? undefined : email.sender}
              date={isFrom ? email.dateFrom : email.replyDate}
              className="grow"
            />
            <Chevron direction={threadOpen ? "up" : "down"} className="text-primary-500" />
          </Disclosure.Button>
          <Disclosure.Panel className="mt-8">
            <EmailBody subject={email.subject} body={isFrom ? email.body : email.action?.value} />
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

const EmailThread: FC<IProps> = ({ emailId, className }) => {
  // Redux
  const selectedReplyOptions = useAppSelector(selectReplyOptions);
  const selectedReplyDates = useAppSelector(selectReplyDates);
  const availableEmails = useAppSelector(selectAvailableEmails);

  // Data
  const { data } = useBundle();

  const emailThread = useMemo(() => {
    const emails = data?.emails ?? [];
    const thread: IThreadItem[] = [];

    let currentEmailId: string | null = emailId;

    const findEmail = (id: string) => {
      return emails.find(email => id === email.id);
    };

    do {
      const currentEmail = findEmail(currentEmailId);
      const nextEmail = emails.find(email => currentEmail?.replyToEmailId === email.id);

      if (currentEmail && nextEmail) {
        // Now find a reply to email.
        const emailFromRedux = availableEmails.find(available => available.id === nextEmail.id);
        currentEmailId = nextEmail.id;
        const actionID = selectedReplyOptions[nextEmail.id];
        const action = nextEmail.actions.find(action => action.id === actionID);
        const replyDate = selectedReplyDates[actionID];

        thread.push({
          ...nextEmail,
          action,
          dateFrom: emailFromRedux?.date ? new Date(emailFromRedux?.date) : new Date(),
          replyDate: new Date(replyDate)
        });
      } else {
        currentEmailId = null;
      }
    } while (currentEmailId);

    return thread.filter(item => Boolean(item.action));
  }, [availableEmails, data, emailId, selectedReplyDates, selectedReplyOptions]);

  return (
    <When condition={emailThread?.length > 0}>
      <Disclosure as="div" className={classNames("bg-white px-15 py-8", className)}>
        {({ open }) => (
          <>
            <Disclosure.Button className="c-button c-button--primary-outline ml-auto flex gap-2.5 px-3">
              <FormattedMessage id="scenarios.inbox.thread.show" values={{ count: emailThread.length * 2 }} />
              <Chevron direction={open ? "up" : "down"} />
            </Disclosure.Button>
            <Disclosure.Panel>
              {emailThread.map(email => (
                <Fragment key={email.id}>
                  <EmailThreadItem isFrom email={email} />
                  <EmailThreadItem isFrom={false} email={email} />
                </Fragment>
              ))}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </When>
  );
};

export default EmailThread;

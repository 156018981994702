import WorkStyleCircle from "components/ui/Results/WorkStyle/WorkStyleCircle";
import { FC, Fragment, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Transition } from "@headlessui/react";

export interface IProps {
  scores?: Record<string, any>;
}

enum WorkStyleHoverCircles {
  Planning = "PLANNING",
  UncertaintyManagement = "UNCERTAINTY_MANAGEMENT",
  Coordinating = "COORDINATING",
  Adapting = "ADAPTING"
}

const WorkStyles: FC<IProps> = props => {
  const { scores = {} } = props;

  const [isPopperOverOpen, setIsPopperOverOpen] = useState(false);
  const [workStyleHoverState, setWorkStyleHoverState] = useState<WorkStyleHoverCircles | null>(null);

  const intl = useIntl();

  return (
    <div className="mb-9 rounded-lg bg-primary-300 px-[1.125rem] py-9">
      <h2 className="header-700 mb-9 text-center">
        <FormattedMessage id="scenarios.results.work.style.title" />
      </h2>

      <div className="flex gap-x-10">
        <WorkStyleCircle
          className="w-1/4"
          title={intl.formatMessage({ id: "scenarios.results.work.style.planning" })}
          colour="#1AA4CE"
          score={scores[WorkStyleHoverCircles.Planning]}
          onMouseOver={() => {
            setWorkStyleHoverState(WorkStyleHoverCircles.Planning);
            setIsPopperOverOpen(true);
          }}
          onMouseLeave={() => setIsPopperOverOpen(false)}
        />

        <WorkStyleCircle
          className="w-1/4"
          title={intl.formatMessage({ id: "scenarios.results.work.style.uncertainty.management" })}
          colour="#ECA53E"
          score={scores[WorkStyleHoverCircles.UncertaintyManagement]}
          onMouseOver={() => {
            setWorkStyleHoverState(WorkStyleHoverCircles.UncertaintyManagement);
            setIsPopperOverOpen(true);
          }}
          onMouseLeave={() => setIsPopperOverOpen(false)}
        />

        <WorkStyleCircle
          className="w-1/4"
          title={intl.formatMessage({ id: "scenarios.results.work.style.coordinating" })}
          colour="#1A6130"
          score={scores[WorkStyleHoverCircles.Coordinating]}
          onMouseOver={() => {
            setWorkStyleHoverState(WorkStyleHoverCircles.Coordinating);
            setIsPopperOverOpen(true);
          }}
          onMouseLeave={() => setIsPopperOverOpen(false)}
        />

        <WorkStyleCircle
          className="w-1/4"
          title={intl.formatMessage({ id: "scenarios.results.work.style.adapting" })}
          colour="#172F60"
          score={scores[WorkStyleHoverCircles.Adapting]}
          onMouseOver={() => {
            setWorkStyleHoverState(WorkStyleHoverCircles.Adapting);
            setIsPopperOverOpen(true);
          }}
          onMouseLeave={() => setIsPopperOverOpen(false)}
        />
      </div>

      <div className="relative">
        <p className="body-600 py-8 text-center">
          <FormattedMessage id="scenarios.results.work.style.desc" />
        </p>

        <Transition
          show={isPopperOverOpen}
          as={Fragment}
          enter="transition-opacity duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute left-0 top-0 mx-2 rounded-[1.25rem] border-2 border-[#9B9B9B40] bg-white opacity-0">
            <p className="body-600 px-[1.313rem] py-3 text-center !font-bold">
              <FormattedMessage
                // "scenarios.results.work.style.XXXX.desc.XXXX"
                id={`scenarios.results.work.style.${workStyleHoverState ?? WorkStyleHoverCircles.Planning}.desc.${
                  parseFloat(scores[workStyleHoverState || WorkStyleHoverCircles.Planning]) >= 0.75 ? "high" : "low"
                }`}
              />
            </p>
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default WorkStyles;

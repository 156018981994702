import { FC, useMemo } from "react";
import { Link, useMatch, useNavigate, useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useTraineeControllerTrainees } from "generated/api/apiComponents";
import BinFillIcon from "components/ui/icons/BinFill";
import Table, { TABLE_ACTION_CELL_SIZE, TableEmpty } from "components/ui/Table/Table";

import LoadingWrapper from "components/ui/Loading/LoadingWrapper";
import DeleteTraineeModal from "./components/DeleteTraineeModal";
import ResetTraineePasswordModal from "./components/ResetTraineePasswordModal";
import useFireGAEventOnMount from "hooks/useFireGAEventOnMount";
import { GAEventName } from "utils/gtag";
import ActionBar from "components/ui/admin/Elements/ActionBar";
import { TraineeResponseDto } from "generated/api/apiSchemas";
import { ColumnDef } from "@tanstack/react-table";
import Card from "components/ui/admin/Elements/Card";

export interface IProps {}

const UsersPage: FC<IProps> = props => {
  // Query
  const { data, isLoading } = useTraineeControllerTrainees({});

  // i18n
  const intl = useIntl();

  // Router
  const navigate = useNavigate();
  const { traineeId } = useParams<{ traineeId: string }>();
  const isDeleteOpen = useMatch("admin/users/delete/:traineeId");
  const isResetOpen = useMatch("admin/users/reset/:traineeId");

  // Table
  const tableHeaders = useMemo<ColumnDef<TraineeResponseDto>[]>(
    () => [
      {
        accessorKey: "username",
        header: intl.formatMessage({ id: "admin.users.table.header.1" }),
        enableSorting: false,
        size: TABLE_ACTION_CELL_SIZE * 3.3
      },
      {
        id: "reset",
        header: intl.formatMessage({ id: "admin.users.table.header.2" }),
        size: TABLE_ACTION_CELL_SIZE * 3,
        cell: ({ row }) => (
          <Link to={`reset/${row.original.id}`} className="underline">
            <FormattedMessage id="admin.users.table.header.2" />
          </Link>
        )
      },
      {
        id: "actions",
        header: "",
        cell: ({ row }) => (
          <Link to={`delete/${row.original.id}`} aria-label={intl.formatMessage({ id: "general.delete" })}>
            <BinFillIcon className="mr-2.5 ml-auto text-error-500" />
          </Link>
        ),
        size: TABLE_ACTION_CELL_SIZE
      }
    ],
    [intl]
  );

  useFireGAEventOnMount({ name: GAEventName.ScreenView, param: "Implementer - Users (Index View)" });

  const closeModal = () => navigate("/admin/users");

  return (
    <LoadingWrapper isLoading={isLoading}>
      {/* Modals */}
      <DeleteTraineeModal traineeId={isDeleteOpen ? traineeId : undefined} close={closeModal} />
      <ResetTraineePasswordModal traineeId={isResetOpen ? traineeId : undefined} close={closeModal} />

      {/* Page content */}
      {/* Title and controls */}
      <ActionBar titleProps={{ id: "admin.users.title" }} className="mb-9" />

      {/* List of tests */}
      <Card hasShadow>
        <Table<TraineeResponseDto>
          data={data ?? []}
          columns={tableHeaders}
          emptyElement={
            <TableEmpty
              title={intl.formatMessage({ id: "admin.users.empty.title" })}
              description={intl.formatMessage({ id: "admin.users.empty.description" })}
            />
          }
        />
      </Card>
    </LoadingWrapper>
  );
};

export default UsersPage;

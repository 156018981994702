import { FC, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormattedMessage, useIntl } from "react-intl";
import yup from "config/yup";
import { matchesCurrentField } from "helpers/yup";
import Button from "components/ui/Button/Button";
import LinkButton from "components/ui/LinkBtn/LinkBtn";
import Form from "components/ui/Form/Form";
import EmailSection, { EmailUpdateInputs } from "./components/EmailSection";
import PasswordSection, { PasswordUpdateInputs } from "./components/PasswordSection";
import CognitoService from "helpers/cognito.service";
import { useMatch, useNavigate } from "react-router-dom";
import EmailVerifyModal from "./components/EmailVerifyModal";
import LoadingWrapper from "components/ui/Loading/LoadingWrapper";
import ErrorMessage from "components/ui/ErrorMessage/ErrorMessage";
import { AuthException } from "helpers/exceptions/auth.exception";
import DeleteUserModal from "./components/DeleteUserModal";
import useFireGAEventOnMount from "hooks/useFireGAEventOnMount";
import { GAEventName } from "utils/gtag";
import Modal from "components/ui/Modal/Modal";

type EmailPasswordUpdateInput = EmailUpdateInputs & PasswordUpdateInputs;

const EmailUpdateSchema = yup
  .object({
    currentEmail: yup.string().email(),
    newEmail: yup.string().when("currentEmail", {
      is: (email: string) => !!email,
      then: yup.string().email().required()
    }),
    confirmNewEmail: yup.string().when("newEmail", {
      is: (email: string) => !!email,
      then: yup.string().when("newEmail", matchesCurrentField({ id: "errors.email.does.not.match" }))
    }),
    currentPassword: yup.string(),
    newPassword: yup.string().when("currentPassword", {
      is: (password: string) => !!password,
      then: yup.string().min(8).required()
    }),
    confirmNewPassword: yup.string().when("newPassword", {
      is: (password: string) => !!password,
      then: yup.string().when("newPassword", matchesCurrentField({ id: "errors.password.does.not.match" }))
    })
  })
  .required();

export interface IProps {}

const SettingsPage: FC<IProps> = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [newEmail, setNewEmail] = useState<string>();
  const [showEmailSection, setShowEmailSection] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  // i18n
  const intl = useIntl();

  // Router
  const navigate = useNavigate();
  const isVerifyOpen = useMatch("admin/settings/verify");
  const isDeleteOpen = useMatch("admin/settings/delete");

  useFireGAEventOnMount({ name: GAEventName.ScreenView, param: "Implementer - Edit Own Account" });

  // Form
  const methods = useForm<EmailPasswordUpdateInput>({
    resolver: yupResolver(EmailUpdateSchema)
  });

  /** Save email & password. */
  const onSubmit: SubmitHandler<EmailPasswordUpdateInput> = async data => {
    setLoading(true);
    setErrorMessage(undefined);

    try {
      const { email } = await CognitoService.updateUser({
        email:
          data.currentEmail && data.newEmail
            ? {
                current: data.currentEmail,
                new: data.newEmail
              }
            : undefined,
        password:
          data.currentPassword && data.newPassword
            ? {
                current: data.currentPassword,
                new: data.newPassword
              }
            : undefined
      });

      // Clear form
      methods.reset();

      if (email && !isVerifyOpen) {
        setNewEmail(data.newEmail);
        navigate("verify");
      }
      if (data.currentPassword) {
        setShowConfirm(true);
      }
    } catch (err) {
      let id = "errors.desc";

      if ((err as AuthException).id) id = (err as AuthException).id;

      setErrorMessage(intl.formatMessage({ id }));
    } finally {
      setLoading(false);
    }
  };

  /** Delete the current user. */
  const handleDeleteUser = () => {
    navigate("delete");
  };

  /** Close modals. */
  const handleCloseModal = async (success?: boolean) => {
    if (success) {
      await CognitoService.signOut();
      return navigate("/admin/login", {
        state: {
          email: newEmail
        }
      });
    }
    navigate("../");
  };

  const handleConfirmDismiss = () => {
    setShowConfirm(false);
  };

  /**
   * Only show email section if user has an email set
   */
  useEffect(() => {
    let active = true;
    const load = async () => {
      const user = await CognitoService.getCurrentUser();

      if (active) setShowEmailSection(!!user?.attributes.email);
    };

    load();

    return () => {
      active = false;
    };
  });

  return (
    <LoadingWrapper isLoading={loading}>
      {/* Password confirm modal */}
      <Modal
        className="py-24"
        title={intl.formatMessage({ id: "admin.forgotten.password.new.password.modal.title" })}
        onClose={handleConfirmDismiss}
        open={showConfirm}
      >
        <Button onClick={handleConfirmDismiss} className="mt-[45px]">
          <FormattedMessage id="general.close" />
        </Button>
      </Modal>

      {/* Email verify */}
      <EmailVerifyModal show={!!isVerifyOpen} onClose={handleCloseModal} resend={() => onSubmit(methods.getValues())} />

      {/* Delete user */}
      <DeleteUserModal show={!!isDeleteOpen} onClose={handleCloseModal} />

      <div className="flex flex-col">
        <FormProvider {...methods}>
          <Form methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
            <EmailSection show={showEmailSection} onDeleteClick={handleDeleteUser} />
            <PasswordSection showDeleteUser={!showEmailSection} onDeleteClick={handleDeleteUser} />

            <ErrorMessage error={errorMessage} className="pt-4" />

            <div className="mt-9 flex w-full justify-end gap-3">
              <LinkButton to="../" variant="secondary">
                Cancel
              </LinkButton>
              <Button type="submit">Save</Button>
            </div>
          </Form>
        </FormProvider>
      </div>
    </LoadingWrapper>
  );
};

export default SettingsPage;

import classNames from "classnames";
import useBundle from "hooks/useBundle";
import useInteraction from "hooks/useInteraction";
import { FC } from "react";
import { Outlet, Link } from "react-router-dom";

export interface IProps {}

const ScenarioWebPage: FC<IProps> = props => {
  const { data } = useBundle();

  const registerInteraction = useInteraction();

  return (
    <div className="flex w-[calc(100vw-165px)] flex-wrap items-start justify-center space-x-12 pt-32">
      {data?.webOptions?.map(({ id, name }) => {
        const handleWebOpen = () => {
          registerInteraction("WEB_OPEN", {
            payload: {
              id,
              name
            }
          });
        };

        return (
          <div key={id}>
            <Link
              to={`./${id}`}
              onClick={handleWebOpen}
              className={classNames("flex w-52 cursor-pointer flex-col space-y-1 text-center")}
            >
              <div className="h-52 w-52 rounded-4xl bg-[#192A56]" />
              <span className={"pt-4 font-sans text-xl font-normal text-neutral-600"}>{name}</span>
            </Link>
          </div>
        );
      })}

      <Outlet />
    </div>
  );
};

export default ScenarioWebPage;

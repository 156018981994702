import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { FC, useEffect } from "react";
import CognitoService from "helpers/cognito.service";
import { Outlet, useMatch } from "react-router-dom";
import IMMainNav from "components/ui/admin/NavBar/MainNav";
import Header from "../components/ui/admin/Header";

export interface IProps {}

const MAIN_NAV = [
  {
    key: "dashboard",
    label: "Organisation",
    to: "dashboard"
  },
  {
    key: "sessions",
    label: "Sessions",
    to: "sessions"
  },
  {
    key: "users",
    label: "Users",
    to: "users"
  }
];

const AdminLayout: FC<IProps> = props => {
  const isDashboardPage = useMatch("/admin/dashboard");

  const { data } = useQuery(["authUser"], () => CognitoService.getCurrentUser());

  // Show and hide the Zendesk Web Widget
  useEffect(() => {
    // @ts-ignore
    if (!window.zE) return;

    // @ts-ignore
    window.zE("webWidget", "show");

    return () => {
      // @ts-ignore
      window.zE("webWidget", "hide");
    };
  }, []);

  return (
    <div className="relative grid h-screen grid-cols-[220px_1fr]">
      <IMMainNav items={MAIN_NAV} />
      <div className="relative">
        <Header
          className={classNames(!isDashboardPage && "shadow-sm shadow-neutral-450")}
          organisationName={data?.attributes["custom:org_name"]}
          organisationCode={data?.username}
          email={data?.attributes["email"]}
          userName={data?.attributes["email"] || data?.attributes["custom:org_name"]}
        />
        <div className="relative px-9 pt-15 pb-20">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;

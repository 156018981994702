import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import Button from "components/ui/Button/Button";
import Modal from "components/ui/Modal/Modal";
import Notification from "components/ui/Notification/Notification";
import Header from "components/ui/scenarios/Header";
import SNMainNav, { IProps as ISNMainNavProps } from "components/ui/scenarios/NavBar/MainNav";
import SNTimer from "components/ui/scenarios/Timer/Timer";
import TourProvider, { TourStep } from "components/ui/Tour";
import TimeIcon from "assets/icons/TimeIcon.svg";
import {
  GET_FINAL_EMAIL_ID,
  GET_START_TOUR_FIRST_STEPS,
  GET_STARTED_EMAIL_ID,
  GET_STARTED_MAIN_NAV_STEPS,
  GET_STARTED_TOUR_LAST_STEPS,
  MOTHERLOAD_CHEAT
} from "constants/scenario";
import { useApiContext } from "generated/api/apiContext";
import useBundle from "hooks/useBundle";
import useInteraction from "hooks/useInteraction";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useScenarioTitle from "hooks/useScenarioTitle";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useInterval } from "react-use";
import { selectClockTick, startClock, stopClock, updateClock } from "redux/modules/clockTick";
import {
  addAvailableEmail,
  clearAvailableEmails,
  clearNotification,
  resetScenario,
  selectAvailableEmails,
  selectHasStartedTest,
  selectNotification,
  selectTotalEmailsShown
} from "redux/modules/scenario";
import { fireGAEvent, GAEventName } from "utils/gtag";

const TIMEOUT_TIME = MOTHERLOAD_CHEAT ? 1 : 60000;

const standardTime = 1000 * 60 * 25;
const extraTime = 1000 * 60 * 40;

const ScenarioLayout: FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showMoreTimeModal, setShowMoreTimeModal] = useState(false);
  const [hasTimeBeenAdded, setHasTimeBeenAdded] = useState(false);
  const [maxTestTime, setMaxTestTime] = useState(standardTime);
  const [mouseEvent, setMouseEvent] = useState<MouseEvent>();
  const [isTourOpen, setIsTourOpen] = useState(false);
  const title = useScenarioTitle();
  const elapsedTime = useAppSelector(selectClockTick);

  // i18n
  const intl = useIntl();

  // API
  const queryClient = useQueryClient();
  const { queryKeyFn } = useApiContext();

  // Redux
  const dispatch = useAppDispatch();
  const availableEmails = useAppSelector(selectAvailableEmails);
  const notification = useAppSelector(selectNotification);
  const hasStartedTest = useAppSelector(selectHasStartedTest);
  const totalEmailsShown = useAppSelector(selectTotalEmailsShown);

  const { data } = useBundle();
  const { screenX, screenY } = window;

  // Router
  const navigate = useNavigate();
  const { scenarioId } = useParams<{ scenarioId: string }>();

  useEffect(() => {
    if (!showModal) return;

    fireGAEvent({ name: GAEventName.ScreenView, param: "Testee - Exit Test" });
  }, [showModal]);

  // Mutation
  const registerInteraction = useInteraction();

  /** Track generic mouse click. */
  const handleClick = ({ clientX, clientY }: MouseEvent) => {
    registerInteraction("CLICK", {
      payload: {
        // INFO: Using snakecase convention for data science
        x_pos: clientX,
        y_pos: clientY,
        screen_size_x: screenX,
        screen_size_y: screenY
      }
    });
  };

  /** Track mouse movement. */
  const handleMove = () => {
    if (!mouseEvent) return;

    registerInteraction("MOUSE_MOVEMENT", {
      payload: {
        // INFO: Using snakecase convention for data science
        x_pos: mouseEvent.clientX,
        y_pos: mouseEvent.clientY,
        screen_size_x: screenX,
        screen_size_y: screenY
      }
    });
  };

  const handleSetMouseEvent = (e: MouseEvent) => {
    setMouseEvent(e);
  };

  const invalidateBundle = useCallback(() => {
    if (!scenarioId) return; // Should never get here

    queryClient.invalidateQueries({
      queryKey: queryKeyFn({
        path: "/trainee/test/{id}",
        operationId: "testControllerTraineeTest",
        variables: { pathParams: { id: scenarioId } }
      })
    });
  }, [queryClient, queryKeyFn, scenarioId]);

  /** Handlers for when the user falls offline */
  useEffect(() => {
    if (!scenarioId) return;

    const handleBackOnline = () => {
      // Invalidate the bundle call is user falls offline and then re-connects
      // This means when the user is back online, the bundle is re-fetched
      // Giving the user a new sessionId, effectively restarting the test
      invalidateBundle();

      // Take user back to landing page for the scenario
      navigate(`/scenario/${scenarioId}`);

      window.removeEventListener("online", handleBackOnline);
    };

    const handleOffline = () => {
      window.addEventListener("online", handleBackOnline);
    };

    window.addEventListener("offline", handleOffline);

    return () => window.removeEventListener("offline", handleOffline);
  }, [invalidateBundle, navigate, scenarioId]);

  const stopTest = () => {
    dispatch(stopClock());

    if (!MOTHERLOAD_CHEAT) {
      navigate("results");
    }
  };

  /** Start clock and set timeouts for each email */
  useEffect(() => {
    if (!data || !hasStartedTest) return;

    // Highest delta to lowest delta
    const emailsInReverseOrder = [...data.emails].sort((a, b) => b.delta - a.delta);

    const checkNextEmailAvailability = (tick: number) => {
      if (
        emailsInReverseOrder.length &&
        // Should the next email display? (email delta is in seconds)
        // Has current tick reached or surpassed the next email's delta value
        tick >= emailsInReverseOrder[emailsInReverseOrder.length - 1].delta * 1000
      ) {
        // Use .pop as it's faster than .shift
        const nextEmailAvailable = emailsInReverseOrder.pop()!;
        dispatch(
          addAvailableEmail(nextEmailAvailable.id, nextEmailAvailable.replyToEmailId, nextEmailAvailable.actionId)
        );

        // In case emails have the same delta value
        checkNextEmailAvailability(tick);
      }
    };

    dispatch(
      startClock({
        maxDelta: standardTime,
        // This will be called as each "interval" passes (see TICK_INTERVAL)
        interval: tick => {
          checkNextEmailAvailability(tick);
        },
        // This will be called once the timer runs out
        callback: stopTest
      })
    );

    return () => {
      dispatch(stopClock());
      dispatch(resetScenario());
      dispatch(clearAvailableEmails());
    };
    // eslint-disable-next-line
  }, [dispatch, data, hasStartedTest]);

  // Polls to track mouse movement
  useInterval(handleMove, hasStartedTest ? 3000 : null);

  /**
   * Show default browser blocker modal if user tries to close or refresh browser early.
   * Registers listeners to track mouse click and movement.
   */
  useEffect(() => {
    const fn = (event: any) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", fn);
    window.addEventListener("click", handleClick);
    window.addEventListener("mousemove", handleSetMouseEvent);

    return () => {
      window.removeEventListener("beforeunload", fn);
      window.removeEventListener("click", handleClick);
      window.removeEventListener("mousemove", handleSetMouseEvent);
    };
    // eslint-disable-next-line
  }, []);

  /** Exits training and resets redux. */
  const confirmExit = () => {
    navigate("./");
    setShowModal(false);
    dispatch(stopClock());
    dispatch(resetScenario());

    invalidateBundle();
  };

  const [navItems, firstTourSteps] = useMemo(() => {
    // Dynamically Build the Tour Steps, depending on the menu options available
    const getStartTourSteps: TourStep[] = [...GET_START_TOUR_FIRST_STEPS];

    const items: ISNMainNavProps["items"] = [
      {
        key: "emails",
        className: classNames("js-reactour-step-2", isTourOpen && "!text-white"),
        label: intl.formatMessage(
          {
            id: "scenarios.inbox.count"
          },
          { count: availableEmails.filter(e => !e.hasBeenRead).length }
        ),
        to: hasStartedTest ? "inbox" : `inbox/${GET_STARTED_EMAIL_ID}`
      }
    ];

    if (!data) return [items, getStartTourSteps];

    // Staff profiles
    if (data.staffProfiles.length > 0) {
      items.push({
        key: "staffProfiles",
        className: classNames("js-reactour-step-3", isTourOpen && "!text-white"),
        label: intl.formatMessage({ id: "scenarios.profiles" }),
        to: "staff"
      });

      getStartTourSteps.push(GET_STARTED_MAIN_NAV_STEPS["staffProfiles"]);
    }

    // Accounts
    if (data.accounts.length > 0) {
      items.push({
        key: "accounts",
        className: classNames("js-reactour-step-4", isTourOpen && "!text-white"),
        label: intl.formatMessage({ id: "scenarios.accounts" }),
        to: "accounts"
      });

      getStartTourSteps.push(GET_STARTED_MAIN_NAV_STEPS["accounts"]);
    }

    // Web
    if (data.webOptions.length > 0) {
      items.push({
        key: "webOptions",
        className: classNames("js-reactour-step-5", isTourOpen && "!text-white"),
        label: intl.formatMessage({ id: "scenarios.web" }),
        to: "web"
      });

      getStartTourSteps.push(GET_STARTED_MAIN_NAV_STEPS["webOptions"]);
    }

    // Files
    if (data.files.length > 0) {
      items.push({
        key: "files",
        className: classNames("js-reactour-step-6", isTourOpen && "!text-white"),
        label: intl.formatMessage({ id: "scenarios.files" }),
        to: "files"
      });

      getStartTourSteps.push(GET_STARTED_MAIN_NAV_STEPS["files"]);
    }

    return [items, getStartTourSteps];
  }, [availableEmails, data, intl, isTourOpen, hasStartedTest]);

  const handleExitEarly = () => {
    fireGAEvent({ name: GAEventName.ButtonExitTest });
    setShowModal(true);
  };

  const handleMoreTime = () => {
    setShowMoreTimeModal(true);
  };

  /**
   * When the number of emails shown === the length from the bundle,
   * show the final email after TIMEOUT_TIME
   */
  useEffect(() => {
    const numberOfEmails = data?.emails.length;
    if (data?.emails && numberOfEmails === totalEmailsShown) {
      setTimeout(() => {
        dispatch(addAvailableEmail(GET_FINAL_EMAIL_ID));
      }, TIMEOUT_TIME);
    }
  }, [data?.emails, data?.emails.length, dispatch, totalEmailsShown]);

  return (
    <TourProvider
      isOpen={isTourOpen}
      onChange={isOpen => setIsTourOpen(isOpen)}
      steps={[...firstTourSteps, ...GET_STARTED_TOUR_LAST_STEPS]}
    >
      <div className="relative">
        <Header className="absolute top-0" title={title}>
          <SNTimer
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className="js-reactour-step-11"
            totalTime={maxTestTime}
          />
          {hasStartedTest && (
            <Button disabled={hasTimeBeenAdded} onClick={handleMoreTime}>
              <FormattedMessage id="scenarios.modal.moreTime" />
            </Button>
          )}
          <Button onClick={handleExitEarly}>
            <FormattedMessage id="general.exit" />
          </Button>
        </Header>
        <div className="grid h-screen grid-cols-[180px_284px_1fr] pt-20">
          {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
          <SNMainNav className="js-reactour-step-1" items={navItems} />
          <Outlet />
        </div>

        <Notification open={!!notification} setOpen={() => dispatch(clearNotification())}>
          <b className="font-bold">
            <FormattedMessage id={notification?.name} />
          </b>{" "}
          <FormattedMessage id={notification?.text} />
        </Notification>

        {/* Exit modal */}
        <Modal
          title={intl.formatMessage({ id: "scenarios.modal.exit.title" })}
          description={intl.formatMessage({ id: "scenarios.modal.exit.desc" })}
          open={showModal}
          onClose={() => setShowModal(false)}
        >
          <div className="flex flex-col items-center space-y-6">
            <Button variant="primary" onClick={() => setShowModal(false)}>
              <FormattedMessage id="general.close" />
            </Button>
            <Button variant="secondary" onClick={confirmExit}>
              <FormattedMessage id="general.exit" />
            </Button>
          </div>
        </Modal>

        {/* More time modal */}
        <Modal
          image={TimeIcon}
          wide={true}
          title={intl.formatMessage({ id: "scenarios.modal.moreTime.title" })}
          description={intl.formatMessage({ id: "scenarios.modal.moreTime.desc" })}
          open={showMoreTimeModal}
          onClose={() => setShowMoreTimeModal(false)}
        >
          <div className="flex items-center gap-11">
            <Button
              variant="primary"
              onClick={() => {
                dispatch(updateClock({ maxDelta: extraTime - elapsedTime, callback: stopTest }));
                setHasTimeBeenAdded(true);
                setMaxTestTime(extraTime);
                setShowMoreTimeModal(false);
              }}
            >
              <FormattedMessage id="scenarios.modal.moreTime.add" />
            </Button>
            <Button variant="secondary" onClick={() => setShowMoreTimeModal(false)}>
              <FormattedMessage id="scenarios.modal.moreTime.cancel" />
            </Button>
          </div>
        </Modal>
      </div>
    </TourProvider>
  );
};

export default ScenarioLayout;

import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormContext } from "react-hook-form";
import Input from "components/ui/Form/Input/Input";
import DeleteUserButton from "./DeleteUserButton";

export type PasswordUpdateInputs = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

interface IProps {
  onDeleteClick: () => void;
  showDeleteUser?: boolean;
}

const PasswordSection: FC<IProps> = ({ onDeleteClick, showDeleteUser }) => {
  const intl = useIntl();

  // Form
  const methods = useFormContext();

  return (
    <div>
      <div className="mb-9 flex items-center justify-between">
        <h1 id="password-update-form-title" className="header-900">
          <FormattedMessage id="admin.settings.password.title" />
        </h1>

        {/* Delete account */}
        {showDeleteUser && <DeleteUserButton onDeleteClick={onDeleteClick} />}
      </div>

      {/* Card form */}
      <div className="c-card flex flex-col space-y-9 p-9">
        {/* Current Password */}
        <div className="flex w-[531px] justify-between">
          <Input
            type="password"
            inlineLabel
            label={intl.formatMessage({ id: "admin.settings.password.form.currentPassword" })}
            placeholder={intl.formatMessage({ id: "admin.settings.password.form.currentPassword" })}
            className="w-[280px]"
            {...methods.register("currentPassword")}
          />
        </div>

        {/* New Password  */}
        <div className="flex w-[531px] justify-between">
          <Input
            type="password"
            inlineLabel
            label={intl.formatMessage({ id: "admin.settings.password.form.newPassword" })}
            placeholder={intl.formatMessage({ id: "admin.settings.password.form.newPassword" })}
            className="w-[280px]"
            {...methods.register("newPassword")}
          />
        </div>

        {/* Confirm New Password */}
        <div className="flex w-[531px] justify-between">
          <Input
            type="password"
            inlineLabel
            label={intl.formatMessage({ id: "admin.settings.password.form.confirmNewPassword" })}
            placeholder={intl.formatMessage({ id: "admin.settings.password.form.confirmNewPassword" })}
            className="w-[280px]"
            {...methods.register("confirmNewPassword")}
          />
        </div>
      </div>
    </div>
  );
};

export default PasswordSection;

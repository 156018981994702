export type FinalEmail = {
  finalEmailId: string;
};

interface FinalEmailAction {
  type: "update";
  task: FinalEmail;
}

export type EmailAction = FinalEmailAction;

const finalEmailReducer = (state: FinalEmail, action: EmailAction): FinalEmail => {
  if (action.type === "update") {
    return action.task;
  } else {
    return state;
  }
};

export default finalEmailReducer;

import Berkley from "components/ui/scenarios/Web/Browser/Berkley";
import Browser from "components/ui/scenarios/Web/Browser/Browser";
import Browser254 from "components/ui/scenarios/Web/Browser/Browser254";
import MoneyTransfer from "components/ui/scenarios/Web/Browser/Money";
import Scribble from "components/ui/scenarios/Web/Browser/Scribble";
import Sketch from "components/ui/scenarios/Web/Browser/Sketch";
import { WebBrowsers } from "constants/scenario";
import useBundle from "hooks/useBundle";
import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";

export interface IProps {}

const BrowserDisplay: FC<IProps> = () => {
  const { data } = useBundle();
  const { webId } = useParams<{ webId: string }>();

  const currentWebBrowser = useMemo(() => data?.webOptions.find(i => i.id === webId), [data, webId]);

  const [url, Window] = useMemo(() => {
    switch (currentWebBrowser?.name.trim()) {
      case WebBrowsers.MyMoneyTransfer:
        return ["mymoneytransfer.com", MoneyTransfer];
      case WebBrowsers.Browse254:
        return ["browser254.com/update", Browser254];
      case WebBrowsers.BookingForm:
        return ["berkleycourt.org/form", Berkley];
      case WebBrowsers.ScribbleDesign:
        return ["scribbledesigns.com/download", Scribble];
      case WebBrowsers.Sketch:
        return ["sketch.org/download", Sketch];
      default:
        return ["", "div"];
    }
  }, [currentWebBrowser]);

  return (
    <Browser url={url}>
      <Window />
    </Browser>
  );
};

export default BrowserDisplay;

import { useTestControllerTraineeTest } from "generated/api/apiComponents";
import { useParams } from "react-router-dom";

/**
 * Do not use this hook on the admin side.
 * useTestControllerTraineeTest will trigger an update on the backend
 * to say a user has viewed the scenario.
 * This would result in admin users being added to the list of users
 * who have viewed the scenario.
 */
const useBundle = () => {
  const { scenarioId } = useParams<{ scenarioId: string }>();

  return useTestControllerTraineeTest(
    {
      pathParams: {
        id: scenarioId!
      }
    },
    {
      enabled: !!scenarioId,
      staleTime: Infinity,
      cacheTime: 1000 * 60 * 20 // 20 minutes
    }
  );
};

export default useBundle;

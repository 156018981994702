import { DetailedHTMLProps, FormHTMLAttributes, PropsWithChildren } from "react";
import { UseFormReturn, FormProvider, FieldValues } from "react-hook-form";

export interface IProps<T extends FieldValues>
  extends DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
  methods: UseFormReturn<T>;
}

const Form = <T extends FieldValues>(props: PropsWithChildren<IProps<T>>) => {
  const { children, methods, ...rest } = props;

  return (
    <FormProvider {...methods}>
      <form {...rest}>{children}</form>
    </FormProvider>
  );
};

export default Form;

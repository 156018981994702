import classNames from "classnames";
import { FC, HTMLAttributes } from "react";

interface IProps extends HTMLAttributes<HTMLDivElement> {
  placement?: "top" | "left" | "right" | "bottom";
}

const Popover: FC<IProps> = ({ className, children, placement = "top", ...rest }) => {
  return (
    <div
      className={classNames(
        className,
        {
          "mt-[35px]": placement === "top",
          "ml-[35px]": placement === "left",
          "mr-[35px]": placement === "right",
          "mb-[35px]": placement === "bottom"
        },
        "absolute z-[1000] w-full max-w-xxs rounded-7xl border-2 border-[#9B9B9B40] bg-white p-9 shadow-light-shadow"
      )}
      {...rest}
    >
      {children}

      {/* Arrow */}
      <div
        className={classNames(
          {
            "left-1/2 top-[30px] -translate-x-1/2 -translate-y-full": placement === "top",
            "left-1/2 bottom-[-13.5px] -translate-x-1/2 translate-y-1/2 rotate-180": placement === "bottom",
            "left-[-13.5px] top-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-90": placement === "left",
            "right-[-13.5px] top-1/2 translate-x-1/2 -translate-y-1/2 rotate-90": placement === "right"
          },
          "absolute"
        )}
      >
        <svg width="110" height="87" viewBox="0 0 110 87" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_5326_2850)">
            <path d="M55 21L85.3109 59.25L24.6891 59.25L55 21Z" fill="white" />
            <path
              d="M26.7575 58.25L55 22.6101L83.2425 58.25L26.7575 58.25Z"
              stroke="#9B9B9B"
              strokeOpacity="0.25"
              strokeWidth="2"
            />
          </g>
          <rect x="87" y="57" width="24" height="69" transform="rotate(90 87 57)" fill="white" />
          <defs>
            <filter
              id="filter0_d_5326_2850"
              x="0.689453"
              y="0"
              width="108.621"
              height="86.25"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="12" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.207843 0 0 0 0 0.207843 0 0 0 0 0.207843 0 0 0 0.08 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5326_2850" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5326_2850" result="shape" />
            </filter>
          </defs>
        </svg>
      </div>
      {/* End Arrow */}
    </div>
  );
};

export default Popover;

import classNames from "classnames";
import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import InputError from "../InputError/InputError";

interface IProps
  extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, keyof UseFormRegisterReturn>,
    UseFormRegisterReturn {
  wrapperClassName?: string;
  entryClassName?: string;
}

const Checkbox = forwardRef<HTMLInputElement, IProps>((props, ref) => {
  const { wrapperClassName, entryClassName, className, children, ...checkboxProps } = props;

  return (
    <div className={wrapperClassName}>
      <div className={classNames("flex items-center", entryClassName)}>
        {/* Checkbox entry */}
        <input type="checkbox" className={classNames("h-6 w-6", className)} {...checkboxProps} ref={ref} />

        {/* Content */}
        {children}
      </div>

      {/* Error message */}
      <InputError name={props.name} />
    </div>
  );
});

export default Checkbox;

import { Popover } from "@headlessui/react";
import Chevron from "components/ui/icons/Chevron";
import { formatDistance } from "date-fns";
import DOMPurify from "dompurify";
import { getEmail } from "helpers/getters";
import useInteraction from "hooks/useInteraction";
import { FC, useMemo, useState } from "react";
import { Modifier, usePopper } from "react-popper";
import EmailPopoverDetail from "./EmailPopoverDetail";
import useBundle from "hooks/useBundle";

export interface IProps {
  name: string;
  email?: string | null;
  emailId?: string;
  subject?: string;
  time?: () => void;
  date?: Date;
  reverseRecipient?: boolean;
}

const ProfilePopover: FC<IProps> = props => {
  const { data } = useBundle();
  const { name, email, emailId, subject, date, reverseRecipient = false } = props;

  const registerInteraction = useInteraction();

  const interactionModifier = useMemo<Modifier<unknown, object>>(
    () => ({
      name: "interaction",
      enabled: true,
      phase: "main",
      fn: () => {
        registerInteraction("EMAIL_SENDER_OPEN", {
          emailId: emailId
        });
      }
    }),
    [emailId, registerInteraction]
  );

  let [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  let [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [interactionModifier]
  });

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className="body-700 flex items-center text-primary-500 underline focus:outline-0"
          >
            <Chevron direction={open ? "up" : "down"} />
          </Popover.Button>

          <Popover.Panel
            ref={setPopperElement}
            className="absolute z-10 ml-28 mt-1.5 min-w-max rounded-xl border-2 bg-white p-9 shadow-light-shadow"
            style={styles.popper}
            {...attributes.popper}
          >
            <div className="flex flex-col gap-3.5">
              {email && (
                <EmailPopoverDetail detail="from">
                  {reverseRecipient
                    ? getEmail(data?.scenario)
                    : ` ${DOMPurify.sanitize(name)}
                    <${DOMPurify.sanitize(email)}>`}
                </EmailPopoverDetail>
              )}
              <EmailPopoverDetail detail="to">
                {!reverseRecipient
                  ? getEmail(data?.scenario)
                  : email &&
                    ` ${DOMPurify.sanitize(name)}
                  <${DOMPurify.sanitize(email)}>`}
              </EmailPopoverDetail>
              {date && (
                <EmailPopoverDetail detail="date">
                  {new Date(date).toLocaleDateString()},
                  {` ${date.toLocaleTimeString("en-GB", {
                    hour: "2-digit",
                    minute: "2-digit"
                  })} `}
                  ({formatDistance(date, new Date(), { addSuffix: true })})
                </EmailPopoverDetail>
              )}
              {subject && <EmailPopoverDetail detail="subject">{subject}</EmailPopoverDetail>}
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default ProfilePopover;

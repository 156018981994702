import { Dialog, Tab } from "@headlessui/react";
import classNames from "classnames";
import { FC, Fragment, PropsWithChildren, useState } from "react";
import { IModalWrapperProps, ModalWrapper } from "../Modal/Modal";
import Button from "../Button/Button";
import { FormattedMessage, useIntl } from "react-intl";
import Chevron from "../icons/Chevron";
import LoadingWrapper from "../Loading/LoadingWrapper";

export interface IProps extends IModalWrapperProps {
  wide?: boolean;
  steps: IStepProps[];
  isLoading?: boolean;
}

export interface IStepProps {
  children: React.ReactNode;
}

const StepModal: FC<PropsWithChildren<IProps>> = props => {
  const { open, onClose, steps, className, backdrop = "translucent", wide = false, isLoading = false } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const intl = useIntl();

  const canGoBack = selectedIndex > 0;
  const canGoForward = selectedIndex < steps.length - 1;
  const isFirstStep = selectedIndex === 0;
  const isLastStep = selectedIndex === steps.length - 1;

  const handleClose = () => {
    setSelectedIndex(0);
    onClose();
  };

  return (
    <ModalWrapper open={open} onClose={handleClose} backdrop={backdrop}>
      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex items-center justify-center p-4 font-medium ">
        <Dialog.Panel
          className={classNames(
            className,
            !wide ? "max-w-3xl" : "max-w-4xl",
            "relative mx-auto max-h-full w-full overflow-y-auto border-2 bg-white text-center shadow-light-shadow"
          )}
        >
          <LoadingWrapper isLoading={isLoading} className="mb-9">
            <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
              <Tab.Panels>
                {steps.map((step, index) => (
                  <Tab.Panel key={index} className="relative">
                    {canGoBack && (
                      <Button
                        className="absolute left-9 top-9 z-10 aspect-square h-8 w-8 rounded-full p-0"
                        onClick={() => setSelectedIndex(selectedIndex - 1)}
                        variant="primary"
                        aria-label={intl.formatMessage({ id: "general.back" })}
                      >
                        <Chevron direction="right" />
                      </Button>
                    )}
                    {step.children}
                  </Tab.Panel>
                ))}
              </Tab.Panels>
              <div className="sticky bottom-0 flex w-full justify-end bg-white p-4 shadow-light-shadow">
                <Tab.List className="absolute inset-0 top-1/2 flex -translate-y-1/2 items-center justify-center gap-4">
                  {steps.map((step, index) => (
                    <Tab as={Fragment} key={index}>
                      {({ selected }) => (
                        /* Use the `selected` state to conditionally style the selected tab. */
                        <button
                          aria-label={intl.formatMessage({ id: `general.openTab` }, { step: index + 1 })}
                          className={classNames("aspect-square h-4 w-4 rounded-full", {
                            "bg-neutral-500": !selected,
                            "bg-primary-500": selected
                          })}
                        />
                      )}
                    </Tab>
                  ))}
                </Tab.List>
                <Button
                  variant="primary"
                  onClick={() => (!isLastStep ? setSelectedIndex(selectedIndex + 1) : handleClose())}
                  disabled={!canGoForward && !isLastStep}
                  className="z-10"
                >
                  {isFirstStep && <FormattedMessage id="general.begin" />}
                  {!isFirstStep && !isLastStep && <FormattedMessage id="general.next" />}
                  {isLastStep && <FormattedMessage id="general.close" />}
                </Button>
              </div>
            </Tab.Group>
          </LoadingWrapper>
        </Dialog.Panel>
      </div>
    </ModalWrapper>
  );
};

export default StepModal;

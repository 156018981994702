import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/ui/Button/Button";
import ErrorMessage from "components/ui/ErrorMessage/ErrorMessage";
import Form from "components/ui/Form/Form";
import Input from "components/ui/Form/Input/Input";
import yup from "config/yup";
import CognitoService from "helpers/cognito.service";
import { getSignUpErrorMessage } from "helpers/getters";
import { matchesCurrentField } from "helpers/yup";
import { FC, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import useFireGAEventOnMount from "hooks/useFireGAEventOnMount";
import { fireGAEvent, GAEventName } from "utils/gtag";
import PasswordMoreInfo from "components/signUp/passwordMoreInfo";
import LoadingWrapper from "components/ui/Loading/LoadingWrapper";

export interface IProps {}

export type TSignUpInputs = {
  username: string;
  password: string;
  confirmPassword: string;
  testId: string;
};

const SignUpSchema = yup
  .object({
    username: yup
      .string()
      .required()
      .trim()
      // Can't include any whitespaces
      .matches(/^(\S+$)/g, {
        message: {
          id: "errors.string.no.whitespace"
        }
      }),
    password: yup.string().min(8).required(),
    confirmPassword: yup
      .string()
      .when("password", matchesCurrentField({ id: "errors.password.does.not.match" }))
      .required(),
    testId: yup.string().required()
  })
  .required();

const TesteeSignUp: FC<IProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();

  // i18n
  const intl = useIntl();

  // Router
  const { scenarioId } = useParams<string>();
  const navigate = useNavigate();

  useFireGAEventOnMount({ name: GAEventName.ScreenView, param: "Testee - Sign Up" });

  // Form
  const methods = useForm<TSignUpInputs>({
    resolver: yupResolver(SignUpSchema),
    defaultValues: {
      testId: scenarioId
    }
  });

  const onSubmit: SubmitHandler<TSignUpInputs> = async data => {
    fireGAEvent({ name: GAEventName.ButtonSignUpTesteeConfirm });

    setIsLoading(true);

    try {
      await CognitoService.traineeSignUp(data);

      navigate(`../welcome`, { replace: true });
    } catch (e: any) {
      if (e.message === "autoSignIn_failure") {
        navigate(`../login`, { replace: true });
      } else {
        methods.reset();
        setError({
          ...e,
          // INFO: Strips Cognito text to prettify the message
          message: e.message.replace(/^.*with\serror\s/, "")
        });
      }
    }

    setIsLoading(false);
  };

  return (
    <LoadingWrapper isLoading={isLoading}>
      <div className="m-auto w-[530px]">
        <h1 className="header-900 mb-9 text-center">
          <FormattedMessage id="trainee.signUp.page.title" />
        </h1>

        <Form className="flex flex-col gap-y-9" methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
          <Input
            label={intl.formatMessage({ id: "general.username" })}
            placeholder={intl.formatMessage({ id: "general.username" })}
            required
            {...methods.register("username")}
          />
          <Input
            label={intl.formatMessage({ id: "general.password" })}
            placeholder={intl.formatMessage({ id: "general.password" })}
            type="password"
            required
            moreInfo={<PasswordMoreInfo />}
            {...methods.register("password")}
          />
          <Input
            label={intl.formatMessage({ id: "general.confirm.password" })}
            placeholder={intl.formatMessage({ id: "general.confirm.password" })}
            type="password"
            required
            {...methods.register("confirmPassword")}
          />

          {error && <ErrorMessage className="-mt-5" error={intl.formatMessage({ id: getSignUpErrorMessage(error) })} />}

          <Button type="submit" className="mx-auto">
            <FormattedMessage id="general.create.account" />
          </Button>
        </Form>
      </div>
    </LoadingWrapper>
  );
};

export default TesteeSignUp;

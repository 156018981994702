import { ComponentProps, FC, HTMLAttributes, JSXElementConstructor } from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import Button from "components/ui/Button/Button";

interface IProps extends HTMLAttributes<HTMLDivElement> {
  titleProps?: ComponentProps<typeof FormattedMessage>;
  titleAs?: JSXElementConstructor<any> | keyof JSX.IntrinsicElements;
  hasShadow?: boolean;
  cta?: {
    textProps: ComponentProps<typeof FormattedMessage>;
    onClick: () => void;
  };
}

const Card: FC<IProps> = ({ className, children, titleProps, titleAs, hasShadow = false, cta, ...rest }) => {
  const TitleComponent = titleAs ?? "h2";

  return (
    <div
      className={classNames(className, "border-secondary-100 border-2 bg-white p-9", {
        "shadow-light-shadow": hasShadow
      })}
      {...rest}
    >
      {(titleProps || cta) && (
        <div className="mb-9 flex min-h-[2.5rem] items-center justify-between gap-2">
          {titleProps && (
            <TitleComponent className="header-800 text-primary-700">
              <FormattedMessage {...titleProps} />
            </TitleComponent>
          )}
          {cta && (
            <Button onClick={cta.onClick} className="px-9">
              <FormattedMessage {...cta.textProps} />
            </Button>
          )}
        </div>
      )}
      {children}
    </div>
  );
};

export default Card;

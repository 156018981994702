import Profile from "components/ui/scenarios/Staff/Profile";
import useBundle from "hooks/useBundle";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useInteraction from "hooks/useInteraction";

type TParams = {
  scenarioId: string;
  staffId: string;
};

export default function StaffDetail() {
  const { staffId } = useParams<TParams>();
  const { data } = useBundle();
  const registerInteraction = useInteraction();

  const StaffDetail = data?.staffProfiles?.find(staff => staff.id === staffId);

  useEffect(() => {
    if (!StaffDetail) return;

    registerInteraction("STAFF_OPEN", {
      payload: StaffDetail
    });
  }, [StaffDetail, registerInteraction]);

  return <>{StaffDetail && <Profile name={StaffDetail.name} imageUrl={StaffDetail.image} />}</>;
}

import { FC, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormattedMessage, useIntl } from "react-intl";
import yup from "config/yup";
import Modal, { ModalButtonContainer } from "components/ui/Modal/Modal";
import Form from "components/ui/Form/Form";
import Input from "components/ui/Form/Input/Input";
import Button from "components/ui/Button/Button";
import LoadingSpinner from "components/ui/Loading/Spinner";
import ErrorMessage from "components/ui/ErrorMessage/ErrorMessage";
import useDeleteTrainee from "../hooks/useDeleteTrainee";
import useFireGAEventOnMount from "hooks/useFireGAEventOnMount";
import { fireGAEvent, GAEventName } from "utils/gtag";

type TDeleteTraineeInputs = {
  confirmation: string;
};

interface IProps {
  traineeId?: string;
  close: () => void;
}

const DeleteTraineeModal: FC<IProps> = ({ traineeId, close }) => {
  // i18n
  const intl = useIntl();

  useFireGAEventOnMount({ name: GAEventName.ScreenView, param: "Implementer - Delete User (Warning)" });

  const { mutateAsync: deleteTraineeMutate, error, isLoading } = useDeleteTrainee();

  const DeleteTraineeSchema = useMemo(
    () =>
      yup
        .object({
          confirmation: yup
            .string()
            .lowercase()
            .trim()
            .equals(
              [intl.formatMessage({ id: "admin.users.modal.deleteTrainee.confirmation.value" })],
              intl.formatMessage({ id: "admin.users.modal.deleteTrainee.confirmation.errorMessage" })
            )
        })
        .required(),
    [intl]
  );

  const methods = useForm<TDeleteTraineeInputs>({
    resolver: yupResolver(DeleteTraineeSchema)
  });

  const closeModal = () => {
    methods.reset();
    close();
  };

  /**
   * Delete a trainee
   * @returns Closes modal on success
   */
  const onSubmit: SubmitHandler<TDeleteTraineeInputs> = async () => {
    fireGAEvent({ name: GAEventName.ButtonDeleteTestee });

    if (!traineeId) return;

    const { id } = await deleteTraineeMutate({
      pathParams: {
        id: traineeId
      }
    });

    // If trainee deleted successfully, close modal
    if (id === traineeId) {
      closeModal();
    }
  };

  return (
    <Modal
      title={intl.formatMessage({ id: "admin.users.modal.deleteTrainee.title" })}
      description={intl.formatMessage({ id: "admin.users.modal.deleteTrainee.description" })}
      open={!!traineeId}
      onClose={closeModal}
    >
      {isLoading ? (
        <LoadingSpinner className="m-auto" />
      ) : (
        <Form className="flex flex-col gap-y-9" methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
          <Input
            placeholder={intl.formatMessage({ id: "admin.users.modal.deleteTrainee.confirmation.placeholder" })}
            required
            {...methods.register("confirmation")}
          />

          <ErrorMessage error={error} />

          <ModalButtonContainer>
            <Button>
              <FormattedMessage id="general.delete" />
            </Button>

            <Button variant="secondary" onClick={closeModal}>
              <FormattedMessage id="general.cancel" />
            </Button>
          </ModalButtonContainer>
        </Form>
      )}
    </Modal>
  );
};

export default DeleteTraineeModal;

import classNames from "classnames";
import { FC, PropsWithChildren } from "react";
import { Link as ReactRouterLink, LinkProps } from "react-router-dom";

export interface IProps extends LinkProps {}

const Link: FC<PropsWithChildren<IProps>> = props => {
  const { className, children, ...rest } = props;

  return (
    <ReactRouterLink className={classNames(className, "body-500")} {...rest}>
      {children}
    </ReactRouterLink>
  );
};

export default Link;

import LinkBtn from "components/ui/LinkBtn/LinkBtn";
import CognitoService from "helpers/cognito.service";
import { FC, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import useFireGAEventOnMount from "hooks/useFireGAEventOnMount";
import { useEffectOnce } from "react-use";
import { GAEventName } from "utils/gtag";
import LoadingWrapper from "components/ui/Loading/LoadingWrapper";
import { useTestControllerCheck } from "generated/api/apiComponents";
import { TestNotFoundException } from "./test-not-found.exception";
import { ModalButtonContainer } from "components/ui/Modal/Modal";

const ScenarioLandingPage: FC = () => {
  const { scenarioId } = useParams<{ scenarioId: string }>();

  // Check if test exists
  const { data, isLoading } = useTestControllerCheck(
    {
      pathParams: {
        id: scenarioId!
      }
    },
    {
      enabled: true
    }
  );

  useEffect(() => {
    if (data?.exists === false) {
      throw new TestNotFoundException();
    }
  }, [data]);

  // Log out user if landed here
  useEffectOnce(() => {
    CognitoService.signOut();
  });

  useFireGAEventOnMount({ name: GAEventName.ScreenView, param: "Testee - Landing Page" });

  return (
    <LoadingWrapper isLoading={isLoading}>
      <div className="m-auto max-w-[560px] text-center">
        <h1 className="header-900 mb-9">
          <FormattedMessage id="trainee.landing.page.title" />
        </h1>
        <h2 className="body-900 mb-8">
          <FormattedMessage id="trainee.landing.page.subTitle.1" />
        </h2>
        <h2 className="body-900 mb-9">
          <FormattedMessage id="trainee.landing.page.subTitle.3" />
        </h2>

        <ModalButtonContainer>
          <LinkBtn to="login">
            <FormattedMessage id="general.login" />
          </LinkBtn>
          <LinkBtn variant="secondary" to="sign-up">
            <FormattedMessage id="general.signup" />
          </LinkBtn>
        </ModalButtonContainer>
      </div>
    </LoadingWrapper>
  );
};

export default ScenarioLandingPage;

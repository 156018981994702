import classNames from "classnames";
import { DetailedHTMLProps, FC, HTMLAttributes, useMemo } from "react";
import { useSpring, animated } from "@react-spring/web";

export interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  colour: string;
  score: number;
  title: string;
}

const MAX_R = 60;
const MIN_R = 0;

const CircleDiagram: FC<{ colour: string; r: number }> = ({ colour, r }) => {
  const props = useSpring({
    from: { r: "0px" },
    to: { r: `${r}px` },
    delay: 300,
    config: { tension: 200, friction: 10 }
  });

  return (
    <svg
      className="h-auto w-full overflow-visible rounded-full border-2 border-transparent bg-white transition-all duration-300 group-hover:border-black"
      width="146"
      height="146"
      viewBox="0 0 146 146"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="73" cy="73" r="73" fill="white" />
      <circle opacity="0.2" cx="73" cy="73" r="67.5" stroke="#686767" />
      <circle r="5" cx="73" cy="73" fill="#686767" opacity="0.2" />
      {/*@ts-ignore*/}
      <animated.circle style={props} cx="73" cy="73" fill={colour} />
    </svg>
  );
};

const WorkStyleCircle: FC<IProps> = props => {
  const { colour, score, title, className, ...rest } = props;

  const r = useMemo(() => {
    if (score === 0) {
      return MIN_R;
    }

    if (score >= 1) {
      return MAX_R;
    }

    const range = MAX_R - MIN_R;

    return range * score + MIN_R;
  }, [score]);

  return (
    <div className={classNames(className, "group relative flex cursor-pointer flex-col pb-8")} {...rest}>
      <CircleDiagram colour={colour} r={r} />

      <h4 className="body-900 group-hover:header-600 my-auto text-center text-sm transition-all duration-300">
        {title}
      </h4>

      {/* The popover arrow */}
      <svg
        className="absolute bottom-[-2px] left-1/2 z-10 -translate-x-1/2 opacity-0 transition-opacity duration-500 group-hover:opacity-100"
        width="33"
        height="28"
        viewBox="0 0 33 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16.7513 0L32.7513 28H16.7513L0.751282 28L16.7513 0Z" fill="white" />
        <path
          d="M2.47446 27L16.7513 2.01557L31.0281 27H16.7513L2.47446 27Z"
          stroke="#9B9B9B"
          strokeOpacity="0.25"
          strokeWidth="2"
        />
        <path d="M16 26H29.3393L30.3831 28H3L4.22401 26H16Z" fill="white" />
      </svg>
    </div>
  );
};

export default WorkStyleCircle;

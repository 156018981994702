import { AccountResponseDto } from "generated/api/apiSchemas";
import { FC, useEffect } from "react";
import { FaCopy } from "react-icons/fa";
import { useIntl } from "react-intl";
import useInteraction from "hooks/useInteraction";
import Button from "../Button/Button";
import { useAppDispatch } from "hooks/useRedux";
import { showNotificationWithTimeout } from "redux/modules/scenario";

interface IProps {
  accountDetail: AccountResponseDto | undefined;
}

const Account: FC<IProps> = ({ accountDetail }) => {
  // Redux
  const dispatch = useAppDispatch();

  // i18n
  const intl = useIntl();

  const registerInteraction = useInteraction();

  /** Copy field to clipboard. */
  const copyText = (text: string) => {
    if (accountDetail)
      registerInteraction("ACCOUNT_DETAILS_COPIED", {
        payload: {
          ...accountDetail,
          text
        }
      });

    navigator.clipboard.writeText(text);
    dispatch(
      showNotificationWithTimeout({
        name: `general.copied`,
        text: `general.copiedToClip`
      })
    );
  };

  useEffect(() => {
    if (!accountDetail) return;
    registerInteraction("ACCOUNT_OPEN", {
      payload: accountDetail
    });
  }, [accountDetail, registerInteraction]);

  if (!accountDetail) return null;

  const { name, content } = accountDetail;

  return (
    <div className="flex flex-col px-8">
      <h2 className="py-8  text-4xl font-light text-[rgb(51,_51,_51)]">{name}</h2>
      {content &&
        Object.entries(content).map(([key, value]) => {
          return (
            <div className="flex w-full flex-col py-4" key={key}>
              <div className="flex items-center justify-between border-b-[1px] border-[#dfe3e6] pb-4">
                <span className="w-1/3 font-normal capitalize text-black">{key}</span>
                <span className="w-1/3 font-normal text-black">{value}</span>
                <Button
                  variant="icon"
                  onClick={() => copyText(value)}
                  aria-label={intl.formatMessage({ id: "general.copy" })}
                  className="border-2 px-3 py-2 hover:bg-primary-500 hover:text-white"
                >
                  <FaCopy />
                </Button>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Account;

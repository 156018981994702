import { TButtonVariants } from "components/ui/Button/Button";
import { FC, PropsWithChildren } from "react";
import classNames from "classnames";
import { Link, LinkProps } from "react-router-dom";

export interface IProps extends LinkProps {
  variant?: TButtonVariants;
}

const Button: FC<PropsWithChildren<IProps>> = props => {
  const { variant = "primary", className, children, ...rest } = props;

  return (
    <Link
      className={classNames(className, "c-button group/c-button", {
        "c-button--primary": variant === "primary",
        "c-button--primary-outline": variant === "primary-outline",
        "c-button--secondary": variant === "secondary"
      })}
      {...rest}
    >
      {children}
    </Link>
  );
};

export default Button;

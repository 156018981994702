import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import configureAmplify from "config/amplify";
import translations from "locales/en.json";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { IntlProvider } from "react-intl";
import { Provider as ReduxProvider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import "./index.css";
import router from "./pages/routes";
import { store } from "./redux/store";
import ToastProvider from "context/toast.provider";
import Toast from "components/ui/Toast/Toast";

configureAmplify();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      useErrorBoundary: true
    }
  }
});

createRoot(document.getElementById("app")!).render(
  <StrictMode>
    <IntlProvider messages={translations} locale="en" defaultLocale="en">
      <ReduxProvider store={store}>
        <QueryClientProvider client={queryClient}>
          <ToastProvider>
            <RouterProvider router={router} />
            <Toast />
          </ToastProvider>
        </QueryClientProvider>
      </ReduxProvider>
    </IntlProvider>
  </StrictMode>
);

const EyeWhite = () => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.202637 4.99992C0.202637 4.99992 2.12024 2.30762 4.85899 2.30762C7.62548 2.30762 9.51534 4.99992 9.51534 4.99992C9.51534 4.99992 8.1457 7.69223 4.85899 7.69223C1.59949 7.69223 0.202637 4.99992 0.202637 4.99992ZM4.8592 3.28689C3.79102 3.28689 2.94213 4.04543 2.94213 4.99992C2.94213 5.95441 3.79102 6.71295 4.8592 6.71295C5.92738 6.71295 6.77627 5.95441 6.77627 4.99992C6.77627 4.04543 5.92738 3.28689 4.8592 3.28689Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.85879 5.73407C4.40481 5.73407 4.03711 5.4055 4.03711 4.99985C4.03711 4.59419 4.40481 4.26562 4.85879 4.26562C5.31276 4.26562 5.68046 4.59419 5.68046 4.99985C5.68046 5.4055 5.31276 5.73407 4.85879 5.73407Z"
        fill="white"
      />
    </svg>
  );
};

export default EyeWhite;

import AccountDetail from "components/ui/scenarios/Accounts/Account";
import useBundle from "hooks/useBundle";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

export default function ScenarioAccountDetail() {
  const { accountId } = useParams();
  const { data } = useBundle();

  const accountDetail = useMemo(
    () =>
      data?.accounts.find(({ id }) => {
        return id === accountId;
      }),
    [data?.accounts, accountId]
  );

  return (
    <div className="flex h-full w-full flex-col p-4">
      <AccountDetail accountDetail={accountDetail} />
    </div>
  );
}

import classNames from "classnames";
import EmailIcon from "components/ui/icons/Email";
import EmailGreen from "components/ui/icons/EmailGreen";
import EmailOrange from "components/ui/icons/EmailOrange";
import EmailRedIcon from "components/ui/icons/EmailRed";
import { FC, PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

export interface IProps {
  stage?: "scores" | "emails-good" | "emails-okay" | "emails-bad";
  maxWidthClassName?: string;
}

const ScenarioResultsLayout: FC<PropsWithChildren<IProps>> = props => {
  const { stage = "scores", maxWidthClassName = "max-w-[826px]", children } = props;

  return (
    <div className="flex min-h-screen flex-col items-center justify-center">
      <main
        className={classNames(
          "my-8 w-full",
          maxWidthClassName,
          stage === "emails-good" && "border-t-[3rem] border-t-[#1E72004D]",
          stage === "emails-okay" && "border-t-[3rem] border-t-[#FFAA054D]",
          stage === "emails-bad" && "border-t-[3rem] border-t-error-400"
        )}
      >
        {stage === "scores" && <div className="c-results-results-top-border h-12" />}

        <div className="flex min-h-[420px] flex-col border-2 border-t-0 border-[#9B9B9B40] bg-white py-9">
          {/*Email Icon*/}
          <div className="mb-7 mt-[-4.125rem] flex h-15 w-15 items-center justify-center self-center rounded-full border border-neutral-400 bg-white">
            {stage === "scores" && <EmailIcon />}
            {stage === "emails-good" && <EmailGreen />}
            {stage === "emails-okay" && <EmailOrange />}
            {stage === "emails-bad" && <EmailRedIcon />}
          </div>

          {/*Content*/}
          {children ?? <Outlet />}
        </div>
      </main>
    </div>
  );
};

export default ScenarioResultsLayout;

import Button from "components/ui/Button/Button";
import EmailHeader from "components/ui/scenarios/Email/Header";
import { GET_FINAL_EMAIL_ID } from "constants/scenario";
import { useAppDispatch } from "hooks/useRedux";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { stopClock } from "redux/modules/clockTick";
import { FC } from "react";

const GetFinalEmailDetail: FC = props => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <div className="flex flex-col items-start gap-9">
      <EmailHeader
        emailId={GET_FINAL_EMAIL_ID}
        from={{ name: intl.formatMessage({ id: "scenarios.administrator" }) }}
        date={new Date()}
        className="w-full"
      />
      <h1 className="header-900">
        <FormattedMessage id="scenarios.inbox.final.email.subject" />
      </h1>
      <h2 className="header-500">
        <FormattedMessage id="scenarios.inbox.final.email.subject.2" />
      </h2>
      <div className="header-400 flex flex-col gap-y-5 font-normal">
        <FormattedMessage id="scenarios.inbox.final.email.body" />
      </div>
      <div>
        <Button
          className="mx-auto px-9"
          onClick={() => {
            dispatch(stopClock());
            navigate("../../results");
          }}
        >
          <FormattedMessage id="scenarios.inbox.final.finish" />
        </Button>
      </div>
    </div>
  );
};

export default GetFinalEmailDetail;

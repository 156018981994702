import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { FC, HTMLAttributes } from "react";

import { ToastType, useToastContext } from "context/toast.provider";

interface IProps extends HTMLAttributes<HTMLDivElement> {}

const Toast: FC<IProps> = ({ ...rest }) => {
  const { details } = useToastContext();

  return (
    <Transition
      show={details.open}
      enter="transition-all ease-in duration-500 transform"
      enterFrom="translate-y-full opacity-0"
      enterTo="-translate-y-0 opacity-100"
      leave="transition-all ease-in duration-500 transform"
      leaveFrom="-translate-y-0"
      leaveTo="translate-y-full opacity-0"
      className={classNames(details.className, "fixed right-9 bottom-[18px] z-20 px-0")}
    >
      <div
        role="alert"
        className={classNames(
          details.type === ToastType.SUCCESS && "bg-secondary-500",
          details.type === ToastType.ERROR && "bg-red-500",
          "button-1000 w-fit rounded p-4 py-[0.56rem] px-[2.25rem] font-bold text-white"
        )}
      >
        <p className="relative">{details.text}</p>
      </div>
    </Transition>
  );
};

export default Toast;

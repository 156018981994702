import { useScenarioControllerScenarioPhishingEmails } from "generated/api/apiComponents";
import { FC, PropsWithChildren, useMemo } from "react";
import HallMark from "./Hallmark";
import { Scenario } from "generated/api/apiSchemas";

interface IProps {
  hallmarkId: string;
  scenario?: Scenario;
  onClick?: (hallmarkId: string) => void;
}

const ImplementerHallmark: FC<PropsWithChildren<IProps>> = ({ children, hallmarkId, scenario, onClick }) => {
  const { data } = useScenarioControllerScenarioPhishingEmails(
    {
      pathParams: { scenario: scenario ?? "" }
    },
    { enabled: !!scenario }
  );

  const hallmark = useMemo(
    () => data?.hallmarks.find(({ id }: { id: string }) => id === hallmarkId),
    [data?.hallmarks, hallmarkId]
  );

  return (
    <HallMark hallmark={hallmark} onClick={onClick}>
      {children}
    </HallMark>
  );
};

export default ImplementerHallmark;

import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Modal, { ModalButtonContainer } from "components/ui/Modal/Modal";
import Button from "components/ui/Button/Button";
import LoadingSpinner from "components/ui/Loading/Spinner";
import ErrorMessage from "components/ui/ErrorMessage/ErrorMessage";
import { useTraineeControllerResetPassword } from "generated/api/apiComponents";
import TemporaryPassword from "./TemporaryPassword";
import useFireGAEventOnMount from "hooks/useFireGAEventOnMount";
import { GAEventName } from "utils/gtag";

interface IProps {
  traineeId?: string;
  close: () => void;
}

const ResetTraineePasswordModal: FC<IProps> = ({ traineeId, close }) => {
  const [password, setPassword] = useState<string>();

  // i18n
  const intl = useIntl();

  // Mutation
  const { mutateAsync: resetTraineePasswordMutate, error, isLoading } = useTraineeControllerResetPassword();

  useFireGAEventOnMount({ name: GAEventName.ScreenView, param: "Implementer - Reset User Password" });

  /**
   * Reset a trainee's password
   * @returns Closes modal on success
   */
  const onSubmit = async () => {
    if (!traineeId) return;

    const { temporaryPassword } = await resetTraineePasswordMutate({
      pathParams: {
        id: traineeId
      }
    });

    setPassword(temporaryPassword);
  };

  return (
    <Modal
      title={intl.formatMessage({ id: "admin.users.modal.resetTraineePassword.title" })}
      description={intl.formatMessage({ id: "admin.users.modal.resetTraineePassword.description" })}
      open={!!traineeId}
      onClose={close}
    >
      {isLoading ? (
        <LoadingSpinner className="m-auto" />
      ) : (
        <div className="flex flex-col gap-y-9">
          <ErrorMessage error={error} />

          <ModalButtonContainer>
            {password ? (
              <TemporaryPassword password={password} />
            ) : (
              <Button onClick={onSubmit}>
                <FormattedMessage id="admin.users.modal.resetTraineePassword.reset" />
              </Button>
            )}

            <Button variant="secondary" onClick={close}>
              <FormattedMessage id="general.cancel" />
            </Button>
          </ModalButtonContainer>
        </div>
      )}
    </Modal>
  );
};

export default ResetTraineePasswordModal;

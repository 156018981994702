import { TourStep } from "components/ui/Tour/index";
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useId } from "react";

export type TTourContext = {
  isOpen: boolean;
  steps: TourStep[];
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onTourFinishListeners: Record<string, () => void>;
};

const TourContext = createContext<TTourContext>({
  isOpen: false,
  steps: [],
  setIsOpen: () => {},
  onTourFinishListeners: {}
});

export const useTourContext = ({ onTourFinish }: { onTourFinish?: () => void } = {}) => {
  const { onTourFinishListeners, ...rest } = useContext(TourContext);

  const listenerId = useId();

  useEffect(() => {
    if (!onTourFinish) return;

    onTourFinishListeners[listenerId] = onTourFinish;

    return () => {
      delete onTourFinishListeners[listenerId];
    };
    // eslint-disable-next-line
  }, []);

  return { ...rest };
};

export default TourContext;

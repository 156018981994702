import { Player as LottiePlayer } from "@lottiefiles/react-lottie-player";
import CertificateJSON from "assets/lottie/certificate.json";
import LinkBtn from "components/ui/LinkBtn/LinkBtn";
import useGetSessionResults from "hooks/useGetSessionResults";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import useFireGAEventOnMount from "hooks/useFireGAEventOnMount";
import { fireGAEvent, GAEventName } from "utils/gtag";

export interface IProps {}

const ResultsCongratulationsCertificate: FC<IProps> = props => {
  // This will save the results in the backend
  useGetSessionResults();

  useFireGAEventOnMount({ name: GAEventName.ScreenView, param: "Testee - Test Congratulations" });
  useFireGAEventOnMount({ name: GAEventName.TestFinish });

  const handleFindOutClick = () => {
    fireGAEvent({ name: GAEventName.ButtonFindOutMore });
  };

  return (
    <div className="text-center">
      <h1 className="header-900 mb-6">
        <FormattedMessage id="scenarios.results.congratulations.title" />
      </h1>

      <div className="mx-auto mb-10 h-[150px] w-[180px]">
        <LottiePlayer autoplay keepLastFrame src={CertificateJSON} />
      </div>

      <p className="body-900 mb-15">
        <FormattedMessage
          id="scenarios.results.congratulations.desc"
          values={{
            br: () => <br />
          }}
        />
      </p>

      <div className="flex justify-center">
        <LinkBtn to="summary" onClick={handleFindOutClick}>
          <FormattedMessage id="scenarios.results.congratulations.find.out.more" />
        </LinkBtn>
      </div>
    </div>
  );
};

export default ResultsCongratulationsCertificate;

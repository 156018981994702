import { Disclosure } from "@headlessui/react";
import classNames from "classnames";
import Button from "components/ui/Button/Button";
import BinWhite from "components/ui/icons/BinWhiteRound";
import Chevron from "components/ui/icons/Chevron";
import EditWhite from "components/ui/icons/EditWhite";
import EyeWhite from "components/ui/icons/EyeWhite";
import ReportWhite from "components/ui/icons/ReportWhiteRound";
import { InteractionResponseDto, InteractionType } from "generated/api/apiSchemas";
import useBundle from "hooks/useBundle";
import { createElement, FC, Fragment } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";

export interface IProps {
  interactions?: InteractionResponseDto[];
}

const interactionTypeMap: Record<InteractionType, { icon: FC; label: string }> = {
  EMAIL_REPLY: {
    icon: EditWhite,
    label: "scenarios.results.phishing.emails.timeline.replied"
  },
  EMAIL_ACTION_REPLY: {
    icon: EditWhite,
    label: "scenarios.results.phishing.emails.timeline.action.replied"
  },
  EMAIL_ACTION_DELETE: {
    icon: BinWhite,
    label: "scenarios.results.phishing.emails.timeline.action.deleted"
  },
  EMAIL_ACTION_REPORT: {
    icon: ReportWhite,
    label: "scenarios.results.phishing.emails.timeline.action.reported"
  },
  CLICK: {
    icon: EyeWhite,
    label: "scenarios.results.phishing.emails.timeline.click"
  },
  MOUSE_MOVEMENT: {
    icon: EyeWhite,
    label: "scenarios.results.phishing.emails.timeline.mouse.move"
  },
  NAVIGATE: {
    icon: EyeWhite,
    label: "scenarios.results.phishing.emails.timeline.navigate"
  },
  ACCOUNT_DETAILS_COPIED: {
    icon: EyeWhite,
    label: "scenarios.results.phishing.emails.timeline.account.details.copied"
  },
  ACCOUNT_OPEN: {
    icon: EyeWhite,
    label: "scenarios.results.phishing.emails.timeline.account.open"
  },
  FILE_OPEN: {
    icon: EyeWhite,
    label: "scenarios.results.phishing.emails.timeline.file.open"
  },
  FILE_DELETE: {
    icon: EyeWhite,
    label: "scenarios.results.phishing.emails.timeline.file.delete"
  },
  WEB_OPEN: {
    icon: EyeWhite,
    label: "scenarios.results.phishing.emails.timeline.web.open"
  },
  STAFF_OPEN: {
    icon: EyeWhite,
    label: "scenarios.results.phishing.emails.timeline.staff.open"
  },
  EMAIL_OPEN: {
    icon: EyeWhite,
    label: "scenarios.results.phishing.emails.timeline.opened"
  },
  EMAIL_SENDER_OPEN: {
    icon: EyeWhite,
    label: "scenarios.results.phishing.emails.timeline.sender.open"
  }
};

const ResultsEmailTimeline: FC<IProps> = props => {
  const { data } = useBundle();
  const { interactions } = props;

  return (
    <Disclosure>
      <Disclosure.Button as={Button} className="mb-7 inline-flex items-center justify-center px-3" variant="primary">
        {({ open }) => (
          <>
            <FormattedMessage id="scenarios.results.phishing.emails.see.timeline" />

            <Chevron className="ml-1.5 group-hover/c-button:fill-white" direction={open ? "up" : "down"} />
          </>
        )}
      </Disclosure.Button>

      <Disclosure.Panel className="bg-primary-300 p-3">
        <div className="ml-2.5 flex flex-col gap-y-3.5 border-l-2 border-primary-500">
          {interactions?.map(interaction => (
            <div key={interaction.id} className="flex items-center">
              <div className="-ml-2.5 mr-3 flex h-4.5 min-w-4.5 items-center justify-center rounded-full border border-white bg-primary-500">
                {createElement(interactionTypeMap[interaction.type].icon)}
              </div>

              <div
                className={classNames(interaction.type === "EMAIL_REPLY" ? "body-700" : "body-600", "leading-[14px]")}
              >
                <span>
                  <FormattedDate
                    value={new Date(interaction.created)}
                    hour="numeric"
                    minute="2-digit"
                    second="2-digit"
                    hour12
                  />
                </span>
                {" - "}

                {interaction.type === "EMAIL_REPLY" ? (
                  <>
                    <FormattedMessage id={interactionTypeMap[interaction.type].label} /> {" - "}
                    {data?.emails?.map(e => {
                      return (
                        e.id === interaction.email &&
                        e.actions.map(a => a.id === interaction.action && <Fragment key={a.id}>"{a.value}"</Fragment>)
                      );
                    })}
                  </>
                ) : (
                  <span>
                    <FormattedMessage id={interactionTypeMap[interaction.type].label} />
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      </Disclosure.Panel>
    </Disclosure>
  );
};

export default ResultsEmailTimeline;

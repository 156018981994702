import { useSessionControllerSession } from "generated/api/apiComponents";
import useScenarioSessionId from "hooks/useScenarioSessionId";

const useGetSessionResults = () => {
  const sessionId = useScenarioSessionId();

  return useSessionControllerSession(
    {
      pathParams: {
        id: sessionId!
      }
    },
    {
      enabled: !!sessionId,
      staleTime: Infinity,
      cacheTime: 1000 * 60 * 20 // 20 minutes
    }
  );
};

export default useGetSessionResults;

import classNames from "classnames";
import Button from "components/ui/Button/Button";
import { GET_STARTED_TOUR_STYLES } from "constants/scenario";
import TourContext from "components/ui/Tour/Context";
import { FC, PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { FormattedMessage } from "react-intl";
import { usePopper } from "react-popper";
import { Mask } from "@reactour/mask";

/**
 * This is a combination of @reactour/mask and Popper.js
 *
 * - @reactour/mask provides mask over the window which creates a cut through for the
 * - current element being shown in the Tour.
 *
 * - Popper.js provides the popover with information about the current element
 */

export type TourStep = {
  /**
   * CSS selector use to position of the Tour Popover
   */
  selector: string;
  /**
   * Translations string for the title of the Tour Popover
   */
  title: string;
  /**
   * Translation string for the description of the Tour Popover
   */
  desc: string;
  /**
   * Extra ClassNames for the Tour Popover
   */
  className?: string;
  /**
   * Padding around selected element
   * @default 0
   */
  mask?: number | [number, number];
  /**
   * Placement of Tour Popover around selected element
   * @default right
   */
  placement?: "top" | "left" | "right" | "bottom";
  /**
   * Offset of Tour Popover
   * @default [0, 43] - width of popover arrow
   */
  offset?: [number, number];
};

export interface IProps {
  isOpen?: boolean;
  steps: TourStep[];
  onChange?: (isOpen: boolean) => void;
}

const TourProvider: FC<PropsWithChildren<IProps>> = props => {
  const { children, onChange } = props;
  const [isOpen, setIsOpen] = useState(props.isOpen ?? false);

  /**
   * Fire the onChange listener whenever the Tour is opened or closed
   */
  useEffect(() => {
    onChange?.(isOpen);
    // eslint-disable-next-line
  }, [isOpen]);

  const onTourFinishListeners = useRef<Record<string, () => void>>({});

  const value = useMemo(
    () => ({ isOpen, setIsOpen, steps: props.steps, onTourFinishListeners: onTourFinishListeners.current }),
    [isOpen, props.steps]
  );

  return (
    <TourContext.Provider value={value}>
      {children}
      <Tourer />
    </TourContext.Provider>
  );
};

const Tourer: FC = props => {
  const { isOpen, steps, setIsOpen, onTourFinishListeners } = useContext(TourContext);

  // States needed for Popper.js
  const [referenceElement, setReferenceElement] = useState(document.querySelector(steps[0].selector));
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [currentStep, setCurrentStep] = useState(0);

  /**
   * Each time our state is opened or closed
   * Reset Tour to the start
   */
  useEffect(() => {
    setCurrentStep(0);
  }, [isOpen]);

  // Popper.js
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: steps[currentStep].placement ?? "right",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: steps[currentStep].offset ?? [0, 44]
        }
      }
    ]
  });

  /**
   * Whenever the current step of the Tour changes
   * Query the new element in the Tour
   * Scroll the new element into view
   */
  useEffect(() => {
    const currentStepReference = document.querySelector(steps[currentStep].selector);

    setReferenceElement(currentStepReference);

    currentStepReference?.scrollIntoView({
      behavior: "smooth"
    });
    // eslint-disable-next-line
  }, [currentStep]);

  /**
   * Which classes should be applied to the arrow on the Tour Popover
   * Depending on the position of the Tour Popover
   */
  const arrowClasses = useMemo(() => {
    switch (steps[currentStep].placement) {
      case "top":
        return "left-1/2 bottom-[4px] -translate-x-1/2 translate-y-full -rotate-90";
      case "bottom":
        return "left-1/2 top-[4px] -translate-x-1/2 -translate-y-full rotate-90";
      case "left":
        return "right-0 top-1/2 translate-x-full -translate-y-1/2 rotate-180";
      default:
        return "left-0 top-1/2 -translate-x-full -translate-y-1/2";
    }
    // eslint-disable-next-line
  }, [currentStep]);

  /**
   * Handle the Tour going backwards
   */
  const handleBack = () => {
    setCurrentStep(s => s - 1);
  };

  /**
   * Handle the Tour going forward
   * If this is the last step, end the Tour
   */
  const handleNext = () => {
    const isLast = currentStep === steps.length - 1;

    if (isLast) {
      setIsOpen(false);
      setCurrentStep(0);

      for (const onTourFinishListener of Object.values(onTourFinishListeners)) {
        onTourFinishListener();
      }
    } else {
      setCurrentStep(s => s + 1);
    }
  };

  const isFirstStep = currentStep === 0;

  if (!isOpen) return null;

  /**
   * Creates a Popper.js popover for the current Tour Step element
   * Create Mask for the current Tour Step element
   */
  return ReactDOM.createPortal(
    <>
      <div
        ref={setPopperElement}
        className={classNames(
          steps[currentStep].className,
          "z-[100000] flex flex-col rounded-[1.25rem] border-2 border-[#9B9B9B40] bg-white p-4"
        )}
        style={styles.popper}
        {...attributes.popper}
      >
        <h1 className="header-600 mb-4 leading-[22px]">
          <FormattedMessage id={steps[currentStep].title} />
        </h1>
        <p className="header-400 mb-4 leading-[22px]">
          <FormattedMessage id={steps[currentStep].desc} />
        </p>

        <div className="flex justify-between">
          {!isFirstStep && (
            <Button variant="secondary" onClick={handleBack}>
              <FormattedMessage id="scenarios.tour.back" />
            </Button>
          )}
          <Button className="ml-auto" onClick={handleNext}>
            <FormattedMessage id="scenarios.tour.next" />
          </Button>
        </div>

        {/* The popover arrow */}
        <svg
          className={classNames("absolute", arrowClasses)}
          width="42"
          height="49"
          viewBox="0 0 42 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M2.75475e-06 24.2487L42 1.34229e-05L42 48.4974L2.75475e-06 24.2487Z" fill="white" />
          <path
            d="M41 46.7654L2 24.2487L41 1.73206L41 46.7654Z"
            stroke="#9B9B9B"
            strokeOpacity="0.25"
            strokeWidth="2"
          />
          <path d="M40 24.045L40 3.48438L42 2.44001L42 46.1L40 45.054L40 24.045Z" fill="white" />
        </svg>
      </div>

      {/* https://github.com/elrumordelaluz/reactour/tree/main/packages/mask */}
      <Mask
        styles={GET_STARTED_TOUR_STYLES}
        padding={steps[currentStep].mask}
        sizes={
          referenceElement?.getBoundingClientRect() ?? {
            bottom: 0,
            height: 0,
            left: 0,
            right: 0,
            top: 0,
            width: 0,
            x: 0,
            y: 0
          }
        }
      />
    </>,
    document.querySelector("body")!
  );
};

export default TourProvider;

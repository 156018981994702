import Button from "components/ui/Button/Button";
import { DetailedHTMLProps, FC, HTMLAttributes } from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import FlagIcon from "assets/icons/flag-with-circle.svg";
import DeleteIcon from "assets/icons/red-delete-with-circle.svg";
import ReplyIcon from "assets/icons/reply-with-circle.svg";

export interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  onReply?: () => void;
  onDelete?: () => void;
  onReport?: () => void;
}

const EmailActions: FC<IProps> = props => {
  const { className, onReply, onDelete, onReport, ...rest } = props;

  return (
    <div className={classNames(className, "flex items-center gap-3")} {...rest}>
      <Button onClick={onReply} iconSrc={ReplyIcon}>
        <FormattedMessage id="scenarios.inbox.reply.action.reply" />
      </Button>
      <Button onClick={onDelete} iconSrc={DeleteIcon}>
        <FormattedMessage id="scenarios.inbox.reply.action.delete" />
      </Button>
      <Button onClick={onReport} iconSrc={FlagIcon}>
        <FormattedMessage id="scenarios.inbox.reply.action.report" />
      </Button>
    </div>
  );
};

export default EmailActions;

import CognitoService from "helpers/cognito.service";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { FC, useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { selectAuthGroups, selectAuthToken, setGroups, setToken } from "redux/modules/auth";

export interface IProps {}

const ImplementerTesteeAuth: FC<IProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authToken = useAppSelector(selectAuthToken);
  const userGroups = useAppSelector(selectAuthGroups);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fn = async () => {
      const token = await CognitoService.getToken();
      const user = await CognitoService.getCurrentUser();

      if (token) {
        dispatch(setToken(token));
      }

      if (user) {
        const groups = user.signInUserSession?.accessToken?.payload["cognito:groups"];
        dispatch(setGroups(groups));
      }

      setLoading(false);
    };

    fn();
  }, [dispatch, navigate]);

  // Don't render anything to the user while AWS Cognito is authenticating the user
  if (loading) {
    return null;
  }

  // If user is not logged in, or does not have implementer or admin permissions,
  // redirect to admin login
  if (!loading && (!authToken || (!userGroups.includes("implementers") && !userGroups.includes("admins")))) {
    return <Navigate to="/admin/login" replace />;
  }

  return <Outlet />;
};

export default ImplementerTesteeAuth;

import { Dialog } from "@headlessui/react";
import Button from "components/ui/Button/Button";
import Modal from "components/ui/Modal/Modal";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";

interface IProps {
  showModal: boolean;
  setShowModal: (value: React.SetStateAction<boolean>) => void;
}

const WorkstyleExplainerModal: FC<IProps> = props => {
  const intl = useIntl();
  return (
    <Modal
      title={intl.formatMessage({ id: "admin.dashboard.explainerModal.workstyle.title" })}
      description={intl.formatMessage({ id: "admin.dashboard.explainerModal.workstyle.description" })}
      open={props.showModal}
      onClose={() => props.setShowModal(false)}
      wide
    >
      <div className="w-full border-t-[0.5px] border-t-neutral-500 pt-12">
        <Dialog.Title className="text-3xl font-bold leading-9">
          <FormattedMessage id="admin.dashboard.explainerModal.phishing.title" />
        </Dialog.Title>
        <Dialog.Description className="my-8 text-base">
          <FormattedMessage id="admin.dashboard.explainerModal.phishing.description" />
        </Dialog.Description>
        <Button variant="primary" onClick={() => props.setShowModal(false)} className="mx-auto px-9">
          <FormattedMessage id="general.gotIt" />
        </Button>
      </div>
    </Modal>
  );
};

export default WorkstyleExplainerModal;

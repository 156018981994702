import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { EmailResponseDto, HallmarkResponseDto, Scenario } from "generated/api/apiSchemas";
import ResultsEmailPreview from "components/ui/admin/PhishingEmail/EmailPreview";
import { FormattedMessage } from "react-intl";

export interface IHallmarkTaskProps {
  className?: string;
  hallmarks: HallmarkResponseDto[];
  email: EmailResponseDto;
  onFoundAll?: () => void;
  scenario: Scenario;
}

const HallmarkTask: React.FC<IHallmarkTaskProps> = ({ className, hallmarks, email, onFoundAll, scenario }) => {
  const [clickedHallmarks, setClickedHallmarks] = useState<string[]>([]);

  // Find the hallmarks from the email
  const foundHallmarks = useMemo(
    () =>
      hallmarks.filter(
        hallmark =>
          email.body.includes(hallmark.id) ||
          email.subject.includes(hallmark.id) ||
          email.sender.email.includes(hallmark.id) ||
          email.cc?.email.includes(hallmark.id)
      ),
    [email.body, email.cc?.email, email.sender.email, email.subject, hallmarks]
  );

  const onHallmarkClick = (hallmarkId: string) => {
    if (clickedHallmarks.includes(hallmarkId)) return;

    setClickedHallmarks(prev => [...prev, hallmarkId]);

    if (clickedHallmarks.length === foundHallmarks.length) {
      onFoundAll?.();
    }
  };

  return (
    <div className={className}>
      <ResultsEmailPreview
        body={email.body}
        subject={email.subject}
        sender={email.sender}
        cc={email.cc}
        onHallmarkClick={onHallmarkClick}
        className="bg-white"
        scenario={scenario}
      />
      <div className="mt-9 border border-primary-500 bg-white p-7">
        <h3 className="header-500 mb-6">
          <FormattedMessage id="admin.dashboard.hallmarkTask.found" defaultMessage="Hallmarks Found" />
        </h3>
        <ol className="flex flex-wrap gap-6">
          {foundHallmarks.map((hallmark, i) => {
            const isFound = clickedHallmarks.includes(hallmark.id);
            return (
              <li
                key={hallmark.id}
                className={classNames(
                  { "border-neutral-100 bg-white": !isFound },
                  { "border-primary-400 bg-primary-400 line-through": isFound },
                  "body-600 flex items-center gap-3 rounded-[18px] border-2  px-3 py-2.5 !font-medium !leading-[14px] text-neutral-600"
                )}
              >
                {isFound ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                    <circle cx="9" cy="9.5" r="8.5" fill="#076EB2" stroke="#076EB2" />
                    <path d="M4 9.3125L7.1875 12.5L13.1875 6.5" stroke="white" strokeWidth="1.6875" />
                  </svg>
                ) : (
                  <span className="aspect-square h-4.5 w-4.5 rounded-full border border-primary-500"></span>
                )}
                <span>
                  {i + 1}. {hallmark.title}
                </span>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
};

export default HallmarkTask;

import Button from "components/ui/Button/Button";
import { useAppDispatch } from "hooks/useRedux";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { setCompletedAction } from "redux/modules/scenario";

const BookingForm = ({ setSubmitted }: { setSubmitted: Function }) => {
  const [startDate, setStartDate] = useState(new Date());
  const peopleQuantity = ["0-10", "10-20", "20-30", "30-40", "40+"];
  const times = ["9am", "10am", "11am", "12pm", "1om", "2pm", "3pm", "4pm", "5pm"];

  const { webId } = useParams<{ webId: string }>();

  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    if (webId) {
      dispatch(setCompletedAction(webId));
    }
    setSubmitted(true);
  };

  return (
    <form action="" className="flex grow flex-col items-start space-y-2 pl-4 font-normal" onSubmit={() => null}>
      <div className="flex items-center space-x-2">
        <label htmlFor="name">Name:</label>
        <input
          className="rounded-md border-[1px] border-neutral-400 px-2 shadow-md"
          id="name"
          name="name"
          type="text"
        />
      </div>
      <div className="flex items-center space-x-2">
        <label htmlFor="company">Company Name:</label>
        <input
          className="rounded-md border-[1px] border-neutral-400 px-2 shadow-md"
          id="company"
          name="company"
          type="text"
        />
      </div>
      <div className="flex items-center space-x-2">
        <label htmlFor="date">Date range:</label>
        <DatePicker
          className="rounded-md border-[1px] border-neutral-400 px-2 shadow-md"
          selected={startDate}
          onChange={(date: Date) => setStartDate(date)}
        />
      </div>
      <div className="flex items-center space-x-2">
        <label htmlFor="people">Number of people:</label>
        <select className="appearance-none rounded-md border-[1px] border-neutral-400 px-2 shadow-md">
          {peopleQuantity.map((quantity, i) => (
            <option key={`people-${i}`} value={quantity}>
              {quantity}
            </option>
          ))}
        </select>
      </div>
      <div className="flex items-center space-x-2 pb-[36px]">
        <label htmlFor="date">Time slot:</label>
        {/* select input with options for times 9-5 */}
        <select
          className="appearance-none rounded-md border-[1px] border-neutral-400 px-2 shadow-md"
          id="time"
          name="time"
        >
          {times.map((time, i) => (
            <option key={`times-${1}`} value={time}>
              {time}
            </option>
          ))}
        </select>
      </div>
      <Button onClick={handleSubmit} className="rounded-md border-0 bg-[#4A5567] px-8 py-2 text-white" type="button">
        <FormattedMessage id="scenarios.web.booking.submit" />
      </Button>
    </form>
  );
};

export default BookingForm;

import finalEmailReducer, { FinalEmail } from "state-management/reducers/finalEmailReducer";
import CognitoService from "helpers/cognito.service";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { FC, useEffect, useMemo, useReducer } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { selectAuthToken, setToken, setUser } from "redux/modules/auth";
import FinalEmailContext from "state-management/contexts/finalEmailContext";

export interface IProps {}

export type TAuthLoaderData = {
  isAuthenticated: boolean;
};

const ScenarioTesteeAuth: FC<IProps> = props => {
  const { scenarioId } = useParams<{ scenarioId: string }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authToken = useAppSelector(selectAuthToken);

  const defaultFinalEmail: FinalEmail = {
    finalEmailId: ""
  };

  const [finalEmail, emailDispatch] = useReducer(finalEmailReducer, defaultFinalEmail);

  useEffect(() => {
    const fn = async () => {
      const token = await CognitoService.getToken();
      const user = await CognitoService.getCurrentUser();

      if (!token) {
        return navigate(`/scenario/${scenarioId}`, { replace: true });
      }

      dispatch(setToken(token));
      dispatch(setUser(user.username));
    };

    fn();
  }, [dispatch, navigate, scenarioId]);

  const value = useMemo(() => ({ finalEmail, emailDispatch }), [finalEmail]);

  if (!authToken) {
    return null;
  }

  return (
    <FinalEmailContext.Provider value={value}>
      <Outlet />
    </FinalEmailContext.Provider>
  );
};

export default ScenarioTesteeAuth;

import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

const cases = [
  { case: "/inbox", intlKey: "scenarios.inbox" },
  { case: "/staff", intlKey: "scenarios.profiles" },
  { case: "/accounts", intlKey: "scenarios.accounts" },
  { case: "/web", intlKey: "scenarios.web" },
  { case: "/files", intlKey: "scenarios.files" }
];

const useScenarioTitle = () => {
  const location = useLocation();
  const intl = useIntl();
  const foundCase = useMemo(() => cases.find(item => location.pathname.includes(item.case)), [location.pathname]);

  if (foundCase) {
    return intl.formatMessage({ id: foundCase.intlKey });
  }

  return intl.formatMessage({ id: "scenarios.inbox" });
};

export default useScenarioTitle;

export const barStyle = {
  barThickness: 30,
  borderColor: "#000000",
  padding: 10,
  borderWidth: { top: 1, right: 1, bottom: 1, left: 1.5 },
  borderSkipped: false
};

export const blackFont = {
  color: "black",
  font: {
    size: 20,
    weight: "700",
    family: "Helvetica Neue"
  }
};

export const linkFont = {
  color: "#076EB2",
  font: {
    size: 20,
    weight: "700",
    family: "Helvetica Neue"
  }
};

export const barScale = {
  stacked: true,
  grid: {
    display: false
  },
  border: {
    width: 3,
    color: "black"
  },
  ticks: {
    ...blackFont,
    padding: 18,
    precision: 0
  }
};

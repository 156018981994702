import SNSecondaryNav from "components/ui/scenarios/NavBar/SecondaryNav";
import {
  GET_FINAL_EMAIL_ID,
  GET_FINAL_EMAIL_MENU_TITLE,
  GET_STARTED_EMAIL_ID,
  GET_STARTED_EMAIL_MENU_TITLE
} from "constants/scenario";
import useBundle from "hooks/useBundle";
import { useAppSelector } from "hooks/useRedux";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Outlet, useLocation } from "react-router-dom";
import useFireGAEventOnMount from "hooks/useFireGAEventOnMount";
import { selectAvailableEmails } from "redux/modules/scenario";
import { GAEventName } from "utils/gtag";
import DOMPurify from "dompurify";

export interface IProps {}

const ScenarioEmailInbox: FC<IProps> = props => {
  const availableEmails = useAppSelector(selectAvailableEmails);
  const intl = useIntl();
  const { data } = useBundle();

  useFireGAEventOnMount({ name: GAEventName.ScreenView, param: "Testee - Inbox" });

  const emailDetails = useMemo(
    () =>
      data?.emails.map(({ id, subject, sender, delta }) => ({
        id,
        label: subject,
        subLabel: sender.name,
        delta: delta,
        to: id
      })) ?? [],
    [data]
  );

  // Filter all the Emails by the availableEmails, in the same order as they display in availableEmails
  const inboxList = useMemo(() => {
    const inboxListInOrder = [];

    for (let email of availableEmails) {
      if (email.id === GET_STARTED_EMAIL_ID) {
        inboxListInOrder.push(GET_STARTED_EMAIL_MENU_TITLE(intl));

        continue;
      } else if (email.id === GET_FINAL_EMAIL_ID) {
        inboxListInOrder.push(GET_FINAL_EMAIL_MENU_TITLE(intl));
        continue;
      }

      const emailDetail = emailDetails.find(e => e.id === email.id)!;

      inboxListInOrder.push({
        ...emailDetail,
        label: emailDetail?.label && DOMPurify.sanitize(emailDetail.label),
        subLabel: emailDetail?.subLabel && DOMPurify.sanitize(emailDetail.subLabel)
      });
    }

    return inboxListInOrder.reverse();
  }, [emailDetails, availableEmails, intl]);

  return (
    <>
      <SNSecondaryNav items={inboxList} />
      {useLocation().pathname === `/scenario/${data?.id}/inbox` ? (
        <div className="grid h-full w-full place-items-center">
          <FormattedMessage
            id="scenarios.inbox.unread"
            values={{ count: availableEmails.filter(email => !email.hasBeenRead).length }}
          />
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default ScenarioEmailInbox;

import { ColumnDef } from "@tanstack/react-table";
import Table from "components/ui/Table/Table";
import { useDashboardControllerTestUserList } from "generated/api/apiComponents";
import { TestUsersResponseDto } from "generated/api/apiSchemas";
import { FC, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export interface IProps {
  testId: string;
  testName?: string;
}

const DashboardTestTable: FC<IProps> = props => {
  const { testId, testName } = props;
  const [page, setPage] = useState(1);

  const intl = useIntl();

  const { data, isFetching } = useDashboardControllerTestUserList(
    {
      pathParams: { testId },
      queryParams: { page }
    },
    {
      keepPreviousData: true
    }
  );

  const tableHeaders = useMemo<ColumnDef<TestUsersResponseDto>[]>(
    () => [
      {
        accessorKey: "username",
        header: intl.formatMessage({ id: "admin.sessions.details.participant" }),
        enableSorting: false
      },
      {
        id: "session",
        header: intl.formatMessage({ id: "admin.sessions.details.session" }),
        cell: () => testName
      },
      {
        accessorKey: "completionDate",
        header: intl.formatMessage({ id: "admin.sessions.details.sessionCompletion" }),
        cell: value => {
          const dateStr = value.getValue() as string;

          if (dateStr) {
            const dateStrUK = new Date(dateStr).toLocaleDateString();
            return dateStrUK;
          } else {
            return <FormattedMessage id="admin.sessions.details.notCompleted" />;
          }
        },
        enableSorting: false
      }
    ],
    [intl, testName]
  );

  return (
    <>
      <Table<TestUsersResponseDto>
        data={data?.data ?? []}
        serverSidePaginationResponse={data?.meta}
        onPageChange={setPage}
        columns={tableHeaders}
        isServerSide
      />
      {isFetching && (
        <p className="body-600 text-center">
          <FormattedMessage id="general.loading" />
        </p>
      )}
    </>
  );
};

export default DashboardTestTable;

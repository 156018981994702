import classNames from "classnames";
import CpniIcon from "components/ui/icons/CPNI";
import Link from "components/ui/Link/Link";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { fireGAEvent, GAEventName } from "utils/gtag";

interface IProps {
  className?: string;
}

const HomeFooter: FC<IProps> = ({ className }) => {
  const handleSignUpClick = () => {
    fireGAEvent({ name: GAEventName.ButtonSignUpImplementer, param: "footer" });
  };

  const handleFaqsClick = () => {
    fireGAEvent({ name: GAEventName.LinkFaqsSupport });
  };

  const handleTermsClick = () => {
    fireGAEvent({ name: GAEventName.LinkTermsConditions });
  };

  const handlePrivacyClick = () => {
    fireGAEvent({ name: GAEventName.LinkPrivacyPolicy });
  };

  const handleCookiesClick = () => {
    fireGAEvent({ name: GAEventName.LinkCookies });
  };

  return (
    <footer
      className={classNames(
        className,
        "items-start bg-neutral-600 px-[49px] pb-[35px] pt-[40px] text-white sm:flex sm:px-[84px] sm:pb-[78px] sm:pt-[47px]"
      )}
    >
      {/* PHIT / NPSA Logo */}
      <div className="mb-9 flex items-center sm:mb-0 sm:mr-[74px] lg:mr-[148px]">
        <CpniIcon className="mr-4 h-[35px] object-scale-down sm:h-[42px]" white />
      </div>

      {/* Section 1 */}
      <div className="mb-4 flex flex-col space-y-4 sm:mb-0 sm:mr-[110px] lg:mr-[220px]">
        {/* Sign up*/}
        <Link to="/sign-up/email" className="!no-underline" onClick={handleSignUpClick}>
          <span className="header-600">
            <FormattedMessage id="footer.signup" />
          </span>
        </Link>

        {/* Try the demo */}
        <Link to="/demo" className="!no-underline">
          <span className="header-600">
            <FormattedMessage id="general.try" />
          </span>
        </Link>

        {/* FAQ */}
        <a
          href="https://www.npsa.gov.uk/frequently-asked-questions-0"
          target="_blank"
          rel="noreferrer"
          className="header-600 no-underline"
          onClick={handleFaqsClick}
        >
          <FormattedMessage id="footer.faq" />
        </a>
      </div>

      {/* Section 2 */}
      <div className="flex flex-col space-y-4">
        {/* TCs */}
        <Link to="/terms-conditions" className="!no-underline" onClick={handleTermsClick}>
          <span className="header-600">
            <FormattedMessage id="footer.terms" />
          </span>
        </Link>

        {/* Privacy */}
        <a
          href="https://www.npsa.gov.uk/privacy-cookies-legal-information"
          target="_blank"
          rel="noreferrer"
          className="header-600 no-underline"
          onClick={handlePrivacyClick}
        >
          <FormattedMessage id="footer.privacy" />
        </a>

        {/* Cookies */}
        <a
          href="https://www.npsa.gov.uk/privacy-cookies-legal-information"
          target="_blank"
          rel="noreferrer"
          className="header-600 no-underline"
          onClick={handleCookiesClick}
        >
          <FormattedMessage id="footer.cookies" />
        </a>
      </div>
    </footer>
  );
};

export default HomeFooter;

import classNames from "classnames";
import { FC, PropsWithChildren } from "react";
import LoadingSpinner from "./Spinner";

interface IProps {
  isLoading: boolean;
  className?: string;
}

/** Shows spinner if isLoading flag is true, else show children. */
const LoadingWrapper: FC<PropsWithChildren<IProps>> = ({ isLoading, children, className }) => {
  if (isLoading)
    return (
      <div className={classNames("flex h-full w-full items-center justify-center", className)}>
        <LoadingSpinner />
      </div>
    );

  return <>{children}</>;
};

export default LoadingWrapper;

import React, { useCallback, useContext, useMemo, useState } from "react";

import { useDebounce } from "hooks/useDebounce";

/* eslint-disable no-unused-vars */
export enum ToastType {
  SUCCESS,
  ERROR
}
const OPEN_TIME = 5000; // 5 seconds

type ToastContextType = {
  openToast: (text: string, type?: ToastType, isAdmin?: boolean) => void;
  closeToast: () => void;
  details: { text: string; type: ToastType; open: boolean; className?: string };
};

export const ToastContext = React.createContext<ToastContextType>({
  openToast: () => {},
  closeToast: () => {},
  details: { text: "", type: ToastType.SUCCESS, open: false }
});

type ToastProviderProps = {
  children: React.ReactNode;
};

const ToastProvider = ({ children }: ToastProviderProps) => {
  const [toastDetails, setToastDetails] = useState<ToastContextType["details"]>({
    text: "",
    type: ToastType.SUCCESS,
    open: false,
    className: ""
  });

  const closeToast = useCallback(() => {
    setToastDetails(prev => ({
      ...prev,
      open: false,
      className: ""
    }));
  }, []);

  const closeModalAfterTime = useDebounce(closeToast, OPEN_TIME);

  const openToast = useCallback(
    (text: string, type = ToastType.SUCCESS, isAdmin = false) => {
      setToastDetails({
        text,
        type,
        open: true,
        className: isAdmin ? "!right-[10.5rem]" : ""
      });
      closeModalAfterTime();
    },
    [closeModalAfterTime]
  );

  const value = useMemo(
    () => ({
      openToast,
      closeToast,
      details: toastDetails
    }),
    [closeToast, openToast, toastDetails]
  );

  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>;
};

export const useToastContext = () => useContext(ToastContext);

export default ToastProvider;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "redux/store";

// Define a type for the slice state
export interface IAuthState {
  token: string;
  userGroups: string[];
  user: string;
}

// Define the initial state using that type
const initialState: IAuthState = {
  token: "",
  user: "",
  userGroups: []
};

export const authSlice = createSlice({
  name: "auth",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setUser: (state, action: PayloadAction<string>) => {
      state.user = action.payload;
    },
    setGroups: (state, action: PayloadAction<string[]>) => {
      state.userGroups = action.payload;
    }
  }
});

export const { setToken, setGroups, setUser } = authSlice.actions;

export const selectAuthToken = (state: RootState) => state.auth.token;

export const selectUser = (state: RootState) => state.auth.user;

export const selectAuthGroups = (state: RootState) => state.auth.userGroups;

export default authSlice.reducer;

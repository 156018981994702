import { FC } from "react";
import PhitIconBlack from "assets/phitNpsaLogo.png";
import PhitIconWhite from "assets/phitNpsaLogoWhite.png";
import { useIntl } from "react-intl";

interface IProps {
  className?: string;
  white?: boolean;
}

const CpniIcon: FC<IProps> = ({ className, white }) => {
  // i18n
  const intl = useIntl();

  let icon = PhitIconBlack;
  if (white) {
    icon = PhitIconWhite;
  }
  return <img src={icon} className={className} alt={intl.formatMessage({ id: "general.mainLogo.alt" })} />;
};

export default CpniIcon;

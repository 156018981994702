import Button from "components/ui/Button/Button";
import CognitoService from "helpers/cognito.service";
import FullPageContentLayout from "layouts/FullPageContent";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useRouteError } from "react-router-dom";
import { TestNotFoundException } from "../scenario/test-not-found.exception";
import TakenTestErrorWrapper from "./TakenTestErrorWrapper";
import TestNotFoundErrorWrapper from "./TestNotFoundErrorWrapper";

export interface IProps {}

const ErrorWrapper: FC<IProps> = props => {
  const error = useRouteError() as Record<string, any>;
  const navigate = useNavigate();

  // @ts-ignore
  const status = error?.status === "unknown" ? "500" : (error.status as number);

  const handleRefresh = async () => {
    if (status === 404) {
      navigate("/");
    } else {
      await CognitoService.signOut();

      window.location.reload();
    }
  };

  if (error?.name === "AlreadyCompletedTestException") return <TakenTestErrorWrapper />;
  if (error instanceof TestNotFoundException) return <TestNotFoundErrorWrapper />;

  return (
    <FullPageContentLayout hasError>
      <div className="m-auto max-w-[610px] text-center">
        <h1 className="header-900 mb-9">{status}</h1>

        <p className="body-900 mb-9">
          <FormattedMessage id={status === 404 ? "general.404.desc" : "errors.desc"} />
        </p>

        <Button onClick={handleRefresh} className="mx-auto">
          <FormattedMessage id={status === 404 ? "general.home" : "general.refresh"} />
        </Button>
      </div>
    </FullPageContentLayout>
  );
};

export default ErrorWrapper;

import { yupResolver } from "@hookform/resolvers/yup";
import PasswordMoreInfo from "components/signUp/passwordMoreInfo";
import Button from "components/ui/Button/Button";
import ErrorMessage from "components/ui/ErrorMessage/ErrorMessage";
import Checkbox from "components/ui/Form/Checkbox/Checkbox";
import Form from "components/ui/Form/Form";
import Input from "components/ui/Form/Input/Input";
import LinkBtn from "components/ui/LinkBtn/LinkBtn";
import LoadingWrapper from "components/ui/Loading/LoadingWrapper";
import yup from "config/yup";
import CognitoService from "helpers/cognito.service";
import { getSignUpErrorMessage } from "helpers/getters";
import { nanoid } from "nanoid";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useMatch, useNavigate } from "react-router-dom";
import { fireGAEvent, GAEventName } from "utils/gtag";

export type ImplementerSignUpInputs = {
  email: string;
  orgCode: string;
  password: string;
  role: string;
  organisation: string;
  agree: boolean;
};

const SignUpPage: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  // Router
  const navigate = useNavigate();
  const isEmailSignUp = useMatch("sign-up/email");

  // i18n
  const intl = useIntl();

  // Form
  const SignUpSchema = yup
    .object({
      ...(isEmailSignUp ? { email: yup.string().email().required() } : { orgCode: yup.string().required() }),
      password: yup.string().min(8).required(),
      role: yup.string(),
      organisation: yup.string(),
      agree: yup.bool().isTrue({ id: "general.error.agree.terms" })
    })
    .required();

  const methods = useForm<ImplementerSignUpInputs>({
    resolver: yupResolver(SignUpSchema)
  });

  /** Sign up a new user. */
  const onSubmit = async (data: ImplementerSignUpInputs) => {
    fireGAEvent({ name: GAEventName.ButtonSignUpImplementerConfirm });

    setIsLoading(true);
    try {
      // If email, generates an id, else use orgCode
      let username = data.orgCode;
      let redirectLink = "/admin/login";
      if (!username || username?.trim().length === 0) {
        username = nanoid();
        redirectLink = "/sign-up/thank-you";
      }

      await CognitoService.implementerSignUp({ ...data, username });
      navigate(redirectLink, {
        state: {
          username
        }
      });
    } catch (e) {
      setError(e as Error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fireGAEvent({
      name: GAEventName.ScreenView,
      param: `Implementer - Sign Up (${isEmailSignUp ? "Email" : "Organisation Code"})`
    });
  }, [isEmailSignUp]);

  return (
    <div className="pb-[233px] pt-20">
      {/* Card */}
      <div className="c-card mx-auto max-w-[775px] py-15">
        <LoadingWrapper isLoading={isLoading}>
          {/* Form */}
          <Form
            methods={methods}
            onSubmit={methods.handleSubmit(onSubmit)}
            className="mx-auto flex max-w-[535px] flex-col items-center space-y-9"
          >
            <h1 className="header-900">
              <FormattedMessage id={isEmailSignUp ? "home.signup.title.email" : "home.signup.title.code"} />
            </h1>

            {/* Email or Org. code */}
            <Input
              label={`${intl.formatMessage({ id: isEmailSignUp ? "general.email" : "general.org.code" })}*`}
              hideLabel
              placeholder={`${intl.formatMessage({ id: isEmailSignUp ? "general.email" : "general.org.code" })}*`}
              wrapperClassName="w-full"
              type={isEmailSignUp ? "email" : "text"}
              isUnderlined
              {...methods.register(isEmailSignUp ? "email" : "orgCode")}
            />

            {/* Password */}
            <Input
              label={`${intl.formatMessage({ id: "general.password" })}*`}
              hideLabel
              placeholder={`${intl.formatMessage({ id: "general.password" })}*`}
              wrapperClassName="w-full"
              type="password"
              moreInfo={<PasswordMoreInfo />}
              isUnderlined
              {...methods.register("password")}
            />

            <>
              {isEmailSignUp && (
                <>
                  {/* Role */}
                  <Input
                    label={intl.formatMessage({ id: "general.role" })}
                    hideLabel
                    placeholder={intl.formatMessage({ id: "general.role" })}
                    wrapperClassName="w-full"
                    isUnderlined
                    {...methods.register("role")}
                  />

                  {/* Organisation */}
                  <Input
                    label={`${intl.formatMessage({ id: "general.organisation" })}`}
                    hideLabel
                    placeholder={`${intl.formatMessage({ id: "general.organisation" })}`}
                    wrapperClassName="w-full"
                    isUnderlined
                    {...methods.register("organisation")}
                  />
                </>
              )}
            </>

            {/* Agree */}
            <Checkbox {...methods.register("agree")} wrapperClassName="max-w-[440px]">
              <p className="ml-8 font-medium text-neutral-700 opacity-60">
                <FormattedMessage
                  id="home.signup.agree.label"
                  values={{
                    a: chunks => (
                      <Link to="/terms-conditions" target="_blank" className="underline">
                        {chunks}
                      </Link>
                    )
                  }}
                />
              </p>
            </Checkbox>

            {/* Email/org code toggle */}
            <LinkBtn to={`/sign-up/${isEmailSignUp ? "code" : "email"}`} variant="secondary">
              <FormattedMessage id="home.signup.with" />{" "}
              {isEmailSignUp
                ? intl.formatMessage({ id: "home.signup.with.org.code" })
                : intl.formatMessage({ id: "home.signup.with.email" })}
            </LinkBtn>

            {/* Submit */}
            <Button type="submit">
              <FormattedMessage id="general.signup" />
            </Button>

            {error && <ErrorMessage error={intl.formatMessage({ id: getSignUpErrorMessage(error) })} />}
          </Form>
        </LoadingWrapper>
      </div>
    </div>
  );
};

export default SignUpPage;

import classNames from "classnames";
import { IMAGE_REGEX } from "constants/scenario";
import { useOnClickOutside } from "helpers/click";
import { FC, useRef } from "react";
import { useIntl } from "react-intl";

interface IProps {
  file: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

export const Modal: FC<IProps> = ({ file, visible, setVisible }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  // i18n
  const intl = useIntl();

  useOnClickOutside(modalRef, () => setVisible(false));

  return (
    <div
      className={classNames(
        "absolute left-1/2 top-1/2 -z-10 h-[80vh] w-full max-w-5xl -translate-x-1/2 -translate-y-1/2 transform place-items-center border-t-4 border-primary-500 bg-neutral-200 pt-14 opacity-0 shadow-sm transition-opacity duration-300",
        visible && "z-40 opacity-100"
      )}
      data-testid="dialog"
      ref={modalRef}
    >
      {/* Close button */}
      <button
        className="absolute right-0 top-0 m-2 px-2 pb-1 text-2xl"
        onClick={() => setVisible(!visible)}
        aria-label={intl.formatMessage({ id: "modal.close" })}
      >
        ×
      </button>

      {/* Show image */}
      {IMAGE_REGEX.exec(file) && <img className="h-full w-full object-contain" src={file} alt={file} />}

      {/* Show PDF */}
      {file.includes(".pdf") && (
        <embed
          className="h-full w-full"
          type="application/pdf"
          title={intl.formatMessage({ id: "scenarios.modal.iframe.pdf.title" })}
          src={file}
        />
      )}
    </div>
  );
};

export default Modal;

import { Amplify } from "aws-amplify";
import { COGNITO_REGION, COGNITO_USER_POOL_ID, COGNITO_WEB_CLIENT_ID } from "constants/env";

const configureAmplify = () => {
  Amplify.configure({
    Auth: {
      // Amazon Cognito Identity Pool ID
      // identityPoolId: COGNITO_IDENTITY_POOL_ID,
      identityPoolId: "", // Not in use
      // Amazon Cognito Region
      region: COGNITO_REGION,
      // Amazon Cognito User Pool ID
      userPoolId: COGNITO_USER_POOL_ID,
      // Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: COGNITO_WEB_CLIENT_ID
    }
  });
};

export default configureAmplify;

import LinkBtn from "components/ui/LinkBtn/LinkBtn";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import useFireGAEventOnMount from "hooks/useFireGAEventOnMount";
import { GAEventName } from "utils/gtag";
import { ModalButtonContainer } from "components/ui/Modal/Modal";

export interface IProps {}

const TesteeForgottenPassword: FC<IProps> = props => {
  useFireGAEventOnMount({ name: GAEventName.ScreenView, param: "Testee - Forgotten Password" });

  return (
    <div className="m-auto w-[610px] text-center">
      <h1 className="header-900 mb-9">
        <FormattedMessage id="trainee.forgotten.password.title" />
      </h1>

      <p className="body-900 mb-9">
        <FormattedMessage id="trainee.forgotten.password.desc.1" />
        <br />
        <FormattedMessage id="trainee.forgotten.password.desc.2" />
      </p>
      <ModalButtonContainer>
        <LinkBtn to="../login">
          <FormattedMessage id="trainee.back.to.login" />
        </LinkBtn>
      </ModalButtonContainer>
    </div>
  );
};

export default TesteeForgottenPassword;

import classNames from "classnames";
import { FC } from "react";
import { Player as LottiePlayer } from "@lottiefiles/react-lottie-player";
import LoadingSpinnerJSON from "assets/lottie/loading-spinner.json";
import { FormattedMessage } from "react-intl";

export interface IProps {
  className?: string;
}

const LoadingSpinner: FC<IProps> = props => {
  const { className } = props;

  return (
    <div className={classNames(className, "relative flex w-[250px] flex-col")}>
      <div className="my-auto">
        <LottiePlayer autoplay loop src={LoadingSpinnerJSON} />
      </div>
      <h1 className="body-900 mt-14 text-center">
        <FormattedMessage id="general.loading" />
      </h1>
    </div>
  );
};

export default LoadingSpinner;

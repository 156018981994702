import classNames from "classnames";
import { FC } from "react";

interface IProps {
  direction: "up" | "down" | "left" | "right";
  className?: string;
}

const ChevronBold: FC<IProps> = ({ direction, className }) => {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(
        {
          "rotate-90": direction === "down",
          "rotate-180": direction === "left",
          "-rotate-90": direction === "up"
        },
        className
      )}
    >
      <path
        d="M0.488348 0.468746C0.813864 0.156249 1.23993 2.78452e-07 1.66673 2.64886e-07C2.09352 2.51319e-07 2.51959 0.156251 2.84511 0.468745L9.51173 6.86872C10.1628 7.49302 10.1628 8.5069 9.51173 9.13128L2.84511 15.5313C2.19408 16.1562 1.1394 16.1562 0.488274 15.5313C-0.162759 14.907 -0.162759 13.8931 0.488274 13.2687L5.97657 7.99991L0.488349 2.73131C-0.162684 2.10701 -0.162684 1.09313 0.488348 0.468746Z"
        fill="#076EB2"
      />
    </svg>
  );
};

export default ChevronBold;

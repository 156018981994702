import classNames from "classnames";

const Avatar = ({
  name,
  size = "lg",
  variant = "primary"
}: {
  name: string;
  size?: "sm" | "lg";
  variant?: "primary" | "secondary";
}) => {
  const [firstName, lastName] = name.split(" ");

  return (
    <span
      className={classNames(
        size === "sm" && "h-9 w-9 text-lg",
        size === "lg" && "h-15 w-15  text-2xl",
        variant === "primary" && "bg-purple text-neutral-200",
        variant === "secondary" && "bg-workstyle text-black",
        "grid aspect-square place-items-center rounded-full uppercase"
      )}
    >
      {firstName?.charAt(0)}
      {lastName?.charAt(0)}
    </span>
  );
};

export default Avatar;

import { ITermsSection } from "../components/Section";

const listObj = {
  ol: (chunks: React.ReactNode[]) => <ul className="ml-6 list-alpha">{chunks}</ul>,
  li: (chunks: React.ReactNode[]) => <li className="pl-6">{chunks}</li>
};

const genEnqLink = {
  a: (chunks: React.ReactNode[]) => (
    <a
      target="_blank"
      href="https://www.npsa.gov.uk/general-enquiries-form"
      rel="noreferrer"
      className="text-primary-500 underline"
    >
      {chunks}
    </a>
  )
};

const termsArray: ITermsSection[] = [
  {
    title: {
      id: "terms.definition.title"
    },
    text: {
      id: "terms.definition.text"
    },
    table: {
      headers: [
        {
          id: "terms.definition.table.header.definition"
        },
        {
          id: "terms.definition.table.header.description"
        }
      ],
      rows: [
        { ids: ["terms.definition.table.header.account", "terms.definition.table.account"] },
        {
          ids: [
            "terms.definition.table.header.confidentialInformation",
            "terms.definition.table.confidentialInformation"
          ]
        },
        {
          ids: ["terms.definition.table.header.content", "terms.definition.table.content"]
        },
        {
          ids: ["terms.definition.table.header.ipr", "terms.definition.table.ipr"]
        },
        {
          ids: ["terms.definition.table.header.npsa", "terms.definition.table.npsa"]
        },
        {
          ids: ["terms.definition.table.header.outputContent", "terms.definition.table.outputContent"]
        },
        {
          ids: ["terms.definition.table.header.pilotPartner", "terms.definition.table.pilotPartner"]
        },
        {
          ids: ["terms.definition.table.header.pilotTerm", "terms.definition.table.pilotTerm"]
        },
        {
          ids: ["terms.definition.table.header.services", "terms.definition.table.services"]
        },
        {
          ids: ["terms.definition.table.header.user", "terms.definition.table.user"]
        },
        {
          ids: ["terms.definition.table.header.website", "terms.definition.table.website"]
        },
        {
          ids: ["terms.definition.table.header.yourContent", "terms.definition.table.yourContent"]
        }
      ]
    },
    sections: [
      {
        text: {
          id: "terms.definition.description"
        }
      }
    ]
  },
  {
    title: {
      id: "terms.purpose.title"
    },
    text: {
      id: "terms.purpose.text"
    }
  },

  {
    title: {
      id: "terms.services.title"
    },
    text: {
      id: "terms.services.text"
    }
  },
  {
    title: {
      id: "terms.rights.title"
    },
    text: {
      id: "terms.rights.text"
    }
  },
  {
    title: {
      id: "terms.warranty.title"
    },
    text: {
      id: "terms.warranty.text",
      values: listObj
    }
  },
  {
    title: {
      id: "terms.pilot.title"
    },
    text: {
      id: "terms.pilot.text",
      values: {
        b: chunks => <b className="font-bold">{chunks}</b>,
        ...listObj
      }
    }
  },
  {
    title: {
      id: "terms.personal.title"
    },
    text: {
      id: "terms.personal.text"
    }
  },
  {
    title: {
      id: "terms.disclaimer.title"
    },
    text: {
      id: "terms.disclaimer.text",
      values: genEnqLink
    }
  },
  {
    title: {
      id: "terms.hypertext.title"
    },
    text: {
      id: "terms.hypertext.text",
      values: {
        a: chunks => (
          <a
            target="_blank"
            href="https://www.npsa.gov.uk/privacy-cookies-and-legal-information"
            rel="noreferrer"
            className="text-primary-500 underline"
          >
            {chunks}
          </a>
        )
      }
    }
  },
  {
    title: {
      id: "terms.ip.title"
    },
    text: {
      id: "terms.ip.text"
    }
  },
  {
    title: {
      id: "terms.indemnity.title"
    },
    text: {
      id: "terms.indemnity.text",
      values: listObj
    }
  },
  {
    title: {
      id: "terms.liability.title"
    },
    text: {
      id: "terms.liability.text",
      values: listObj
    }
  },
  {
    title: {
      id: "terms.termination.title"
    },
    text: {
      id: "terms.termination.text",
      values: {
        b: chunks => <b className="font-bold">{chunks}</b>
      }
    }
  },
  {
    title: {
      id: "terms.consequences.title"
    },
    text: {
      id: "terms.consequences.text",
      values: listObj
    }
  },
  {
    title: {
      id: "terms.confidentiality.title"
    },
    text: {
      id: "terms.confidentiality.text",
      values: listObj
    }
  },
  {
    title: {
      id: "terms.freedom.title"
    },
    text: {
      id: "terms.freedom.text"
    }
  },
  {
    title: {
      id: "terms.general.title"
    },
    sections: [
      {
        title: {
          id: "terms.general.language.title"
        },
        text: {
          id: "terms.general.language.text"
        },
        level: 2
      },
      {
        title: {
          id: "terms.general.conflict.title"
        },
        text: {
          id: "terms.general.conflict.text"
        },
        level: 2
      },
      {
        title: {
          id: "terms.general.entire.title"
        },
        text: {
          id: "terms.general.entire.text"
        },
        level: 2
      },
      {
        title: {
          id: "terms.general.waiver.title"
        },
        text: {
          id: "terms.general.waiver.text"
        },
        level: 2
      },
      {
        title: {
          id: "terms.general.assignment.title"
        },
        text: {
          id: "terms.general.assignment.text"
        },
        level: 2
      },
      {
        title: {
          id: "terms.general.rightsThird.title"
        },
        text: {
          id: "terms.general.rightsThird.text"
        },
        level: 2
      },
      {
        title: {
          id: "terms.general.serverability.title"
        },
        text: {
          id: "terms.general.serverability.text"
        },
        level: 2
      },
      {
        title: {
          id: "terms.general.notices.title"
        },
        text: {
          id: "terms.general.notices.text",
          values: genEnqLink
        },
        level: 2
      },
      {
        title: {
          id: "terms.general.partnership.title"
        },
        text: {
          id: "terms.general.partnership.text"
        },
        level: 2
      },
      {
        title: {
          id: "terms.general.law.title"
        },
        text: {
          id: "terms.general.law.text",
          values: listObj
        },
        level: 2
      }
    ]
  }
];

export default termsArray;

export const NODE_ENV = process.env.NODE_ENV;

export const GTM_DEBUG = process.env.REACT_APP_GTM_DEBUG ?? "false";

// AWS Creds
export const COGNITO_IDENTITY_POOL_ID = process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID;
export const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION;
export const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID;
export const COGNITO_WEB_CLIENT_ID = process.env.REACT_APP_COGNITO_WEB_CLIENT_ID;

// Sentry
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

import LoadingWrapper from "components/ui/Loading/LoadingWrapper";
import CognitoService from "helpers/cognito.service";
import { FC, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const SignUpConfirmPage: FC = () => {
  // Router
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Confirm user sign-up
  useEffect(() => {
    (async () => {
      try {
        const username = searchParams.get("user_name");
        const code = searchParams.get("confirmation_code");

        if (!username || !code) return navigate("/");

        await CognitoService.confirmSignUp(username, code);

        navigate("/admin/login");
      } catch {
        navigate("/");
      }
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <LoadingWrapper isLoading={true} className="flex-1">
      <div />
    </LoadingWrapper>
  );
};

export default SignUpConfirmPage;

import { FC } from "react";
import { FormattedMessage } from "react-intl";
import useFireGAEventOnMount from "hooks/useFireGAEventOnMount";
import { GAEventName } from "utils/gtag";
import TermsSection from "./components/Section";
import termsArray from "./constants/termsContent";

const TermsConditionsPage: FC = () => {
  useFireGAEventOnMount({ name: GAEventName.ScreenView, param: "Terms and Conditions Page" });

  return (
    <>
      <div className="c-home-section py-15">
        <div className="mx-auto max-w-[561px] text-center text-white">
          <h1 className="header-1000">
            <FormattedMessage id="terms.title" />
          </h1>
        </div>
      </div>
      <div className="px-[49px] py-9 md:px-20 md:py-15">
        <section className="mb-15">
          <p className="body-900 whitespace-pre-line">
            <FormattedMessage
              id="terms.intro"
              values={{
                span: chunks => <span className="font-bold italic">{chunks}</span>
              }}
            />
          </p>
        </section>
        {termsArray.map((section, index) => (
          <TermsSection {...section} key={index} className="mb-15" />
        ))}
      </div>
    </>
  );
};

export default TermsConditionsPage;

import AllTests from "assets/demo/all-tests.webp";
import CreateTest from "assets/demo/create-test.webp";
import Dashboard from "assets/demo/dashboard.webp";
import Score from "assets/demo/score.webp";
import SignUp from "assets/demo/sign-up.webp";
import TakingTest from "assets/demo/taking-test.webp";
import Understand from "assets/demo/understand.webp";

export type DemoSlideName =
  | "organisation"
  | "tests"
  | "test-links"
  | "taking-tests"
  | "score"
  | "understand"
  | "dashboard";

export type DemoSlide = {
  name: DemoSlideName;
  image: string;
  alt: string;
  title: string;
  desc: string;
};

export const DEMO_SLIDES: DemoSlide[] = [
  {
    name: "organisation",
    image: SignUp,
    alt: "demo.slide.organisation.alt",
    title: "demo.slide.organisation.title",
    desc: "demo.slide.organisation.desc"
  },
  {
    name: "tests",
    image: CreateTest,
    alt: "demo.slide.tests.alt",
    title: "demo.slide.tests.title",
    desc: "demo.slide.tests.desc"
  },
  {
    name: "test-links",
    image: AllTests,
    alt: "demo.slide.test.links.alt",
    title: "demo.slide.test.links.title",
    desc: "demo.slide.test.links.desc"
  },
  {
    name: "taking-tests",
    image: TakingTest,
    alt: "demo.slide.taking.tests.alt",
    title: "demo.slide.taking.tests.title",
    desc: "demo.slide.taking.tests.desc"
  },
  {
    name: "score",
    image: Score,
    alt: "demo.slide.score.alt",
    title: "demo.slide.score.title",
    desc: "demo.slide.score.desc"
  },
  {
    name: "understand",
    image: Understand,
    alt: "demo.slide.understand.alt",
    title: "demo.slide.understand.title",
    desc: "demo.slide.understand.desc"
  },
  {
    name: "dashboard",
    image: Dashboard,
    alt: "demo.slide.dashboard.alt",
    title: "demo.slide.dashboard.title",
    desc: "demo.slide.dashboard.desc"
  }
];

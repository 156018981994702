import { FC } from "react";
import { NavLink, NavLinkProps, To, useNavigate } from "react-router-dom";
import classNames from "classnames";
import useInteraction from "hooks/useInteraction";

export interface IProps {
  className?: string;
  items: ({ key: string; label: string; className?: string } & Omit<NavLinkProps, "className">)[];
}

const SNMainNav: FC<IProps> = props => {
  const { className, items } = props;
  const navigate = useNavigate();

  // Mutation
  const registerInteraction = useInteraction();

  const handleLink = (key: string, to: To) => {
    registerInteraction("NAVIGATE", {
      payload: {
        key
      }
    });

    navigate(to);
  };

  return (
    <div className="h-full bg-black">
      <ul className={classNames(className, "flex flex-col gap-y-9 py-6")}>
        {items.map(({ key, label, className, ...rest }) => (
          <li key={key}>
            <NavLink
              onClick={() => handleLink(key, rest.to)}
              className={({ isActive }) =>
                classNames(
                  className,
                  "block py-0 pl-8 pr-4 text-lg font-bold tracking-wider",
                  isActive ? "text-white" : "text-neutral-500"
                )
              }
              {...rest}
            >
              {label}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SNMainNav;

import classNames from "classnames";
import { DetailedHTMLProps, HTMLAttributes } from "react";
import Chevron from "../icons/Chevron";
import { useIntl } from "react-intl";

export interface PaginationProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  pageIndex: number;

  nextPage: () => void;
  getCanNextPage: () => boolean;

  previousPage: () => void;
  getCanPreviousPage: () => boolean;

  getPageCount: () => number;
  setPageIndex: (index: number) => void;
}

const Pagination = ({
  nextPage,
  getCanNextPage,
  getPageCount,
  setPageIndex,
  previousPage,
  getCanPreviousPage,
  pageIndex,
  className,
  ...props
}: PaginationProps) => {
  const intl = useIntl();
  const currentPage = pageIndex + 1;

  if (getPageCount() > 1)
    return (
      <div {...props} className={classNames(className, "header-800 flex gap-5")}>
        <button
          onClick={previousPage}
          disabled={!getCanPreviousPage()}
          aria-label={intl.formatMessage({ id: "table.previousPage" })}
          className="text-primary-500"
        >
          <Chevron direction="right" />
        </button>
        {getPaginationItems(currentPage, getPageCount()).map((pageNumber, index) => {
          return (
            <button
              key={index}
              className={classNames(currentPage === pageNumber ? "text-black" : "text-neutral-500")}
              disabled={typeof pageNumber !== "number"}
              onClick={() => typeof pageNumber === "number" && setPageIndex(pageNumber)}
            >
              {pageNumber}
            </button>
          );
        })}
        <button
          onClick={nextPage}
          disabled={!getCanNextPage()}
          aria-label={intl.formatMessage({ id: "table.nextPage" })}
          className="text-primary-500"
        >
          <Chevron direction="left" />
        </button>
      </div>
    );
  else return null;
};

function getPaginationItems(current: number, max: number) {
  if (!current || !max) return [];

  const items: (string | number)[] = [1];

  if (current === 1 && max === 1) return items;
  if (current > 4) items.push("…");

  let r = 2;
  let r1 = current - r;
  let r2 = current + r;

  for (let i = r1 > 2 ? r1 : 2; i <= Math.min(max, r2); i++) items.push(i);

  if (r2 + 1 < max) items.push("…");
  if (r2 < max) items.push(max);

  return items;
}

export default Pagination;

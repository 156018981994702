import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MOTHERLOAD_CHEAT } from "constants/scenario";
import type { AppDispatch, RootState } from "redux/store";

const TICK_INTERVAL = MOTHERLOAD_CHEAT ? 1 : 1000; // 1s

// Define a type for the slice state
export interface IClockTickState {
  delta: number;
}

export interface IStartClockOptions {
  maxDelta?: number;
  callback: () => void;
  interval: (tick: number) => void;
}

// Define the initial state using that type
const initialState: IClockTickState = {
  delta: 0
};

export const clockTickSlice = createSlice({
  name: "clockTick",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    tick: (state, action: PayloadAction<number>) => {
      state.delta = action.payload;
    },
    reset: state => {
      state.delta = 0;
    }
  }
});

const { tick, reset } = clockTickSlice.actions;

let intervalId: NodeJS.Timeout;
let timeoutId: NodeJS.Timeout;

let startTime: number | null = null;
let previousInterval = 0;

// Action to start the clock
export const startClock = (options: IStartClockOptions) => (dispatch: AppDispatch) => {
  clearInterval(intervalId);

  /**
   * Poll every second to check how many intervals have passed
   * Each interval is represented by 1000 milliseconds
   *
   * - If the TICK_INTERVAL = 1000ms then *one* interval passes every second
   * - If the TICK_INTERVAL = 500ms then *two* intervals pass every second (speed the clock up for testing)
   *
   * As the setInterval() function can be paused while the browser doesn't have focus
   * @see https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#timeouts_in_inactive_tabs
   * Then we can't assume every poll was called for every second that has passed in real time.
   * So use performance.now() util to calculate how many real time milliseconds have passed
   * since the clock was originally started by the user.
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Performance/now
   * Why not use Date.now() instead?
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Performance/now#performance.now_vs._date.now
   */
  intervalId = setInterval(() => {
    const time = performance.now();

    if (!startTime) startTime = time;

    // Floor to nearest 1000
    const msElapsed = Math.floor((time - startTime) / TICK_INTERVAL) * 1000;

    // When more intervals have passed since the last poll, trigger a tick dispatch event
    if (msElapsed > previousInterval) {
      previousInterval = msElapsed;

      dispatch(tick(msElapsed));

      options.interval?.(msElapsed);
    }
  }, 1000);

  // When the maxTime changes, the clock resets remembering the time elapsed and maxTime required
  timeoutId = setTimeout(options.callback, options.maxDelta);
};

// Action to stop and reset the clock
export const stopClock = () => (dispatch: AppDispatch) => {
  clearInterval(intervalId);
  clearTimeout(timeoutId);

  startTime = null;
  previousInterval = 0;

  dispatch(reset());
};

// Action to update the clock when maxTime has been updated
export const updateClock = (options: Omit<IStartClockOptions, "interval">) => (dispatch: AppDispatch) => {
  clearTimeout(timeoutId);
  timeoutId = setTimeout(options.callback, options.maxDelta);
};

// Getter for the current clock tick
export const selectClockTick = (state: RootState) => state.clockTick.delta;

export default clockTickSlice.reducer;
